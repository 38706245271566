import { List } from "immutable"

export const getCustomerSourceIdentification = (
  attributesMapBySourceId,
  customerAttributes,
  sources,
) => {
  const identifiedInSources = sources.map(source => {
    const list = attributesMapBySourceId.get(source.id)
    if (List.isList(list)) {
      return list.some(src => customerAttributes.has(src.id))
    }
    return false
  })
  return identifiedInSources
}
