import {
  LeafOperator,
  ConditionSubject,
  datetimeSubjects,
  DataType,
} from "resources/webBanner/webBannerConditionTypes"
import { isOperatorAllowed } from "./utils"

export type SubjectDropdownOption = { value: ConditionSubject; label: string }
export const subjectOptions: SubjectDropdownOption[] = [
  { label: "Browser storage: cookie", value: "cookie" },
  { label: "Browser storage: local storage", value: "local_storage" },
  { label: "Browser: language", value: "browser_language" },
  { label: "Browser: name", value: "browser" },
  { label: "Device: operating system", value: "os" },
  { label: "Device: type", value: "device" },
  { label: "Google Tag Manager", value: "gtm" },
  { label: "Page: hostname", value: "hostname" },
  { label: "Page: pathname", value: "pathname" },
  { label: "Page: title", value: "page_title" },
  { label: "Page: URL", value: "url" },
  { label: "Server: HTTP request", value: "http_request" },
  { label: "Time: datetime", value: "current_datetime" },
  { label: "Time: day of the week", value: "current_day_of_week" },
  { label: "Time: hour of the day", value: "current_hour" },
  { label: "Traffic source: referrer", value: "referrer" },
  { label: "Traffic source: UTM campaign", value: "utm_campaign" },
  { label: "Traffic source: UTM content", value: "utm_content" },
  { label: "Traffic source: UTM medium", value: "utm_medium" },
  { label: "Traffic source: UTM source", value: "utm_source" },
  { label: "Traffic source: UTM term", value: "utm_term" },
  { label: "Show after: pageviews", value: "page_views_count" },
]
export const getSubjectOption = (subject: ConditionSubject | null) =>
  subjectOptions.find(({ value }) => value === subject) ?? null

export type StorageTypeOption = { value: DataType; label: string }
export const storageTypeOptions: StorageTypeOption[] = [
  { value: "boolean", label: "boolean" },
  { value: "datetime", label: "datetime" },
  { value: "number", label: "number" },
  { value: "relative_datetime", label: "relative datetime" },
  { value: "string", label: "string" },
]
export const getStorageTypeOption = (storageType?: DataType) =>
  storageTypeOptions.find(({ value }) => value === storageType) ?? null

export type OperatorDropdownOption = { value: LeafOperator; label: string; bool_value?: boolean }
const operatorOptions: OperatorDropdownOption[] = [
  { value: "is_ok", label: "response is OK" },
  { value: "is_set", label: "is set" },
  { value: "is_not_set", label: "is not set" },
  { value: "contains", label: "contains" },
  { value: "not_contains", label: "doesn't contain" },
  { value: "lower", label: "is less than" },
  { value: "greater", label: "is greater than" },
  { value: "between", label: "is between" },
  { value: "equals", label: "equals" },
  { value: "not_equals", label: "doesn't equal" },
  { value: "in", label: "equals any of" },
  { value: "not_in", label: "doesn't equal any of" },
]

export function getOperatorOptions(
  subject: ConditionSubject | null,
  type?: DataType,
): OperatorDropdownOption[] {
  // exceptions >:(
  if (subject === "current_hour") {
    return [
      { value: "equals", label: "equals" },
      { value: "not_equals", label: "doesn't equal" },
      { value: "greater", label: "since" },
      { value: "lower", label: "until" },
      { value: "between", label: "since / until" },
    ]
  }

  if (
    (subject && (datetimeSubjects as readonly string[]).includes(subject)) ||
    type === "datetime" ||
    type === "relative_datetime"
  ) {
    return [
      { value: "greater", label: "since" },
      { value: "lower", label: "until" },
      { value: "between", label: "since / until" },
    ]
  }

  if (type === "boolean") {
    return [
      { value: "equals", label: "yes", bool_value: true },
      { value: "equals", label: "no", bool_value: false },
    ]
  }
  // end exceptions

  return operatorOptions.filter(({ value }) => isOperatorAllowed(value, subject, type))
}

export const deviceOptions = [
  { value: "console", label: "Console" },
  { value: "desktop", label: "Desktop" },
  { value: "embedded", label: "Embedded" },
  { value: "mobile", label: "Mobile" },
  { value: "smarttv", label: "Smart TV" },
  { value: "tablet", label: "Tablet" },
  { value: "wearable", label: "Wearable" },
]

export const osOptions = [
  { value: "Android", label: "Android" },
  { value: "iOS", label: "iOS" },
  { value: "Mac OS", label: "Mac OS" },
  { value: "Windows", label: "Windows" },
  { value: "other", label: "other" },
]

export const browserOptions = [
  { value: "Chrome", label: "Chrome" },
  { value: "Edge", label: "Edge" },
  { value: "Firefox", label: "Firefox" },
  { value: "Opera", label: "Opera" },
  { value: "Safari", label: "Safari" },
  { value: "other", label: "other" },
]

export const dayOfWeekOptions = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" },
]

export const languageOptions = [
  { value: "ab", label: "Abkhazian (ab)" },
  { value: "aa", label: "Afar (aa)" },
  { value: "af", label: "Afrikaans (af)" },
  { value: "ak", label: "Akan (ak)" },
  { value: "sq", label: "Albanian (sq)" },
  { value: "am", label: "Amharic (am)" },
  { value: "ar", label: "Arabic (ar)" },
  { value: "an", label: "Aragonese (an)" },
  { value: "hy", label: "Armenian (hy)" },
  { value: "as", label: "Assamese (as)" },
  { value: "av", label: "Avaric (av)" },
  { value: "ae", label: "Avestan (ae)" },
  { value: "ay", label: "Aymara (ay)" },
  { value: "az", label: "Azerbaijani (az)" },
  { value: "bm", label: "Bambara (bm)" },
  { value: "ba", label: "Bashkir (ba)" },
  { value: "eu", label: "Basque (eu)" },
  { value: "be", label: "Belarusian (be)" },
  { value: "bn", label: "Bengali (Bangla) (bn)" },
  { value: "bh", label: "Bihari (bh)" },
  { value: "bi", label: "Bislama (bi)" },
  { value: "bs", label: "Bosnian (bs)" },
  { value: "br", label: "Breton (br)" },
  { value: "bg", label: "Bulgarian (bg)" },
  { value: "my", label: "Burmese (my)" },
  { value: "ca", label: "Catalan (ca)" },
  { value: "ch", label: "Chamorro (ch)" },
  { value: "ce", label: "Chechen (ce)" },
  { value: "ny", label: "Chichewa, Chewa, Nyanja (ny)" },
  { value: "zh", label: "Chinese (zh)" },
  { value: "cv", label: "Chuvash (cv)" },
  { value: "kw", label: "Cornish (kw)" },
  { value: "co", label: "Corsican (co)" },
  { value: "cr", label: "Cree (cr)" },
  { value: "hr", label: "Croatian (hr)" },
  { value: "cs", label: "Czech (cs)" },
  { value: "da", label: "Danish (da)" },
  { value: "dv", label: "Divehi, Dhivehi, Maldivian (dv)" },
  { value: "nl", label: "Dutch (nl)" },
  { value: "dz", label: "Dzongkha (dz)" },
  { value: "en", label: "English (en)" },
  { value: "eo", label: "Esperanto (eo)" },
  { value: "et", label: "Estonian (et)" },
  { value: "ee", label: "Ewe (ee)" },
  { value: "fo", label: "Faroese (fo)" },
  { value: "fj", label: "Fijian (fj)" },
  { value: "fi", label: "Finnish (fi)" },
  { value: "fr", label: "French (fr)" },
  { value: "ff", label: "Fula, Fulah, Pulaar, Pular (ff)" },
  { value: "gl", label: "Galician (gl)" },
  { value: "gd", label: "Gaelic (Scottish) (gd)" },
  { value: "gv", label: "Gaelic (Manx) (gv)" },
  { value: "ka", label: "Georgian (ka)" },
  { value: "de", label: "German (de)" },
  { value: "el", label: "Greek (el)" },
  { value: "kl", label: "Greenlandic (kl)" },
  { value: "gn", label: "Guarani (gn)" },
  { value: "gu", label: "Gujarati (gu)" },
  { value: "ht", label: "Haitian Creole (ht)" },
  { value: "ha", label: "Hausa (ha)" },
  { value: "he", label: "Hebrew (he)" },
  { value: "hz", label: "Herero (hz)" },
  { value: "hi", label: "Hindi (hi)" },
  { value: "ho", label: "Hiri Motu (ho)" },
  { value: "hu", label: "Hungarian (hu)" },
  { value: "is", label: "Icelandic (is)" },
  { value: "io", label: "Ido (io)" },
  { value: "ig", label: "Igbo (ig)" },
  { value: "id", label: "Indonesian (id)" },
  { value: "in", label: "Indonesian (in)" },
  { value: "ia", label: "Interlingua (ia)" },
  { value: "ie", label: "Interlingue (ie)" },
  { value: "iu", label: "Inuktitut (iu)" },
  { value: "ik", label: "Inupiak (ik)" },
  { value: "ga", label: "Irish (ga)" },
  { value: "it", label: "Italian (it)" },
  { value: "ja", label: "Japanese (ja)" },
  { value: "jv", label: "Javanese (jv)" },
  { value: "kl", label: "Kalaallisut, Greenlandic (kl)" },
  { value: "kn", label: "Kannada (kn)" },
  { value: "kr", label: "Kanuri (kr)" },
  { value: "ks", label: "Kashmiri (ks)" },
  { value: "kk", label: "Kazakh (kk)" },
  { value: "km", label: "Khmer (km)" },
  { value: "ki", label: "Kikuyu (ki)" },
  { value: "rw", label: "Kinyarwanda (Rwanda) (rw)" },
  { value: "rn", label: "Kirundi (rn)" },
  { value: "ky", label: "Kyrgyz (ky)" },
  { value: "kv", label: "Komi (kv)" },
  { value: "kg", label: "Kongo (kg)" },
  { value: "ko", label: "Korean (ko)" },
  { value: "ku", label: "Kurdish (ku)" },
  { value: "kj", label: "Kwanyama (kj)" },
  { value: "lo", label: "Lao (lo)" },
  { value: "la", label: "Latin (la)" },
  { value: "lv", label: "Latvian (Lettish) (lv)" },
  { value: "li", label: "Limburgish ( Limburger) (li)" },
  { value: "ln", label: "Lingala (ln)" },
  { value: "lt", label: "Lithuanian (lt)" },
  { value: "lu", label: "Luga-Katanga (lu)" },
  { value: "lg", label: "Luganda, Ganda (lg)" },
  { value: "lb", label: "Luxembourgish (lb)" },
  { value: "gv", label: "Manx (gv)" },
  { value: "mk", label: "Macedonian (mk)" },
  { value: "mg", label: "Malagasy (mg)" },
  { value: "ms", label: "Malay (ms)" },
  { value: "ml", label: "Malayalam (ml)" },
  { value: "mt", label: "Maltese (mt)" },
  { value: "mi", label: "Maori (mi)" },
  { value: "mr", label: "Marathi (mr)" },
  { value: "mh", label: "Marshallese (mh)" },
  { value: "mo", label: "Moldavian (mo)" },
  { value: "mn", label: "Mongolian (mn)" },
  { value: "na", label: "Nauru (na)" },
  { value: "nv", label: "Navajo (nv)" },
  { value: "ng", label: "Ndonga (ng)" },
  { value: "nd", label: "Northern Ndebele (nd)" },
  { value: "ne", label: "Nepali (ne)" },
  { value: "no", label: "Norwegian (no)" },
  { value: "nb", label: "Norwegian bokmål (nb)" },
  { value: "nn", label: "Norwegian nynorsk (nn)" },
  { value: "ii", label: "Nuosu (ii)" },
  { value: "oc", label: "Occitan (oc)" },
  { value: "oj", label: "Ojibwe (oj)" },
  { value: "cu", label: "Old Church Slavonic, Old Bulgarian (cu)" },
  { value: "or", label: "Oriya (or)" },
  { value: "om", label: "Oromo (Afaan Oromo) (om)" },
  { value: "os", label: "Ossetian (os)" },
  { value: "pi", label: "Pāli (pi)" },
  { value: "ps", label: "Pashto, Pushto (ps)" },
  { value: "fa", label: "Persian (Farsi) (fa)" },
  { value: "pl", label: "Polish (pl)" },
  { value: "pt", label: "Portuguese (pt)" },
  { value: "pa", label: "Punjabi (Eastern) (pa)" },
  { value: "qu", label: "Quechua (qu)" },
  { value: "rm", label: "Romansh (rm)" },
  { value: "ro", label: "Romanian (ro)" },
  { value: "ru", label: "Russian (ru)" },
  { value: "se", label: "Sami (se)" },
  { value: "sm", label: "Samoan (sm)" },
  { value: "sg", label: "Sango (sg)" },
  { value: "sa", label: "Sanskrit (sa)" },
  { value: "sr", label: "Serbian (sr)" },
  { value: "sh", label: "Serbo-Croatian (sh)" },
  { value: "st", label: "Sesotho (st)" },
  { value: "tn", label: "Setswana (tn)" },
  { value: "sn", label: "Shona (sn)" },
  { value: "ii", label: "Sichuan Yi (ii)" },
  { value: "sd", label: "Sindhi (sd)" },
  { value: "si", label: "Sinhalese (si)" },
  { value: "ss", label: "Siswati (ss)" },
  { value: "sk", label: "Slovak (sk)" },
  { value: "sl", label: "Slovenian (sl)" },
  { value: "so", label: "Somali (so)" },
  { value: "nr", label: "Southern Ndebele (nr)" },
  { value: "es", label: "Spanish (es)" },
  { value: "su", label: "Sundanese (su)" },
  { value: "sw", label: "Swahili (Kiswahili) (sw)" },
  { value: "ss", label: "Swati (ss)" },
  { value: "sv", label: "Swedish (sv)" },
  { value: "tl", label: "Tagalog (tl)" },
  { value: "ty", label: "Tahitian (ty)" },
  { value: "tg", label: "Tajik (tg)" },
  { value: "ta", label: "Tamil (ta)" },
  { value: "tt", label: "Tatar (tt)" },
  { value: "te", label: "Telugu (te)" },
  { value: "th", label: "Thai (th)" },
  { value: "bo", label: "Tibetan (bo)" },
  { value: "ti", label: "Tigrinya (ti)" },
  { value: "to", label: "Tonga (to)" },
  { value: "ts", label: "Tsonga (ts)" },
  { value: "tr", label: "Turkish (tr)" },
  { value: "tk", label: "Turkmen (tk)" },
  { value: "tw", label: "Twi (tw)" },
  { value: "ug", label: "Uyghur (ug)" },
  { value: "uk", label: "Ukrainian (uk)" },
  { value: "ur", label: "Urdu (ur)" },
  { value: "uz", label: "Uzbek (uz)" },
  { value: "ve", label: "Venda (ve)" },
  { value: "vi", label: "Vietnamese (vi)" },
  { value: "vo", label: "Volapük (vo)" },
  { value: "wa", label: "Wallon (wa)" },
  { value: "cy", label: "Welsh (cy)" },
  { value: "wo", label: "Wolof (wo)" },
  { value: "fy", label: "Western Frisian (fy)" },
  { value: "xh", label: "Xhosa (xh)" },
  { value: "yi", label: "Yiddish (yi)" },
  { value: "ji", label: "Yiddish (ji)" },
  { value: "yo", label: "Yoruba (yo)" },
  { value: "za", label: "Zhuang, Chuang (za)" },
  { value: "zu", label: "Zulu (zu)" },
]
