import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { Dispatch } from "redux"
import { OrderDir } from "types/util"
import {
  PopupWebBanner,
  PopupWebBannerCreatePayload,
  PopupWebBannerModifyPayload,
  PopupWebBannerSettingsModifyPayload,
  PopupWebBannerSettingsResponse,
  PopupWebBannerSort,
} from "./popupWBTypes"

export const POPUP_WEB_BANNERS = {
  ACTION: {
    FETCH_ALL: "POPUP_WEB_BANNERS_FETCH_ALL",
    RETRIEVE: "POPUP_WEB_BANNER_RETRIEVE",
    CREATE: "POPUP_WEB_BANNER_CREATE",
    COPY: "POPUP_WEB_BANNER_COPY",
    MODIFY: "POPUP_WEB_BANNER_MODIFY",
    DELETE: "POPUP_WEB_BANNER_DELETE",
    FETCH_SETTINGS: "POPUP_WEB_BANNERS_FETCH_SETTINGS",
    MODIFY_SETTINGS: "POPUP_WEB_BANNERS_MODIFY_SETTINGS",
    POPUP_WEB_BANNER_SORT: "POPUP_WEB_BANNER_SORT",
  },
} as const

export const fetchAllPopupWebBanners = () => async (dispatch: Dispatch) => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: POPUP_WEB_BANNERS.ACTION.FETCH_ALL,
    payload: {
      promise: caller
        .setEndpointCall((offset: number, limit: number) => api.popupWebBanner.list(offset, limit))
        .setDataPath("web_banners")
        .run(),
    },
  })
}

export const retrievePopupWebBanner = (id: PopupWebBanner["id"]) => async (dispatch: Dispatch) => {
  return dispatch({
    type: POPUP_WEB_BANNERS.ACTION.RETRIEVE,
    payload: {
      promise: api.popupWebBanner.retrieve(id),
    },
  })
}

export const createPopupWebBanner =
  (data: PopupWebBannerCreatePayload) => async (dispatch: Dispatch) => {
    return dispatch({
      type: POPUP_WEB_BANNERS.ACTION.CREATE,
      payload: {
        promise: api.popupWebBanner.create(data),
      },
    })
  }

export const copyPopupWebBanner = (id: PopupWebBanner["id"]) => async (dispatch: Dispatch) => {
  return dispatch({
    type: POPUP_WEB_BANNERS.ACTION.COPY,
    payload: {
      promise: api.popupWebBanner.copy(id),
    },
  })
}

export const modifyPopupWebBanner =
  (id: PopupWebBanner["id"], data: PopupWebBannerModifyPayload) => async (dispatch: Dispatch) => {
    return dispatch({
      type: POPUP_WEB_BANNERS.ACTION.MODIFY,
      payload: {
        promise: api.popupWebBanner.modify(id, data),
      },
    })
  }

export const deletePopupWebBanner = (id: PopupWebBanner["id"]) => async (dispatch: Dispatch) => {
  return dispatch({
    type: POPUP_WEB_BANNERS.ACTION.DELETE,
    meta: {
      webBannerId: id,
    },
    payload: {
      promise: api.popupWebBanner.delete(id),
    },
  })
}

export const fetchPopupWebBannersSettings = () => async (dispatch: Dispatch) =>
  dispatch({
    type: POPUP_WEB_BANNERS.ACTION.FETCH_SETTINGS,
    payload: {
      promise: api.popupWebBanner.settings.retrieve(),
    },
  })

export const modifyPopupWebBannersSettings =
  (data: PopupWebBannerSettingsModifyPayload) => async (dispatch: Dispatch) =>
    dispatch({
      type: POPUP_WEB_BANNERS.ACTION.MODIFY_SETTINGS,
      payload: {
        promise: api.popupWebBanner.settings.modify(data),
      },
    })

export const sortPopupWebBanners =
  (orderBy: PopupWebBannerSort, orderDir: OrderDir) => (dispatch: Dispatch) =>
    dispatch({
      type: sortPopupWebBannerType,
      payload: {
        orderBy,
        orderDir,
      },
    })

export const sortPopupWebBannerType = POPUP_WEB_BANNERS.ACTION.POPUP_WEB_BANNER_SORT
type SortPopupWebBannerListAction = {
  type: typeof sortPopupWebBannerType
  payload: {
    orderBy: PopupWebBannerSort
    orderDir: OrderDir
  }
}

export const deletePopupWebBannerFulfilledType =
  `${POPUP_WEB_BANNERS.ACTION.DELETE}_FULFILLED` as const
type DeletePopupWebBannerAction = {
  type: typeof deletePopupWebBannerFulfilledType
  meta: {
    webBannerId: PopupWebBanner["id"]
  }
  payload: { message: "OK" }
}

export const modifyPopupWebBannerFulfilledType =
  `${POPUP_WEB_BANNERS.ACTION.MODIFY}_FULFILLED` as const
type ModifyPopupWebBannerAction = {
  type: typeof modifyPopupWebBannerFulfilledType
  payload: {
    web_banner: PopupWebBanner
  }
}

export const createPopupWebBannerFulfilledType =
  `${POPUP_WEB_BANNERS.ACTION.CREATE}_FULFILLED` as const
type CreatePopupWebBannerAction = {
  type: typeof createPopupWebBannerFulfilledType
  payload: {
    web_banner: PopupWebBanner
  }
}

export const copyPopupWebBannerFulfilledType = `${POPUP_WEB_BANNERS.ACTION.COPY}_FULFILLED` as const
type CopyPopupWebBannerAction = {
  type: typeof copyPopupWebBannerFulfilledType
  payload: {
    web_banner: PopupWebBanner
  }
}

export const retrievePopupWebBannerFulfilledType =
  `${POPUP_WEB_BANNERS.ACTION.RETRIEVE}_FULFILLED` as const
type RetrievePopupWebBannerAction = {
  type: typeof retrievePopupWebBannerFulfilledType
  payload: {
    web_banner: PopupWebBanner
  }
}

export const fetchAllPopupWebBannersFulfilledType =
  `${POPUP_WEB_BANNERS.ACTION.FETCH_ALL}_FULFILLED` as const
type FetchAllPopupWebBannersAction = {
  type: typeof fetchAllPopupWebBannersFulfilledType
  payload: PopupWebBanner[]
}

export const fetchPopupWebBannersSettingsType =
  `${POPUP_WEB_BANNERS.ACTION.FETCH_SETTINGS}_FULFILLED` as const
type FetchPopupWebBannersSettingsAction = {
  type: typeof fetchPopupWebBannersSettingsType
  payload: PopupWebBannerSettingsResponse
}

export const modifyPopupWebBannerSettingsType =
  `${POPUP_WEB_BANNERS.ACTION.MODIFY_SETTINGS}_FULFILLED` as const
type ModifyPopupWebBannersSettingsAction = {
  type: typeof modifyPopupWebBannerSettingsType
  payload: PopupWebBannerSettingsResponse
}

export type PopupWebBannersAction =
  | FetchAllPopupWebBannersAction
  | CreatePopupWebBannerAction
  | ModifyPopupWebBannerAction
  | DeletePopupWebBannerAction
  | RetrievePopupWebBannerAction
  | FetchPopupWebBannersSettingsAction
  | ModifyPopupWebBannersSettingsAction
  | CopyPopupWebBannerAction
  | SortPopupWebBannerListAction
