import Modal from "components/UI/elements/Modal"
import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import styles from "./UpdatePromoCodesListModal.module.scss"
import { maxFileSize, required, requiredFile } from "helpers/validators.helper"
import FileField from "components/UI/elements/FileField/FileField"
import Button from "components/UI/elements/Button/Button"
import classnames from "classnames"
import EditableValue from "components/UI/components/EditableValue/EditableValue"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { TOAST } from "sharedConstants"
import DropCsvFile from "../DropCsvFile/DropCsvFile"
import { MAX_UPLOADED_FILE_SIZE } from "components/PromoCodes/PromoCodesLists/PromoCodesLists"

export type UpdatePromoCodesListFormValues = {
  file: FileList | null
}

type UpdatePromoCodesListModalProps = {
  open: boolean
  handleClose: () => void
  onFileUpload: (id: PromoCodesList["id"], file: FileList) => void
  onNameChange: (id: PromoCodesList["id"], name: PromoCodesList["name"]) => void
  promoCodesList: PromoCodesList | null
}

export default function UpdatePromoCodesListModal({
  open,
  handleClose,
  onFileUpload,
  promoCodesList,
  onNameChange,
}: UpdatePromoCodesListModalProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UpdatePromoCodesListFormValues>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()

  const closeModal = useCallback(() => {
    handleClose()
    reset()
  }, [handleClose, reset])

  const submitForm: SubmitHandler<UpdatePromoCodesListFormValues> = useCallback(
    async ({ file }) => {
      if (!isSubmitting && promoCodesList && file) {
        setIsSubmitting(true)
        try {
          await onFileUpload(promoCodesList.id, file)
          reset()
          setIsSubmitting(false)
        } catch (err: any) {
          setIsSubmitting(false)
        }
      }
    },
    [isSubmitting, promoCodesList, reset, onFileUpload],
  )

  const submitName = useCallback(
    async (name: string) => {
      if (!isSubmitting && promoCodesList && name) {
        setIsSubmitting(true)
        try {
          await onNameChange(promoCodesList.id, name)
          setIsSubmitting(false)
        } catch {
          setIsSubmitting(false)
        }
      }
    },
    [onNameChange, promoCodesList, isSubmitting],
  )

  const onFileDrop = (files: FileList) => {
    if (files && files[0]) {
      const file = files[0]
      if (file.type !== "text/csv") {
        dispatch(showToast("Only CSV files are supported.", TOAST.TYPE.ERROR))
        dispatch(showToast("Only CSV files are supported.", TOAST.TYPE.ERROR))
      } else {
        setValue("file", files)
      }
    }
  }

  return (
    <Modal open={open} handleClose={closeModal} title="Edit promo codes list" size="large">
      <div className={styles.nameFieldRow}>
        <EditableValue
          initValue={promoCodesList?.name ?? ""}
          onChange={submitName}
          className={styles.editableNameValue}
          inputClassName={styles.editableNameValueInput}
          validate={required}
        />
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={styles.fileFieldRow}>
          <DropCsvFile onFileDrop={onFileDrop} label="Add more promo codes">
            <div className={styles.fileFieldFlexWrapper}>
              <div>
                <FileField
                  error={errors.file?.message}
                  {...register("file", {
                    validate: value =>
                      requiredFile(value) || maxFileSize(MAX_UPLOADED_FILE_SIZE, value),
                  })}
                  accept=".csv"
                  className={styles.fileField}
                />
                <p className={styles.fileFieldDescription}>
                  CSV with 1 column with codes. Max size: 2MB
                </p>
              </div>
              <Button
                type="submit"
                size="small"
                color="primary"
                className={classnames(styles.uploadButton, { loading: isSubmitting })}
              >
                Upload
              </Button>
            </div>
          </DropCsvFile>
        </div>
      </form>
      <p className={styles.availablePromoCodeInfo}>
        <strong>Available promo codes:</strong> {promoCodesList?.promo_codes_count}
      </p>
    </Modal>
  )
}
