import React, { useCallback } from "react"
import styles from "./DimensionLeaf.module.scss"
import {
  areValuesCompatible,
  getDataTypeDefaultContext,
  getDataTypeDisabledOperations,
} from "../../utils"
import { assocPath as set } from "ramda"
import { DimensionOption } from "../types"
import SubAttributePicker from "../SubattributePicker/SubattributePicker"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import ConditionOperationDropdown from "pages/Segments/components/SegmentDetail/components/SegmentCondition/ConditionOperationDropdown/ConditionOperationDropdown"
import ValueContainer from "../../ValueContainer/ValueContainer"
import { fetchCustomerAttributeValues } from "helpers/attributeValue.helper"
import { Attribute } from "types/attributes"
import {
  DimensionConditionObject,
  DimensionLeafError,
} from "resources/segment/segment/segmentConditionsTypes"
import { OPERATION_CONTEXT_TYPE } from "resources/segment/segment/utilities/segmentOperationsConstants"

type DimensionLeafProps = {
  operationObject: DimensionConditionObject["condition"]
  onChange: (operationObject: DimensionConditionObject["condition"]) => void
  isEditable: boolean
  dimensions: DimensionOption[]
  subattributeIdsUsed: string[]
  attributeId: Attribute["id"] | null
  removeSelf: () => void
  error?: DimensionLeafError
}

export default function DimensionLeaf({
  operationObject,
  onChange,
  isEditable,
  dimensions,
  subattributeIdsUsed,
  attributeId,
  removeSelf,
  error,
}: DimensionLeafProps) {
  const setSubattributeId = useCallback(
    newAttributeId => {
      onChange({ sub_attribute_id: newAttributeId, operation: null })
    },
    [onChange],
  )

  const setOperation = useCallback(
    newOperation => {
      onChange({
        ...operationObject,
        operation: newOperation,
        value: areValuesCompatible(operationObject.operation, newOperation)
          ? operationObject.value
          : null,
      })
    },
    [onChange, operationObject],
  )

  const setValue = useCallback(
    newValue => {
      onChange(set(["value"], newValue, operationObject))
    },
    [onChange, operationObject],
  )

  const { sub_attribute_id } = operationObject
  const selectedDimension = dimensions.find(dim => dim.id === sub_attribute_id)
  const defaultContext = selectedDimension && getDataTypeDefaultContext(selectedDimension.data_type)
  const disabledOperations =
    selectedDimension && getDataTypeDisabledOperations(selectedDimension.data_type)

  return (
    <div className={styles.dimension}>
      <div className={styles.topRow}>
        <SubAttributePicker
          options={dimensions}
          selected={dimensions.find(dim => dim.id === operationObject.sub_attribute_id) ?? null}
          alreadySelectedIds={subattributeIdsUsed}
          isEditable={isEditable}
          errorMarkup={error?.sub_attribute_id}
          isVisible={true} // TODO:
          handleValueChange={setSubattributeId}
        />

        <ConditionOperationDropdown
          value={operationObject.operation}
          handleValueChange={setOperation}
          isDisabled={!isEditable || !sub_attribute_id}
          allowedContexts={[defaultContext, OPERATION_CONTEXT_TYPE.OTHER]}
          defaultContext={defaultContext}
          disabledOperations={disabledOperations}
          errorMarkup={sub_attribute_id && error?.operation}
          isVisible={true} // TODO:
        />

        {isEditable && removeSelf && (
          <div className={styles.verticalAlignmentWrapper}>
            <IconButton
              onClick={removeSelf}
              iconName="trash-alt"
              color={COLOR.RED}
              tooltip="Delete"
              withBackground
              size={SIZE.TAG}
            />
          </div>
        )}
      </div>

      {operationObject.operation && (
        <ValueContainer
          onChange={setValue}
          condition={operationObject}
          errors={
            error?.value
              ? [error.value]
              : error?.min_value || error?.max_value
              ? [error.min_value, error.max_value]
              : null
          }
          isVisible={true} // TODO:
          isEditable={isEditable}
          stringSelectOptionsFetch={() =>
            fetchCustomerAttributeValues(`${attributeId}.${sub_attribute_id}`)
          }
          dataType={selectedDimension?.data_type}
        />
      )}
    </div>
  )
}
