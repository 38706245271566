import { Record } from "immutable"

const User = Record({
  id: 0,
  name: "",
  email: "",
  role: "",
  disabled: 0,
  deleted: 0,
  password_reset_token: null,
  created: "",
  last_login: "",
  okta_enabled: 0,
})

export default User
