import { ATTRIBUTE, IDENTITY_GRAPH_COLORS } from "sharedConstants"
import { List, fromJS, Map } from "immutable"
import _map from "lodash/map"
import Attribute from "models/attribute.model"

const initialState = Map({
  isFetching: true,
  isFulfilled: false,
  data: List(),
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${ATTRIBUTE.ACTION.LIST}_PENDING`: {
      return state.set("isFetching", true)
    }
    case `${ATTRIBUTE.ACTION.LIST}_FULFILLED`: {
      let colorIndex = 0
      return state
        .set("isFetching", false)
        .set("isFulfilled", true)
        .set(
          "data",
          List(
            _map(payload, attr => {
              if (attr.is_used_in_stitching) {
                const color =
                  colorIndex < IDENTITY_GRAPH_COLORS.length
                    ? IDENTITY_GRAPH_COLORS[colorIndex]
                    : "#fe7f66"
                colorIndex++
                return new Attribute(
                  fromJS({
                    ...attr,
                    identity_graph_color: color,
                  }),
                )
              } else {
                return new Attribute(fromJS(attr))
              }
            }),
          ),
        )
    }
    case `${ATTRIBUTE.ACTION.MODIFY}_FULFILLED`:
      return state.set(
        "data",
        state.get("data").map(attribute => {
          if (attribute.id === payload.id) {
            return new Attribute(
              fromJS({ ...payload, identity_graph_color: attribute.identity_graph_color }),
            )
          }
          return attribute
        }),
      )
    default:
      return state
  }
}
