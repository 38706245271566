import React, { useCallback } from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import useTypedSelector from "hooks/useTypedSelector"
import { modifyAuthUser } from "actions/authenticatedUser.action"
import Button from "components/UI/elements/Button/Button"
import { useDispatch } from "react-redux"

const CURRENT_NEW_FEATURE = "users_activity"

export default function NewReleaseModal() {
  const { id, frontend_settings } = useTypedSelector(state => state.authenticatedUser.data)!
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(
      modifyAuthUser(id, {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      }),
    )
  }, [dispatch, frontend_settings, id])

  const lastReleaseModalShown = frontend_settings?.lastReleaseModalShown

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      title="Check out the updates:"
      handleClose={handleClose}
    >
      <div className={styles.container}>
        <h3>Users Activity tab</h3>
        <p>
          Update for admins: the new tab <strong>Administration → Users Activity</strong> shows how
          users use Meiro Business Explorer and its various features over time.{" "}
          <a
            href="https://www.meiro.io/product-updates/business-explorer/meiro-business-explorer-reporting-about-users-activity/"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </p>

        <div className={styles.buttonWrapper}>
          <Button color="primary" size="big" onClick={handleClose}>
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  )
}
