import React from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import CreatableSelect from "react-select/lib/Creatable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNil from "lodash/isNil"

import { selectStyles } from "helpers/customSelectStyle.helper"
import Avatar from "../Avatar"

import "./SelectField.scss"

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={["fas", "caret-down"]} className="caret-down caret-down-indicator" />
    </components.DropdownIndicator>
  )
}

export const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <FontAwesomeIcon icon={["far", "times"]} className="cross-icon" />
    </components.ClearIndicator>
  )
}

const SingleValue = props => {
  const { data } = props

  return (
    <components.SingleValue {...props}>
      <div className="select-single-value">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.hidden && <FontAwesomeIcon className="hidden-icon" icon={["far", "eye-slash"]} />}
        {data.label}
      </div>
    </components.SingleValue>
  )
}

export const AsyncSelectCountOption = props => {
  const { data } = props

  return (
    <components.Option {...props}>
      <div className="async_select__option">
        {data.label}
        {!_isNil(data.count) && <span className="count">({data.count})</span>}
      </div>
    </components.Option>
  )
}

export const AsyncSelectHighLowIndicatedOption = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="async_select__option">
        {data.label}
        {data.highest && <span className="count">(Most common)</span>}
      </div>
    </components.Option>
  )
}

const Option = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="select-option">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.hidden && <FontAwesomeIcon className="hidden-icon" icon={["far", "eye-slash"]} />}
        {data.label}
      </div>
    </components.Option>
  )
}

const SelectField = ({
  input,
  label,
  meta: { touched, error } = {},
  options,
  onChange = null,
  isSearchable = true,
  isClearable = false,
  isLoading = false,
  size = "medium",
  className = "",
  maxDropdownHeight = "300px",
  isCreatable = false,
  inputId,
  placeholder = "Select...",
  disabled = false,
  focusedBorderColor = "#FE7F66",
  noOptionsMessage,
  isMulti = false,
  isOptionSelected = undefined,
  hideErrorMessage = false,
}) => {
  const errorMessage = touched ? error : ""
  const SelectToRender = isCreatable ? CreatableSelect : Select
  return (
    <div
      className={`select-field ${className} ${errorMessage ? "error" : ""} ${
        disabled ? "disabled" : ""
      }`}
      data-testid="select-field-wrapper"
    >
      {label !== undefined && <label data-testid="label">{label}</label>}

      <SelectToRender
        value={input.value}
        onChange={
          onChange
            ? event => {
                input.onChange(event)
                onChange(event)
              }
            : input.onChange
        }
        options={options}
        styles={selectStyles(size, "all", maxDropdownHeight, focusedBorderColor)}
        simpleValue
        isSearchable={isCreatable ? true : isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        className="select-input"
        formatCreateLabel={value => `add "${value}"`}
        inputId={inputId}
        placeholder={placeholder}
        components={{
          SingleValue: SingleValue,
          Option: Option,
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: ClearIndicator,
        }}
        noOptionsMessage={() => {
          if (noOptionsMessage) {
            return noOptionsMessage
          } else {
            return isCreatable ? "Create new by typing" : "Empty"
          }
        }}
        isDisabled={disabled}
        classNamePrefix="react-select-redux-field"
        isMulti={isMulti}
        isOptionSelected={isOptionSelected}
      />
      {errorMessage && !hideErrorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  )
}

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCreatable: PropTypes.bool,
  size: PropTypes.string,
  maxDropdownHeight: PropTypes.string,
  inputId: PropTypes.string,
  focusedBorderColor: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
}

export default SelectField
