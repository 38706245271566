import { createSelector } from "reselect"
import _toInteger from "lodash/toInteger"
import _isNil from "lodash/isNil"
import _isNumber from "lodash/isNumber"
import { List } from "immutable"

import { parseConditionsOperationObject } from "resources/segment/segment/utilities/segmentConditionsUtils"
import { areAttributesFulfilled, getAttributesMapById } from "./attributes.selector"
import { OPERATION } from "resources/segment/segment/utilities/segmentOperationsConstants"

const ZERO_PREMISE_OPERATIONS = [
  OPERATION.STRING_NOT_EQUAL,
  OPERATION.NOT_CONTAIN,
  OPERATION.NUMBER_LOWER_THAN,
  OPERATION.IS_FALSE,
  OPERATION.IS_NOT_SET,
]

const getSegment = (state, id) => state.segments.get("data").find(sg => sg.id === _toInteger(id))

const getCustomersPerAttributeCounts = state =>
  state.longLoadingBar.get("customersPerAttributeCounts")

export const getCustomersCount = state => state.longLoadingBar.get("customersCount")
export const getCustomersCountFetching = state => state.longLoadingBar.get("customersCountFetching")

export const getTotalAttributesValuesCount = createSelector(
  [
    areAttributesFulfilled,
    getAttributesMapById,
    getSegment,
    getCustomersPerAttributeCounts,
    getCustomersCount,
  ],
  (
    areAttributesFulfilled,
    attributesMapById,
    segment,
    customersPerAttributeCounts,
    customersCount,
  ) => {
    if (segment && areAttributesFulfilled && _isNumber(customersCount)) {
      if (segment.hasIn(["settings", "conditions_operation"])) {
        const conditionsOperation = segment.getIn(["settings", "conditions_operation"])
        if (!_isNil(conditionsOperation)) {
          const { filters } = parseConditionsOperationObject(conditionsOperation, attributesMapById)
          let value = 0
          filters.forEach(filter => {
            const attributeId = filter.get("attribute_id")
            const attributeValue = customersPerAttributeCounts.getIn([attributeId, "count"])
            const filterConditions = filter.get("conditions")
            const subFilters = filter.get("subFilters")
            if (List.isList(filterConditions) && filterConditions.size > 0) {
              filterConditions.forEach(condition => {
                const op = condition.get("operation")
                if (ZERO_PREMISE_OPERATIONS.includes(op)) {
                  value += customersCount
                } else {
                  value += attributeValue
                }
              })
            } else if (List.isList(subFilters) && subFilters.size > 0) {
              subFilters.forEach(sf => {
                const sfConditions = sf.get("conditions")
                if (List.isList(sfConditions) && sfConditions.size > 0) {
                  sfConditions.forEach(condition => {
                    const op = condition.get("operation")
                    if (ZERO_PREMISE_OPERATIONS.includes(op)) {
                      value += customersCount
                    } else {
                      value += attributeValue
                    }
                  })
                }
              })
            }
          })
          return _isNumber(value) ? value : 0
        }
      }
    }
    return 0
  },
)
