import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import SmartSegmentSetupDetail from "./SmartSegmentSetupDetail/SmartSegmentSetupDetail"
import SmartSegmentsSetupList from "./SmartSegmentsSetupList/SmartSegmentsSetupList"

export default function SmartSegmentsSetup() {
  return (
    <Switch>
      <Route path={getRoutePath("setup.segments.smart")} exact component={SmartSegmentsSetupList} />
      <Route
        path={getRoutePath("setup.segments.smart.detail")}
        component={SmartSegmentSetupDetail}
      />
    </Switch>
  )
}
