import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map, List } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNil from "lodash/isNil"
import _toLower from "lodash/toLower"
import _isFunction from "lodash/isFunction"
import _size from "lodash/size"
import moment from "moment"
import SimpleBar from "simplebar-react"

import { getIconSrc } from "helpers/image.helper"
import {
  getCompoundAttributeSubAttribute,
  isAttributeCompound,
} from "helpers/compoundAttribute.helper"
import { shortenText } from "helpers/string.helper"
import AttributeBadge from "../../elements/AttributeBadge"
import Button from "../../elements/Button/Button"

import "./AttributePicker.scss"
import Tippy from "@tippyjs/react"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"

const DEFAULT_HEIGHT = 34

class AttributePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      editMode: false,
      searchText: "",
      searchResults: Map(),
      selectedSource: null,
      selectedLabel: null,
      height: DEFAULT_HEIGHT,
      attributesMapBySourceName: Map(),
      attributesMapByLabel: Map(),
      filterBy: "source",
      needsTooltip: true,
    }

    this.inputRef = React.createRef()
    this.wrapperRef = React.createRef()
    this.scrollToRef = React.createRef()
    this.dropdown = React.createRef()
    this.ghost = React.createRef()

    window.addEventListener("keyup", this.handleKeyUp, false)
    setTimeout(() => document.addEventListener("click", this.handleOutsideClick, false), 0)
  }

  componentDidMount() {
    if (this.props.focusOnLoad) {
      this.toggleEditMode()
    }
    this.setFilledTextareaHeight()
    this.setAttributesMapBySourceName()
    this.setAttributesMapByLabel()
  }

  componentDidUpdate(prevProps) {
    if (this.props.attributesMapByIdForSelect !== prevProps.attributesMapByIdForSelect) {
      this.setAttributesMapBySourceName()
      this.setAttributesMapByLabel()
    }
    const needsTooltip = this.ghost.current.clientHeight > this.inputRef.current.clientHeight
    if (this.state.needsTooltip !== needsTooltip) {
      this.setState({ needsTooltip })
    }
  }

  setAttributesMapBySourceName = () => {
    const { attributesMapByIdForSelect: attributes } = this.props

    const attributesMapBySourceName = Map().withMutations(map => {
      attributes.forEach(attr => {
        const sourceName = attr.getIn(["attribute_source", "name"])
        if (map.has(sourceName)) {
          // attributes Map already exists, push
          map.set(sourceName, map.get(sourceName).push(attr))
        } else {
          map.set(sourceName, List([attr]))
        }
      })
    })

    this.setState({ attributesMapBySourceName })
  }

  setAttributesMapByLabel = () => {
    const { attributesMapByIdForSelect: attributes } = this.props

    const attributesMapByLabel = Map().withMutations(map => {
      attributes.forEach(attr => {
        const labels = attr.tags
        if (labels) {
          labels.forEach(label => {
            if (map.has(label.get("name"))) {
              map.set(label.get("name"), map.get(label.get("name")).push(attr))
            } else {
              map.set(label.get("name"), List([attr]))
            }
          })
        }
      })
    })

    this.setState({ attributesMapByLabel })
  }

  setFilledTextareaHeight = () => {
    if (!this.props.fixedSize && !_isNil(this.inputRef.current)) {
      const element = this.ghost

      // + borders
      if (this.state.height !== element.current.clientHeight + 2) {
        this.setState({
          height: element.current.clientHeight + 2,
        })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    document.removeEventListener("click", this.handleOutsideClick, false)
  }

  clearPicker = () => {
    this.setState({
      editMode: false,
      searchText: "",
      searchResults: Map(),
      selectedSource: null,
      selectedLabel: null,
      filterBy: "source",
    })
    this.props.handleAttributeSelect(null)
    requestAnimationFrame(this.setFilledTextareaHeight)
  }

  handleKeyUp = evt => {
    const keys = {
      8: () => {
        if (this.state.editMode && !this.state.searchText) {
          this.backToFirstLevel()
          this.props.handleAttributeSelect(null)
        }
      },
      27: () => {
        if (this.state.editMode) {
          this.toggleEditMode()
        }
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  handleOutsideClick = evt => {
    if (this.state.editMode && !_isNil(this.wrapperRef.current)) {
      if (!this.wrapperRef.current.contains(evt.target)) {
        this.toggleEditMode()
      }
    }
  }

  toggleEditMode = () => {
    const { editMode, selectedSource, selectedLabel } = this.state
    const { attributesMapByIdForSelect, attributeId } = this.props
    const selectedAttribute = attributesMapByIdForSelect.get(attributeId)
    let selectedSourceBySelectedAttribute = selectedSource
    if (selectedAttribute && !selectedSource && !selectedLabel) {
      selectedSourceBySelectedAttribute = selectedAttribute.getIn(
        ["attribute_source", "name"],
        null,
      )
    }

    this.setState(
      {
        editMode: !editMode,
        searchText: "",
        selectedSource: selectedSourceBySelectedAttribute,
      },
      () => {
        if (!editMode && !_isNil(this.dropdown.current) && !_isNil(this.scrollToRef.current)) {
          this.dropdown.current.scrollTop = this.scrollToRef.current.offsetTop - 60
        }
        if (!editMode && !_isNil(this.inputRef.current)) {
          this.inputRef.current.focus()
        }
        if (editMode && _isFunction(this.props.onClose)) {
          this.props.onClose()
        }
        if (editMode) {
          requestAnimationFrame(this.setFilledTextareaHeight)
        }
      },
    )

    if (editMode && !_isNil(this.inputRef.current)) {
      this.inputRef.current.blur()
    }
  }

  _filterAttributesByName = name => {
    const { compoundAttributesHidden } = this.props
    const { attributesMapBySourceName, attributesMapByLabel, filterBy } = this.state
    let attributesToFilter =
      filterBy === "source" ? attributesMapBySourceName : attributesMapByLabel
    if (compoundAttributesHidden) {
      attributesToFilter = attributesToFilter.map(attrList =>
        attrList.filterNot(attr => isAttributeCompound(attr.data_type)),
      )
    }

    this.setState(
      {
        selectedLabel: null,
        selectedSource: null,
        searchText: name,
        searchResults: attributesToFilter.map(attrList =>
          attrList.filter(attr => _toLower(attr.name).includes(_toLower(name))),
        ),
      },
      this.setFilledTextareaHeight,
    )
  }

  handleInputChange = evt => {
    this._filterAttributesByName(evt.target.value)
  }

  handleInputClick = evt => {
    const { editMode } = this.state
    if (!editMode) {
      this.toggleEditMode()
    }
  }

  handleAttributeClick = attributeId => () => {
    this.props.handleAttributeSelect(attributeId)
    this.toggleEditMode()
  }

  backToFirstLevel = evt => {
    if (evt) {
      evt.stopPropagation()
    }
    this.setState({
      selectedSource: null,
    })
  }

  goToSecondLevel = sourceName => () => {
    this.setState({
      selectedSource: sourceName,
    })
  }

  renderAttrName = attr => {
    let examplesContent = <span>No examples</span>
    if (List.isList(attr.examples) && attr.examples.size > 0) {
      examplesContent = (
        <span>
          {attr.examples
            .map(v => getUserFriendlyValueFormat(v, attr.data_type))
            .toArray()
            .join(", ")}
        </span>
      )
    } else if (Map.isMap(attr.examples) && attr.examples.size > 0) {
      examplesContent = (
        <div className="compound-tooltip-content">
          {attr.examples
            .map((list, key) => {
              const subAttribute = getCompoundAttributeSubAttribute(key, attr.data_type)
              let values = "No examples"
              if (List.isList(list) && list.size > 0) {
                values = list
                  .map(v => getUserFriendlyValueFormat(v, subAttribute?.data_type ?? "string"))
                  .toArray()
                  .join(", ")
              }
              return (
                <span key={key}>
                  {subAttribute ? subAttribute.name : key}: {values}
                </span>
              )
            })
            .toArray()}
        </div>
      )
    }
    return (
      <>
        <Tippy
          className="attribute-examples-tooltip"
          placement="bottom-start"
          delay={[400, null]}
          content={examplesContent}
        >
          <span className="attrname" data-tip={attr.name} data-for={attr.id}>
            {attr.is_hidden === 1 && (
              <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye" />
            )}
            {attr.name}
          </span>
        </Tippy>
        {moment().diff(attr.created, "days") < 8 && (
          <AttributeBadge text="new" className={isAttributeCompound(attr.data_type) ? "mr" : ""} />
        )}
        {isAttributeCompound(attr.data_type) && <AttributeBadge text="compound" />}
      </>
    )
  }

  renderSearchResults = () => {
    const { searchResults } = this.state
    let noResults = true
    searchResults.forEach(attributes => {
      if (attributes.size > 0) {
        noResults = false
      }
    })

    if (noResults) {
      return <li className="no-results">No results found</li>
    }

    return searchResults
      .map((attributes, categoryName) => {
        if (attributes.size === 0) {
          return null
        }
        return (
          <li key={categoryName} className="source-name">
            {categoryName}
            <ul className="sublist">
              {attributes
                .map(attr => (
                  <li
                    key={attr.id}
                    className="compound"
                    onClick={this.handleAttributeClick(attr.id)}
                  >
                    {this.renderAttrName(attr)}
                  </li>
                ))
                .toArray()}
            </ul>
          </li>
        )
      })
      .toArray()
  }

  selectFilterBy = filterBy => () => {
    this.setState(
      {
        filterBy,
      },
      () => this._filterAttributesByName(this.state.searchText),
    )
  }

  render() {
    const {
      isEditable,
      attributesMapByIdForSelect,
      attributeId,
      showSource,
      showSourceLogo,
      fixedSize,
      compoundAttributesHidden,
      errorMarkup,
      isVisible,
      isClearable,
      renderSubmit = false,
      placeholder,
      inputTextLimit,
      className,
    } = this.props
    const {
      editMode,
      searchText,
      selectedSource,
      selectedLabel,
      height,
      attributesMapBySourceName,
      attributesMapByLabel,
      filterBy,
      needsTooltip,
    } = this.state

    let dropdownAlignment = "left"
    if (this.wrapperRef && this.wrapperRef.current) {
      const windowWidth = window.innerWidth
      const position = this.wrapperRef.current.getBoundingClientRect()
      if (position.x + 680 > windowWidth) {
        dropdownAlignment = "right"
      }
    }
    const selectedAttribute = attributesMapByIdForSelect.get(attributeId)
    let attributeName = ""
    let attributeSourceLogo = null
    if (selectedAttribute) {
      if (showSource) {
        attributeName = `${selectedAttribute.getIn(["attribute_source", "name"], null)}: ${
          selectedAttribute.name
        }`
      } else {
        attributeName = selectedAttribute.name
      }
      if (showSourceLogo) {
        let color = selectedAttribute.getIn(
          ["attribute_source", "frontend_settings", "color"],
          "primary",
        )
        attributeSourceLogo = (
          <div className={`source-bg ${color}`}>
            <img
              src={getIconSrc(
                {
                  primary: selectedAttribute.getIn([
                    "attribute_source",
                    "frontend_settings",
                    "icon",
                  ]),
                  secondary: _toLower(selectedAttribute.getIn(["attribute_source", "type"])),
                },
                selectedAttribute.getIn(["attribute_source", "frontend_settings", "alt_icon"]),
                true,
              )}
              alt="icon"
            />
          </div>
        )
      }
    }

    let inputValue = ""
    if (editMode) {
      inputValue = searchText
    } else {
      inputValue = attributeName
      if (inputTextLimit) {
        inputValue = shortenText(inputValue, inputTextLimit)
      }
    }

    let placeholderValue = placeholder ? placeholder : "Select attribute or type to search"
    if (editMode && attributeName) {
      placeholderValue = attributeName
    }

    let filteredAttributesMap =
      filterBy === "source" ? attributesMapBySourceName : attributesMapByLabel
    if (compoundAttributesHidden) {
      filteredAttributesMap = filteredAttributesMap.map(attrList =>
        attrList.filterNot(attr => isAttributeCompound(attr.data_type)),
      )
    }

    const [...categoryNames] = filteredAttributesMap.keys()
    const showCategoryAttributes =
      List.isList(filteredAttributesMap.get(selectedSource)) ||
      List.isList(filteredAttributesMap.get(selectedLabel))

    let categoryAttributesSize = 0
    if (showCategoryAttributes) {
      if (selectedSource) {
        categoryAttributesSize = filteredAttributesMap.get(selectedSource).size
      } else if (selectedLabel) {
        categoryAttributesSize = filteredAttributesMap.get(selectedLabel).size
      }
    }

    return (
      <div
        className={`filter-attribute-select-wrapper ${
          attributeSourceLogo !== null ? "has-source" : "no-source"
        } ${errorMarkup ? "error" : ""} ${isVisible ? "is-visible" : ""} ${
          renderSubmit ? "submit-rendered" : ""
        } ${fixedSize ? fixedSize : ""} ${editMode ? "is-open" : ""} ${
          className ? className : ""
        } ${dropdownAlignment}`}
        ref={this.wrapperRef}
      >
        <React.Fragment>
          {attributeSourceLogo}
          <div
            className={`ghost-field ${fixedSize ? fixedSize : ""} ${
              isClearable ? "clearable" : ""
            }`}
            ref={this.ghost}
            aria-hidden="true"
          >
            {inputValue || attributeName}
          </div>
          {fixedSize && (
            <Tippy
              content={inputValue}
              disabled={!needsTooltip || !inputValue}
              placement="top"
              delay={200}
            >
              <input
                type="text"
                value={inputValue}
                onChange={this.handleInputChange}
                onClick={this.handleInputClick}
                onKeyUp={this.setFilledTextareaHeight}
                disabled={!isEditable}
                placeholder={placeholderValue}
                className={`filter-attribute-select-input ${fixedSize ? fixedSize : ""}`}
                ref={this.inputRef}
                autoComplete="off"
              />
            </Tippy>
          )}
          {!fixedSize && (
            <textarea
              value={inputValue}
              onChange={this.handleInputChange}
              onClick={this.handleInputClick}
              onKeyUp={this.setFilledTextareaHeight}
              disabled={!isEditable}
              placeholder={placeholderValue}
              className={`filter-attribute-select-input ${isClearable ? "clearable" : ""}`}
              style={{
                height: `${height}px`,
              }}
              ref={this.inputRef}
            />
          )}
          <button
            type="button"
            className={`filter-attribute-select-button ${!editMode ? "click-through" : ""} ${
              fixedSize ? fixedSize : ""
            }`}
            disabled={!isEditable}
            onClick={this.toggleEditMode}
          >
            {editMode && <FontAwesomeIcon icon={["fas", "caret-up"]} className="caret-up" />}
            {!editMode && <FontAwesomeIcon icon={["fas", "caret-down"]} className="caret-down" />}
          </button>
          {isClearable && isEditable && _size(inputValue) !== 0 && (
            <button
              type="button"
              className="filter-attribute-clear-button"
              onClick={this.clearPicker}
            >
              <FontAwesomeIcon icon={["far", "times"]} />
            </button>
          )}
          {renderSubmit && (
            <Button
              color="primary"
              className={`${fixedSize ? fixedSize : ""} search-button`}
              type="submit"
            >
              <FontAwesomeIcon icon="search" />
            </Button>
          )}
        </React.Fragment>
        {editMode && (
          <div
            className={`filter-attribute-select-dropdown ${
              !searchText && selectedSource ? "attribute-selecting" : ""
            }`}
            ref={this.dropdown}
          >
            <div className="menu-header">
              {selectedSource && (
                <span className="menu-header-title" onClick={this.backToFirstLevel}>
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} className="chevron-left" />{" "}
                  {selectedSource}
                </span>
              )}
              {!selectedSource && (
                <span className="menu-header-title filter-by">
                  Filter by:
                  <div className="filter-type-picker-wrapper">
                    <span
                      onClick={this.selectFilterBy("source")}
                      className={filterBy === "source" ? "active" : ""}
                    >
                      Source
                    </span>
                    <span
                      onClick={this.selectFilterBy("label")}
                      className={filterBy === "label" ? "active" : ""}
                    >
                      Label
                    </span>
                  </div>
                </span>
              )}
            </div>
            <SimpleBar className="scrollable">
              <ul
                className={`menu-first-lvl ${
                  !searchText && !selectedSource ? "displayed" : "hidden"
                }`}
              >
                {categoryNames.length > 0 ? (
                  categoryNames.sort().map(categoryName => (
                    <li
                      key={categoryName}
                      className="source-name"
                      onClick={this.goToSecondLevel(categoryName)}
                    >
                      {categoryName}{" "}
                      <FontAwesomeIcon icon={["fas", "chevron-right"]} className="chevron-right" />
                    </li>
                  ))
                ) : (
                  <li className="source-name">No {filterBy} exists.</li>
                )}
              </ul>
              <div
                className={`menu-second-lvl ${
                  !searchText && selectedSource ? "displayed" : "hidden"
                }`}
              >
                <div className="menu-second-lvl-content">
                  {showCategoryAttributes && (
                    <ul className="first-col">
                      {filteredAttributesMap
                        .get(selectedSource)
                        .take(Math.ceil(categoryAttributesSize / 2))
                        .map((attr, index) => (
                          <li
                            key={attr.id}
                            className={attributeId === attr.id ? "compound active" : "compound"}
                            ref={attributeId === attr.id ? this.scrollToRef : null}
                            onClick={this.handleAttributeClick(attr.id)}
                          >
                            {this.renderAttrName(attr)}
                          </li>
                        ))
                        .toArray()}
                    </ul>
                  )}
                  {showCategoryAttributes && (
                    <ul className="second-col">
                      {filteredAttributesMap
                        .get(selectedSource)
                        .takeLast(Math.floor(categoryAttributesSize / 2))
                        .map((attr, index) => (
                          <li
                            key={attr.id}
                            className={attributeId === attr.id ? "compound active" : "compound"}
                            ref={attributeId === attr.id ? this.scrollToRef : null}
                            onClick={this.handleAttributeClick(attr.id)}
                          >
                            {this.renderAttrName(attr)}
                          </li>
                        ))
                        .toArray()}
                    </ul>
                  )}
                </div>
              </div>
              <ul className={`search-results ${searchText ? "displayed" : "hidden"}`}>
                {this.renderSearchResults()}
              </ul>
            </SimpleBar>
          </div>
        )}
      </div>
    )
  }
}

AttributePicker.defaultProps = {
  compoundAttributesHidden: false,
}

AttributePicker.propTypes = {
  attributeId: PropTypes.string,
  attributesMapByIdForSelect: PropTypes.instanceOf(Map).isRequired,
  isEditable: PropTypes.bool,
  handleAttributeSelect: PropTypes.func.isRequired,
  showSource: PropTypes.bool,
  showSourceLogo: PropTypes.bool,
  focusOnLoad: PropTypes.bool,
  onClose: PropTypes.func,
  fixedSize: PropTypes.string,
  compoundAttributesHidden: PropTypes.bool,
  errorMarkup: PropTypes.bool,
  isVisible: PropTypes.bool,
  isClearable: PropTypes.bool,
  renderSubmit: PropTypes.bool,
  placeholder: PropTypes.string,
  buttonMode: PropTypes.bool,
  buttonPlaceholder: PropTypes.string,
  className: PropTypes.string,
}

export default AttributePicker
