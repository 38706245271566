import styles from "./EmbeddedWebBannersList.module.scss"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import { useHistory } from "react-router-dom"
import { hasAccess } from "helpers/authenticatedUser.helper"
import {
  deleteEmbeddedWebBanner,
  fetchAllEmbeddedWebBanners,
  modifyEmbeddedWebBanner,
  sortEmbeddedWebBanners,
} from "resources/webBanner/embeddedWebBanner/embeddedWBActions"
import useTypedSelector from "hooks/useTypedSelector"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Paper from "components/UI/elements/Paper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, {
  RowMessage,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SortButton,
} from "components/UI/elements/Table"
import ToggleButton from "components/UI/elements/ToggleButton"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerSort,
} from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { showToast } from "actions/toast.action"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import SearchForm from "components/UI/components/SearchForm"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"
import { prop, sort, path } from "ramda"
import { OrderDir } from "types/util"
import { shortenText } from "helpers/string.helper"
import Tippy from "@tippyjs/react"
import { ascend, descend } from "utilities/comparators"

export default function EmbeddedWebBannersList() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { initialFetching, webBanners, orderBy, orderDir } = useTypedSelector(
    state => state.embeddedWebBanners,
  )
  const [deleteModal, setDeleteModal] = useState<{
    open: boolean
    banner: EmbeddedWebBanner | null
  }>({
    open: false,
    banner: null,
  })
  const searchFormValue: string | undefined = useSelector(
    // @ts-ignore
    state => state.form?.WebBannersListSearchForm?.values?.search,
  )

  useEffect(() => {
    dispatch(fetchAllEmbeddedWebBanners())
  }, [dispatch])

  const toggleBannerDisabled = useCallback(
    (banner: EmbeddedWebBanner) => async () => {
      await dispatch(modifyEmbeddedWebBanner(banner.id, { disabled: !banner.disabled }))
      dispatch(showToast(`Web Banner has been ${banner.disabled ? "enabled" : "disabled"}.`))
    },
    [dispatch],
  )

  const closeDeleteBannerModal = useCallback(() => {
    setDeleteModal({
      ...deleteModal,
      open: false,
    })
  }, [deleteModal])

  const deleteBanner = useCallback(async () => {
    await dispatch(deleteEmbeddedWebBanner(deleteModal.banner!.id))
    closeDeleteBannerModal()
    dispatch(showToast("Web Banner has been deleted."))
  }, [dispatch, deleteModal.banner, closeDeleteBannerModal])

  const setOrderByHandler = (orderByParam: EmbeddedWebBannerSort) => {
    let orderDirParam: OrderDir = "ASC"
    if (orderByParam === orderBy && orderDir === "ASC") {
      orderDirParam = "DESC"
    }
    dispatch(sortEmbeddedWebBanners(orderByParam, orderDirParam))
  }

  let filteredData
  if (searchFormValue) {
    filteredData = webBanners.filter(
      list =>
        list.name.toLowerCase().includes(searchFormValue.toLowerCase()) ||
        list.element_id.toLowerCase().includes(searchFormValue.toLowerCase()),
    )
  } else {
    filteredData = webBanners
  }

  let sortingProperty
  if (orderBy === "priority") {
    sortingProperty = path(["settings", "priority"])
  } else {
    // @ts-ignore
    sortingProperty = prop(String(orderBy))
  }

  if (orderDir === "ASC") {
    filteredData = sort(ascend(sortingProperty), filteredData)
  } else {
    filteredData = sort(descend(sortingProperty), filteredData)
  }

  return (
    <div className={styles.wrapper}>
      <PaperHeader titleText="Embedded Web Banners" size="small" className={styles.header}>
        <Button
          color="primary"
          type="button"
          size="small"
          onClick={() => {
            history.push(getRoutePath("personalization.embedded-web-banners.create"))
          }}
          disabled={!hasAccess.webBanners.edit()}
          className={styles.buttonRight}
        >
          + Create Embedded Web Banner
        </Button>
      </PaperHeader>
      <Paper className={styles.bannersBox}>
        <div className={styles.formHeader}>
          <h3>Embedded Web Banners</h3>
          <SearchForm
            placeholder="Search for name / element ID"
            form="WebBannersListSearchForm"
            className={styles.searchField}
          />
        </div>
        {initialFetching ? (
          <LoadingIndicator />
        ) : filteredData.length === 0 ? (
          <RowMessage>No banners found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th>
                <SortButton
                  column="name"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setOrderByHandler("name")}
                  label="Name"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="element_id"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setOrderByHandler("element_id")}
                  label="Element ID"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="priority"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setOrderByHandler("priority")}
                  label="Priority"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="disabled"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setOrderByHandler("disabled")}
                  label="Active"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="created"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setOrderByHandler("created")}
                  label="Creation Date"
                />
              </Th>
              <Th>&nbsp;</Th>
            </Thead>
            <Tbody>
              {filteredData.map(webBanner => (
                <React.Fragment key={webBanner.id}>
                  <Tr>
                    <Td textBigger textBlack textBold>
                      {webBanner.name}
                    </Td>
                    <Td textAlignRight>
                      {webBanner.element_id.length > 30 ? (
                        <Tippy
                          content={webBanner.element_id}
                          placement="bottom"
                          className={styles.elementIdTooltip}
                        >
                          {<span>{shortenText(webBanner.element_id, 30)}</span>}
                        </Tippy>
                      ) : (
                        webBanner.element_id
                      )}
                    </Td>
                    <Td textAlignRight className={styles.priorityColumn}>
                      {webBanner.settings.priority}
                    </Td>
                    <Td textAlignRight className={styles.toggleColumn}>
                      <ToggleButton
                        value={!webBanner.disabled}
                        handleToggle={toggleBannerDisabled(webBanner)}
                        disabled={!hasAccess.webBanners.edit()}
                      />
                    </Td>
                    <Td textAlignRight className={styles.createdColumn}>
                      {format(new Date(webBanner.created), DATEFNS.DATETIME_FORMAT)}
                    </Td>
                    <Td textAlignRight className={styles.actionsColumn}>
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.BLACK}
                        size={SIZE.TAG}
                        onClick={() => {
                          history.push(
                            getRoutePath("personalization.embedded-web-banners.detail", {
                              id: webBanner.id,
                            }),
                          )
                        }}
                        withBackground
                        iconName="pencil-alt"
                        tooltip="Edit"
                      />
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.RED}
                        size={SIZE.TAG}
                        onClick={() => {
                          setDeleteModal({
                            open: true,
                            banner: webBanner,
                          })
                        }}
                        withBackground
                        iconName="trash-alt"
                        tooltip="Delete"
                        className={styles.actionButtonMargin}
                        disabled={!hasAccess.webBanners.edit()}
                      />
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        )}
      </Paper>
      <ConfirmModal
        title="Are you sure?"
        open={deleteModal.open}
        type="delete"
        text={`Do you really want to delete web banner: ${deleteModal.banner?.name}?`}
        handleClose={closeDeleteBannerModal}
        handleConfirm={deleteBanner}
      />
    </div>
  )
}
