import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import IconButton, { COLOR } from "../IconButton"
import Button from "../Button/Button"

import "./SearchField.scss"

const SearchField = ({
  input,
  placeholder,
  handleClear,
  fullWidth,
  handleChange = () => {},
  autoFocus = false,
  size = "large",
  ...fieldAttrs
}) => {
  const onInputChange = input => evt => {
    input.onChange(evt.target.value)
    handleChange()
  }

  return (
    <div className={`search-field-wraper ${size}`} data-testid="wrapper">
      <div className="text-field search-field">
        <input
          {...input}
          onChange={onInputChange(input)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          data-testid="input-field"
          className={fullWidth ? "full-width" : "shrinked"}
          autoComplete="off"
          {...fieldAttrs}
        />
        {input.value && handleClear && (
          <IconButton
            type="button"
            color={COLOR.BLACK}
            className="clear-button"
            data-testid="clear-button"
            onClick={handleClear}
            iconName="times"
            iconStyle="far"
          />
        )}
        <Button color="primary" className="search-button" data-testid="search-button" type="submit">
          <FontAwesomeIcon icon="search" />
        </Button>
      </div>
    </div>
  )
}

SearchField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleClear: PropTypes.func,
  handleChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
}

export default SearchField
