import React, { Fragment, useCallback } from "react"
import { assocPath as set, path as get, remove } from "ramda"
import { DimensionOption, DimensionPath } from "../types"
import DimensionLeaf from "../DimensionLeaf/DimensionLeaf"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import { Attribute } from "types/attributes"
import { isDimensionBranch } from "resources/segment/segment/utilities/segmentConditionTypeChecks"
import {
  CompoundSegmentCondition,
  DimensionBranch as DimensionBranchType,
  DimensionTreeError,
} from "resources/segment/segment/segmentConditionsTypes"

type DimensionBranchProps = {
  path: DimensionPath
  condition: CompoundSegmentCondition
  onChange: (condition: CompoundSegmentCondition) => void
  isEditable: boolean
  dimensions: DimensionOption[]
  subattributeIdsUsed: string[]
  attributeId: Attribute["id"] | null
  dimensionsError?: DimensionTreeError
}

export default function DimensionBranch({
  path,
  condition,
  onChange,
  isEditable,
  dimensions,
  subattributeIdsUsed,
  attributeId,
  dimensionsError,
}: DimensionBranchProps) {
  const branch = get<DimensionBranchType>(path, condition)!
  const isTopLevel = path.length === 0

  const removeDimension = useCallback(
    (index: number) => {
      if (branch.operands.length > 2) {
        onChange(set([...path, "operands"], remove(index, 1, branch.operands), condition))
      } else {
        // Branch only has two operands
        const otherOperand = branch.operands[index === 1 ? 0 : 1]

        // Replace self in the tree with the remaining operand
        if (isTopLevel) {
          const { attribute_id, negation } = condition

          if (isDimensionBranch(otherOperand)) {
            onChange({
              attribute_id,
              negation,
              operation: otherOperand.operation,
              operands: otherOperand.operands,
            })
          } else {
            onChange({ attribute_id, negation, condition: otherOperand.condition })
          }
        } else {
          onChange(set(path, otherOperand, condition))
        }
      }
    },
    [branch.operands, condition, isTopLevel, onChange, path],
  )

  const toggleOperation = useCallback(() => {
    onChange(set([...path, "operation"], branch.operation === "and" ? "or" : "and", condition))
  }, [branch.operation, condition, onChange, path])

  return (
    <>
      {branch.operands.map((operand, index) => (
        <Fragment key={index}>
          {isDimensionBranch(operand) ? (
            <DimensionBranch
              path={path.concat(["operands", index])}
              condition={condition}
              onChange={onChange}
              isEditable={isEditable}
              dimensions={dimensions}
              subattributeIdsUsed={subattributeIdsUsed}
              attributeId={attributeId}
              dimensionsError={dimensionsError}
            />
          ) : (
            <DimensionLeaf
              operationObject={operand.condition}
              onChange={newOperationObject =>
                onChange(
                  set([...path, "operands", index, "condition"], newOperationObject, condition),
                )
              }
              isEditable={isEditable}
              dimensions={dimensions}
              subattributeIdsUsed={subattributeIdsUsed}
              attributeId={attributeId}
              removeSelf={() => removeDimension(index)}
              error={get(path.concat(["operands", index]), dimensionsError)}
            />
          )}
          {index < branch.operands.length - 1 && (
            <ToggleSwitch
              checked={branch.operation}
              disabled={!isEditable}
              handleToggle={toggleOperation}
              leftValue={"and"}
              rightValue={"or"}
              width="70px"
              size="tiny"
            />
          )}
        </Fragment>
      ))}
    </>
  )
}
