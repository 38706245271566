import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"
import _toLower from "lodash/toLower"
import _noop from "lodash/noop"
import PropTypes from "prop-types"
import { Map } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// ui elements
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import DestinationAttributes from "./DestinationAttributes"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"

// selectors
import {
  getExportDestinationsData,
  isExportDestinationsFulfilled,
} from "resources/exportDestination/exportDestinationSelectors"
import {
  getDataSourcesData,
  isDataSourcesFulfilled,
} from "resources/dataSource/dataSourceSelectors"
import { getAttributesMapById, areAttributesFulfilled } from "selectors/attributes.selector"

// actions
import { fetchExportDestinationsList } from "resources/exportDestination/exportDestinationActions"

// helpers, constants
import { MOMENT } from "sharedConstants"
import { getIconSrc } from "helpers/image.helper"

import "./SourcesDestinations.scss"
import Tippy from "@tippyjs/react"

class SourcesDestinations extends Component {
  componentDidMount() {
    const { fetchExportDestinationsList } = this.props

    fetchExportDestinationsList().catch(_noop)
  }

  render() {
    const {
      dataSources,
      isDataSourcesFulfilled,
      exportDestinations,
      isExportDestinationsFulfilled,
    } = this.props

    let dataSourcesList = null

    if (isDataSourcesFulfilled) {
      if (dataSources.size > 0) {
        dataSourcesList = dataSources
          .map(source => {
            return (
              <Tr key={source.id}>
                <Td className="icon">
                  <img
                    src={getIconSrc(
                      {
                        primary: source.getIn(["frontend_settings", "icon"]),
                        secondary: _toLower(source.type),
                      },
                      source.getIn(["frontend_settings", "alt_icon"]),
                    )}
                    alt="icon"
                  />
                </Td>
                <Td
                  className="name"
                  textBigger
                  textBlack
                  textBold
                  withNewBadge={moment().diff(source.created, "days") < 8}
                >
                  {source.name}
                </Td>
                <Td className="description">
                  {source.description ? `${source.description}` : "—"}
                </Td>
                <Td textAlignRight className="created">
                  {moment.utc(source.created).local().format(MOMENT.DATE_FORMAT)}
                </Td>
              </Tr>
            )
          })
          .toArray()
      }
    }

    let exportDestinationsList = null
    if (isExportDestinationsFulfilled) {
      if (exportDestinations.size > 0) {
        exportDestinationsList = exportDestinations
          .map(destination => {
            return (
              <Tr key={destination.id}>
                <Td className="icon">
                  <img
                    src={getIconSrc(
                      _toLower(destination.icon),
                      destination.getIn(["frontend_settings", "alt_icon"]),
                    )}
                    alt="icon"
                  />
                </Td>
                <Td textBigger textBlack textBold className="name">
                  {destination.name}
                </Td>
                <Td className="description">
                  {destination.description ? `${destination.description}` : "—"}
                </Td>
                <Td className="attribute-container">
                  <DestinationAttributes destination={destination} showAttributes="exported" />
                </Td>
                <Td className="attribute-container">
                  <DestinationAttributes destination={destination} showAttributes="mandatory" />
                </Td>
                <Td textAlignRight className="created">
                  {destination.last_export
                    ? moment.utc(destination.last_export).local().format(MOMENT.DATE_FORMAT)
                    : "never"}
                </Td>
              </Tr>
            )
          })
          .toArray()
      }
    }

    return (
      <React.Fragment>
        <section className="wrapper sources-destinations">
          <div className="connected-sources-container">
            <PaperHeader
              className="header connected-sources-list-header"
              titleText="connected sources"
            />
            <Paper hasHeader={true} noPaddingTop>
              {isDataSourcesFulfilled && dataSourcesList !== null && (
                <Table className="sources-table">
                  <Thead stickyHeader="sources-table-sticky-header">
                    <Th />
                    <Th className="service-name">Source</Th>
                    <Th className="description-col">description</Th>
                    <Th className="align-right date-added">date added</Th>
                  </Thead>
                  <Tbody>{dataSourcesList}</Tbody>
                </Table>
              )}
              {isDataSourcesFulfilled && !dataSourcesList && (
                <RowMessage>No connected source found.</RowMessage>
              )}
            </Paper>
          </div>
          <div className="connected-destinations-container">
            <PaperHeader
              className="header connected-destinations-list-header"
              titleText="connected destinations"
            />
            <Paper hasHeader={true} noPaddingTop>
              {isExportDestinationsFulfilled && exportDestinationsList !== null && (
                <Table className="destinations-table">
                  <Thead stickyHeader="destinations-table-sticky-header">
                    <Th />
                    <Th className="service-name">Destination</Th>
                    <Th className="description-col">description</Th>
                    <Th className="exported-attributes-col">
                      exported attributes{" "}
                      <Tippy
                        content="Attributes that will be exported to your chosen destination. This is a
                        mandatory field."
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={["fas", "info-circle"]}
                            data-tip
                            data-for="exported-attributes-tooltip"
                          />
                        </span>
                      </Tippy>
                    </Th>
                    <Th className="mandatory-attributes-col">
                      mandatory attributes{" "}
                      <Tippy
                        content="Attributes that must be present in order to export to the destination. This
                        is an optional field."
                      >
                        <span>
                          <FontAwesomeIcon icon={["fas", "info-circle"]} />
                        </span>
                      </Tippy>
                    </Th>
                    <Th textAlignRight className="date-added">
                      Last&nbsp;export
                    </Th>
                  </Thead>
                  <Tbody>{exportDestinationsList}</Tbody>
                </Table>
              )}
              {isExportDestinationsFulfilled && !exportDestinationsList && (
                <RowMessage>No connected destination found.</RowMessage>
              )}
            </Paper>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

SourcesDestinations.propTypes = {
  dataSources: PropTypes.instanceOf(Map).isRequired,
  isDataSourcesFulfilled: PropTypes.bool.isRequired,
  exportDestinations: PropTypes.instanceOf(Map).isRequired,
  isExportDestinationsFulfilled: PropTypes.bool.isRequired,
  attributesMap: PropTypes.instanceOf(Map).isRequired,
  areAttributesFulfilled: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    dataSources: getDataSourcesData(state),
    isDataSourcesFulfilled: isDataSourcesFulfilled(state),
    exportDestinations: getExportDestinationsData(state),
    isExportDestinationsFulfilled: isExportDestinationsFulfilled(state),
    attributesMap: getAttributesMapById(state),
    areAttributesFulfilled: areAttributesFulfilled(state),
  }
}

SourcesDestinations = connect(mapStateToProps, {
  fetchExportDestinationsList,
})(SourcesDestinations)

export default SourcesDestinations
