import { showToast } from "actions/toast.action"
import {
  deletePopupWebBanner,
  modifyPopupWebBanner,
  retrievePopupWebBanner,
} from "resources/webBanner/popupWebBanner/popupWBActions"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import useTypedSelector from "hooks/useTypedSelector"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import {
  WBMinimizedHideCondition,
  PopupWebBanner,
  PopupWebBannerRequestPayload,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import WebBannersForm, {
  POSITION_OPTIONS,
  PRIORITY_OPTIONS,
  HIDE_OPERATOR_OPTIONS,
  PopupWebBannersFormData,
  PopupWebBannersFormValues,
  ANIMATION_OPTIONS,
  DISPLAY_CONDITION_OPTIONS,
} from "../components/PopupWebBannersForm/PopupWebBannersForm"
import { FREQUENCY_CAP_TYPE_OPTIONS } from "../components/PopupWebBannerComponentsTypes"
import { getImgSrcFromHtml } from "resources/webBanner/popupWebBanner/getImgSrcFromHtml"
import _isEmpty from "lodash/isEmpty"

export default function PopupWebBannersDetail() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: PopupWebBanner["id"] }>()
  const webBanner = useTypedSelector(state =>
    state.popupWebBanners.webBanners.find(banner => banner.id === id),
  )

  useEffect(() => {
    if (!webBanner && !isDeleting) {
      dispatch(retrievePopupWebBanner(id))
    }
  }, [dispatch, id, webBanner, isDeleting])

  const editWebBanner = useCallback(
    async ({
      name,
      condition,
      frequency_cap,
      priority,
      position,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      minimized_width,
      close_button,
      minimized_close_button,
      disabled,
      minimized,
      minimized_image_local,
      minimized_image_name,
      minimized_image_remote,
      minimized_html,
      minimized_position,
      minimized_hide_values,
      minimized_hide_operator,
      display_minimized_first,
      margin_x,
      margin_y,
      minimized_margin_x,
      minimized_margin_y,
      animation,
      minimized_animation,
      display_on,
    }: PopupWebBannersFormData) => {
      const fe_settings = {
        width,
        minimized_width,
        closeButton: close_button,
        minimized_close_button,
        margins: {
          x: margin_x,
          y: margin_y,
          minimized_x: minimized_margin_x,
          minimized_y: minimized_margin_y,
        },
        animation,
        minimized_animation,
        display_on,
      }
      // destination_url needs to be present in image contents, otherwise it will be nullified :(
      const content: PopupWebBannerRequestPayload["settings"]["content"] = image_local
        ? {
            data: image_local,
            image_name: image_name!,
            type: "local_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : image_remote
        ? {
            image_url: image_remote,
            type: "remote_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : destination_url !== null
        ? {
            destination_url: destination_url === "" ? null : destination_url,
            type: webBanner?.settings.content.type,
          }
        : html
        ? { html, type: "html" }
        : {}
      const data: PopupWebBannerRequestPayload = {
        name,
        settings: {
          condition,
          frequency_cap,
          priority,
          position,
          fe_settings,
        },
        disabled,
      }
      if (content && !_isEmpty(content)) {
        // needs to be added optionally only when something changes, otherwise it removes
        // image from the storage ...
        data.settings.content = content
      }
      const minimizedHideCondition: WBMinimizedHideCondition | null = minimized_hide_operator
        ? {
            operator: minimized_hide_operator.value,
            subject: "url",
            values: minimized_hide_values,
          }
        : null
      data.settings.minimized_content = minimized_image_local
        ? {
            enabled: minimized,
            data: minimized_image_local,
            image_name: minimized_image_name!,
            type: "local_image",
            position: minimized_position,
            display_minimized_first,
            hide_condition: minimizedHideCondition,
          }
        : minimized_image_remote
        ? {
            enabled: minimized,
            image_url: minimized_image_remote,
            type: "remote_image",
            position: minimized_position,
            display_minimized_first,
            hide_condition: minimizedHideCondition,
          }
        : minimized_html
        ? {
            html: minimized_html,
            enabled: minimized,
            type: "html",
            position: minimized_position,
            display_minimized_first,
            hide_condition: minimizedHideCondition,
          }
        : {
            enabled: minimized,
            type: webBanner?.settings.minimized_content.type ?? "html",
            position: minimized_position,
            display_minimized_first,
            hide_condition: minimizedHideCondition,
          }
      await dispatch(modifyPopupWebBanner(id, data))
      history.push(getRoutePath("personalization.popup-web-banners"))
      dispatch(showToast("Web Banner modified."))
    },
    [dispatch, id, history, webBanner],
  )

  const deleteBanner = useCallback(async () => {
    setIsDeleting(true)
    try {
      await dispatch(deletePopupWebBanner(id))
      history.push(getRoutePath("personalization.popup-web-banners"))
      dispatch(showToast("Web Banner has been deleted."))
    } catch (err) {
      setIsDeleting(false)
    }
  }, [dispatch, history, id])

  const html = webBanner?.settings.content.type !== "html" ? "" : webBanner?.settings.content.html
  const minimized_html =
    webBanner?.settings.minimized_content?.type !== "html"
      ? ""
      : webBanner?.settings.minimized_content.html
  const initialValues = webBanner
    ? ({
        name: webBanner.name,
        condition: webBanner.settings.condition,
        frequency_cap_window_max_count:
          webBanner.settings.frequency_cap.per_user.window.max_display_count,
        frequency_cap_window_type: FREQUENCY_CAP_TYPE_OPTIONS.find(
          type => type.value === webBanner.settings.frequency_cap.per_user.window.type,
        ),
        frequency_cap_window_size:
          webBanner.settings.frequency_cap.per_user.window.type === "session"
            ? null
            : webBanner.settings.frequency_cap.per_user.window.size,
        frequency_cap_user_max_count: webBanner.settings.frequency_cap.per_user.max_display_count,
        frequency_cap_banner_max_count: webBanner.settings.frequency_cap.max_display_count,
        priority: PRIORITY_OPTIONS.find(prio => prio.value === webBanner.settings.priority),
        position: POSITION_OPTIONS.find(pos => pos.value === webBanner.settings.position),
        destination_url: webBanner.settings.content.destination_url,
        content_type: webBanner.settings.content.type,
        html,
        image_url: getImgSrcFromHtml(webBanner),
        width: webBanner.settings.fe_settings?.width,
        minimized_width: webBanner.settings.fe_settings?.minimized_width,
        close_button: {
          ...webBanner.settings.fe_settings?.closeButton,
          disabled: webBanner.settings.fe_settings?.closeButton?.disabled ?? false,
          css: webBanner.settings.fe_settings?.closeButton?.css ?? {
            color: "#222222",
            background_color: "#ffffff",
            border_color: "#cccccc",
          },
        },
        minimized_close_button: {
          ...webBanner.settings.fe_settings?.minimized_close_button,
          disabled: webBanner.settings.fe_settings?.minimized_close_button?.disabled ?? false,
          css: webBanner.settings.fe_settings?.minimized_close_button?.css ?? {
            color: "#222222",
            background_color: "#ffffff",
            border_color: "#cccccc",
          },
        },
        disabled: webBanner.disabled,
        minimized: webBanner.settings.minimized_content.enabled,
        minimized_html,
        minimized_image_url: getImgSrcFromHtml(webBanner, "minimized"),
        minimized_content_type: webBanner.settings.minimized_content.type,
        minimized_position: POSITION_OPTIONS.find(
          pos => pos.value === webBanner.settings.minimized_content.position,
        ),
        display_minimized_first: webBanner.settings.minimized_content.display_minimized_first,
        minimized_hide_operator: HIDE_OPERATOR_OPTIONS.find(
          prio => prio.value === webBanner.settings.minimized_content.hide_condition?.operator,
        ),
        minimized_hide_values: webBanner.settings.minimized_content.hide_condition?.values ?? [],
        margin_x: webBanner.settings.fe_settings?.margins?.x ?? 20,
        margin_y: webBanner.settings.fe_settings?.margins?.y ?? 20,
        minimized_margin_x: webBanner.settings.fe_settings?.margins?.minimized_x ?? 20,
        minimized_margin_y: webBanner.settings.fe_settings?.margins?.minimized_y ?? 20,
        animation: ANIMATION_OPTIONS.find(
          anim => anim.value === webBanner.settings.fe_settings?.animation,
        ),
        minimized_animation: ANIMATION_OPTIONS.find(
          anim => anim.value === webBanner.settings.fe_settings?.minimized_animation,
        ),
        display_on: {
          action: DISPLAY_CONDITION_OPTIONS.find(
            condition =>
              condition.value ===
              (webBanner.settings.fe_settings?.display_on?.action ?? "page_load"),
          ),
          value: webBanner.settings.fe_settings?.display_on?.value ?? null,
        },
      } as PopupWebBannersFormValues)
    : undefined

  if (!webBanner) return <LoadingIndicator />
  return (
    <>
      <WebBannersForm
        key={id}
        initialValues={initialValues}
        onSubmit={editWebBanner}
        onDelete={() => {
          setDeleteModalOpen(true)
        }}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete web banner?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={deleteBanner}
      />
    </>
  )
}
