import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { CSSTransition } from "react-transition-group"
import { Map } from "immutable"
import PropTypes from "prop-types"
import _isNumber from "lodash/isNumber"

// actions
import {
  showLongLoadingBar,
  hideLongLoadingBar,
  fetchCustomersPerAttributeCounts,
  fetchTotalCustomersCount,
} from "actions/longLoadingBar.action"

// selectors
import { getSegmentsNumbers } from "resources/segment/segmentNumbers/segmentNumbersSelectors"
import {
  getTotalAttributesValuesCount,
  getCustomersCount,
  getCustomersCountFetching,
} from "selectors/longLoadingBar.selector"

// helpers
import { abbreviateNumber } from "helpers/number.helper"

// ui components
import IconButton, { COLOR } from "components/UI/elements/IconButton"

import MeiroLabyrinthSrc from "images/meiro-labyrinth.svg"
import "./LongLoadingBar.scss"
import { getRoutePath } from "routes"

class LongLoadingBar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      closeButtonShow: false,
      scrollClass: "",
    }
    this.visibilityTimeout = null
    this.closeButtonTimeout = null
    this.barRef = React.createRef()
  }

  componentDidMount() {
    const {
      longLoadingBar,
      fetchCustomersPerAttributeCounts,
      fetchTotalCustomersCount,
      customersCount,
      customersCountFetching,
    } = this.props
    window.addEventListener("scroll", this.handleScroll)
    if (longLoadingBar.get("customersPerAttributeCounts").size === 0) {
      fetchCustomersPerAttributeCounts()
    }
    if (!_isNumber(customersCount) && !customersCountFetching) {
      fetchTotalCustomersCount()
    }
  }

  initVisibilityTimeout = () => {
    if (this.props.segmentNumbers.get("loading") && !this.visibilityTimeout) {
      this.visibilityTimeout = setTimeout(() => {
        if (this.props.segmentNumbers.get("loading")) {
          this.props.showLongLoadingBar("segmentNumbers")
        }
        clearTimeout(this.visibilityTimeout)
        this.visibilityTimeout = null
      }, 5000)
    }
  }

  componentDidUpdate(prevProps) {
    // close button show/hide
    if (this.props.longLoadingBar.get("isVisible") && !prevProps.longLoadingBar.get("isVisible")) {
      this.closeButtonTimeout = setTimeout(() => {
        this.setState({
          closeButtonShow: true,
        })
        clearTimeout(this.closeButtonTimeout)
        this.closeButtonTimeout = null
      }, 3000)
    } else if (!this.props.longLoadingBar.get("isVisible") && this.state.closeButtonShow) {
      this.setState({
        closeButtonShow: false,
      })
      clearTimeout(this.closeButtonTimeout)
      this.closeButtonTimeout = null
    }

    this.initVisibilityTimeout()

    // url change - segment detail <-> insights transition
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.longLoadingBar.get("isVisible") && this.visibilityTimeout) {
        clearTimeout(this.visibilityTimeout)
        this.visibilityTimeout = null
        this.initVisibilityTimeout()
      }
    }

    if (
      !this.props.segmentNumbers.get("loading") &&
      prevProps.segmentNumbers.get("loading") &&
      this.props.longLoadingBar.get("isVisible")
    ) {
      this.clearStuff("segmentNumbers")
    }

    // clone
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.clearStuff()
    }
  }

  clearStuff = (things = "all") => {
    clearTimeout(this.visibilityTimeout)
    this.visibilityTimeout = null
    if (things === "all") {
      clearTimeout(this.closeButtonTimeout)
      this.closeButtonTimeout = null
    }
    this.props.hideLongLoadingBar(things)
  }

  componentWillUnmount() {
    this.clearStuff()
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const { scrollClass } = this.state
    const yOffset = window.pageYOffset
    if (yOffset > 124 && scrollClass === "") {
      this.setState({ scrollClass: "scroll-rounded-box" })
    } else if (yOffset < 125 && scrollClass !== "") {
      this.setState({ scrollClass: "" })
    }
  }

  render() {
    const { longLoadingBar, hideLongLoadingBar, totalAttributesValuesCount } = this.props
    const { closeButtonShow, scrollClass } = this.state

    const isDataInsightsTab = this.props.location.pathname === getRoutePath("data.insights")

    return (
      <CSSTransition
        in={longLoadingBar.get("isVisible")}
        timeout={200}
        classNames="longloadingbaranimation"
        unmountOnExit
      >
        <div className={`long-loading-bar ${scrollClass}`} ref={this.barRef}>
          <div className="content">
            <div className="left-part">
              <img src={MeiroLabyrinthSrc} alt="meiro labyrinth" />
              <div className="messages">
                {isDataInsightsTab && (
                  <p className="main-message">
                    Wooha! Crunching all this data is going to take us a little while.
                  </p>
                )}
                {!isDataInsightsTab && (
                  <p className="main-message">
                    Wooha! You selected attributes that have{" "}
                    {totalAttributesValuesCount === 0 || !_isNumber(totalAttributesValuesCount) ? (
                      <strong>a lot of</strong>
                    ) : (
                      abbreviateNumber(totalAttributesValuesCount, true)
                    )}{" "}
                    values for customers in your segment – crunching all this data is going to take
                    us a little while.
                  </p>
                )}
                <p className="secondary-message">Please check back in a couple of minutes!</p>
              </div>
            </div>
            <div className="right-part">
              <CSSTransition in={closeButtonShow} timeout={200} classNames="fade" unmountOnExit>
                <IconButton
                  color={COLOR.GREY}
                  onClick={() => hideLongLoadingBar()}
                  iconStyle="far"
                  iconName="times"
                />
              </CSSTransition>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

LongLoadingBar.propTypes = {
  longLoadingBar: PropTypes.instanceOf(Map).isRequired,
  segmentNumbers: PropTypes.instanceOf(Map).isRequired,
  totalAttributesValuesCount: PropTypes.number,
  customersCount: PropTypes.number,
  customersCountFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  longLoadingBar: state.longLoadingBar,
  segmentNumbers: getSegmentsNumbers(state, ownProps.match.params.id),
  totalAttributesValuesCount: getTotalAttributesValuesCount(state, ownProps.match.params.id),
  customersCount: getCustomersCount(state),
  customersCountFetching: getCustomersCountFetching(state),
})

export default connect(mapStateToProps, {
  showLongLoadingBar,
  hideLongLoadingBar,
  fetchCustomersPerAttributeCounts,
  fetchTotalCustomersCount,
})(LongLoadingBar)
