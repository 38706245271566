import { api } from "api"
import { ATTRIBUTE } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchAttributesList = (showHidden = 0) => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: ATTRIBUTE.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.attribute.list(
              offset,
              limit,
              "name",
              "ASC",
              loadFullStructure,
              [],
              "",
              [],
              showHidden,
            ),
          )
          .setDataPath("attributes")
          .setLoadFullStructure(1)
          .run()
        return data
      })(),
    },
  })
}

export const modifyAttribute = (id, data) => async dispatch =>
  dispatch({
    type: ATTRIBUTE.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.attribute.modify(id, data)
        return response.attribute
      })(),
    },
  })
