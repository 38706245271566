import { SegmentConditionOperation } from "resources/segment/segment/segmentConditionsTypes"
import {
  OPERATION_CONTEXT_TYPE,
  OPERATION,
  OPERATION_CONTEXT_MAPPER,
  OPERATION_HAS_VALUE,
} from "resources/segment/segment/utilities/segmentOperationsConstants"
import { Attribute } from "types/attributes"

export function getDataTypeDefaultContext(dataType: Attribute["data_type"]) {
  switch (dataType) {
    case "int":
    case "float":
      return OPERATION_CONTEXT_TYPE.NUMBER
    case "bool":
      return OPERATION_CONTEXT_TYPE.OTHER
    case "date":
    case "datetime":
      return OPERATION_CONTEXT_TYPE.DATE
    default:
      return OPERATION_CONTEXT_TYPE.STRING
  }
}

export function getDataTypeDisabledOperations(dataType: Attribute["data_type"]) {
  return dataType === "bool" ? undefined : [OPERATION.IS_FALSE, OPERATION.IS_TRUE]
}

export function areValuesCompatible(
  oldOperation: SegmentConditionOperation | null,
  newOperation: SegmentConditionOperation,
) {
  return (
    oldOperation &&
    OPERATION_CONTEXT_MAPPER[oldOperation] === OPERATION_CONTEXT_MAPPER[newOperation] &&
    OPERATION_HAS_VALUE[oldOperation] === OPERATION_HAS_VALUE[newOperation]
  )
}
