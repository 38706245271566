import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _toString from "lodash/toString"
import _toLower from "lodash/toLower"
import _includes from "lodash/includes"
import _filter from "lodash/filter"
import _isObject from "lodash/isObject"
import _isFunction from "lodash/isFunction"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Map, List } from "immutable"
import Waypoint from "react-waypoint"

// ui components
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import PaperHeader from "components/UI/elements/PaperHeader"
import SearchForm from "components/UI/components/SearchForm"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import TagPicker from "components/UI/components/TagPicker"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  SortButton,
  RowMessage,
} from "components/UI/elements/Table"

// actions
import { setUiAttribute } from "actions/authenticatedUser.action"
import {
  fetchSegments,
  deleteSegment,
  setSegmentFilter,
} from "resources/segment/segment/segmentActions.deprecated"
import { createSegment } from "resources/segment/segment/segmentActions"
import { showToast } from "actions/toast.action"
import { setSortingOptions, setFilterByTags, setFilterAndSorting } from "actions/table.action"
import { fetchUsersAclList } from "actions/acl.action"

// selectors
import { getSegmentsList } from "resources/segment/segment/segmentSelectors"
import { getTagsMap, getTagsSortedByName } from "resources/tag/tagSelectors"
import {
  isExportDestinationsFulfilled,
  getExportDestinationsData,
} from "resources/exportDestination/exportDestinationSelectors"

// constants, helpers
import { MOMENT, ITEMS_PER_PAGE, MODAL, TOAST, PERMISSION } from "sharedConstants"
import { getRoutePath } from "routes"
import { usersPermission } from "helpers/authenticatedUser.helper"
import { getScheduleTextLegacy } from "resources/segment/segment/utilities/segmentSchedulesUtils"
import Username from "helpers/Username.helper"
import { getIconSrc } from "helpers/image.helper"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./CustomSegmentsList.scss"
import Tippy from "@tippyjs/react"
import { withRouter } from "react-router"

class SegmentsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCreatingSegment: false,
      deleteModal: {
        open: false,
        item: null,
      },
      isLoading: false,
    }
  }

  _initialSegmentsFetch = () => {
    const { filterValues, fetchSegments, setFilterAndSorting } = this.props
    const searchText = _get(filterValues, "search", "")
    const orderBy = _get(filterValues, "orderBy", "last_export")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const selectedTags = _get(filterValues, "selectedTags", [])
    let showMy = _get(filterValues, "showMy", 0)
    let showSharedWithMe = _get(filterValues, "showSharedWithMe", 0)
    let showForeign = _get(filterValues, "showForeign", 0)
    if (!showMy && !showSharedWithMe && !showForeign) {
      // fetch all by default
      showMy = showSharedWithMe = showForeign = 1
    }
    fetchSegments(
      0,
      ITEMS_PER_PAGE,
      orderBy,
      orderDir,
      searchText,
      selectedTags,
      showMy,
      showSharedWithMe,
      hasAccess.segments.listForeign() ? showForeign : 0,
    ).catch(_noop)
    setFilterAndSorting("SegmentFilter", orderBy, orderDir, selectedTags)
  }

  componentDidMount() {
    this._initialSegmentsFetch()
  }

  onSearchSubmit = filterName => {
    const { filterValues, fetchSegments } = this.props
    const orderBy = _get(filterValues, "orderBy", "last_export")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const selectedTags = _get(filterValues, "selectedTags", [])
    let showMy = _get(filterValues, "showMy", 0)
    let showSharedWithMe = _get(filterValues, "showSharedWithMe", 0)
    let showForeign = _get(filterValues, "showForeign", 0)
    if (!showMy && !showSharedWithMe && !showForeign) {
      // fetch all by default
      showMy = showSharedWithMe = showForeign = 1
    }
    fetchSegments(
      0,
      ITEMS_PER_PAGE,
      orderBy,
      orderDir,
      filterName,
      selectedTags,
      showMy,
      showSharedWithMe,
      hasAccess.segments.listForeign() ? showForeign : 0,
      true,
    ).catch(_noop)
  }

  createSegment = async () => {
    const { createSegment, fetchUsersAclList, authenticatedUser, history, showToast } = this.props

    this.setState({ isCreatingSegment: true })

    try {
      const {
        value: { segment },
      } = await createSegment({ name: "Untitled segment" }, "regular")
      await fetchUsersAclList(authenticatedUser.data.id)

      history.push(getRoutePath("segments.detail", { id: segment.id }))
      showToast(`Segment created.`, TOAST.TYPE.SUCCESS)
    } catch (error) {
      this.setState({ isCreatingSegment: false })
    }
  }

  deleteSegment = () => {
    const { item } = this.state.deleteModal
    const { deleteSegment, showToast, isSmart } = this.props
    deleteSegment(item.id)
      .then(() => {
        showToast(`${isSmart ? "Smart segment" : "Segment"} deleted.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
    this.closeDeleteSegmentModal()
  }

  openDeleteSegmentModal = segment => evt => {
    evt.preventDefault()
    this.setState({
      deleteModal: {
        open: true,
        item: segment,
      },
    })
  }

  closeDeleteSegmentModal = () => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        open: false,
      },
    })
  }

  _loadMoreSegments = () => {
    this.setState({ isLoading: true })
    const { segments, fetchSegments } = this.props
    const selectionSettings = segments.get("selectionSettings")
    fetchSegments(
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.limit,
      selectionSettings.order_by,
      selectionSettings.order_dir,
      selectionSettings.name_filter,
      selectionSettings.tag_ids,
      selectionSettings.show_my === true ? 1 : 0,
      selectionSettings.show_shared_with_me === true ? 1 : 0,
      selectionSettings.show_foreign === true ? 1 : 0,
    )
      .then(() => {
        this.setState({ isLoading: false })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  sortSegmentsBy = column => () => {
    const { filterValues, setSortingOptions, fetchSegments } = this.props
    const orderBy = _get(filterValues, "orderBy")
    const searchText = _get(filterValues, "search", "")
    const selectedTags = _get(filterValues, "selectedTags", [])
    let showMy = _get(filterValues, "showMy", 0)
    let showSharedWithMe = _get(filterValues, "showSharedWithMe", 0)
    let showForeign = _get(filterValues, "showForeign", 0)
    if (!showMy && !showSharedWithMe && !showForeign) {
      // fetch all by default
      showMy = showSharedWithMe = showForeign = 1
    }

    let fetchOrderDir = "ASC"
    if (orderBy === column) {
      // switch orderDir
      const orderDir = _get(filterValues, "orderDir", "DESC")
      fetchOrderDir = orderDir === "ASC" ? "DESC" : "ASC"
      setSortingOptions("SegmentFilter", column, fetchOrderDir)
    } else {
      setSortingOptions("SegmentFilter", column, "ASC")
    }
    fetchSegments(
      0,
      ITEMS_PER_PAGE,
      column,
      fetchOrderDir,
      searchText,
      selectedTags,
      showMy,
      showSharedWithMe,
      hasAccess.segments.listForeign() ? showForeign : 0,
      true,
    )
  }

  selectFilterTag = tagId => evt => {
    if (_isObject(evt) && _isFunction(evt.preventDefault)) {
      evt.preventDefault()
    }
    const { filterValues, setFilterByTags, fetchSegments } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])
    if (!_includes(selectedTags, tagId)) {
      const searchText = _get(filterValues, "search", "")
      const orderBy = _get(filterValues, "orderBy", "last_export")
      const orderDir = _get(filterValues, "orderDir", "DESC")
      let showMy = _get(filterValues, "showMy", 0)
      let showSharedWithMe = _get(filterValues, "showSharedWithMe", 0)
      let showForeign = _get(filterValues, "showForeign", 0)
      if (!showMy && !showSharedWithMe && !showForeign) {
        // fetch all by default
        showMy = showSharedWithMe = showForeign = 1
      }
      const newTags = [...selectedTags, tagId]
      fetchSegments(
        0,
        ITEMS_PER_PAGE,
        orderBy,
        orderDir,
        searchText,
        newTags,
        showMy,
        showSharedWithMe,
        hasAccess.segments.listForeign() ? showForeign : 0,
        true,
      )
      setFilterByTags("SegmentFilter", newTags)
    }
  }

  deselectFilterTag = tagId => () => {
    const { filterValues, setFilterByTags, fetchSegments } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])
    const searchText = _get(filterValues, "search", "")
    const orderBy = _get(filterValues, "orderBy", "last_export")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    let showMy = _get(filterValues, "showMy", 0)
    let showSharedWithMe = _get(filterValues, "showSharedWithMe", 0)
    let showForeign = _get(filterValues, "showForeign", 0)
    if (!showMy && !showSharedWithMe && !showForeign) {
      // fetch all by default
      showMy = showSharedWithMe = showForeign = 1
    }
    const newTags = _filter(selectedTags, id => id !== tagId)
    fetchSegments(
      0,
      ITEMS_PER_PAGE,
      orderBy,
      orderDir,
      searchText,
      newTags,
      showMy,
      showSharedWithMe,
      hasAccess.segments.listForeign() ? showForeign : 0,
      true,
    )
    setFilterByTags("SegmentFilter", newTags)
  }

  setSegmentFilter = filter => () => {
    const { filterValues, setSegmentFilter, fetchSegments } = this.props
    const searchText = _get(filterValues, "search", "")
    const orderBy = _get(filterValues, "orderBy", "last_export")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    let showMy = _get(filterValues, "showMy", 0)
    let showSharedWithMe = _get(filterValues, "showSharedWithMe", 0)
    let showForeign = _get(filterValues, "showForeign", 0)
    if (filter === "showMy") {
      showMy = showMy === 0 ? 1 : 0
      showSharedWithMe = showForeign = 0
      setSegmentFilter("showMy", showMy)
    } else if (filter === "showSharedWithMe") {
      showSharedWithMe = showSharedWithMe === 0 ? 1 : 0
      showMy = showForeign = 0
      setSegmentFilter("showSharedWithMe", showSharedWithMe)
    } else if (filter === "showForeign") {
      showForeign = showForeign === 0 ? 1 : 0
      showMy = showSharedWithMe = 0
      setSegmentFilter("showForeign", showForeign)
    }
    if (!showMy && !showSharedWithMe && !showForeign) {
      // fetch all by default
      showMy = showSharedWithMe = showForeign = 1
    }
    const selectedTags = _get(filterValues, "selectedTags", [])
    fetchSegments(
      0,
      ITEMS_PER_PAGE,
      orderBy,
      orderDir,
      searchText,
      selectedTags,
      showMy,
      showSharedWithMe,
      hasAccess.segments.listForeign() ? showForeign : 0,
      true,
    ).catch(_noop)
  }

  renderSchedulingInfo = (segmentId, scheduling) => {
    if (!this.props.isExportDestinationsFulfilled) {
      return null
    }
    let tooltipContent = []
    const isActive = List.isList(scheduling)
    let schedules = Map()
    if (isActive) {
      const { exportDestinations } = this.props
      scheduling.forEach(entry => {
        if (List.isList(entry.get("destination_ids"))) {
          let schedulesTexts = entry
            .get("schedules")
            .map(schedule => getScheduleTextLegacy(schedule))
          entry.get("destination_ids").forEach(did => {
            schedules = schedules.set(did, schedulesTexts)
          })
        }
      })
      exportDestinations
        .sortBy(dst => _toLower(dst.name))
        .forEach(dst => {
          if (List.isList(schedules.get(dst.id))) {
            tooltipContent.push(
              <div
                className={`destination-schedule ${dst.getIn(["frontend_settings", "color"])}`}
                key={dst.id}
              >
                <img
                  src={getIconSrc(dst.icon, dst.getIn(["frontend_settings", "alt_icon"]), true)}
                  alt="destination icon"
                />
                <p>
                  {dst.name} – {schedules.get(dst.id).join("; ")}
                </p>
              </div>,
            )
          }
        })
    }
    return (
      <React.Fragment>
        <Tippy
          className="scheduling-tooltip"
          content={<>{isActive ? tooltipContent : "Not scheduled"}</>}
        >
          <span className={`scheduling-icon ${isActive ? "active" : ""}`}>
            <FontAwesomeIcon className="icon" icon={["far", "clock"]} />
          </span>
        </Tippy>
      </React.Fragment>
    )
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { isLoading } = this.state
    const { segments } = this.props
    if (!isLoading && segments.get("hasMoreItems")) {
      return <Waypoint onEnter={this._loadMoreSegments} bottomOffset={-300} />
    } else if (isLoading && segments.get("hasMoreItems")) {
      return <LoadingIndicator />
    }
  }

  render() {
    const { deleteModal, isCreatingSegment } = this.state
    const { segments, tags, tagsList, filterValues } = this.props

    const orderBy = _get(filterValues, "orderBy")
    const orderDir = _get(filterValues, "orderDir")
    const selectedTags = _get(filterValues, "selectedTags", [])
    const showMy = _get(filterValues, "showMy")
    const showSharedWithMe = _get(filterValues, "showSharedWithMe")
    const showForeign = _get(filterValues, "showForeign")

    return (
      <React.Fragment>
        <section className="wrapper segments-list">
          <PaperHeader className="segments-list-header" size="small" titleText="Custom segments">
            <SearchForm
              placeholder="Search for name or tag"
              className="segments-search"
              onSubmit={this.onSearchSubmit}
              initialValues={{
                search: "",
                showMy: 0,
                showSharedWithMe: 0,
                showForeign: 0,
              }}
              form="SegmentFilter"
            />
            <Button
              color="primary"
              size="small"
              className="create-segment-button"
              onClick={this.createSegment}
              disabled={!hasAccess.segments.create()}
              isLoading={isCreatingSegment}
            >
              + Create segment
            </Button>
          </PaperHeader>
          <Paper hasHeader={true}>
            <div className="tag-filter">
              <div className="tags-wrapper">
                <div className="label-tags">
                  <span className="selected-tags">Filter by:</span>
                  <span className="tags-and-picker">
                    {selectedTags.map(tagId => {
                      const stringTagId = _toString(tagId)
                      let color = tags.getIn([stringTagId, "color"])
                      if (!color) {
                        color = "primary"
                      }
                      return (
                        <Tag
                          key={tagId}
                          clickable={true}
                          color={color}
                          onClick={this.deselectFilterTag(tagId)}
                        >
                          {tags.getIn([stringTagId, "name"], "Deleted tag")}
                        </Tag>
                      )
                    })}
                    <TagPicker
                      selectedTagIds={selectedTags}
                      allTags={tagsList}
                      onTagSelect={tagId => this.selectFilterTag(tagId)()}
                      className="selected-tags-picker"
                    />
                  </span>
                </div>
              </div>
              <div className="segment-filters">
                <span className="segment-filters-label">Filter:</span>
                <Button
                  className="segment-filter-button"
                  size="small"
                  color={showMy === 1 ? "primary" : "white"}
                  onClick={this.setSegmentFilter("showMy")}
                >
                  <FontAwesomeIcon icon={["far", "user"]} className="button-icon" /> My segments
                </Button>
                <Button
                  className="segment-filter-button"
                  size="small"
                  color={showSharedWithMe === 1 ? "primary" : "white"}
                  onClick={this.setSegmentFilter("showSharedWithMe")}
                >
                  <FontAwesomeIcon icon={["far", "users"]} className="button-icon" /> Shared with me
                </Button>
                <Button
                  className="segment-filter-button"
                  size="small"
                  color={showForeign === 1 ? "primary" : "white"}
                  onClick={this.setSegmentFilter("showForeign")}
                  disabled={!hasAccess.segments.listForeign()}
                >
                  <FontAwesomeIcon icon={["far", "list"]} className="button-icon" /> Others
                </Button>
              </div>
            </div>
            {!segments.get("isFulfilled") && <LoadingIndicator className="segments-loading" />}
            {segments.get("isFulfilled") && segments.get("data").size > 0 && (
              <Table className="segments">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortSegmentsBy("name")}
                      label="Name"
                    />
                  </Th>
                  <Th>
                    <FontAwesomeIcon icon={["far", "tag"]} /> Tags
                  </Th>
                  <Th>
                    <SortButton
                      column="author_name"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortSegmentsBy("author_name")}
                      label="Author"
                    />
                  </Th>
                  <Th className="date export-date">
                    <SortButton
                      column="last_export"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortSegmentsBy("last_export")}
                      label={<span>Last&nbsp;export</span>}
                    />
                  </Th>
                  <Th className="date" textAlignRight>
                    <SortButton
                      column="created"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortSegmentsBy("created")}
                      label={<span>Last&nbsp;modified</span>}
                    />
                  </Th>
                  <Th className="table-head">&nbsp;</Th>
                </Thead>
                <Tbody>
                  {segments.get("data").map(segment => {
                    const isForbidden = ![PERMISSION.WRITE, PERMISSION.READ].includes(
                      usersPermission(segment.id),
                    )
                    return (
                      <React.Fragment key={segment.id}>
                        <Tr
                          href={getRoutePath("segments.detail", { id: segment.id })}
                          disabled={isForbidden}
                          onClick={e => {
                            if (isForbidden) {
                              e.preventDefault()
                            }
                          }}
                        >
                          <Td className="segment-name" textBigger textBlack textBold>
                            <div className="segment-name-content-wrapper">
                              {!isForbidden &&
                                this.renderSchedulingInfo(
                                  segment.id,
                                  segment.getIn(["settings", "scheduling"]),
                                )}
                              <Tippy
                                content="You have no permission to access."
                                disabled={!isForbidden}
                              >
                                <span className="segment-name-text">{segment.name}</span>
                              </Tippy>
                            </div>
                          </Td>
                          <Td className="tags">
                            {segment.tag_ids.map(tagId => {
                              const tag = tags.get(_toString(tagId))
                              if (tag) {
                                return (
                                  <Tag
                                    key={tag.id}
                                    color={tag.color ? tag.color : "primary"}
                                    onClick={this.selectFilterTag(tag.id)}
                                  >
                                    {tag.name}
                                  </Tag>
                                )
                              }
                              return null
                            })}
                          </Td>
                          <Td className="author">
                            <Username userId={segment.author_id} />
                          </Td>
                          <Td className="last-export">
                            {segment.last_export !== null &&
                              moment
                                .utc(segment.last_export)
                                .local()
                                .format(MOMENT.DATETIME_FORMAT)}
                            {segment.last_export === null && "—"}
                          </Td>
                          <Td className="date-modified" textAlignRight>
                            {moment.utc(segment.created).local().format(MOMENT.DATE_FORMAT)}
                          </Td>
                          <Td className="action-column" textAlignRight>
                            <IconButton
                              color={COLOR.RED}
                              size={SIZE.TAG}
                              withBackground
                              onClick={this.openDeleteSegmentModal(segment)}
                              disabled={usersPermission(segment.id) !== PERMISSION.WRITE}
                              iconName="trash-alt"
                              tooltip="Delete"
                            />
                          </Td>
                        </Tr>
                      </React.Fragment>
                    )
                  })}
                </Tbody>
              </Table>
            )}
            {segments.get("isFulfilled") && segments.get("data").size === 0 && (
              <RowMessage className="no-segments-found">No segments found</RowMessage>
            )}
            {this.renderWaypoint()}
          </Paper>
          <ConfirmModal
            open={deleteModal.open}
            type={MODAL.TYPE.DELETE}
            handleClose={this.closeDeleteSegmentModal}
            handleConfirm={this.deleteSegment}
            title="Delete segment"
            action="delete"
            what="segment"
            item={_get(deleteModal, "item.name", "")}
          />
        </section>
      </React.Fragment>
    )
  }
}

SegmentsList.propTypes = {
  segments: PropTypes.instanceOf(Map).isRequired,
  tags: PropTypes.instanceOf(Map).isRequired,
  tagsList: PropTypes.instanceOf(List).isRequired,
  fetchSegments: PropTypes.func.isRequired,
  deleteSegment: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  setSortingOptions: PropTypes.func.isRequired,
  setFilterByTags: PropTypes.func.isRequired,
  setFilterAndSorting: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.object.isRequired,
  setSegmentFilter: PropTypes.func.isRequired,
  setUiAttribute: PropTypes.func.isRequired,
  isExportDestinationsFulfilled: PropTypes.bool.isRequired,
  exportDestinations: PropTypes.instanceOf(Map).isRequired,
}

const mapStateToProps = state => {
  return {
    segments: getSegmentsList(state),
    tags: getTagsMap(state),
    tagsList: getTagsSortedByName(state),
    filterValues: getFormValues("SegmentFilter")(state),
    authenticatedUser: state.authenticatedUser,
    isExportDestinationsFulfilled: isExportDestinationsFulfilled(state),
    exportDestinations: getExportDestinationsData(state),
  }
}

export default connect(mapStateToProps, {
  createSegment,
  fetchSegments,
  deleteSegment,
  showToast,
  setSortingOptions,
  setFilterByTags,
  setFilterAndSorting,
  setSegmentFilter,
  setUiAttribute,
  fetchUsersAclList,
})(withRouter(SegmentsList))
