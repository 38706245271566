import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record, List, Map } from "immutable"
import _random from "lodash/random"
import _take from "lodash/take"
import _isNil from "lodash/isNil"
import _isArray from "lodash/isArray"

import InsightTile from "components/UI/elements/InsightTile"

import { SEGMENT_ANALYTICS_FUNCTIONS } from "sharedConstants"
import { isAttributeCompound } from "helpers/compoundAttribute.helper"

export const RANDOM_FLOATS = [
  52.57, 3.98, 456.78, 453.22, 98.09, 789.87, 246.47, 289.88, 876.44, 535.02,
]
export const RANDOM_INTS = [76, 87, 988, 344, 234, 134, 765, 23, 9, 145]
export const RANDOM_PERCENTAGES = [19, 15, 2, 12, 5, 3, 18, 15, 0.45, 7.8]
export const RANDOM_VALUES_COUNT = 10
export const RANDOM_DATES = ["1991-10-22", "1998-08-23", "2009-03-19", "2003-10-21", "1996-07-25"]
export const RANDOM_DATETIMES = [
  "1991-10-22 13:10:24",
  "2008-12-19 05:32:55",
  "1998-05-09 09:43:01",
  "2000-01-11 01:33:55",
  "2001-04-12 10:10:21",
]

export const RANDOM_WORDS = ["example", "customer", "value", "experience", "data"]

class DummyInsight extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
      percentage: null,
      attributePercentage: null,
    }
  }

  getRandomValue = defaultValue => {
    const { attribute } = this.props
    return List.isList(attribute.examples) && attribute.examples.size > 0
      ? attribute.examples.get(_random(attribute.examples.size - 1))
      : defaultValue
  }

  getAttributeExamples = () => {
    const { attribute, subAttribute } = this.props
    if (subAttribute && Map.isMap(attribute.examples)) {
      return List.isList(attribute.examples.get(subAttribute.id))
        ? attribute.examples.get(subAttribute.id).toArray()
        : []
    } else {
      return List.isList(attribute.examples) ? attribute.examples.toArray() : []
    }
  }

  generateState = () => {
    const { attribute, funcType, subAttribute } = this.props
    let valueType = null
    if (isAttributeCompound(attribute.data_type)) {
      valueType = subAttribute.data_type
    } else if (attribute) {
      valueType = attribute.data_type
    }

    switch (valueType) {
      case "int": {
        if (funcType === SEGMENT_ANALYTICS_FUNCTIONS.AVG.value) {
          this.setState({
            value: RANDOM_FLOATS[_random(RANDOM_VALUES_COUNT - 1)],
            percentage: null,
          })
        } else if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
            SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
          ].includes(funcType)
        ) {
          this.setState({
            value: _take(RANDOM_INTS, 5),
            percentage: [
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
            ],
          })
        } else if (!_isNil(funcType)) {
          const val = RANDOM_INTS[_random(RANDOM_VALUES_COUNT - 1)]
          this.setState({
            value: val,
            percentage: null,
          })
        }
        break
      }
      case "float": {
        if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
            SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
          ].includes(funcType)
        ) {
          const val = RANDOM_INTS[_random(RANDOM_VALUES_COUNT - 1)]
          this.setState({
            value: val,
            percentage: null,
          })
        } else if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
            SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
          ].includes(funcType)
        ) {
          this.setState({
            value: _take(RANDOM_FLOATS, 5),
            percentage: [
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
            ],
          })
        } else if (!_isNil(funcType)) {
          this.setState({
            value: RANDOM_FLOATS[_random(RANDOM_VALUES_COUNT - 1)],
            percentage: null,
          })
        }
        break
      }
      case "date":
      case "datetime": {
        if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY.value,
            SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH.value,
            SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR.value,
            SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value,
          ].includes(funcType)
        ) {
          const val = RANDOM_INTS[_random(RANDOM_VALUES_COUNT - 1)]
          this.setState({
            value: val,
            percentage: null,
          })
        } else if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
            SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
          ].includes(funcType)
        ) {
          const values = this.getAttributeExamples()
          if (values.length < 5) {
            let i = 0
            while (values.length < 5) {
              if (valueType === "date") {
                values.push(RANDOM_DATES[i])
              } else {
                values.push(RANDOM_DATETIMES[i])
              }
              i++
            }
          }
          this.setState({
            value: values,
            percentage: [
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
            ],
          })
        } else if (!_isNil(funcType)) {
          if (valueType === "date") {
            this.setState({
              value: this.getRandomValue("1991-10-22"),
              percentage: null,
            })
          } else {
            this.setState({
              value: this.getRandomValue("1991-10-22 13:10:24"),
              percentage: null,
            })
          }
        }
        break
      }
      case "bool": {
        if (!_isNil(funcType)) {
          const val = RANDOM_INTS[_random(RANDOM_VALUES_COUNT - 1)]
          this.setState({
            value: val,
            percentage: null,
          })
        }
        break
      }
      default: {
        // string
        if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
            SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
            SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
          ].includes(funcType)
        ) {
          const values = this.getAttributeExamples()
          if (values.length < 5) {
            let i = 0
            while (values.length < 5) {
              values.push(RANDOM_WORDS[i])
              i++
            }
          }
          this.setState({
            value: values,
            percentage: [
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
              RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
            ],
          })
        } else if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
          ].includes(funcType)
        ) {
          const val = RANDOM_INTS[_random(RANDOM_VALUES_COUNT - 1)]
          this.setState({
            value: val,
            percentage: null,
          })
        }
      }
    }
  }

  componentDidMount() {
    const { funcType, attribute, subAttribute } = this.props
    if (funcType && attribute) {
      if (isAttributeCompound(attribute.data_type) && !subAttribute) {
        return
      }

      this.generateState()
    }
  }

  componentDidUpdate(prevProps) {
    const { funcType, attribute, subAttribute } = this.props
    if (funcType && attribute) {
      const newSubAttribute = subAttribute ?? {}
      const prevSubAttribute = prevProps.subAttribute ?? {}
      if (
        funcType !== prevProps.funcType ||
        attribute !== prevProps.attribute ||
        newSubAttribute.id !== prevSubAttribute.id
      ) {
        this.generateState()
      }
    } else {
      this.setState({
        value: null,
        percentage: null,
      })
    }

    if (attribute && this.state.attributePercentage === null) {
      this.setState({
        attributePercentage: RANDOM_PERCENTAGES[_random(RANDOM_VALUES_COUNT - 1)],
      })
    } else if (!attribute && this.state.attributePercentage !== null) {
      this.setState({
        attributePercentage: null,
      })
    }
  }

  _nullValueIfNotValid = value => {
    const { funcType } = this.props
    if (
      [
        SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
      ].includes(funcType) &&
      !_isArray(value)
    ) {
      return null
    } else if (
      ![
        SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
      ].includes(funcType) &&
      _isArray(value)
    ) {
      return null
    }
    return value
  }

  render() {
    const {
      name,
      funcType,
      attribute,
      attributeDeleted,
      subAttribute,
      color,
      displayType,
      showNewBadge,
      id,
      compareValue,
    } = this.props
    let { count } = this.props
    const { attributePercentage } = this.state
    let { value, percentage } = this.state

    if (
      count &&
      [
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
        SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
      ].includes(funcType)
    ) {
      if (_isArray(value) && _isArray(percentage)) {
        value = _take(value, count)
        percentage = _take(percentage, count)
      }
    }

    value = this._nullValueIfNotValid(value)

    return (
      <InsightTile
        id={id}
        className="tile"
        name={name ? name : "Fill in the name"}
        funcType={funcType}
        attribute={attribute}
        attributeDeleted={attributeDeleted}
        subAttribute={subAttribute}
        color={color}
        displayType={displayType}
        hideActionButtons
        value={value ? value : []}
        compareValue={compareValue}
        percentage={percentage ? percentage : []}
        outOf={1299}
        attributePercentage={attributePercentage}
        showNewBadge={showNewBadge}
        mode="preview"
      />
    )
  }
}

DummyInsight.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  funcType: PropTypes.string,
  attribute: PropTypes.instanceOf(Record),
  attributeDeleted: PropTypes.bool,
  subAttribute: PropTypes.object,
  color: PropTypes.string,
  displayType: PropTypes.string,
  count: PropTypes.number,
  showNewBadge: PropTypes.bool,
  compareValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default DummyInsight
