import { CUSTOMER, ITEMS_PER_PAGE } from "sharedConstants"
import { List, Map, fromJS } from "immutable"
import SelectionSettings from "models/selectionSettings.model"
import AttributeModel from "models/attribute.model"

const initialState = Map({
  data: List(),
  selectionSettings: SelectionSettings(),
  hasMoreItems: false,
  selectedAttributeId: null,
  additionalAttribute: null,
  results: null,
  isShowingFavorites: false,
  isLoading: false,
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${CUSTOMER.ACTION.SEARCH_VALUE}_PENDING`:
    case `${CUSTOMER.ACTION.SEARCH_VALUE_IN_ATTRIBUTE}_PENDING`:
    case `${CUSTOMER.ACTION.LIST_FAVORITE_CUSTOMERS}_PENDING`: {
      return state.set("isLoading", true)
    }
    case `${CUSTOMER.ACTION.SEARCH_VALUE}_REJECTED`:
    case `${CUSTOMER.ACTION.SEARCH_VALUE_IN_ATTRIBUTE}_REJECTED`:
    case `${CUSTOMER.ACTION.LIST_FAVORITE_CUSTOMERS}_REJECTED`: {
      return state.set("isLoading", false)
    }
    case `${CUSTOMER.ACTION.SEARCH_VALUE}_FULFILLED`:
    case `${CUSTOMER.ACTION.SEARCH_VALUE_IN_ATTRIBUTE}_FULFILLED`:
    case `${CUSTOMER.ACTION.LIST_FAVORITE_CUSTOMERS}_FULFILLED`: {
      const isFavoriteList = type === `${CUSTOMER.ACTION.LIST_FAVORITE_CUSTOMERS}_FULFILLED`
      const items = isFavoriteList ? payload.favourite_customers : payload.search_result
      if (payload.selection_settings.offset === 0) {
        // create new List
        return state
          .set("data", List(items))
          .set("selectionSettings", new SelectionSettings(payload.selection_settings))
          .set("hasMoreItems", items.length === ITEMS_PER_PAGE)
          .set("selectedAttributeId", payload.selectedAttributeId)
          .set(
            "additionalAttribute",
            payload.additional_attribute
              ? new AttributeModel(fromJS(payload.additional_attribute))
              : null,
          )
          .set("results", payload.customers_total_count)
          .set("isShowingFavorites", isFavoriteList)
          .set("isLoading", false)
      } else {
        // concat items
        return state
          .set("data", state.get("data").concat(items))
          .set("selectionSettings", new SelectionSettings(payload.selection_settings))
          .set("hasMoreItems", items.length === ITEMS_PER_PAGE)
          .set("results", payload.customers_total_count)
          .set("isLoading", false)
      }
    }

    case CUSTOMER.ACTION.SEARCH_RESULTS_RESET:
      return initialState

    default:
      return state
  }
}
