import { TRASH } from "sharedConstants"
import { Map, List, Record, fromJS } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import _toString from "lodash/toString"
import { EXPORT } from "./exportDestinationActions"

const ExportDestination = Record({
  id: 0,
  name: "",
  description: "",
  created: "",
  settings: Map(),
  frontend_settings: Map(),
  icon: "",
  attribute_ids: List(),
  mandatory_attribute_ids: List(),
  last_export: "",
  mi_workspace_id: null,
})

/*
 * { destinationId: { destinationObject } }
 */
const initialState = Map(
  fromJS({
    data: {},
    isFulfilled: false,
    // isFetching is set to true because sources are loaded at the app start
    // from AuthenticatedLayout.js
    isFetching: true,
  }),
)

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${EXPORT.DESTINATION.ACTION.LIST}_PENDING`:
      return state.set("isFetching", true)
    case `${EXPORT.DESTINATION.ACTION.LIST}_FULFILLED`:
      return state
        .set(
          "data",
          state.get("data").merge(
            _mapKeys(
              _map(payload, exportDestination => new ExportDestination(fromJS(exportDestination))),
              "id",
            ),
          ),
        )
        .set("isFulfilled", true)
        .set("isFetching", false)
    case `${EXPORT.DESTINATION.ACTION.MODIFY}_FULFILLED`:
    case `${TRASH.ACTION.RESTORE_EXPORT_DESTINATION}_FULFILLED`: {
      return state.set(
        "data",
        state.get("data").merge(fromJS({ [payload.id]: new ExportDestination(fromJS(payload)) })),
      )
    }
    case `${EXPORT.DESTINATION.ACTION.DELETE}_FULFILLED`:
      return state.deleteIn(["data", _toString(payload)])
    default:
      return state
  }
}
