import React, { useCallback, useState } from "react"
import styles from "./BatchAddModal.module.scss"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"

type BatchAddModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: string[]) => void
}

export default function BatchAddModal({ isOpen, onClose, onSubmit }: BatchAddModalProps) {
  const [text, setText] = useState("")

  const addValues = useCallback(() => {
    if (text.trim()) {
      onSubmit(
        text
          .replaceAll("\r", "")
          .replace(/\n+/g, "\n")
          .trim()
          .split("\n")
          .filter(line => Boolean(line.trim())),
      )
    }
    setText("")
    onClose()
  }, [onClose, onSubmit, text])

  return (
    <Modal open={isOpen} handleClose={onClose} title="Add multiple values">
      <div className={styles.description}>
        Paste a list of values to be added, one value per line.
      </div>
      <textarea
        className={styles.inputArea}
        value={text}
        onChange={e => setText(e.target.value)}
        rows={20}
        autoFocus
      />
      <div className={styles.footer}>
        <Button color="primary" size="small" onClick={addValues}>
          Add values
        </Button>
      </div>
    </Modal>
  )
}
