import React, { useCallback, useState } from "react"
import {
  ConditionComponent as ConditionComponentType,
  ConditionPath,
  ConditionTree,
} from "../../types/conditionTree"
import { idToPath, isAndOrCondition } from "./utils"
import ConditionBranch from "./components/ConditionBranch/ConditionBranch"
import { addCondition, mergeUpLoneConditions, moveCondition, removeCondition } from "./treeMethods"
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { equals, last, path as get } from "ramda"
import { getSymbol } from "./treeSymbols"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./ConditionBuilder.module.scss"
import classNames from "classnames"

type ConditionBuilderProps<T> = {
  conditionTree: ConditionTree<T> | null
  onChange: (conditionTree: ConditionTree<T> | null) => void
  conditionComponent: ConditionComponentType<T>
  getNewCondition: () => T
  validateCondition?: (condition: T) => any // TODO: properly type this?
  isEditable: boolean
  error: ConditionTree<any> | null // TODO: properly type this?
}

export default function ConditionBuilder<T>({
  conditionTree,
  onChange,
  conditionComponent: ConditionComponent,
  getNewCondition,
  isEditable,
  error,
}: ConditionBuilderProps<T>) {
  const sensors = useSensors(useSensor(PointerSensor))

  const [activeId, setActiveId] = useState<string | null>(null)
  const [insertPath, setInsertPath] = useState<ConditionPath | null>(null)

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setActiveId(active.id)
  }, [])

  const handleDragMove = useCallback(
    ({ active, over }: DragOverEvent) => {
      const activeRect = active.rect.current.translated

      if (over === null || !activeRect) {
        return
      }

      // Insert either before or after the condition we're dragging over, based on the position
      const overRect = over.rect
      const middleOfActive = activeRect.top + activeRect.height / 2 + window.scrollY
      const middleOfOver = overRect.offsetTop + overRect.height / 2
      const isActiveBelowOver = middleOfActive > middleOfOver
      const overPath = idToPath(over.id)
      const overIndex = last(overPath) as number
      const newInsertPath = isActiveBelowOver ? [...overPath.slice(0, -1), overIndex + 1] : overPath

      const activePath = idToPath(active.id)
      const isSameArray = equals(activePath.slice(0, -1), newInsertPath.slice(0, -1))
      const activeIndex = last(activePath) as number
      const insertIndex = last(newInsertPath) as number

      // Do not insert the condition before or after itself
      if (isSameArray && (insertIndex === activeIndex || insertIndex === activeIndex + 1)) {
        if (insertPath !== null) {
          setInsertPath(null)
        }
      } else if (!equals(insertPath, newInsertPath)) {
        setInsertPath(newInsertPath)
      }
    },
    [insertPath],
  )

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveId(null)
      setInsertPath(null)

      if (over === null || !active.data.current || !over.data.current || !insertPath) {
        return
      }

      const activePath = idToPath(active.id)
      const isSameArray = equals(activePath.slice(0, -1), insertPath.slice(0, -1))
      const activeIndex = last(activePath) as number
      const insertIndex = last(insertPath) as number
      const isInsertingBelowActive = isSameArray && activeIndex < insertIndex
      const adjustedInsertPath = isInsertingBelowActive
        ? [...insertPath.slice(0, -1), insertIndex - 1]
        : insertPath

      if (!equals(activePath, adjustedInsertPath)) {
        onChange(
          mergeUpLoneConditions(
            moveCondition(idToPath(active.id), adjustedInsertPath, conditionTree!),
          ),
        )
      }
    },
    [conditionTree, insertPath, onChange],
  )

  const addNewCondition = useCallback(
    () => onChange(addCondition(getNewCondition(), conditionTree)),
    [conditionTree, getNewCondition, onChange],
  )

  return (
    <div>
      {conditionTree && (
        <>
          {isAndOrCondition(conditionTree) ? (
            <DndContext
              sensors={sensors}
              collisionDetection={rectIntersection}
              onDragStart={handleDragStart}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
            >
              <ConditionBranch
                conditionTree={conditionTree}
                onChange={onChange}
                conditionComponent={ConditionComponent}
                path={[]}
                isEditable={isEditable}
                error={error}
                insertPath={insertPath}
              />
              <DragOverlay dropAnimation={null}>
                {activeId ? (
                  <ConditionComponent
                    condition={get<T>(idToPath(activeId), conditionTree)!}
                    onChange={onChange}
                    symbol={getSymbol(idToPath(activeId), conditionTree)}
                    isEditable={isEditable}
                    error={get(idToPath(activeId), error)}
                    dragListeners={{}}
                    removeSelf={() => {}}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          ) : (
            <div className={styles.singleCondition}>
              <ConditionComponent
                condition={conditionTree as T}
                onChange={onChange}
                symbol={getSymbol([], conditionTree)}
                isEditable={isEditable}
                error={error}
                removeSelf={() => onChange(removeCondition([], conditionTree))}
              />
            </div>
          )}
        </>
      )}
      {isEditable && (
        <Button
          onClick={addNewCondition}
          color="white"
          size="small"
          className={classNames(styles.addButton, { [styles.noConditions]: !conditionTree })}
        >
          <FontAwesomeIcon icon={["far", "plus"]} /> add condition
        </Button>
      )}
      {!isEditable && !conditionTree && <div className={styles.emptyMessage}>No conditions.</div>}
    </div>
  )
}
