import _get from "lodash/get"

import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { api } from "api"

export const EXPORT = {
  DESTINATION: {
    ACTION: {
      LIST: "EXPORT_DESTINATION_LIST",
      MODIFY: "EXPORT_DESTINATION_MODIFY",
      DELETE: "EXPORT_DESTINATION_DELETE",
    },
  },
}

export const fetchExportDestinationsList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: EXPORT.DESTINATION.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.exportDestination.list(offset, limit, "id", "ASC", loadFullStructure),
          )
          .setDataPath("segment_export_destinations")
          .setLoadFullStructure(0)
          .run()
      })(),
    },
  })
}

export const modifyExportDestination = (destinationId, data) => async dispatch =>
  dispatch({
    type: EXPORT.DESTINATION.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.exportDestination.modify(destinationId, data)
        return _get(response, "segment_export_destination", {})
      })(),
    },
  })

export const deleteExportDestination = id => async dispatch =>
  dispatch({
    type: EXPORT.DESTINATION.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.exportDestination.delete(id)
        return id
      })(),
    },
  })
