import notifications from "./notification/notificationReducer"
import embeddedWebBanners from "./webBanner/embeddedWebBanner/embeddedWBReducer"
import popupWebBanners from "./webBanner/popupWebBanner/popupWBReducer"
import dataSources from "./dataSource/dataSourceReducer"
import tags from "./tag/tagReducer"
import reports from "./report/reportReducer"
import exportDestinations from "./exportDestination/exportDestinationReducer"
import customSegments from "./segment/segment/customSegmentReducer"
import featuredSegments from "./segment/segment/featuredSegmentReducer"
import smartSegments from "./segment/segment/smartSegmentReducer"
import segmentNumbers from "./segment/segmentNumbers/segmentNumbersReducer"
import segmentCustomers from "./segment/segmentCustomers/segmentCustomersReducer"
import segmentExports from "./segment/segmentExport/segmentExportReducer"
import segmentsNumbers from "./segment/segmentNumbers/segmentNumbersReducer"

const resourceReducers = {
  notifications,
  embeddedWebBanners,
  popupWebBanners,
  dataSources,
  tags,
  reports,
  exportDestinations,
  segments: customSegments,
  featuredSegments,
  smartSegments,
  segmentNumbers,
  segmentCustomers,
  segmentExports,
  segmentsNumbers,
}

export default resourceReducers
