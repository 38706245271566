import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record, Map } from "immutable"
import { Form, Field, reduxForm } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import Waypoint from "react-waypoint"
import { getFormValues } from "redux-form"
import { whereEq } from "ramda"

// selectors
import {
  getCustomerSearchesData,
  getCustomerSearchesSelectionSettings,
  hasCustomerSearchesMoreResults,
  getCustomerSearchesSelectedAttributeId,
  getCustomerSearchesResultsCount,
  getCustomerSearchesAdditionalAttribute,
  getCustomerSearchesFavoritesFlag,
  getCustomerSearchesLoadingFlag,
} from "selectors/customerSearch.selector"
import { getAttributesMapByIdForSelect } from "selectors/attributes.selector"

// actions
import {
  fulltextCustomerSearch,
  fulltextCustomerAttributeSearch,
  listFavoriteCustomers,
  resetCustomerSearchResults,
} from "actions/customerSearch.action"
import { setCustomersIterator } from "actions/customersIterator.action"
import { setLatestSearch, toggleFavoriteCustomer } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import SearchField from "components/UI/elements/SearchField"
import CustomerAvatar from "components/UI/elements/CustomerAvatar"
import AttributePicker from "components/UI/components/AttributePicker"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"
// api, helpers
import {
  shortenTextArroundPattern,
  shortenText,
  shortenTextWithoutDots,
} from "helpers/string.helper"
import { formatSearchNumber } from "helpers/number.helper"
import { getRoutePath } from "routes"
import {
  getUserFriendlyValueFormat,
  getBackendValueFromUserFriendly,
} from "helpers/attributeValue.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "helpers/compoundAttribute.helper"
import { isJSONString } from "helpers/validators.helper"

//constants
import { ITEMS_PER_PAGE, TOAST, TOASTS } from "sharedConstants"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./CustomersList.scss"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { range } from "lodash"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"

// messages
const DEFAULT_MESSAGE = (
  <p className="info-message">
    <span>Specify attribute</span> or <span>Search</span>.
  </p>
)
const NOT_FOUND_MESSAGE = (
  <p className="info-message">
    <strong>No results found.</strong> Please try different criteria.
  </p>
)

class CustomersList extends PureComponent {
  constructor(props) {
    super(props)
    const customers = props.authenticatedUser.latestSearch?.customers
    const isLatestSearchEmpty =
      _isEmpty(customers) || (!customers.attributeId && !customers.searchText?.trim())

    this.state = {
      isFetching: false,
      isFetchingFavouriteCustomers: false,
      message:
        (props.customers.size === 0 || props.isShowingFavorites) && isLatestSearchEmpty
          ? DEFAULT_MESSAGE
          : "",
      toggledFavoriteCustomerId: null,
      rowsExpanded: [],
    }
  }

  toggleFavoriteCustomer = async (id, isCustomerFavorite) => {
    this.setState({ toggledFavoriteCustomerId: id })
    try {
      const { showToast, toggleFavoriteCustomer } = this.props
      await toggleFavoriteCustomer(id)
      const { ADDED, REMOVED } = TOASTS.FAVORITE_CUSTOMERS
      showToast(isCustomerFavorite ? REMOVED : ADDED)
    } catch {
      // noop
    }
    this.setState({ toggledFavoriteCustomerId: null })
  }

  onSubmit = values => {
    const {
      fulltextCustomerSearch,
      fulltextCustomerAttributeSearch,
      attributesMapByIdForSelect,
      setLatestSearch,
      showToast,
      authenticatedUser,
    } = this.props

    if (
      values.searchText !== undefined &&
      values.searchText !== "" &&
      values.searchText.length < 3
    ) {
      showToast("Please type in at least 3 characters into the search field.", TOAST.TYPE.ERROR)
      return
    }

    if (!values.attributeId) {
      // case 1) only search text, fulltext call
      fulltextCustomerSearch(values.searchText, 0, ITEMS_PER_PAGE, 1)
        .then(response => {
          this.setState(
            { message: response.value.search_result.length === 0 ? NOT_FOUND_MESSAGE : "" },
            () => {
              if (values.searchText) {
                let customerSearches = authenticatedUser.latestSearch?.customers
                if (Array.isArray(customerSearches)) {
                  if (!this.searchExists(customerSearches, values.searchText, null)) {
                    setLatestSearch("customers", [
                      ...customerSearches.slice(-4),
                      { searchText: values.searchText, attributeId: null },
                    ])
                  }
                } else {
                  setLatestSearch("customers", [{ searchText: values.searchText }])
                }
              }
            },
          )
        })
        .catch(() => {})
    } else {
      // convert search text from user friendly values to backend values
      const attribute = attributesMapByIdForSelect.get(values.attributeId)
      const searchText = getBackendValueFromUserFriendly(values.searchText, attribute.data_type)
      fulltextCustomerAttributeSearch(values.attributeId, searchText, 0, ITEMS_PER_PAGE, 1)
        .then(response => {
          this.setState(
            { message: response.value.search_result.length === 0 ? NOT_FOUND_MESSAGE : "" },
            () => {
              let customerSearches = authenticatedUser.latestSearch?.customers
              if (Array.isArray(customerSearches)) {
                if (!this.searchExists(customerSearches, values.searchText, values.attributeId)) {
                  setLatestSearch("customers", [
                    ...customerSearches.slice(-4),
                    { searchText, attributeId: values.attributeId },
                  ])
                }
              } else {
                setLatestSearch("customers", [{ searchText, attributeId: values.attributeId }])
              }
            },
          )
        })
        .catch(() => {})
    }
  }

  searchExists = (customerSearches, searchText, attributeId) =>
    customerSearches.find(whereEq({ searchText, attributeId }))

  async componentDidMount() {
    const { customers, listFavoriteCustomers } = this.props

    if (customers.size === 0) {
      this.setState({ isFetchingFavouriteCustomers: true })
      try {
        await listFavoriteCustomers(0, ITEMS_PER_PAGE, 1)
      } catch {
      } finally {
        this.setState({ isFetchingFavouriteCustomers: false })
      }
    }
  }

  loadMoreCustomers = async () => {
    const {
      selectionSettings,
      selectedAttributeId,
      fulltextCustomerSearch,
      fulltextCustomerAttributeSearch,
      isShowingFavorites,
      listFavoriteCustomers,
    } = this.props

    this.setState({ isFetching: true })

    try {
      if (isShowingFavorites) {
        await listFavoriteCustomers(
          selectionSettings.offset + selectionSettings.limit,
          selectionSettings.limit,
          1,
        )
        return
      }

      if (selectedAttributeId) {
        await fulltextCustomerAttributeSearch(
          selectedAttributeId,
          selectionSettings.search_value,
          selectionSettings.offset + selectionSettings.limit,
          selectionSettings.limit,
          1,
        )
        return
      }

      await fulltextCustomerSearch(
        selectionSettings.search_text,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        1,
      )
    } catch {
      // noop
    } finally {
      this.setState({ isFetching: false })
    }
  }

  setCustomersPaginationIterator = arrIndex => () => {
    this.props.setCustomersIterator(arrIndex)
  }

  renderCustomerRow = (customer, customerIndex, searchText) => {
    const { authenticatedUser, additionalAttribute, setCustomersIterator } = this.props
    let additionalAttributeValue = _get(customer, "additional_attribute_values[0]", "—")
    const hasAdditionalAttributeMoreValues =
      _get(customer, "additional_attribute_values.length") > 1
    if (additionalAttributeValue !== "—") {
      additionalAttributeValue = getUserFriendlyValueFormat(
        additionalAttributeValue,
        additionalAttribute.data_type,
      )
    }

    const { id } = customer.customer_entity
    const isCustomerFavorite = authenticatedUser.data.cdp_settings?.favourite_customers?.find(
      ({ customer_entity_id }) => customer_entity_id === id,
    )
    const showAsFavorite =
      // between the click and the response coming back from the server, already show the button in
      // the new state
      (isCustomerFavorite && this.state.toggledFavoriteCustomerId !== id) ||
      (!isCustomerFavorite && this.state.toggledFavoriteCustomerId === id)

    const onRowClick = evt => {
      if (!hasAccess.customers.detail()) {
        evt.preventDefault()
      }
      if (hasAccess.customers.detail()) {
        setCustomersIterator(customerIndex)
      }
    }

    const rowHref = {
      pathname: getRoutePath("customers.detail", { id: customer.customer_entity.id }),
      state: { previous: true },
    }

    const favoriteButtonCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "favorite-button-cell", {
          "no-border": withoutBorder,
        })}
      >
        <DelayedTooltip content={showAsFavorite ? "Remove from favorites" : "Add to favorites"}>
          <button
            className={classNames("favorite-button", { "is-faved": showAsFavorite })}
            onClick={e => {
              e.preventDefault()
              this.toggleFavoriteCustomer(id, isCustomerFavorite)
            }}
            disabled={this.state.toggledFavoriteCustomerId === id}
          >
            <FontAwesomeIcon icon={[showAsFavorite ? "fas" : "far", "star"]} />
          </button>
        </DelayedTooltip>
      </Td>
    )

    const avatarCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "customer-avatar-cell", {
          "no-border": withoutBorder,
        })}
      >
        <CustomerAvatar className="customer-avatar" />
      </Td>
    )

    const idCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "customer-id", {
          "no-border": withoutBorder,
        })}
        textBigger
        textBlack
        textBold
      >
        {shortenText(customer.customer_entity.id, 10)}
      </Td>
    )

    const additionalAttributeCell = withoutBorder =>
      additionalAttribute !== null && (
        <Td className={classNames("customer-table-cell", { "no-border": withoutBorder })}>
          {(additionalAttributeValue.length > 30 || hasAdditionalAttributeMoreValues) && (
            <React.Fragment>
              <Tippy
                content={_get(customer, "additional_attribute_values", []).map((val, index) => {
                  return (
                    <span className="add-value" key={index}>
                      {getUserFriendlyValueFormat(val, additionalAttribute.data_type)}
                    </span>
                  )
                })}
              >
                <span>{shortenTextWithoutDots(additionalAttributeValue, 30)}...</span>
              </Tippy>
            </React.Fragment>
          )}
          {additionalAttributeValue.length <= 30 && !hasAdditionalAttributeMoreValues && (
            <React.Fragment>{additionalAttributeValue}</React.Fragment>
          )}
        </Td>
      )

    const expandOrCompactRow = customerIndex => {
      this.setState(({ rowsExpanded }) => ({
        rowsExpanded: rowsExpanded.includes(customerIndex)
          ? rowsExpanded.filter(el => el !== customerIndex)
          : rowsExpanded.concat(customerIndex),
      }))
    }

    const chevronCellExpand = customerIndex => {
      const isRowExpanded = this.state.rowsExpanded.includes(customerIndex)
      return (
        <Td className={classNames("chevron-cell", "no-border")}>
          <DelayedTooltip content={isRowExpanded ? "Compact" : "Expand"}>
            <button
              className={classNames("expand-button")}
              onClick={e => {
                expandOrCompactRow(customerIndex)
                e.preventDefault()
              }}
            >
              <FontAwesomeIcon
                className="chevron-down"
                icon={["fas", isRowExpanded ? "chevron-up" : "chevron-down"]}
              />
            </button>
          </DelayedTooltip>
        </Td>
      )
    }

    const chevronCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "chevron-cell", {
          "no-border": withoutBorder,
        })}
      >
        <FontAwesomeIcon className="chevron-right" icon={["fas", "chevron-right"]} />
      </Td>
    )

    if (customer.customer_attributes.length === 0) {
      return (
        <Tr
          key={`${customerIndex}-0`}
          href={rowHref}
          onClick={onRowClick}
          disabled={!hasAccess.customers.detail()}
        >
          {favoriteButtonCell()}
          {avatarCell()}
          {idCell()}
          {additionalAttributeCell()}
          <Td className="customer-table-cell">&nbsp;</Td>
          <Td className="customer-table-cell">&nbsp;</Td>
          <Td className="customer-table-cell">&nbsp;</Td>
          {chevronCell()}
        </Tr>
      )
    }

    return customer.customer_attributes.map((ca, valueIndex) => {
      const attributesCount = customer.customer_attributes.length
      const value = ca.value ? ca.value : "N/A"
      let valueDOM = null
      if (isAttributeCompound(ca.attribute.data_type) && isJSONString(value)) {
        const subAttributes = List(getCompoundAttributeSubAttributes(ca.attribute.data_type))
        const values = List([JSON.parse(value)])
        valueDOM = (
          <div className="compound-value-wrapper">
            <CompoundAttributeValuesTable subAttributes={subAttributes} values={values} />
          </div>
        )
      } else {
        if (value.length > 40) {
          valueDOM = (
            <React.Fragment>
              <Tippy content={value}>
                <span>{shortenTextArroundPattern(searchText, value, 40)}</span>
              </Tippy>
            </React.Fragment>
          )
        } else {
          valueDOM = getUserFriendlyValueFormat(value, ca.attribute.data_type)
        }
      }

      const numberOfLeadingEmptyCells = additionalAttribute !== null ? 4 : 3
      return (
        <Tr
          key={`${customerIndex}-${valueIndex}`}
          href={rowHref}
          onClick={onRowClick}
          disabled={!hasAccess.customers.detail()}
          className={
            this.state.rowsExpanded.includes(customerIndex) === false && valueIndex > 0
              ? "expandedHidden"
              : "expandedVisible"
          }
        >
          {valueIndex === 0 && (
            <>
              {favoriteButtonCell(attributesCount > 1)}
              {avatarCell(attributesCount > 1)}
              {idCell(attributesCount > 1)}
            </>
          )}
          {valueIndex > 0 &&
            range(0, numberOfLeadingEmptyCells).map(key => (
              <Td
                key={key}
                className={classNames("customer-table-cell", "smaller", {
                  "no-border": valueIndex < attributesCount - 1,
                })}
              >
                &nbsp;
              </Td>
            ))}
          {valueIndex === 0 && additionalAttributeCell(attributesCount > 1)}
          <Td className={classNames("customer-table-cell", { smaller: valueIndex > 0 })}>
            {ca.attribute.source.name}
          </Td>
          <Td className={classNames("customer-table-cell", "no-wrap", { smaller: valueIndex > 0 })}>
            {ca.attribute.name.length > 20 && (
              <React.Fragment>
                <Tippy content={ca.attribute.name}>
                  <span>{shortenText(ca.attribute.name, 20)}</span>
                </Tippy>
              </React.Fragment>
            )}
            {ca.attribute.name.length <= 20 && ca.attribute.name}
          </Td>
          <Td className={classNames("customer-table-cell", "no-wrap", { smaller: valueIndex > 0 })}>
            {valueDOM}
          </Td>
          {valueIndex === 0 && (attributesCount > 1 ? chevronCellExpand(customerIndex) : <Td></Td>)}
          {valueIndex > 0 && (
            <Td
              className={classNames("customer-table-cell", "smaller", {
                "no-border": valueIndex < attributesCount - 1,
              })}
            >
              &nbsp;
            </Td>
          )}
        </Tr>
      )
    })
  }

  resetFulltextSearchResults = () => {
    const { resetCustomerSearchResults } = this.props

    resetCustomerSearchResults()
    this.setState({ message: DEFAULT_MESSAGE })
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { moreCustomersExist } = this.props
    const { isFetching } = this.state

    if (moreCustomersExist && !isFetching) {
      return <Waypoint onEnter={this.loadMoreCustomers} bottomOffset={-600} />
    } else if (isFetching && moreCustomersExist) {
      return <LoadingIndicator />
    }
  }

  selectAttribute = attributeId => {
    this.props.change("attributeId", attributeId)
  }

  repeatSearch = (attributeId, searchText) => {
    const { change } = this.props
    change("attributeId", attributeId)
    change("searchText", searchText)
    this.onSubmit({
      searchText: searchText,
      attributeId: attributeId,
    })
  }

  renderLatestSearch = () => {
    const { authenticatedUser, attributesMapByIdForSelect } = this.props
    const searchValues = _get(authenticatedUser, "latestSearch.customers", {})

    if (_isEmpty(searchValues)) {
      return null
    }
    let attributeText = "All attributes"
    if (attributesMapByIdForSelect.size > 0) {
      let lastSearches = searchValues.map(searchValue => {
        let searched = ""
        if (searchValue.searchText) {
          searched = searchValue.searchText
        }

        if (searchValue.attributeId) {
          const attribute = attributesMapByIdForSelect.get(searchValue.attributeId)
          if (attribute) {
            attributeText = `${attribute.getIn(["attribute_source", "name"])}: ${attribute.get(
              "name",
            )}`
          } else {
            attributeText = "Attribute no longer exists"
          }
        }

        return {
          attributeText: attributeText,
          searchText: searched,
          attributeId: searchValue.attributeId,
        }
      })

      return (
        <div className="latest-search">
          <div className="last-search-label">Last search:</div>
          {lastSearches.map(searchedItem => (
            <div
              key={`${searchedItem.attributeId}-${searchedItem.searchText}`}
              className="search-box"
              onClick={() => this.repeatSearch(searchedItem.attributeId, searchedItem.searchText)}
            >
              <div className="searched-text">
                {searchedItem.searchText === "" ? (
                  <strong>───</strong>
                ) : (
                  <Tippy content={searchedItem.searchText}>
                    <strong>{shortenText(searchedItem.searchText, 15)}</strong>
                  </Tippy>
                )}
              </div>
              <Tippy content={searchedItem.attributeText}>
                <div className="bottom-text">
                  {searchedItem.attributeId !== null
                    ? shortenText(searchedItem.attributeText, 25)
                    : "All attributes"}
                </div>
              </Tippy>
            </div>
          ))}
        </div>
      )
    }
    return null
  }

  clearSearchText = () => {
    const { change } = this.props
    change("searchText", "")
  }

  render() {
    const {
      handleSubmit,
      customers,
      selectionSettings,
      attributesMapByIdForSelect,
      filterValues,
      results,
      isLoading,
      additionalAttribute,
    } = this.props
    const { message, isFetching: isLoadingNextPage, isFetchingFavouriteCustomers } = this.state

    let searchText = selectionSettings.search_text
      ? selectionSettings.search_text
      : selectionSettings.search_value

    const attributeId = _get(filterValues, "attributeId", null)

    return (
      <React.Fragment>
        <section className="customers-list wrapper">
          <PaperHeader size="small" className="customers-list-header" titleText="Customers search">
            <Form autoComplete="off" className="search-form" onSubmit={handleSubmit(this.onSubmit)}>
              <div className="customers-list-attribute-picker">
                <AttributePicker
                  attributeId={attributeId}
                  attributesMapByIdForSelect={attributesMapByIdForSelect}
                  isEditable
                  handleAttributeSelect={this.selectAttribute}
                  fixedSize="large-size"
                  placeholder="All attributes"
                  inputTextLimit={22}
                  isClearable
                />
              </div>
              <Field
                name="searchText"
                component={SearchField}
                placeholder="Search attributes, customers and more..."
                className="large search-box"
                autoFocus={true}
                type="text"
                handleClear={this.clearSearchText}
              />
            </Form>
          </PaperHeader>
          <Paper hasHeader={true} className="customers-list-content">
            {results > 0 && (
              <p className="number-of-results">
                <strong>{formatSearchNumber(results)}</strong>{" "}
                {results === 1 ? "result" : "results"}
              </p>
            )}
            {this.renderLatestSearch()}
            {message !== "" && message}
            {((isLoading && !isLoadingNextPage) || isFetchingFavouriteCustomers) && (
              <LoadingIndicator />
            )}

            {(!isLoading || isLoadingNextPage) && customers.size > 0 && (
              <React.Fragment>
                <Table className="customers-search">
                  <Thead stickyHeader>
                    <Th></Th>
                    <Th className="avatar">&nbsp;</Th>
                    <Th>ID</Th>
                    {additionalAttribute !== null && (
                      <Th className="additional-attribute">{additionalAttribute.name}</Th>
                    )}
                    <Th>Source</Th>
                    <Th>Attribute</Th>
                    <Th>Value</Th>
                    <Th className="chevron-head">&nbsp;</Th>
                  </Thead>
                  <Tbody>
                    {customers.map((customer, index) =>
                      this.renderCustomerRow(customer, index, searchText),
                    )}
                  </Tbody>
                </Table>
                {this.renderWaypoint()}
              </React.Fragment>
            )}
          </Paper>
        </section>
      </React.Fragment>
    )
  }
}

CustomersList.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  customers: PropTypes.instanceOf(List).isRequired,
  selectionSettings: PropTypes.instanceOf(Record).isRequired,
  moreCustomersExist: PropTypes.bool.isRequired,
  selectedAttributeId: PropTypes.string,
  attributesMapByIdForSelect: PropTypes.instanceOf(Map).isRequired,
  results: PropTypes.number,
  authenticatedUser: PropTypes.object.isRequired,
  setCustomersIterator: PropTypes.func.isRequired,
  setLatestSearch: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  customers: getCustomerSearchesData(state),
  selectionSettings: getCustomerSearchesSelectionSettings(state),
  moreCustomersExist: hasCustomerSearchesMoreResults(state),
  selectedAttributeId: getCustomerSearchesSelectedAttributeId(state),
  additionalAttribute: getCustomerSearchesAdditionalAttribute(state),
  results: getCustomerSearchesResultsCount(state),
  isShowingFavorites: getCustomerSearchesFavoritesFlag(state),
  isLoading: getCustomerSearchesLoadingFlag(state),
  attributesMapByIdForSelect: getAttributesMapByIdForSelect(state),
  filterValues: getFormValues("SearchCustomerForm")(state),
  authenticatedUser: state.authenticatedUser,
})

export default reduxForm({
  form: "SearchCustomerForm",
  touchOnBlur: false,
  destroyOnUnmount: false,
})(
  connect(mapStateToProps, {
    fulltextCustomerSearch,
    fulltextCustomerAttributeSearch,
    listFavoriteCustomers,
    resetCustomerSearchResults,
    setCustomersIterator,
    setLatestSearch,
    toggleFavoriteCustomer,
    showToast,
  })(CustomersList),
)
