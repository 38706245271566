import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { List, Map, Record } from "immutable"
import { Radar, Polar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import _toInteger from "lodash/toInteger"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import _has from "lodash/has"

// ui components
import Paper from "components/UI/elements/Paper"
import CustomerAvatar from "components/UI/elements/CustomerAvatar"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button/Button"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable"

// actions
import { setCustomersIteratorIndex } from "actions/customersIterator.action"
import {
  fulltextCustomerSearch,
  fulltextCustomerAttributeSearch,
} from "actions/customerSearch.action"
import {
  storeSegmentCustomers,
  initSegmentCustomers,
  dispatchSegmentCustomersError,
} from "resources/segment/segmentCustomers/segmentCustomersActions"
import { showToast } from "actions/toast.action"
import { toggleFavoriteCustomer } from "actions/authenticatedUser.action"

// selectors
import { getCustomersIterator } from "selectors/customerIterator.selector"
import {
  hasCustomerSearchesMoreResults,
  getCustomerSearchesSelectionSettings,
  getCustomerSearchesSelectedAttributeId,
} from "selectors/customerSearch.selector"
import { getSegmentCustomersState } from "resources/segment/segmentCustomers/segmentCustomersSelectors"

// helpers
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { goBackInHistory } from "helpers/backButton.helper"
import { getRoutePath } from "routes"
import { hasAccess } from "helpers/authenticatedUser.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "helpers/compoundAttribute.helper"
import { isJSONString } from "helpers/validators.helper"
import { SEGMENT, TOAST, TOASTS } from "sharedConstants"
import PendingPromise from "helpers/pendingPromise.helper"

import "./Header.scss"
import { SocketContext } from "context/socket"
import InfoTooltip from "components/UI/elements/InfoTooltip"

const radarOptions = {
  responsive: false,
  scale: {
    gridLines: {
      circular: true,
      color: "#efefef",
    },
    ticks: {
      display: false,
      stepSize: 25,
      suggestedMin: 0,
      suggestedMax: 100,
    },
    pointLabels: {
      fontFamily: "'Inter UI', sans-serif",
      fontSize: 12,
      color: "#777777",
      lineHeight: 1,
    },
    angleLines: {
      color: "#efefef",
    },
  },
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 3,
    },
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.borderColor
      },
      color: "white",
      borderRadius: 3,
      padding: 2,
      font: {
        size: 10,
        weight: "normal",
      },
      anchor: "end",
      align: "end",
      offset: -10,
    },
  },
}

const polarOptions = {
  responsive: false,
  scale: {
    ticks: {
      display: false,
    },
    pointLabels: {
      fontFamily: "'Inter UI', sans-serif",
      fontSize: 12,
      color: "#777777",
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 3,
    },
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.borderColor
      },
      color: "black",
      borderRadius: 3,
      padding: 2,
      font: {
        size: 10,
        weight: "normal",
      },
      anchor: "end",
      align: "end",
      offset: -10,
    },
  },
}

const breakRadarLabelIfLong = label => {
  if (label && label.length > 20) {
    const indices = [...label].flatMap((char, i) => (char === " " ? i + 1 : []))
    let breakPoint = -1
    let diff = label.length
    indices.forEach(idx => {
      const iDiff = Math.abs(label.length / 2 - idx)
      if (iDiff < diff) {
        breakPoint = idx
        diff = iDiff
      }
    })
    if (breakPoint !== -1) {
      return [label.substring(0, breakPoint - 1), label.substring(breakPoint)]
    }
  }
  return label
}

class Header extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      usedCustomersNavigation: false,
      navigatingForward: false,
      navigatingBackward: false,
      forwardButtonDisabled: false,
      isTogglingFavorite: false,
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      customersIterator,
      segmentCustomers,
      hasCustomerSearchMoreResults,
      dispatchSegmentCustomersError,
      storeSegmentCustomers,
      showToast,
    } = this.props
    const index = customersIterator.get("index")
    const customerIds = customersIterator.get("customerIds")
    if (index !== null) {
      // chech if visited customer is not last in mem, if yes, fetch more when possible
      if (customersIterator.get("segmentId")) {
        // segment customers pagination
        const currentSegmentCustomers = segmentCustomers.get(customersIterator.get("segmentId"))
        this.context.on("segment_customer_entities_response", msg => {
          this.setState({
            forwardButtonDisabled: false,
          })
          if (_has(msg, "error")) {
            dispatchSegmentCustomersError(customersIterator.get("segmentId"))
            showToast(msg.error, TOAST.TYPE.ERROR)
          } else {
            storeSegmentCustomers(customersIterator.get("segmentId"), msg)
          }
        })

        if ([customerIds.size - 1, customerIds.size - 2].includes(index)) {
          if (currentSegmentCustomers.get("hasMoreItems")) {
            this.loadMoreSegmentCustomers()
          }
        }
      } else {
        // customer search pagination
        if ([customerIds.size - 1, customerIds.size - 2].includes(index)) {
          if (hasCustomerSearchMoreResults) {
            this.loadMoreSearchCustomers()
          }
        }
      }
      if (index === customerIds.size - 1) {
        this.setState({ forwardButtonDisabled: true })
      }
    }
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    this.context.off("segment_customer_entities_response")
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.customerDataReady &&
      this.props.customerDataReady &&
      (this.state.navigatingForward || this.state.navigatingBackward)
    ) {
      this.setState({
        navigatingForward: false,
        navigatingBackward: false,
      })
    }

    const { customersIterator, segmentCustomers, hasCustomerSearchMoreResults } = this.props
    const index = customersIterator.get("index")
    const customerIds = customersIterator.get("customerIds")
    if (index !== null && index !== prevProps.customersIterator.get("index")) {
      if (customersIterator.get("segmentId")) {
        const currentSegmentCustomers = segmentCustomers.get(customersIterator.get("segmentId"))
        if (index === customerIds.size - 1 && !this.state.forwardButtonDisabled) {
          this.setState({ forwardButtonDisabled: true })
        } else if (index === customerIds.size - 2 && currentSegmentCustomers.get("hasMoreItems")) {
          this.loadMoreSegmentCustomers()
        }
      } else {
        if (index === customerIds.size - 1 && !this.state.forwardButtonDisabled) {
          this.setState({ forwardButtonDisabled: true })
        } else if (index === customerIds.size - 2 && hasCustomerSearchMoreResults) {
          this.loadMoreSearchCustomers()
        }
      }
    }
  }

  toggleFavoriteCustomer = async () => {
    this.setState({ isTogglingFavorite: true })
    try {
      const { showToast, toggleFavoriteCustomer, isCustomerFavorite, customerId } = this.props
      await toggleFavoriteCustomer(customerId)
      const { ADDED, REMOVED } = TOASTS.FAVORITE_CUSTOMERS
      showToast(isCustomerFavorite ? REMOVED : ADDED)
    } catch {
      // noop
    }
    this.setState({ isTogglingFavorite: false })
  }

  loadMoreSegmentCustomers = () => {
    const { customersIterator, segmentCustomers, initSegmentCustomers } = this.props
    const segmentId = customersIterator.get("segmentId")
    const currentSegmentCustomers = segmentCustomers.get(segmentId)

    initSegmentCustomers(segmentId)
    this.context.emit("segment_customer_entities", {
      segment_id: segmentId,
      offset:
        currentSegmentCustomers.getIn(["selectionSettings", "offset"]) +
        SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
      limit: SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
    })
  }

  loadMoreSearchCustomers = () => {
    const {
      customerSearchSelectionSettings: selectionSettings,
      customerSearchAttributeId: selectedAttributeId,
      fulltextCustomerSearch,
      fulltextCustomerAttributeSearch,
    } = this.props
    if (selectedAttributeId) {
      fulltextCustomerAttributeSearch(
        selectedAttributeId,
        selectionSettings.search_value,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        1,
      )
        .then(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
        .catch(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
    } else {
      fulltextCustomerSearch(
        selectionSettings.search_text,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        1,
      )
        .then(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
        .catch(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
    }
  }

  toggleExpanded = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }))
  }

  previousCustomer = () => {
    const { customersIterator, history, setCustomersIteratorIndex } = this.props
    if (!this.state.navigatingBackward) {
      this.setState({
        navigatingBackward: true,
        usedCustomersNavigation: true,
        forwardButtonDisabled: false,
      })
      const customerIds = customersIterator.get("customerIds")
      if (List.isList(customerIds)) {
        const newIndex = customersIterator.get("index") - 1
        if (newIndex >= 0) {
          const customerId = customerIds.get(newIndex)
          if (customerId) {
            setCustomersIteratorIndex(newIndex)
            history.push(getRoutePath("customers.detail", { id: customerId }))
          }
        }
      }
    }
  }

  nextCustomer = () => {
    const { customersIterator, history, setCustomersIteratorIndex } = this.props
    if (!this.state.navigatingForward) {
      this.setState({
        navigatingForward: true,
        usedCustomersNavigation: true,
      })
      const customerIds = customersIterator.get("customerIds")
      if (List.isList(customerIds)) {
        const newIndex = customersIterator.get("index") + 1
        const customerId = customerIds.get(newIndex)
        if (customerId) {
          setCustomersIteratorIndex(newIndex)
          history.push(getRoutePath("customers.detail", { id: customerId }))
        }
      }
    }
  }

  goBack = () => {
    const { history, customersIterator } = this.props
    const { usedCustomersNavigation } = this.state
    if (!usedCustomersNavigation) {
      goBackInHistory(history, getRoutePath("customers"))()
    } else {
      if (customersIterator.get("segmentId")) {
        history.push(getRoutePath("segments.detail", { id: customersIterator.get("segmentId") }))
      } else {
        history.push(getRoutePath("customers"))
      }
    }
  }

  render() {
    const {
      channelEngagementAttributes,
      customerId,
      contactInfoAttributes,
      customerAttributes,
      isGlobalSettingsFulfilled,
      customersIterator,
      isCustomerFavorite,
    } = this.props
    const { navigatingForward, navigatingBackward, forwardButtonDisabled, isTogglingFavorite } =
      this.state
    const showAsFavorite =
      (isCustomerFavorite && !isTogglingFavorite) || (!isCustomerFavorite && isTogglingFavorite)
    let data = {}
    if (channelEngagementAttributes.size < 3) {
      data = {
        labels: channelEngagementAttributes
          .map(attribute => breakRadarLabelIfLong(attribute.name))
          .toArray(),
        datasets: [
          {
            data: channelEngagementAttributes
              .map(attribute => {
                if (List.isList(customerAttributes.get(attribute.id))) {
                  const val = _toInteger(customerAttributes.getIn([attribute.id, "0"], 0))
                  return val > 100 ? 0 : val
                }
                return 0
              })
              .toArray(),
            backgroundColor: ["#FE7F66", "#FABE53"],
          },
        ],
      }
    } else {
      data = {
        labels: channelEngagementAttributes
          .map(attribute => breakRadarLabelIfLong(attribute.name))
          .toArray(),
        datasets: [
          {
            backgroundColor: "rgba(254, 127, 102, 0.75)",
            borderColor: "#FE7F66",
            pointBackgroundColor: "#C4C4C4",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#FE7F66",
            data: channelEngagementAttributes
              .map(attribute => {
                if (List.isList(customerAttributes.get(attribute.id))) {
                  const val = _toInteger(customerAttributes.getIn([attribute.id, "0"], 0))
                  return val > 100 ? 0 : val
                }
                return 0
              })
              .toArray(),
          },
        ],
      }
    }

    return (
      <header className="customer-detail-header">
        <div className="customer-detail-header-row1">
          <div className="above-paper-left">
            <IconButton
              color={COLOR.GREY}
              onClick={this.goBack}
              className="back-button"
              iconName="chevron-left"
            />
          </div>
          <div className="above-paper-right">
            {customersIterator.get("index") !== null && (
              <Button
                color="white"
                size="small"
                className={`left-button ${navigatingBackward ? "loading" : ""}`}
                onClick={this.previousCustomer}
                disabled={customersIterator.get("index") === 0}
              >
                <FontAwesomeIcon icon={["far", "chevron-left"]} className="icon" /> Previous
                customer
              </Button>
            )}
            <Button
              color={showAsFavorite ? "primary" : "white"}
              size="small"
              onClick={this.toggleFavoriteCustomer}
            >
              <FontAwesomeIcon icon={[showAsFavorite ? "fas" : "far", "star"]} /> favorite
            </Button>
            {customersIterator.get("index") !== null && (
              <Button
                color="white"
                size="small"
                className={`right-button ${navigatingForward ? "loading" : ""}`}
                onClick={this.nextCustomer}
                disabled={forwardButtonDisabled}
              >
                Next customer <FontAwesomeIcon icon={["far", "chevron-right"]} className="icon" />
              </Button>
            )}
          </div>

          <Paper className="basic-info">
            <div className="customer-title-avatar">
              <CustomerAvatar className="customer-avatar" />
              <h2>Customer {customerId}</h2>
            </div>
            <h3 className="basic-info-title">Customer Identifiers</h3>
            <div className="customer-attributes-values-list">
              {contactInfoAttributes.map(attribute => {
                const hasValue = List.isList(customerAttributes.get(attribute.id))
                const compoundAttributeView = isAttributeCompound(attribute.data_type)
                const firstCompoundAttributeValue = customerAttributes.getIn([attribute.id, 0])

                if (compoundAttributeView && isJSONString(firstCompoundAttributeValue)) {
                  const subAttributes = List(getCompoundAttributeSubAttributes(attribute.data_type))
                  const values =
                    hasValue && customerAttributes.get(attribute.id).map(val => JSON.parse(val))
                  return (
                    <div className="row compound-attr-view" key={attribute.id}>
                      <div className="attrname">
                        <div className="name-tags">
                          <span className="name-wrapper">{attribute.name}</span>
                        </div>
                      </div>
                      <div className="attrvalue text-grey align-right">
                        <CompoundAttributeValuesTable
                          subAttributes={subAttributes}
                          values={values}
                        />
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="row" key={attribute.id}>
                      <div className={!hasValue ? "no-value attrname" : "attrname"}>
                        <div className="name-tags">
                          <span className="name-wrapper">{attribute.name}</span>
                        </div>
                      </div>
                      <div
                        className={`attrvalue text-grey align-right ${!hasValue ? "no-value" : ""}`}
                      >
                        <div className="value-wrapper">
                          {hasValue &&
                            customerAttributes
                              .get(attribute.id)
                              .map((customerAttributeValue, key) => (
                                <span className="customer-attribute-value" key={key}>
                                  {getUserFriendlyValueFormat(
                                    customerAttributeValue,
                                    attribute.data_type,
                                    ["date", "datetime"].includes(attribute.data_type)
                                      ? true
                                      : false,
                                  )}
                                </span>
                              ))}
                          {!hasValue && "—"}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </Paper>
          <Paper className="chart-box">
            <h3>
              <InfoTooltip>
                This chart helps you to understand how data sources are popular among your clients.
              </InfoTooltip>{" "}
              Channel engagement
            </h3>
            <div className="chart-wrapper">
              {isGlobalSettingsFulfilled && channelEngagementAttributes.size === 0 && (
                <p className="chart-na">
                  {hasAccess.administration.globalSettings() && (
                    <span>
                      The data source for this chart has not been set.
                      <br />
                      Please go to the{" "}
                      <Link to={getRoutePath("administration.settings")}>Settings tab</Link>.
                    </span>
                  )}
                  {!hasAccess.administration.globalSettings() && (
                    <span>
                      The data source for this chart has not been set.
                      <br />
                      Please contact your administrator.
                    </span>
                  )}
                </p>
              )}
              {isGlobalSettingsFulfilled &&
                channelEngagementAttributes.size < 3 &&
                channelEngagementAttributes.size > 0 && (
                  <Polar
                    width={440}
                    height={250}
                    data={data}
                    options={polarOptions}
                    plugins={[ChartDataLabels]}
                  />
                )}
              {isGlobalSettingsFulfilled && channelEngagementAttributes.size >= 3 && (
                <Radar
                  width={440}
                  height={250}
                  data={data}
                  options={radarOptions}
                  plugins={[ChartDataLabels]}
                />
              )}
            </div>
          </Paper>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  customerId: PropTypes.string.isRequired,
  contactInfoAttributes: PropTypes.instanceOf(List).isRequired,
  channelEngagementAttributes: PropTypes.instanceOf(List).isRequired,
  customerAttributes: PropTypes.instanceOf(Map).isRequired,
  isGlobalSettingsFulfilled: PropTypes.bool.isRequired,
  customersIterator: PropTypes.instanceOf(Map).isRequired,
  setCustomersIteratorIndex: PropTypes.func.isRequired,
  customerDataReady: PropTypes.bool.isRequired,
  segmentCustomers: PropTypes.instanceOf(Map).isRequired,
  hasCustomerSearchMoreResults: PropTypes.bool.isRequired,
  customerSearchSelectionSettings: PropTypes.instanceOf(Record).isRequired,
  customerSearchAttributeId: PropTypes.string,
  fulltextCustomerSearch: PropTypes.func.isRequired,
  fulltextCustomerAttributeSearch: PropTypes.func.isRequired,
  storeSegmentCustomers: PropTypes.func.isRequired,
  initSegmentCustomers: PropTypes.func.isRequired,
  dispatchSegmentCustomersError: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => ({
  customersIterator: getCustomersIterator(state),
  segmentCustomers: getSegmentCustomersState(state),
  hasCustomerSearchMoreResults: hasCustomerSearchesMoreResults(state),
  customerSearchSelectionSettings: getCustomerSearchesSelectionSettings(state),
  customerSearchAttributeId: getCustomerSearchesSelectedAttributeId(state),
  isCustomerFavorite: state.authenticatedUser.data.cdp_settings?.favourite_customers?.find(
    ({ customer_entity_id }) => customer_entity_id === props.customerId,
  ),
})

export default withRouter(
  connect(mapStateToProps, {
    setCustomersIteratorIndex,
    fulltextCustomerSearch,
    fulltextCustomerAttributeSearch,
    storeSegmentCustomers,
    initSegmentCustomers,
    dispatchSegmentCustomersError,
    showToast,
    toggleFavoriteCustomer,
  })(Header),
)
