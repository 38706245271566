import requestFactory from "api/request"
import { Tag } from "resources/tag/tagTypes"
import { Flag, OrderDir } from "types/util"
import { segmentFromAPIFormat, segmentToAPIFormat } from "./utilities/segmentConditionsAPITransform"
import {
  SegmentReturnType,
  SegmentListResponse,
  SegmentCreatePayload,
  SegmentModifyPayload,
  Segment,
  FeaturedSegmentListResponse,
  SmartSegmentListResponse,
} from "./segmentTypes"

export const segment = {
  async list<T extends Flag = 0>(
    offset = 0,
    limit = 50,
    order_by: keyof SegmentReturnType<T> = "id",
    order_dir: OrderDir = "DESC",
    name_filter = "",
    tag_ids: Tag["id"][] = [],
    load_full_structure: T = 0 as T,
    show_my: Flag = 0,
    show_shared_with_me: Flag = 0,
    show_foreign: Flag = 0,
  ): Promise<SegmentListResponse<T>> {
    const result = await requestFactory("get", "/segments", {
      offset,
      limit,
      order_by,
      order_dir,
      name_filter,
      load_full_structure,
      tag_ids,
      show_my,
      show_shared_with_me,
      show_foreign,
    })

    return {
      ...result,
      segments: result.segments.map(segmentFromAPIFormat),
    }
  },
  async create(data: SegmentCreatePayload): Promise<{ segment: Segment }> {
    const result = await requestFactory("post", "/segments", data)

    return { segment: segmentFromAPIFormat(result.segment) }
  },
  async retrieve(id: Segment["id"]): Promise<{ segment: Segment }> {
    const result = await requestFactory("get", `/segments/${id}`)

    return { segment: segmentFromAPIFormat(result.segment) }
  },
  async modify(id: Segment["id"], data: SegmentModifyPayload): Promise<{ segment: Segment }> {
    const result = await requestFactory("patch", `/segments/${id}`, segmentToAPIFormat(data))

    return { segment: segmentFromAPIFormat(result.segment) }
  },
  delete(id: Segment["id"]): Promise<void> {
    return requestFactory("delete", `/segments/${id}`)
  },
  async clone<T extends Flag = 0>(
    id: Segment["id"],
    data: { featured: 0 | 1 } = { featured: 0 },
    load_full_structure: T = 0 as T,
  ): Promise<{ segment: SegmentReturnType<T> }> {
    const result = await requestFactory(
      "post",
      `/segments/${id}?load_full_structure=${load_full_structure}`,
      data,
    )

    return { segment: segmentFromAPIFormat(result.segment) as SegmentReturnType<T> }
  },
}

export const smartSegment = {
  async list<T extends Flag = 0>(
    offset = 0,
    limit = 50,
    order_by: keyof SegmentReturnType<T> = "id",
    order_dir: OrderDir = "DESC",
    load_full_structure: T = 0 as T,
  ): Promise<SmartSegmentListResponse<T>> {
    const result = await requestFactory("get", "/prebuilt_segments", {
      offset,
      limit,
      order_by,
      order_dir,
      load_full_structure,
    })

    return {
      ...result,
      prebuilt_segments: result.prebuilt_segments.map(segmentFromAPIFormat),
    }
  },
}

export const featuredSegment = {
  async list<T extends Flag = 0>(
    offset = 0,
    limit = 50,
    name_filter = "",
    order_by: keyof SegmentReturnType<T> | "author_name" = "name",
    order_dir: OrderDir = "ASC",
    tag_ids: Tag["id"][] = [],
    load_full_structure: T = 0 as T,
  ): Promise<FeaturedSegmentListResponse<T>> {
    const result = await requestFactory("get", "/featured_segments", {
      offset,
      limit,
      name_filter,
      order_by,
      order_dir,
      tag_ids,
      load_full_structure,
    })

    return {
      ...result,
      featured_segments: result.featured_segments.map(segmentFromAPIFormat),
    }
  },
}
