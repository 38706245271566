import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _map from "lodash/map"
import _toString from "lodash/toString"
import _get from "lodash/get"
import moment from "moment"
import SimpleBar from "simplebar-react"

import { api } from "api"
import { showToast } from "actions/toast.action"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { getUsersData } from "selectors/user.selector"
import { shortenMarkdownText } from "helpers/string.helper"
import NoteModel from "resources/segment/segmentNote/segmentNoteModel"
import { MOMENT, TOAST } from "sharedConstants"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import SingleView from "./SingleView"

import "./SegmentNotes.scss"

const TYPE = {
  LIST: "NOTES_LIST",
  SINGLE: "NOTE_SINGLE",
}

const defaultNoteObject = {
  id: 0,
  name: "",
  content: "",
}

class SegmentNotes extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      notes: List(),
      isFetching: true,
      type: TYPE.LIST,
      selectedNote: defaultNoteObject,
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    this._fetchAllSegmentsNotes()
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      window.addEventListener("keyup", this.handleKeyUp, false)
    } else if (!this.props.isOpen && prevProps.isOpen) {
      window.removeEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    this.pendingPromises.cancelAll()
  }

  handleKeyUp = evt => {
    const { toggleNotes } = this.props
    const keys = {
      27: () => {
        evt.preventDefault()
        toggleNotes()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  _fetchAllSegmentsNotes = async () => {
    const { segmentId } = this.props
    const caller = new AllResourceItemsFetcher()
    const notesRequest = this.pendingPromises.create(
      caller
        .setEndpointCall((offset, limit, loadFullStructure) =>
          api.segment.note.list(segmentId, offset, limit, "modified", "DESC", loadFullStructure),
        )
        .setDataPath("segment_notes")
        .setLoadFullStructure(0)
        .run(),
    )

    notesRequest.promise
      .then(data => {
        this.setState({
          isFetching: false,
          notes: List(
            _map(
              data,
              note =>
                new NoteModel({
                  id: note.id,
                  name: note.name,
                  segment_id: note.segment_id,
                  content: note.content,
                  excerpt: shortenMarkdownText(note.content, 80),
                  modified: note.modified,
                  user_id: note.user_id,
                }),
            ),
          ),
        })
        this.pendingPromises.remove(notesRequest)
      })
      .catch(() => {
        this.pendingPromises.remove(notesRequest)
      })
  }

  openSingleView = note => () => {
    this.setState({
      type: TYPE.SINGLE,
      selectedNote: note ? note.toJS() : defaultNoteObject,
    })
  }

  closeSingleView = () => {
    this.setState({
      type: TYPE.LIST,
    })
  }

  deleteNote = note => async evt => {
    const { showToast } = this.props
    evt.stopPropagation()
    await api.segment.note.delete(this.props.segmentId, note.id)
    showToast("Note deleted.", TOAST.TYPE.SUCCESS)
    this.setState(prevState => ({
      notes: prevState.notes.delete(prevState.notes.findIndex(item => item.id === note.id)),
    }))
  }

  saveNote = async attributes => {
    const { segmentId, showToast } = this.props
    if (attributes.id !== 0) {
      // save modifications
      const response = await api.segment.note.modify(segmentId, attributes.id, {
        name: attributes.name,
        content: attributes.content,
      })
      if (response) {
        const note = new NoteModel(_get(response, "segment_note", {})).set(
          "excerpt",
          shortenMarkdownText(_get(response, "segment_note.content", ""), 80),
        )
        showToast("Note edited.", TOAST.TYPE.SUCCESS)
        this.setState(prevState => ({
          notes: prevState.notes
            .delete(prevState.notes.findIndex(item => item.id === note.id))
            .unshift(note),
          type: TYPE.LIST,
        }))
      }
    } else {
      // create new
      const response = await api.segment.note.create(segmentId, {
        name: attributes.name,
        content: attributes.content,
      })
      if (response) {
        const note = new NoteModel(_get(response, "segment_note", {})).set(
          "excerpt",
          shortenMarkdownText(_get(response, "segment_note.content", ""), 80),
        )
        showToast("Note created.", TOAST.TYPE.SUCCESS)
        this.setState(prevState => ({
          notes: prevState.notes.unshift(note),
          type: TYPE.LIST,
        }))
      }
    }
  }

  render() {
    const { notes, type, selectedNote, isFetching } = this.state
    const { users, isEditable, isOpen } = this.props

    // const notesCount = isFetching ? null : notes.size
    return (
      <section className="notes">
        {!isFetching && typeof notes.size === "number" && (
          <div className="note-count-badge">{notes.size}</div>
        )}
        {isOpen && (
          <div>
            <div className={`notes-box ${type === TYPE.SINGLE ? "single-view" : ""}`}>
              {type === TYPE.LIST && (
                <div>
                  <SimpleBar className="scroll">
                    {notes.map(note => (
                      <div
                        className="note-excerpt"
                        key={note.id}
                        onClick={this.openSingleView(note)}
                      >
                        <h4>{note.name}</h4>
                        <p className="author-date">
                          Last edit: <span>{users.getIn([_toString(note.user_id), "name"])}</span>
                          {", "}
                          {moment.utc(note.modified).local().format(MOMENT.DATE_FORMAT)}
                        </p>
                        <p>{note.excerpt}</p>
                        <div className="delete-column">
                          <div className="center">
                            <IconButton
                              color={COLOR.RED}
                              onClick={this.deleteNote(note)}
                              disabled={!isEditable}
                              iconName="trash-alt"
                              className="delete-note"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </SimpleBar>
                  <button
                    className={`add-note ${notes.size === 0 ? "no-border-top" : ""}`}
                    onClick={this.openSingleView()}
                    disabled={!isEditable}
                  >
                    Add note <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                  </button>
                </div>
              )}
              {type === TYPE.SINGLE && (
                <SingleView
                  initialValues={selectedNote}
                  handleBackAction={this.closeSingleView}
                  handleNoteSave={this.saveNote}
                  isEditable={isEditable}
                />
              )}
            </div>
          </div>
        )}
      </section>
    )
  }
}

SegmentNotes.propTypes = {
  segmentId: PropTypes.number.isRequired,
  users: PropTypes.instanceOf(Map).isRequired,
  isEditable: PropTypes.bool.isRequired,
  toggleNotes: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  users: getUsersData(state),
})

export default connect(mapStateToProps, { showToast })(SegmentNotes)
