import PaperHeader from "components/UI/elements/PaperHeader"
import TextField from "components/UI/elements/TextField/HookFormTextField"
import { isEmptyField, validateUrl, required, validImageFile } from "helpers/validators.helper"
import { SubmitHandler, useForm, Controller, FieldError } from "react-hook-form"
import {
  PWBPosition,
  PopupWebBanner,
  PWBFeSettings,
  PWBContentType,
  WBMinimizedHideCondition,
  PWBAnimation,
  PWBCloseButton,
  PWBDisplayOn,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import styles from "./PopupWebBannersForm.module.scss"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import BannerPreview from "./BannerPreview/BannerPreview"
import _toInteger from "lodash/toInteger"
import SelectField from "components/UI/elements/SelectField"
import { hasAccess } from "helpers/authenticatedUser.helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Prompt, useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import classnames from "classnames"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import _forEach from "lodash/forEach"
import _isEmpty from "lodash/isEmpty"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-tomorrow"
import ToggleButton from "components/UI/elements/ToggleButton"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { WBCondition } from "resources/webBanner/webBannerConditionTypes"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import Condition, {
  getNewWebBannerCondition,
} from "../../../UI/components/WebBannerCondition/WebBannerCondition"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateWebBannerCondition } from "../../../UI/components/WebBannerCondition/validation"
import {
  FrequencyCapTypeOption,
  FREQUENCY_CAP_TYPE_OPTIONS,
} from "../PopupWebBannerComponentsTypes"
import classNames from "classnames"
import FileField from "components/UI/elements/FileField/FileField"
import { showToast } from "actions/toast.action"
import { TOAST } from "sharedConstants"
import { useDispatch } from "react-redux"
import { copyPopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBActions"
import Tag from "components/UI/elements/Tag"
import { shortenTextInMiddle } from "helpers/string.helper"
import { isEmpty, without } from "ramda"
import CloseButtonFields from "./CloseButtonFields/CloseButtonFields"
import Tippy from "@tippyjs/react"

type PositionOption = { label: string; value: PWBPosition }
export const POSITION_OPTIONS: PositionOption[] = [
  { label: "Top left", value: "top_left" },
  { label: "Top middle", value: "top" },
  { label: "Top right", value: "top_right" },
  { label: "Middle left", value: "left" },
  { label: "Middle", value: "middle" },
  { label: "Middle right", value: "right" },
  { label: "Bottom left", value: "bottom_left" },
  { label: "Bottom middle", value: "bottom" },
  { label: "Bottom right", value: "bottom_right" },
]
type AnimationOption = { label: string; value: PWBAnimation }
export const ANIMATION_OPTIONS: AnimationOption[] = [
  { label: "None", value: "none" },
  { label: "Fade In", value: "fade_in" },
  { label: "Slide In", value: "slide_in" },
]
type PriorityOption = { label: string; value: number }
export const PRIORITY_OPTIONS: PriorityOption[] = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
]

type HideOperatorOption = { label: string; value: WBMinimizedHideCondition["operator"] }
export const HIDE_OPERATOR_OPTIONS: HideOperatorOption[] = [
  { value: "equals_any", label: "equals any" },
  { value: "not_equals_any", label: "doesn't equal any" },
  { value: "contains_any", label: "contains any" },
  { value: "not_contains_any", label: "doesn't contain any" },
]

type DisplayOnOption = { label: string; value: PWBDisplayOn }
export const DISPLAY_CONDITION_OPTIONS: DisplayOnOption[] = [
  { value: "page_load", label: "Page load" },
  { value: "scroll", label: "Scroll" },
  { value: "exit", label: "Exit" },
]

export type PopupWebBannersFormValues = {
  name: PopupWebBanner["name"]
  condition: PopupWebBanner["settings"]["condition"] | null
  frequency_cap_window_type: FrequencyCapTypeOption
  frequency_cap_window_size: number | string | null
  frequency_cap_window_max_count: number | string
  frequency_cap_user_max_count: number | string | null
  frequency_cap_banner_max_count: number | string | null
  priority: PriorityOption
  position: PositionOption
  html: PopupWebBanner["settings"]["content"]["html"] | null
  minimized_html: PopupWebBanner["settings"]["minimized_content"]["html"] | null
  destination_url: PopupWebBanner["settings"]["content"]["destination_url"] | null
  image_url?: string
  image?: FileList
  minimized_image_url?: string
  minimized_image?: FileList
  width: PWBFeSettings["width"] | null
  minimized_width?: PWBFeSettings["minimized_width"] | null
  disabled: boolean
  minimized: boolean
  content_type?: PWBContentType
  minimized_content_type?: PWBContentType
  minimized_position?: PositionOption
  display_minimized_first?: boolean
  minimized_hide_operator?: HideOperatorOption
  minimized_hide_values: string[]
  minimized_hide_input?: string
  minimized_margin_x?: number
  minimized_margin_y?: number
  margin_x?: number
  margin_y?: number
  animation?: AnimationOption
  minimized_animation?: AnimationOption
  close_button: {
    disabled: boolean
    css?: PWBCloseButton["css"]
  }
  minimized_close_button: {
    disabled: boolean
    css?: PWBCloseButton["css"]
  }
  display_on: {
    action: DisplayOnOption
    value: number | null
  }
}
export type PopupWebBannersFormData = Omit<
  PopupWebBannersFormValues,
  | "image_url"
  | "image"
  | "position"
  | "frequency_cap_window_type"
  | "frequency_cap_window_size"
  | "frequency_cap_window_max_count"
  | "frequency_cap_user_max_count"
  | "frequency_cap_banner_max_count"
  | "priority"
  | "minimized_position"
  | "animation"
  | "minimized_animation"
  | "display_on"
> & {
  image_local: string | null
  image_name: string | null
  image_remote: string | null
  minimized_image_local: string | null
  minimized_image_name: string | null
  minimized_image_remote: string | null
  minimized_position?: PWBPosition
  position: PWBPosition
  animation?: PWBAnimation
  minimized_animation?: PWBAnimation
  frequency_cap: PopupWebBanner["settings"]["frequency_cap"]
  priority: PopupWebBanner["settings"]["priority"]
  display_on: {
    action: PWBDisplayOn
    value: number | null
  }
}
type PopupWebBannersFormProps = {
  initialValues?: PopupWebBannersFormValues
  onSubmit: (data: PopupWebBannersFormData) => void
  onDelete?: () => void
}
type WebBannerFormType = "create" | "edit"
export type WebBannerType = "image" | "html"

const validateConditionTree = makeConditionTreeValidator(validateWebBannerCondition)

export default function PopupWebBannersForm({
  initialValues,
  onSubmit,
  onDelete,
}: PopupWebBannersFormProps) {
  const dispatch = useDispatch()
  const type: WebBannerFormType = initialValues ? "edit" : "create"
  const history = useHistory()
  const { id } = useParams<{ id: PopupWebBanner["id"] }>()
  const [minimizedBannerTab, setMinimizedBannerTab] = useState<"general" | "close" | "hide">(
    "general",
  )
  const [bannerTab, setBannerTab] = useState<"general" | "close">("general")

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm<PopupWebBannersFormValues>({
    defaultValues: initialValues ?? {
      disabled: true,
      priority: PRIORITY_OPTIONS.find(item => item.value === 0)!,
      condition: null,
      minimized: false,
      display_minimized_first: false,
      minimized_hide_values: [],
      margin_x: 20,
      margin_y: 20,
      minimized_margin_x: 20,
      minimized_margin_y: 20,
      close_button: {
        disabled: false,
        css: {
          color: "#222222",
          background_color: "#ffffff",
          border_color: "#cccccc",
        },
      },
      minimized_close_button: {
        disabled: false,
        css: {
          color: "#222222",
          background_color: "#ffffff",
          border_color: "#cccccc",
        },
      },
      display_on: {
        action: DISPLAY_CONDITION_OPTIONS.find(item => item.value === "page_load")!,
        value: null,
      },
    },
  })
  const [submitting, setSubmitting] = useState(false)
  const [bannerType, setBannerType] = useState<WebBannerType>(
    !initialValues ||
      (initialValues.content_type &&
        ["local_image", "remote_image"].includes(initialValues.content_type))
      ? "image"
      : "html",
  )
  const [minimizedBannerType, setMinimizedBannerType] = useState<WebBannerType>(
    !initialValues ||
      (initialValues.minimized_content_type &&
        ["local_image", "remote_image"].includes(initialValues.minimized_content_type))
      ? "image"
      : "html",
  )
  const [base64BannerImage, setBase64BannerImage] = useState<string | null>(null)
  const [base64BannerMinimizedImage, setBase64BannerMinimizedImage] = useState<string | null>(null)
  const [isCopying, setIsCopying] = useState(false)
  // @ts-ignore
  const position = watch("position")
  const minimized_position = watch("minimized_position")
  const imageUrl = watch("image_url")
  const minimizedImageUrl = watch("minimized_image_url")
  const html = watch("html")
  const minimized_html = watch("minimized_html")
  const width = watch("width")
  const minimized_width = watch("minimized_width")
  const minimized = watch("minimized")
  const closeButtonStyles = watch("close_button")
  const minimizedCloseButtonStyles = watch("minimized_close_button")
  const frequencyCapWindowType = watch("frequency_cap_window_type")
  const minimizedHideInputValue = watch("minimized_hide_input")
  const minimizedHideArrayValues = watch("minimized_hide_values")
  const customMarginX = watch("margin_x")
  const customMarginY = watch("margin_y")
  const customMinimizedMarginX = watch("minimized_margin_x")
  const customMinimizedMarginY = watch("minimized_margin_y")
  const animation = watch("animation")
  const minimizedAnimation = watch("minimized_animation")
  const displayOnAction = watch("display_on.action")
  const isEditable = hasAccess.webBanners.edit()

  useEffect(() => {
    if (frequencyCapWindowType?.value === "session") {
      setValue("frequency_cap_window_size", null)
      clearErrors("frequency_cap_window_size")
    }
  }, [frequencyCapWindowType, setValue, clearErrors])

  const submitForm: SubmitHandler<PopupWebBannersFormValues> = useCallback(
    async ({
      name,
      condition,
      frequency_cap_window_type,
      frequency_cap_window_size,
      frequency_cap_window_max_count,
      frequency_cap_user_max_count,
      frequency_cap_banner_max_count,
      priority,
      position,
      html,
      minimized_html,
      image_url,
      image,
      minimized_image_url,
      minimized_image,
      destination_url,
      width,
      minimized_width,
      disabled,
      minimized,
      minimized_position,
      display_minimized_first,
      minimized_hide_values,
      minimized_hide_operator,
      margin_x,
      margin_y,
      minimized_margin_x,
      minimized_margin_y,
      animation,
      minimized_animation,
      close_button,
      minimized_close_button,
      display_on,
    }) => {
      if (!submitting) {
        setSubmitting(true)
        const frequencyCapWindow: PopupWebBanner["settings"]["frequency_cap"]["per_user"]["window"] =
          frequency_cap_window_type.value === "session"
            ? {
                type: frequency_cap_window_type.value,
                max_display_count: _toInteger(frequency_cap_window_max_count),
              }
            : {
                type: frequency_cap_window_type.value,
                max_display_count: _toInteger(frequency_cap_window_max_count),
                size: _toInteger(frequency_cap_window_size),
              }
        const frequencyCap: PopupWebBanner["settings"]["frequency_cap"] = {
          per_user: {
            window: frequencyCapWindow,
          },
        }
        if (!isEmptyField(frequency_cap_user_max_count)) {
          frequencyCap.per_user.max_display_count = _toInteger(frequency_cap_user_max_count)
        }
        if (!isEmptyField(frequency_cap_banner_max_count)) {
          frequencyCap.max_display_count = _toInteger(frequency_cap_banner_max_count)
        }

        if (bannerType === "image" && (!image || !image[0]) && !image_url) {
          dispatch(
            showToast("One of 'Image upload' or 'Image URL' must be filled.", TOAST.TYPE.ERROR),
          )
          setSubmitting(false)
          return
        }

        if (_isEmpty(minimized_hide_values) && minimized_hide_operator) {
          dispatch(
            showToast(
              "Hide minimized banner values needs to be filled if operator is selected.",
              TOAST.TYPE.ERROR,
            ),
          )
          setSubmitting(false)
          return
        } else if (!_isEmpty(minimized_hide_values) && !minimized_hide_operator) {
          dispatch(
            showToast(
              "Hide minimized banner operator needs to be selected if values are filled.",
              TOAST.TYPE.ERROR,
            ),
          )
          setSubmitting(false)
          return
        } else if (!position) {
          dispatch(showToast("Banner position needs to be set.", TOAST.TYPE.ERROR))
          setSubmitting(false)
          return
        } else if (bannerType === "html" && !width) {
          dispatch(showToast("Banner width needs to be filled.", TOAST.TYPE.ERROR))
          setSubmitting(false)
          return
        }

        if (minimized) {
          if (
            minimizedBannerType === "image" &&
            (!minimized_image || !minimized_image["0"]) &&
            !minimized_image_url
          ) {
            dispatch(
              showToast(
                "One of 'Minimized image upload' or 'Minimized image URL' must be filled.",
                TOAST.TYPE.ERROR,
              ),
            )
            setSubmitting(false)
            return
          } else if (!minimized_position) {
            dispatch(showToast("Position for minimized banner must be set.", TOAST.TYPE.ERROR))
            setSubmitting(false)
            return
          } else if (!minimized_width && minimizedBannerType === "html") {
            dispatch(showToast("Width for minimized banner must be filled.", TOAST.TYPE.ERROR))
            setSubmitting(false)
            return
          } else if (!minimized_position) {
            dispatch(showToast("Minimized banner position needs to be set.", TOAST.TYPE.ERROR))
            setSubmitting(false)
            return
          } else if (minimizedBannerType === "html" && !minimized_width) {
            dispatch(showToast("Minimized banner width needs to be filled.", TOAST.TYPE.ERROR))
            setSubmitting(false)
            return
          }
        }

        const sendImagePayloadData =
          bannerType === "image" &&
          (dirtyFields["image"] || dirtyFields["image_url"] || type === "create")
        const sendMinimizedImagePayloadData =
          minimizedBannerType === "image" &&
          (dirtyFields["minimized_image"] ||
            dirtyFields["minimized_image_url"] ||
            type === "create")
        const values: PopupWebBannersFormData = {
          name,
          condition,
          frequency_cap: frequencyCap,
          priority: _toInteger(priority.value),
          position: position.value,
          image_local:
            sendImagePayloadData && image && image[0] ? base64BannerImage!.split(",")[1] : null,
          image_name: sendImagePayloadData && image && image[0] ? image[0].name : null,
          image_remote: sendImagePayloadData && image_url ? image_url : null,
          minimized_image_local:
            sendMinimizedImagePayloadData && minimized_image && minimized_image["0"]
              ? base64BannerMinimizedImage!.split(",")[1]
              : null,
          minimized_image_name:
            sendMinimizedImagePayloadData && minimized_image && minimized_image["0"]
              ? minimized_image["0"].name
              : null,
          minimized_image_remote:
            sendMinimizedImagePayloadData && minimized_image_url ? minimized_image_url : null,
          html: bannerType === "html" ? html : null,
          minimized_html: minimizedBannerType === "html" ? minimized_html : null,
          destination_url:
            bannerType === "image" &&
            (dirtyFields["destination_url"] || sendImagePayloadData || type === "create")
              ? destination_url
              : null,
          width: width ? _toInteger(width) : null,
          minimized_width: minimized_width ? _toInteger(minimized_width) : null,
          disabled: Boolean(disabled),
          minimized: Boolean(minimized),
          minimized_position: minimized_position?.value,
          display_minimized_first,
          minimized_hide_values,
          minimized_hide_operator,
          margin_x: isNaN(_toInteger(margin_x)) ? 0 : _toInteger(margin_x),
          margin_y: isNaN(_toInteger(margin_y)) ? 0 : _toInteger(margin_y),
          minimized_margin_x: isNaN(_toInteger(minimized_margin_x))
            ? 0
            : _toInteger(minimized_margin_x),
          minimized_margin_y: isNaN(_toInteger(minimized_margin_y))
            ? 0
            : _toInteger(minimized_margin_y),
          animation: animation?.value,
          minimized_animation: minimized_animation?.value,
          close_button: {
            ...close_button,
            disabled: Boolean(close_button.disabled),
          },
          minimized_close_button: {
            ...minimized_close_button,
            disabled: Boolean(minimized_close_button.disabled),
          },
          display_on: {
            action: display_on.action.value,
            value:
              display_on.action.value === "scroll"
                ? isNaN(_toInteger(display_on.value))
                  ? 0
                  : _toInteger(display_on.value)
                : null,
          },
        }
        try {
          await onSubmit(values)
        } catch (err: any) {
          // TODO: typescript here, validate everything??
          // @ts-ignore
          setSubmitting(false)
          const errors = err.response?.data?.errors
          if (errors) {
            if (errors.name) {
              setError("name", { type: "manual", message: errors.name[0] })
            }
            if (errors.settings) {
              _forEach(errors.settings, (error: string[], key: string) => {
                setError(key as keyof PopupWebBannersFormValues, {
                  type: "manual",
                  message: error[0],
                })
              })
            }
          }
        }
      }
    },
    [
      bannerType,
      minimizedBannerType,
      onSubmit,
      setError,
      submitting,
      base64BannerImage,
      base64BannerMinimizedImage,
      dispatch,
      dirtyFields,
      type,
    ],
  )

  const toggleBannerType = useCallback(() => {
    setBannerType(bannerType === "image" ? "html" : "image")
    setValue("close_button", { ...closeButtonStyles, disabled: false })
    clearErrors("width")
  }, [bannerType, clearErrors, setValue, closeButtonStyles])

  const toggleMinimizedBannerType = useCallback(() => {
    setMinimizedBannerType(minimizedBannerType === "image" ? "html" : "image")
    setValue("minimized_close_button", { ...minimizedCloseButtonStyles, disabled: false })
    clearErrors("minimized_width")
  }, [minimizedBannerType, clearErrors, setValue, minimizedCloseButtonStyles])

  const copyBanner = useCallback(async () => {
    if (!isCopying) {
      setIsCopying(true)
      try {
        const response = await dispatch(copyPopupWebBanner(id))
        history.push(
          getRoutePath("personalization.popup-web-banners.detail", {
            // @ts-ignore
            id: response.value.web_banner.id,
          }),
        )
        dispatch(showToast("Web Banner has been copied."))
      } catch {
        setIsCopying(false)
      }
    }
  }, [id, history, dispatch, isCopying])

  const encodeFileToBase64 = useCallback(
    (file: Blob) =>
      new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      }),
    [],
  )

  const verifyAndEncodeFile = useCallback(
    (field: "image" | "minimized_image") => async (evt: ChangeEvent) => {
      clearErrors(field)
      const target = evt.target as HTMLInputElement
      const { files } = target
      if (files && files[0]) {
        try {
          const encodedFile = await encodeFileToBase64(files[0])
          if (typeof encodedFile === "string") {
            field === "image"
              ? setBase64BannerImage(encodedFile)
              : setBase64BannerMinimizedImage(encodedFile)
            setValue(field, files, { shouldDirty: true })
            setValue(field === "image" ? "image_url" : "minimized_image_url", "", {
              shouldDirty: true,
            })
          }
        } catch {}
      }
    },
    [encodeFileToBase64, setValue, clearErrors],
  )

  const clearImageSelection = useCallback(
    (field: "image" | "minimized_image") => () => {
      clearErrors(field)
      if (base64BannerImage && field === "image") {
        setValue(field, undefined)
        setBase64BannerImage(null)
      }
      if (base64BannerMinimizedImage && field === "minimized_image") {
        setValue(field, undefined)
        setBase64BannerMinimizedImage(null)
      }
    },
    [base64BannerImage, base64BannerMinimizedImage, setValue, clearErrors],
  )

  const periodValidation = useCallback(
    value => {
      if (!frequencyCapWindowType || frequencyCapWindowType?.value === "session") {
        return undefined
      } else {
        return required(value)
      }
    },
    [frequencyCapWindowType],
  )

  const addHideBannerValue = useCallback(() => {
    if (minimizedHideInputValue) {
      if (minimizedHideArrayValues.includes(minimizedHideInputValue)) {
        dispatch(showToast("Value is already added", TOAST.TYPE.ERROR))
      } else {
        setValue("minimized_hide_values", [...minimizedHideArrayValues, minimizedHideInputValue])
        setValue("minimized_hide_input", "")
      }
    }
  }, [minimizedHideInputValue, minimizedHideArrayValues, setValue, dispatch])

  const removeHideBannerValue = useCallback(
    value => {
      setValue("minimized_hide_values", without([value], minimizedHideArrayValues))
    },
    [setValue, minimizedHideArrayValues],
  )

  const renderPositionMarginFields = useCallback(
    (type: "large" | "minimized") => {
      const selectedPosition = type === "large" ? position : minimized_position
      if (selectedPosition && selectedPosition.value !== "middle") {
        let fields: { label: string; name: "margin_x" | "margin_y" }[]
        switch (selectedPosition.value) {
          case "top":
            fields = [{ label: "Margin from top", name: "margin_y" }]
            break
          case "top_right":
            fields = [
              { label: "Margin from top", name: "margin_y" },
              { label: "Margin from right", name: "margin_x" },
            ]
            break
          case "right":
            fields = [{ label: "Margin from right", name: "margin_x" }]
            break
          case "bottom_right":
            fields = [
              { label: "Margin from bottom", name: "margin_y" },
              { label: "Margin from right", name: "margin_x" },
            ]
            break
          case "bottom":
            fields = [{ label: "Margin from bottom", name: "margin_y" }]
            break
          case "bottom_left":
            fields = [
              { label: "Margin from bottom", name: "margin_y" },
              { label: "Margin from left", name: "margin_x" },
            ]
            break
          case "left":
            fields = [{ label: "Margin from left", name: "margin_x" }]
            break
          case "top_left":
            fields = [
              { label: "Margin from top", name: "margin_y" },
              { label: "Margin from left", name: "margin_x" },
            ]
            break
          default:
            fields = []
            break
        }
        if (fields.length) {
          return (
            <div className={styles.positionMarginsFields}>
              {fields.map(field => (
                <TextField
                  key={field.name}
                  label={field.label}
                  disabled={!isEditable}
                  type="number"
                  min="0"
                  className={styles.positionMarginField}
                  {...register(`${type === "minimized" ? "minimized_" : ""}${field.name}`)}
                />
              ))}
            </div>
          )
        }
      }
      return null
    },
    [position, minimized_position, isEditable, register],
  )

  const onPositionChange = useCallback(
    (type: "large" | "minimized") => (option?: PositionOption) => {
      if (option?.value === "middle") {
        if (type === "large" && animation?.value === "slide_in") {
          setValue("animation", ANIMATION_OPTIONS[1])
        } else if (type === "minimized" && minimizedAnimation?.value === "slide_in") {
          setValue("minimized_animation", ANIMATION_OPTIONS[1])
        }
      }
    },
    [animation, minimizedAnimation, setValue],
  )

  return (
    <div className={styles.wrapper}>
      <Prompt
        when={!isEmpty(dirtyFields) && !submitting}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(submitForm)}>
        <Paper hasHeader className={classnames(styles.content, styles.triggerSettings)}>
          <PaperHeader size="small" className={styles.header}>
            <TextField
              label="Name Pop up Web Banner"
              error={errors.name?.message}
              placeholder="Name"
              className={styles.bannerNameInput}
              disabled={!isEditable}
              {...register("name", { validate: required })}
            />
            <div className={styles.buttons}>
              <Controller
                name="disabled"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.disabledButtonToggleWrapper}>
                      <label>Active</label>
                      <ToggleButton
                        value={!field.value}
                        handleToggle={() => {
                          field.onChange(!field.value)
                        }}
                      />
                    </div>
                  )
                }}
              />
              {type === "edit" && (
                <>
                  <Button
                    type="button"
                    size="small"
                    color="white-red"
                    onClick={onDelete}
                    disabled={!isEditable}
                    className={styles.buttonMargin}
                  >
                    <FontAwesomeIcon className="icon" icon={["fas", "trash-alt"]} /> Delete
                  </Button>
                  <Button
                    size="small"
                    color="white"
                    onClick={copyBanner}
                    className={classnames(styles.buttonMargin, { loading: isCopying })}
                  >
                    <FontAwesomeIcon icon={["fas", "clone"]} className="icon" /> Copy
                  </Button>
                </>
              )}
              <Button
                type="submit"
                color="primary"
                size="small"
                disabled={!isEditable}
                className={submitting ? "loading" : ""}
              >
                {type === "create" ? "Create" : "Save"}
              </Button>
            </div>
          </PaperHeader>
          <div className={styles.conditionBuilderWrapper}>
            <Controller
              name="condition"
              control={control}
              rules={{
                validate: tree => {
                  const result = validateConditionTree(tree)
                  // Validator returns a stringified tree of errors because react-hook-forms only
                  // works with string errors
                  return result === null ? true : JSON.stringify(result)
                },
              }}
              render={({ field }) => {
                const stringifiedError = (errors.condition as FieldError | undefined)?.message
                return (
                  <>
                    <PaperHeader
                      size="small"
                      titleText="Conditions"
                      className={classNames(styles.conditionsHeader, {
                        [styles.empty]: !field.value,
                      })}
                    >
                      <ConditionTreeOverview<WBCondition> conditionTree={field.value} />
                    </PaperHeader>
                    <ConditionBuilder<WBCondition>
                      conditionTree={field.value}
                      onChange={field.onChange}
                      isEditable={hasAccess.webBanners.edit()}
                      error={stringifiedError ? JSON.parse(stringifiedError) : null}
                      conditionComponent={Condition}
                      getNewCondition={getNewWebBannerCondition}
                    />
                  </>
                )
              }}
            />
          </div>
          <div className={styles.freqPosPrioRow}>
            <div className={classnames(styles.frequencyCapPerUserBox, styles.greyBox)}>
              <h4 className={styles.greyBoxTitle}>Frequency cap per user</h4>
              <div className={styles.frequencyCapFieldsRow}>
                <TextField
                  type="number"
                  label="How many times *"
                  error={errors.frequency_cap_window_max_count?.message}
                  placeholder="1 to 50"
                  {...register("frequency_cap_window_max_count", { validate: required })}
                  fullWidth
                  min="1"
                  max="50"
                  step="1"
                  disabled={!isEditable}
                  className={styles.frequencyCapCountField}
                />
                <span>during</span>
                <TextField
                  type="number"
                  label="Period *"
                  error={errors.frequency_cap_window_size?.message}
                  placeholder={
                    frequencyCapWindowType?.value === "hours"
                      ? "1 to 168"
                      : frequencyCapWindowType?.value === "days"
                      ? "1 to 7"
                      : ""
                  }
                  {...register("frequency_cap_window_size", {
                    validate: periodValidation,
                  })}
                  min="1"
                  max={frequencyCapWindowType?.value === "hours" ? "168" : "7"}
                  step="1"
                  fullWidth
                  disabled={
                    !isEditable ||
                    !frequencyCapWindowType ||
                    frequencyCapWindowType?.value === "session"
                  }
                  className={styles.frequencyCapWindowSizeField}
                />
                <Controller
                  name="frequency_cap_window_type"
                  control={control}
                  rules={{ validate: required }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      label=""
                      meta={{
                        touched: true,
                        // @ts-ignore
                        error: errors.frequency_cap_window_type?.message,
                      }}
                      options={FREQUENCY_CAP_TYPE_OPTIONS}
                      className={styles.frequencyCapWindowTypeSelect}
                      disabled={!isEditable}
                    />
                  )}
                />
                <span>and</span>
                <div
                  className={classnames(
                    styles.fieldWithTooltip,
                    styles.frequencyCapUserCountFieldWrapper,
                  )}
                >
                  <TextField
                    type="number"
                    label="Total"
                    error={errors.frequency_cap_user_max_count?.message}
                    placeholder="1 to 50"
                    {...register("frequency_cap_user_max_count")}
                    min="1"
                    max="50"
                    step="1"
                    disabled={!isEditable}
                    fullWidth
                  />
                  <InfoTooltip placement="top" className={styles.tooltip} interactive>
                    <div className={styles.tooltipTotalCap}>
                      <p>
                        Maximum number of impressions for 1 user over the lifetime of the banner.
                      </p>
                      <p>
                        <strong>Warning: </strong>the number of impressions is stored in the
                        browser's local storage. If the local storage is cleared, the user might see
                        the banner more times. More info{" "}
                        <a
                          href="https://www.cookiestatus.com/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                  </InfoTooltip>
                </div>
              </div>
            </div>

            <div className={classnames(styles.greyBox, styles.priorityBox)}>
              <h4 className={styles.greyBoxTitle}>Priority</h4>
              <div className={styles.positionPrioFieldsRow}>
                <div className={classnames(styles.fieldWithTooltip, styles.priorityFieldWrapper)}>
                  <Controller
                    name="priority"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <SelectField
                        input={field}
                        label="Priority *"
                        meta={{
                          touched: true,
                          // @ts-ignore
                          error: errors.priority?.message,
                        }}
                        options={PRIORITY_OPTIONS}
                        className={styles.prioritySelect}
                        disabled={!isEditable}
                      />
                    )}
                  />
                  <InfoTooltip placement="top" className={styles.tooltip}>
                    Select priority from 0-10. Web banner with higher priority will appear first. 10
                    is the highest.
                  </InfoTooltip>
                </div>
              </div>
            </div>

            <div className={classnames(styles.greyBox, styles.displayConditionBox)}>
              <h4 className={styles.greyBoxTitle}>Display condition</h4>
              <div className={styles.displayConditionContent}>
                <Controller
                  name="display_on.action"
                  control={control}
                  rules={{ validate: required }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      label="Show on"
                      meta={{
                        touched: true,
                        // @ts-ignore
                        error: errors.display_on?.action,
                      }}
                      options={DISPLAY_CONDITION_OPTIONS}
                      className={classnames(styles.displayConditionSelect, {
                        [styles.scrollValue]: displayOnAction?.value === "scroll",
                      })}
                      disabled={!isEditable}
                    />
                  )}
                />
                {displayOnAction?.value === "scroll" && (
                  <>
                    <span>greater or equal than</span>
                    <TextField
                      type="number"
                      min="0"
                      max="100"
                      className={styles.scrollPercentageField}
                      error={errors.display_on?.value?.message}
                      disabled={!isEditable}
                      {...register("display_on.value", {
                        validate: required,
                      })}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Paper>
        <Paper
          className={classnames(
            styles.content,
            styles.nextBox,
            styles.bannerBox,
            styles.bannerContent,
          )}
        >
          <div className={styles.leftPart}>
            <div className={styles.paperInnerHeader}>
              <h3>Banner</h3>
              <div className={styles.bannerToggleBox}>
                <ToggleSwitch
                  name="banner_type"
                  leftValue="image"
                  rightValue="html"
                  checked={bannerType}
                  handleToggle={toggleBannerType}
                  width="120px"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <div
              className={classnames(styles.bannerContent, {
                [styles.htmlContent]: bannerType === "html",
                [styles.imageContent]: bannerType === "image",
              })}
            >
              {bannerType === "image" && (
                <>
                  <TextField
                    className={styles.linkInput}
                    label="Destination URL"
                    error={errors.destination_url?.message}
                    placeholder="https://www.example.com/promotion"
                    fullWidth
                    disabled={!isEditable}
                    {...register("destination_url", {
                      validate: value => validateUrl(value),
                    })}
                  />
                  <div className={styles.imageUploadRow}>
                    <div className={styles.imageUploadRowContent}>
                      <FileField
                        label="Image upload"
                        error={errors.image?.message}
                        disabled={!isEditable}
                        {...register("image", {
                          validate: value => validImageFile(value),
                        })}
                        onChange={verifyAndEncodeFile("image")}
                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                        onClearClick={clearImageSelection("image")}
                        className={styles.fileInput}
                      />
                      <p className={styles.imageUploadDescription}>
                        Image type: APNG, AVIF, GIF, JPG, PNG, SVG, WebP; Max size: 500 kB
                      </p>
                    </div>
                    <TextField
                      label="Image URL"
                      error={errors.image_url?.message}
                      placeholder="https://www.example.com/image.jpg"
                      fullWidth
                      className={styles.imageUrlField}
                      disabled={!isEditable || base64BannerImage !== null}
                      {...register("image_url", {
                        validate: value => validateUrl(value),
                      })}
                    />
                  </div>
                </>
              )}
              {bannerType === "html" && (
                <div>
                  <Controller
                    name="html"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <div className={`ace-editor-wrapper ${errors.html?.message ? "error" : ""}`}>
                        <p className="label-like">Banner HTML *</p>
                        <AceEditor
                          mode="html"
                          theme="tomorrow"
                          onChange={field.onChange}
                          name="html"
                          width="808px"
                          height="398px"
                          editorProps={{ $blockScrolling: true }}
                          wrapEnabled={true}
                          className="ace-editor"
                          defaultValue={field.value ?? undefined}
                          setOptions={{
                            tabSize: 2,
                            showPrintMargin: false,
                          }}
                        />
                        {errors.html?.message && (
                          <p className="error-message">{errors.html.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightPart}>
            <div className={styles.tabs}>
              <Button
                size="small"
                color="white"
                className={classnames(styles.leftButton, {
                  [styles.active]: bannerTab === "general",
                })}
                onClick={() => {
                  setBannerTab("general")
                }}
              >
                General
              </Button>
              <Button
                size="small"
                color="white"
                className={classnames(styles.rightButton, {
                  [styles.active]: bannerTab === "close",
                })}
                onClick={() => {
                  setBannerTab("close")
                }}
              >
                Close button
              </Button>
            </div>

            <div className={styles.bannerSettingsContent}>
              {bannerTab === "general" && (
                <>
                  <h4>General settings</h4>
                  <div className={styles.rightPartInnerBox}>
                    <Controller
                      name="position"
                      control={control}
                      rules={{ validate: required }}
                      render={({ field }) => (
                        <SelectField
                          input={field}
                          label="Position *"
                          meta={{
                            touched: true,
                            // @ts-ignore
                            error: errors.position?.message,
                          }}
                          options={POSITION_OPTIONS}
                          className={classnames(styles.settingsBoxSelect)}
                          disabled={!isEditable}
                          /*
                          // @ts-ignore */
                          onChange={onPositionChange("large")}
                        />
                      )}
                    />
                    {renderPositionMarginFields("large")}
                    <div className={classnames(styles.bannerWidthField, styles.fieldWithTooltip)}>
                      <TextField
                        type="number"
                        label={`Banner width in pixels${bannerType === "html" ? " *" : ""}`}
                        error={errors.width?.message}
                        fullWidth
                        {...register("width", bannerType === "html" ? { validate: required } : {})}
                        min="1"
                        step="1"
                        disabled={!isEditable}
                      />
                      <InfoTooltip placement="top" className={styles.tooltip}>
                        If it does not fit into the viewport, it will be responsive: 100% viewport
                        width, 40 pixels of margins.
                      </InfoTooltip>
                    </div>
                    {position?.value && (
                      <Controller
                        name="animation"
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            input={field}
                            label="Animation"
                            options={
                              position?.value === "middle"
                                ? ANIMATION_OPTIONS.filter(anim => anim.value !== "slide_in")
                                : ANIMATION_OPTIONS
                            }
                            className={classnames(styles.settingsBoxSelect, styles.animationSelect)}
                            disabled={!isEditable}
                          />
                        )}
                      />
                    )}
                  </div>
                </>
              )}
              {bannerTab === "close" && (
                <Controller
                  name="close_button"
                  control={control}
                  render={({ field }) => (
                    <CloseButtonFields
                      value={field.value}
                      onChange={field.onChange}
                      disabled={!isEditable}
                      name={field.name}
                      disabledEnableCloseButton={bannerType === "image"}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </Paper>

        <Paper
          className={classnames(
            styles.content,
            styles.bannerContent,
            styles.nextBox,
            styles.minimizedBannerPaper,
          )}
        >
          <div className={styles.leftPart}>
            <div className={styles.paperInnerHeader}>
              <div
                className={classnames(styles.titleWithToggle, {
                  [styles.minimizeDisabled]: !minimized,
                })}
              >
                <h3>
                  Minimized banner{" "}
                  <InfoTooltip placement="top">
                    Banner will be minimized in the right bottom corner when closed.
                  </InfoTooltip>
                </h3>
                <Controller
                  name="minimized"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className={styles.minimizedToggleWrapper}>
                        <ToggleButton
                          value={field.value}
                          handleToggle={() => {
                            field.onChange(!field.value)
                          }}
                        />
                      </div>
                    )
                  }}
                />
              </div>
              <div className={styles.bannerToggleBox}>
                <ToggleSwitch
                  name="minimized_banner_type"
                  leftValue="image"
                  rightValue="html"
                  checked={minimizedBannerType}
                  handleToggle={toggleMinimizedBannerType}
                  width="120px"
                  disabled={!isEditable || !minimized}
                />
              </div>
            </div>
            {minimized && (
              <div
                className={classnames(styles.bannerContent, {
                  [styles.htmlContent]: minimizedBannerType === "html",
                  [styles.imageContent]: minimizedBannerType === "image",
                })}
              >
                {minimizedBannerType === "image" && (
                  <>
                    <div
                      className={classnames(styles.imageUploadRow, styles.minimizedImageUploadRow)}
                    >
                      <div className={styles.imageUploadRowContent}>
                        <FileField
                          label="Minimized image upload"
                          error={errors.minimized_image?.message}
                          disabled={!isEditable}
                          {...register("minimized_image", {
                            validate: value => validImageFile(value),
                          })}
                          onChange={verifyAndEncodeFile("minimized_image")}
                          accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                          onClearClick={clearImageSelection("minimized_image")}
                          className={styles.fileInput}
                        />
                        <p className={styles.imageUploadDescription}>
                          Image type: APNG, AVIF, GIF, JPG, PNG, SVG, WebP; Max size: 500 kB
                        </p>
                      </div>
                      <TextField
                        label="Minimized image URL"
                        error={errors.minimized_image_url?.message}
                        placeholder="https://www.example.com/image.jpg"
                        fullWidth
                        className={styles.imageUrlField}
                        disabled={!isEditable || base64BannerMinimizedImage !== null}
                        {...register("minimized_image_url", {
                          validate: value => validateUrl(value),
                        })}
                      />
                    </div>
                  </>
                )}
                {minimizedBannerType === "html" && (
                  <>
                    <div>
                      <Controller
                        name="minimized_html"
                        control={control}
                        rules={{ validate: required }}
                        render={({ field }) => (
                          <div
                            className={`ace-editor-wrapper ${styles.minimizedHtmlField} ${
                              errors.minimized_html?.message ? "error" : ""
                            }`}
                          >
                            <p className="label-like">Minimized banner HTML *</p>
                            <AceEditor
                              mode="html"
                              theme="tomorrow"
                              onChange={field.onChange}
                              name="minimized_html"
                              width="808px"
                              height="398px"
                              editorProps={{ $blockScrolling: true }}
                              wrapEnabled={true}
                              className="ace-editor"
                              defaultValue={field.value ?? undefined}
                              setOptions={{
                                tabSize: 2,
                                showPrintMargin: false,
                              }}
                            />
                            {errors.minimized_html?.message && (
                              <p className="error-message">{errors.minimized_html.message}</p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className={styles.rightPart}>
            <div
              className={classnames(styles.tabs, styles.minimizedTabs, {
                [styles.imageBannerTabs]: minimizedBannerType === "image",
              })}
            >
              <Button
                size="small"
                color="white"
                className={classnames(styles.leftButton, {
                  [styles.active]: minimizedBannerTab === "general",
                })}
                onClick={() => {
                  setMinimizedBannerTab("general")
                }}
                disabled={!minimized}
              >
                General
              </Button>
              <Button
                size="small"
                color="white"
                className={classnames(styles.middleButton, {
                  [styles.active]: minimizedBannerTab === "close",
                  [styles.activeLeft]: minimizedBannerTab === "general",
                  [styles.activeRight]: minimizedBannerTab === "hide",
                })}
                onClick={() => {
                  setMinimizedBannerTab("close")
                }}
                disabled={!minimized}
              >
                Close button
              </Button>
              <Button
                size="small"
                color="white"
                className={classnames(styles.rightButton, {
                  [styles.active]: minimizedBannerTab === "hide",
                })}
                onClick={() => {
                  setMinimizedBannerTab("hide")
                }}
                disabled={!minimized}
              >
                Hide banner
              </Button>
            </div>

            {minimized && (
              <div className={styles.minimizedSettingsContent}>
                {minimizedBannerTab === "general" && (
                  <>
                    <h4>General settings</h4>
                    <div className={styles.rightPartInnerBox}>
                      <Controller
                        name="display_minimized_first"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className={styles.minimizedFirstToggleWrapper}>
                              <label>Display minimized version first</label>
                              <ToggleButton
                                value={field.value || false}
                                handleToggle={() => {
                                  field.onChange(!field.value)
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                      <Controller
                        name="minimized_position"
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            input={field}
                            label="Position *"
                            meta={{
                              touched: true,
                              // @ts-ignore
                              error: errors.minimized_position?.message,
                            }}
                            options={POSITION_OPTIONS}
                            className={classnames(styles.settingsBoxSelect)}
                            disabled={!isEditable}
                            /*
                            // @ts-ignore */
                            onChange={onPositionChange("minimized")}
                          />
                        )}
                      />
                      {renderPositionMarginFields("minimized")}
                      <div
                        className={classnames(
                          styles.bannerMinimizedWidthField,
                          styles.fieldWithTooltip,
                        )}
                      >
                        <TextField
                          type="number"
                          label={`Minimized banner width in pixels${
                            minimizedBannerType === "html" ? " *" : ""
                          }`}
                          error={errors.minimized_width?.message}
                          fullWidth
                          {...register(
                            "minimized_width",
                            minimizedBannerType === "html" ? { validate: required } : {},
                          )}
                          min="1"
                          step="1"
                          disabled={!isEditable}
                        />
                        <InfoTooltip placement="top" className={styles.tooltip}>
                          If it does not fit into the viewport, it will be responsive: 100% viewport
                          width, 40 pixels of margins.
                        </InfoTooltip>
                      </div>
                      {minimized_position?.value && (
                        <Controller
                          name="minimized_animation"
                          control={control}
                          render={({ field }) => (
                            <SelectField
                              input={field}
                              label="Animation"
                              options={
                                minimized_position?.value === "middle"
                                  ? ANIMATION_OPTIONS.filter(anim => anim.value !== "slide_in")
                                  : ANIMATION_OPTIONS
                              }
                              className={classnames(
                                styles.settingsBoxSelect,
                                styles.animationSelect,
                              )}
                              disabled={!isEditable}
                            />
                          )}
                        />
                      )}
                    </div>
                  </>
                )}
                {minimizedBannerTab === "close" && (
                  <Controller
                    name="minimized_close_button"
                    control={control}
                    render={({ field }) => (
                      <CloseButtonFields
                        value={field.value}
                        onChange={field.onChange}
                        disabled={!isEditable}
                        name={field.name}
                        disabledEnableCloseButton={minimizedBannerType === "image"}
                      />
                    )}
                  />
                )}
                {minimizedBannerTab === "hide" && (
                  <>
                    <h4>Hide banner</h4>
                    <div className={styles.rightPartInnerBox}>
                      <Controller
                        name="minimized_hide_operator"
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            input={field}
                            label="When URL"
                            meta={{
                              touched: true,
                              // @ts-ignore
                              error: errors.minimized_hide_operator?.message,
                            }}
                            options={HIDE_OPERATOR_OPTIONS}
                            disabled={!isEditable}
                            className={styles.settingsBoxSelect}
                            isClearable
                          />
                        )}
                      />
                      <div className={styles.minimizedHideValuesInputWrapper}>
                        <TextField
                          type="text"
                          label="Value(s)"
                          error={errors.minimized_hide_input?.message}
                          fullWidth
                          {...register("minimized_hide_input")}
                          disabled={!isEditable}
                          className={styles.minimizedHideValueInput}
                          onKeyPress={evt => {
                            if (evt.key === "Enter") {
                              evt.preventDefault()
                              addHideBannerValue()
                            }
                          }}
                          autoComplete="off"
                        />
                        <Button
                          size="small"
                          type="button"
                          color="primary"
                          className={styles.minimizedHideValueButtonAdd}
                          onClick={addHideBannerValue}
                        >
                          + add
                        </Button>
                        <div className={styles.minimizedHideValues}>
                          {minimizedHideArrayValues.map(value => (
                            <React.Fragment key={value}>
                              {value.length > 35 ? (
                                <Tippy content={value}>
                                  <div>
                                    <Tag
                                      color="primary"
                                      key={value}
                                      onClick={() => removeHideBannerValue(value)}
                                      clickable
                                    >
                                      {shortenTextInMiddle(value, 35)}
                                    </Tag>
                                  </div>
                                </Tippy>
                              ) : (
                                <Tag
                                  color="primary"
                                  key={value}
                                  onClick={() => removeHideBannerValue(value)}
                                  clickable
                                >
                                  {value}
                                </Tag>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Paper>
        <BannerPreview
          type={bannerType}
          minimizedType={minimizedBannerType}
          html={html}
          minimizedHtml={minimized_html ?? null}
          imageUrl={imageUrl}
          minimizedImageUrl={minimizedImageUrl}
          base64Image={base64BannerImage}
          base64MinimizedImage={base64BannerMinimizedImage}
          width={width}
          minimizedWidth={minimized_width}
          position={position?.value}
          minimizedPosition={minimized_position?.value}
          margins={{
            x: isNaN(_toInteger(customMarginX)) ? undefined : _toInteger(customMarginX),
            y: isNaN(_toInteger(customMarginY)) ? undefined : _toInteger(customMarginY),
            minimizedX: isNaN(_toInteger(customMinimizedMarginX))
              ? undefined
              : _toInteger(customMinimizedMarginX),
            minimizedY: isNaN(_toInteger(customMinimizedMarginY))
              ? undefined
              : _toInteger(customMinimizedMarginY),
          }}
          minimizedBannerEnabled={minimized}
          animation={animation?.value}
          minimizedAnimation={minimizedAnimation?.value}
          closeButtonStyles={closeButtonStyles}
          minimizedCloseButtonStyles={minimizedCloseButtonStyles}
        />
      </form>
    </div>
  )
}
