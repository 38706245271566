import React from "react"
import styles from "./HomePage.module.scss"

const TimeComponent = ({ value, unit }: { value: string | number; unit: string }) => (
  <>
    <span className={styles.mainValue}>{value}</span> {unit} ago
  </>
)

const unitMapping = {
  second: "s",
  minute: "min",
  hour: "hour",
  day: "day",
  week: "week",
  month: "month",
  year: "year"
}

const timeAgoFormatter = (value: number, unit: keyof typeof unitMapping, suffix = "ago") => {
  if (unit === "second") {
    return <TimeComponent value="1" unit="min" />
  } else {
    if (value <= 1) {
      return <TimeComponent value="1" unit={unitMapping[unit]} />
    } else {
      return <TimeComponent value={value} unit={`${unitMapping[unit]}s`} />
    }
  }
}

export default timeAgoFormatter
