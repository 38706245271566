import React, { PureComponent } from "react"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _noop from "lodash/noop"
import {
  areAttributesFetching,
  getAttributesMapById,
  areAttributesFulfilled,
} from "selectors/attributes.selector"
import { fetchAttributesList } from "actions/attribute.action"
import { Map } from "immutable"
import Paper from "components/UI/elements/Paper"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { showToast } from "actions/toast.action"
import { MODAL } from "sharedConstants"
import _get from "lodash/get"
import { getEventsIsFetching } from "selectors/event.selector"
import { fetchEventsList } from "actions/event.action"
import {
  fetchStitchingAttributeList,
  deleteStitchingAttribute,
} from "actions/stitchingAttribute.action"
import {
  getStitchingAttributesMap,
  areStitchingAttributesFulfilled,
} from "selectors/stitchingAttribute.selector"
import _size from "lodash/size"
import _sortBy from "lodash/sortBy"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./IdentityStitchingList.scss"

class IdentityStitchingList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteStitchingAttribute: {
        modalOpen: false,
        stitchingAttribute: null,
        isLoading: false,
      },
    }
  }

  componentDidMount() {
    // refetch attributes & events if necessary, fetch stitching attributes
    const {
      areAttributesFetching,
      fetchAttributesList,
      areEventsFetching,
      fetchEventsList,
      fetchStitchingAttributeList,
    } = this.props
    if (!areAttributesFetching) {
      fetchAttributesList(1).catch(_noop)
    }
    if (!areEventsFetching) {
      fetchEventsList(1).catch(_noop)
    }
    fetchStitchingAttributeList().catch(_noop)
  }

  goToStitchingAttributeDetail = stitchingAttributeId => () => {
    this.props.history.push(
      getRoutePath("setup.identity-stitching.detail", { id: stitchingAttributeId }),
    )
  }

  toggleDeleteModal =
    (stitchingAttribute = null) =>
    () => {
      if (!this.state.deleteStitchingAttribute.isLoading) {
        this.setState(prevState => ({
          deleteStitchingAttribute: {
            ...prevState.deleteStitchingAttribute,
            modalOpen: !prevState.deleteStitchingAttribute.modalOpen,
            stitchingAttribute: stitchingAttribute
              ? stitchingAttribute
              : prevState.deleteStitchingAttribute.stitchingAttribute,
          },
        }))
      }
    }

  deleteStitchingAttribute = async () => {
    const { deleteStitchingAttribute: modalState } = this.state
    const { showToast, deleteStitchingAttribute } = this.props
    this.setState(prevState => ({
      deleteStitchingAttribute: {
        ...prevState.deleteStitchingAttribute,
        isLoading: true,
      },
    }))
    try {
      await deleteStitchingAttribute(modalState.stitchingAttribute.attribute_id)
      showToast("Stitching rule deleted.")
      this.setState(prevState => ({
        deleteStitchingAttribute: {
          ...prevState.deleteStitchingAttribute,
          isLoading: false,
          modalOpen: false,
        },
      }))
    } catch {
      this.setState(prevState => ({
        deleteStitchingAttribute: {
          ...prevState.deleteStitchingAttribute,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { deleteStitchingAttribute } = this.state
    const {
      history,
      attributesMap,
      stitchingAttributes,
      areAttributesFulfilled,
      areStitchingAttributesFulfilled,
    } = this.props

    const stitchingAttributesArray = areAttributesFulfilled
      ? _sortBy(
          Object.values(stitchingAttributes).map(stitchingAttribute => {
            const attribute = attributesMap.get(stitchingAttribute.attribute_id)
            const attributeName = attribute ? attribute.name : stitchingAttribute.attribute_id
            return {
              ...stitchingAttribute,
              attribute_name: attributeName,
            }
          }),
          "attribute_name",
        )
      : []
    return (
      <section className="wrapper setup-identity-stitching">
        <PaperHeader
          size="small"
          titleText="Identity stitching"
          className="setup-identity-stitching-header"
        >
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(getRoutePath("setup.identity-stitching.create"))
            }}
          >
            + Create stitching rule
          </Button>
        </PaperHeader>
        {areAttributesFulfilled && _size(stitchingAttributes) > 0 && (
          <Paper hasHeader noPaddingTop>
            <Table className="setup-stitching-table">
              <Thead stickyHeader>
                <Th className="attribute-name">Attribute</Th>
                <Th className="attribute-rules">Rules</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {stitchingAttributesArray.map((stitchingAttribute, index) => {
                  return (
                    <Tr key={index}>
                      <Td textBold textBigger textBlack className="attribute-name">
                        {stitchingAttribute.attribute_name}
                      </Td>
                      <Td className="attribute-rules">{stitchingAttribute.configs.length}</Td>
                      <Td textAlignRight className="actions">
                        <IconButton
                          color={COLOR.BLACK}
                          size={SIZE.TAG}
                          onClick={this.goToStitchingAttributeDetail(
                            stitchingAttribute.attribute_id,
                          )}
                          withBackground
                          iconName="pencil-alt"
                          tooltip="Edit"
                        />
                        <IconButton
                          color={COLOR.RED}
                          size={SIZE.TAG}
                          onClick={this.toggleDeleteModal(stitchingAttribute)}
                          className="left-margin"
                          withBackground
                          iconName="trash-alt"
                          tooltip="Delete"
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <ConfirmModal
              open={deleteStitchingAttribute.modalOpen}
              type={MODAL.TYPE.DELETE}
              handleClose={this.toggleDeleteModal()}
              handleConfirm={this.deleteStitchingAttribute}
              title="Are you sure?"
              action="delete"
              what="identinty stitching rule for attribute"
              item={attributesMap.getIn(
                [_get(deleteStitchingAttribute, "stitchingAttribute.attribute_id", ""), "name"],
                _get(deleteStitchingAttribute, "stitchingAttribute.attribute_id"),
              )}
              isLoading={deleteStitchingAttribute.isLoading}
            />
          </Paper>
        )}
        {areAttributesFulfilled &&
          areStitchingAttributesFulfilled &&
          _size(stitchingAttributes) === 0 && (
            <p className="info-message">Click on the "Create Stitching Rule" to get started.</p>
          )}
      </section>
    )
  }
}

IdentityStitchingList.propTypes = {
  areAttributesFetching: PropTypes.bool.isRequired,
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMap: PropTypes.instanceOf(Map).isRequired,
  fetchAttributesList: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  fetchEventsList: PropTypes.func.isRequired,
  areEventsFetching: PropTypes.bool.isRequired,
  fetchStitchingAttributeList: PropTypes.func.isRequired,
  stitchingAttributes: PropTypes.object.isRequired,
  deleteStitchingAttribute: PropTypes.func.isRequired,
  areStitchingAttributesFulfilled: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  areAttributesFetching: areAttributesFetching(state),
  areAttributesFulfilled: areAttributesFulfilled(state),
  attributesMap: getAttributesMapById(state, true),
  areEventsFetching: getEventsIsFetching(state),
  stitchingAttributes: getStitchingAttributesMap(state),
  areStitchingAttributesFulfilled: areStitchingAttributesFulfilled(state),
})

export default connect(mapStateToProps, {
  fetchAttributesList,
  showToast,
  fetchEventsList,
  fetchStitchingAttributeList,
  deleteStitchingAttribute,
})(IdentityStitchingList)
