import React, { useCallback } from "react"
import EmbeddedWebBannersForm, {
  EmbeddedWebBannersFormData,
} from "../components/EmbeddedWebBannersForm/EmbeddedWebBannersForm"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import { showToast } from "actions/toast.action"
import { createEmbeddedWebBanner } from "resources/webBanner/embeddedWebBanner/embeddedWBActions"

export default function EmbeddedWebBannersCreate() {
  const dispatch = useDispatch()
  const history = useHistory()

  const createBanner = useCallback(
    async ({
      name,
      element_id,
      condition,
      priority,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      disabled,
    }: EmbeddedWebBannersFormData) => {
      await dispatch(
        createEmbeddedWebBanner({
          name,
          element_id,
          settings: {
            condition,
            priority,
            content: image_local
              ? {
                  destination_url: destination_url === "" ? null : destination_url,
                  data: image_local,
                  image_name: image_name!,
                  type: "local_image",
                }
              : image_remote
              ? {
                  destination_url: destination_url === "" ? null : destination_url,
                  image_url: image_remote,
                  type: "remote_image",
                }
              : { html: html!, type: "html" },
            fe_settings: {
              width: width,
            },
          },
          disabled,
        }),
      )
      history.push(getRoutePath("personalization.embedded-web-banners"))
      dispatch(showToast("Web Banner created."))
    },
    [dispatch, history],
  )

  return (
    <>
      <EmbeddedWebBannersForm onSubmit={createBanner} />
    </>
  )
}
