import { token } from "helpers/authenticatedUser.helper"
import { Label, LabelListResponse, LabelPayload } from "types/labels"
import { DateString, DBFormatDateTime, Flag, ISO8601DateTime, OrderDir } from "types/util"
import {
  Attribute,
  AttributeCreatePayload,
  AttributeListResponse,
  AttributeModifyPayload,
  AttributeReturnType,
} from "types/attributes"
import {
  AllDataAggregationsResponse,
  AttributeAggregation,
  AttributeAggregationCreatePayload,
  AttributeAggregationListResponse,
  AttributeAggregationModifyPayload,
  AttributeAggregationReturnType,
} from "types/attributeAggregations"
import {
  StitchingAttributeConfig,
  StitchingAttributeConfigModifyPayload,
  StitchingAttributeConfigListResponse,
} from "types/stitchingAttributeConfigs"
import {
  UserRole,
  UserRoleCreatePayload,
  UserRoleListResponse,
  UserRoleModifyPayload,
} from "types/userRoles"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import {
  User,
  UserCreatePayload,
  UserFull,
  UserInvitePayload,
  UserListResponse,
  UserModifyPayload,
  UserReturnType,
} from "types/users"
import {
  Event,
  EventCreatePayload,
  EventListResponse,
  EventModifyPayload,
  EventType,
} from "types/events"
import { Source } from "resources/dataSource/dataSourceTypes"
import {
  GlobalSetting,
  GlobalSettingModifyPayload,
  GlobalSettingReturnType,
} from "types/globalSettings"
import {
  SegmentUserCreatePayload,
  SegmentUserListResponse,
  SegmentUserModifyPayload,
  SegmentUserReturnType,
  UserSegmentListResponse,
} from "types/segmentUsers"
import { MIWorkspace, MIWorkspaceListResponse } from "types/MIWorkspaces"
import {
  CustomerEvent,
  CustomerEventCountsResponse,
  CustomerEventGroupsResponse,
  CustomerEventListResponse,
  EventCount,
} from "types/customerEvents"
import {
  CustomerAttributeCount,
  CustomerAttributeCountResponse,
  CustomerAttributeListResponse,
  CustomerAttributeRetrieveResponse,
  CustomerAttributeSearchResultReturnType,
  CustomerAttributeValueCount,
  CustomerAttributeValueCountResponse,
  CustomerFulltextSearchResponse,
  FavoriteCustomersListResponse,
} from "types/customerAttributes"
import { TrashedItemListResponse, TrashItemType } from "types/trash"
import {
  FetchUserCountsResponse,
  FetchUsersActivityRequestParams,
  FetchUsersActivityResponse,
  UsersActivityFilters,
} from "types/usersActivity"
import requestFactory from "api/request"
import { Segment, SegmentReturnType } from "resources/segment/segment/segmentTypes"
import endpoints from "resources/endpoints"

export const api = {
  ...endpoints,

  // TODO: move the endpoints below into the resources folder
  user: {
    login(data: { email: string; password: string }): Promise<{ token: string }> {
      return requestFactory("post", "/users/login", data, true, false)
    },
    logout(): Promise<void> {
      return requestFactory("delete", "/users/login")
    },
    oktaLogin(data: { code: string }): Promise<{ token: string }> {
      return requestFactory("post", "/users/okta_login", data, true, false)
    },
    refreshToken(): Promise<{ token: string }> {
      return requestFactory("patch", "/users/login", {
        token: token(),
      })
    },
    me(token?: string): Promise<{ me: UserFull }> {
      return requestFactory("get", "/users/me", {}, false, true, token)
    },
    resetPassword(data: { email: string }): Promise<void> {
      return requestFactory("post", "/users/password_reset", data, true)
    },
    setPassword(data: {
      email: string
      password: string
      password_reset_token: string
    }): Promise<void> {
      return requestFactory("patch", "/users/password_reset", data)
    },
    sendInvitation<T extends Flag = 1>(
      data: UserInvitePayload,
      load_full_structure: T = 1 as T,
    ): Promise<{ user: UserReturnType<T> }> {
      return requestFactory(
        "post",
        `/users/invitation?load_full_structure=${load_full_structure}`,
        data,
      )
    },
    resendInvitation(email: string): Promise<{ user: User }> {
      return requestFactory("patch", "/users/invitation", { email })
    },
    getInvitationLink(userId: User["id"]): Promise<{ user_invitation_link: string }> {
      return requestFactory("get", `/users/${userId}/invitation_link`)
    },
    list<T extends Flag = 1>(offset = 0, limit = 50): Promise<UserListResponse<T>> {
      return requestFactory("get", "/users", {
        offset,
        limit,
      })
    },
    modify<T extends Flag = 1>(
      id: User["id"],
      data: UserModifyPayload,
    ): Promise<{ user: UserReturnType<T> }> {
      return requestFactory("patch", `/users/${id}`, data)
    },
    delete(id: User["id"]): Promise<void> {
      return requestFactory("delete", `/users/${id}`)
    },
    create<T extends Flag = 1>(data: UserCreatePayload): Promise<{ user: UserReturnType<T> }> {
      return requestFactory("post", `/users/`, data)
    },
    trash: {
      list<T extends Flag = 1>(offset = 0, limit = 50): Promise<UserListResponse<T>> {
        return requestFactory("get", "/users/trash", {
          offset,
          limit,
        })
      },
    },
  },
  role: {
    list(
      offset = 0,
      limit = 50,
      order_by: keyof UserRole = "name",
      order_dir: OrderDir = "ASC",
    ): Promise<UserRoleListResponse> {
      return requestFactory("get", "/users/user_roles", { offset, limit, order_by, order_dir })
    },
    create(data: UserRoleCreatePayload): Promise<{ role: UserRole }> {
      return requestFactory("post", "/users/user_roles", data)
    },
    modify(roleId: UserRole["id"], data: UserRoleModifyPayload): Promise<{ role: UserRole }> {
      return requestFactory("patch", `/users/user_roles/${roleId}`, data)
    },
    delete(roleId: UserRole["id"]): Promise<void> {
      return requestFactory("delete", `/users/user_roles/${roleId}`, {}, true)
    },
  },
  acl: {
    segment: {
      list<T extends Flag = 0>(
        segmentId: Segment["id"],
        offset = 0,
        limit = 50,
      ): Promise<SegmentUserListResponse<T>> {
        return requestFactory("get", `/acl/segments/${segmentId}`, {
          offset,
          limit,
        })
      },
      create<T extends Flag = 0>(
        segmentId: Segment["id"],
        data: SegmentUserCreatePayload,
        load_full_structure: T = 0 as T,
      ): Promise<{ segment_user: SegmentUserReturnType<T> }> {
        return requestFactory(
          "post",
          `/acl/segments/${segmentId}?load_full_structure=${load_full_structure}`,
          data,
        )
      },
      user: {
        modify<T extends Flag = 0>(
          segmentId: Segment["id"],
          userId: User["id"],
          data: SegmentUserModifyPayload,
          load_full_structure: T = 0 as T,
        ): Promise<{ segment_user: SegmentUserReturnType<T> }> {
          return requestFactory(
            "patch",
            `/acl/segments/${segmentId}/users/${userId}?load_full_structure=${load_full_structure}`,
            data,
          )
        },
        delete(segmentId: Segment["id"], userId: User["id"]): Promise<void> {
          return requestFactory("delete", `/acl/segments/${segmentId}/users/${userId}`)
        },
      },
    },
    user: {
      list<T extends Flag = 0>(userId: User["id"]): Promise<UserSegmentListResponse<T>> {
        return requestFactory("get", `/acl/users/${userId}`, {})
      },
      create<T extends Flag = 0>(
        userId: User["id"],
        segments_acl: {},
      ): Promise<UserSegmentListResponse<T>> {
        return requestFactory("post", `/acl/users/${userId}`, segments_acl)
      },
    },
  },
  attribute: {
    list<T extends Flag = 1>(
      offset: number | null = null,
      limit = 50,
      order_by: keyof AttributeReturnType<T> = "name",
      order_dir: OrderDir = "ASC",
      load_full_structure: T = 1 as T,
      tag_ids: Label["id"][] = [],
      name_filter = "",
      source_ids: Source["id"][] = [],
      show_hidden: Flag = 0,
    ): Promise<AttributeListResponse<T>> {
      return requestFactory("get", "/attributes", {
        offset,
        limit,
        order_by,
        order_dir,
        load_full_structure,
        tag_ids,
        name_filter,
        source_ids,
        show_hidden,
      })
    },
    create<T extends Flag = 1>(
      data: AttributeCreatePayload,
      load_full_structure: T = 1 as T,
    ): Promise<{ attribute: AttributeReturnType<T> }> {
      return requestFactory("post", `/attributes?load_full_structure=${load_full_structure}`, data)
    },
    modify<T extends Flag = 1>(
      id: Attribute["id"],
      data: AttributeModifyPayload,
      load_full_structure: T = 1 as T,
    ): Promise<{ attribute: AttributeReturnType<T> }> {
      return requestFactory(
        "patch",
        `/attributes/${id}?load_full_structure=${load_full_structure}`,
        data,
      )
    },
    retrieve<T extends Flag = 0>(
      attribute_id: Attribute["id"],
      load_full_structure: T = 0 as T,
    ): Promise<{ attribute: AttributeReturnType<T> }> {
      return requestFactory("get", `/attributes/${attribute_id}`, {
        load_full_structure,
      })
    },
    label: {
      list(
        limit = 50,
        offset: number | null = null,
        order_by: keyof Label = "id",
        order_dir: OrderDir = "ASC",
        name_filter = "",
      ): Promise<LabelListResponse> {
        return requestFactory("get", "/attributes/tags", {
          limit,
          offset,
          order_by,
          order_dir,
          name_filter,
        })
      },
    },
  },
  customer: {
    attribute: {
      list<T extends Flag = 0>(
        customer_entity_id: string,
        offset = 0,
        limit = 50,
        load_full_structure: T = 0 as T,
        attribute_values_max_count = 10,
      ): Promise<CustomerAttributeListResponse<T>> {
        return requestFactory("get", `/customers/${customer_entity_id}/attributes`, {
          offset,
          limit,
          load_full_structure,
          attribute_values_max_count,
        })
      },
      retrieve(
        customer_entity_id: string,
        attribute_id: Attribute["id"],
        offset = 0,
        limit = 10,
      ): Promise<CustomerAttributeRetrieveResponse> {
        return requestFactory(
          "get",
          `/customers/${customer_entity_id}/attributes/${attribute_id}`,
          { offset, limit },
        )
      },
      search<T extends Flag = 0>(
        customer_attribute_id: Attribute["id"],
        search_value = "",
        offset = 0,
        limit = 50,
        load_full_structure: T = 0 as T,
      ): Promise<CustomerAttributeSearchResultReturnType<T>> {
        return requestFactory("get", `/customers/attributes/${customer_attribute_id}/search`, {
          search_value,
          offset,
          limit,
          load_full_structure,
        })
      },
      count: {
        list(
          name_start = "",
          offset = 0,
          limit = 50,
          order_by: keyof CustomerAttributeCount = "name",
          order_dir: OrderDir = "ASC",
        ): Promise<CustomerAttributeCountResponse> {
          return requestFactory("get", "/customers/attributes/counts", {
            offset,
            limit,
            order_by,
            order_dir,
            name_start,
          })
        },
      },
      value: {
        list(
          attributeId: Attribute["id"],
          value_contains = "",
          offset = 0,
          limit = 50,
          order_by?: keyof CustomerAttributeValueCount,
          order_dir: OrderDir = "ASC",
        ): Promise<CustomerAttributeValueCountResponse> {
          return requestFactory(
            "get",
            `/customers/attributes/${attributeId}/values/counts`,
            {
              offset,
              limit,
              order_by,
              order_dir,
              value_contains,
            },
            true,
            true,
            "",
            false,
            true,
          )
        },
      },
    },
    search: {
      fulltext<T extends Flag = 0>(
        search_text?: string,
        offset = 0,
        limit = 50,
        load_full_structure: T = 0 as T,
      ): Promise<CustomerFulltextSearchResponse<T>> {
        return requestFactory("get", "/customers/fulltext_search", {
          search_text,
          offset,
          limit,
          load_full_structure,
        })
      },
    },
    list: {
      favorite<T extends Flag = 0>(
        offset = 0,
        limit = 50,
        load_full_structure: T = 0 as T,
      ): Promise<FavoriteCustomersListResponse<T>> {
        return requestFactory("get", "/customers/favourite/search", {
          offset,
          limit,
          load_full_structure,
        })
      },
    },
    event: {
      list<T extends Flag = 0>(
        customer_entity_id: string,
        start_date?: DBFormatDateTime,
        end_date?: DBFormatDateTime,
        offset = 0,
        limit = 50,
        order_by: keyof CustomerEvent = "id",
        order_dir: OrderDir = "DESC",
        load_full_structure: T = 0 as T,
        source_ids: Source["id"][] = [],
        event_ids: Event["id"][] = [],
        tag_ids: Label["id"][] = [],
      ): Promise<CustomerEventListResponse<T>> {
        return requestFactory("get", `/customers/${customer_entity_id}/events`, {
          start_date,
          end_date,
          offset,
          limit,
          order_by,
          order_dir,
          load_full_structure,
          source_ids,
          event_ids,
          tag_ids,
        })
      },
      group: {
        list<T extends Flag = 0>(
          customer_entity_id: string,
          start_date?: DateString,
          end_date?: DateString,
          load_full_structure: T = 0 as T,
        ): Promise<CustomerEventGroupsResponse<T>> {
          return requestFactory("get", `/customers/${customer_entity_id}/event_groups`, {
            start_date,
            end_date,
            load_full_structure,
          })
        },
      },
      count: {
        list<T extends Flag = 0>(
          name_start = "",
          offset = 0,
          limit = 50,
          order_by: keyof EventCount<T>,
          order_dir: OrderDir = "ASC",
          load_full_structure: T = 0 as T,
        ): Promise<CustomerEventCountsResponse<T>> {
          return requestFactory("get", "/customers/events/counts", {
            name_start,
            offset,
            limit,
            order_by,
            order_dir,
            load_full_structure,
          })
        },
      },
    },
    identityGraph(customer_entity_id: string): Promise<{
      nodes: { attribute_id: Attribute["id"]; value: any; node_id: string }[]
      edges: {
        a: string
        b: string
        cardinality: number
        event_id: Event["id"]
        timestamp: ISO8601DateTime
        customer_event_id: CustomerEvent["id"]
      }[]
      total_edges_count: number
    }> {
      return requestFactory("get", `/customers/${customer_entity_id}/stitching_graph`)
    },
  },
  globalSettings: {
    list<T extends Flag = 0>(
      load_full_structure: T = 0 as T,
    ): Promise<{ global_settings: GlobalSettingReturnType<T>[] }> {
      return requestFactory("get", "/global_settings", {
        load_full_structure,
      })
    },
    modify(
      globalSettingsId: GlobalSetting["id"],
      data: GlobalSettingModifyPayload,
    ): Promise<{ global_setting: GlobalSetting }> {
      return requestFactory("patch", `/global_settings/${globalSettingsId}`, data)
    },
  },

  event: {
    list<T extends Flag = 0>(
      offset = 0,
      limit = 20,
      order_by: Event["id"] = "id",
      order_dir: OrderDir = "ASC",
      load_full_structure: T = 0 as T,
      show_hidden: Flag = 0,
      cascade_is_hidden: Flag = 1,
    ): Promise<EventListResponse<T>> {
      return requestFactory("get", "/events", {
        offset,
        limit,
        order_by,
        order_dir,
        load_full_structure,
        show_hidden,
        cascade_is_hidden,
      })
    },
    create(data: EventCreatePayload, cascade_is_hidden: Flag = 1): Promise<{ event: Event }> {
      return requestFactory("post", `/events?cascade_is_hidden=${cascade_is_hidden}`, data)
    },
    modify(
      id: Event["id"],
      data: EventModifyPayload,
      cascade_is_hidden: Flag = 1,
    ): Promise<{ event: Event }> {
      return requestFactory("patch", `/events/${id}?cascade_is_hidden=${cascade_is_hidden}`, data)
    },
  },
  trash: {
    list<T extends Flag = 0>(
      offset = 0,
      limit = 50,
      load_full_structure: T = 0 as T,
      searched_text: string | null = null,
      item_type: TrashItemType | null = null,
      order_dir: OrderDir = "DESC",
    ): Promise<TrashedItemListResponse<T>> {
      return requestFactory("get", "/trash", {
        offset,
        limit,
        load_full_structure,
        searched_text,
        item_type,
        order_dir,
      })
    },
    segments: {
      restore<T extends Flag = 0>(
        segment_id: Segment["id"],
        load_full_structure: T = 0 as T,
      ): Promise<{ segment: SegmentReturnType<T> }> {
        return requestFactory(
          "patch",
          `/segments/trash/${segment_id}?load_full_structure=${load_full_structure}`,
        )
      },
    },
    users: {
      restore(user_id: User["id"]): Promise<{ user: User }> {
        return requestFactory("patch", `/users/trash/${user_id}`)
      },
    },
    exportDestinations: {
      restore(
        destination_id: SegmentExportDestination["id"],
      ): Promise<{ segment_export_destination: SegmentExportDestination }> {
        return requestFactory("patch", `/segments/export/destinations/trash/${destination_id}`)
      },
    },
    roles: {
      restore(role_id: UserRole["id"]): Promise<{ user_role: UserRole }> {
        return requestFactory("patch", `/users/user_roles/trash/${role_id}`)
      },
    },
  },
  attributesAggregations: {
    list<T extends Flag = 0>(
      offset = 0,
      limit = 20,
      order_by: keyof AttributeAggregationReturnType<T> = "order_index",
      order_dir: OrderDir = "ASC",
      load_full_structure: T = 0 as T,
      only_with_valid_attributes = true,
    ): Promise<AttributeAggregationListResponse<T>> {
      return requestFactory("get", "/attributes_aggregations", {
        offset,
        limit,
        order_by,
        order_dir,
        load_full_structure,
        only_with_valid_attributes,
      })
    },
    create<T extends Flag = 0>(
      data: AttributeAggregationCreatePayload,
      load_full_structure: T = 0 as T,
    ): Promise<{ attribute_aggregation: AttributeAggregationReturnType<T> }> {
      return requestFactory(
        "post",
        `/attributes_aggregations?load_full_structure=${load_full_structure}`,
        data,
      )
    },
    modify<T extends Flag = 0>(
      aggregation_id: AttributeAggregation["id"],
      data: AttributeAggregationModifyPayload,
      load_full_structure: T = 0 as T,
    ): Promise<{ attribute_aggregation: AttributeAggregationReturnType<T> }> {
      return requestFactory(
        "patch",
        `/attributes_aggregations/aggregations/${aggregation_id}?load_full_structure=${load_full_structure}`,
        data,
        true,
      )
    },
    delete(aggregation_id: AttributeAggregation["id"]): Promise<void> {
      return requestFactory(
        "delete",
        `/attributes_aggregations/aggregations/${aggregation_id}`,
        {},
        true,
      )
    },
  },
  cache: {
    delete(): Promise<void> {
      return requestFactory("delete", "/cache")
    },
  },
  mi: {
    workspaces: {
      list(
        offset = 0,
        limit = 20,
        order_by: keyof MIWorkspace = "name",
        order_dir: OrderDir = "ASC",
        load_full_structure: Flag = 0,
      ): Promise<MIWorkspaceListResponse> {
        return requestFactory("get", "/mi/workspaces", {
          offset,
          limit,
          order_by,
          order_dir,
          load_full_structure,
        })
      },
    },
  },
  customerEventsCount(
    start_date: DBFormatDateTime | null = null,
    end_date: DBFormatDateTime | null = null,
  ): Promise<{
    customer_events_count: number
    selection_settings: {
      start_date: DBFormatDateTime | null
      end_date: DBFormatDateTime | null
    }
  }> {
    return requestFactory("get", "/customer_events_count", { start_date, end_date })
  },
  customersCount(): Promise<{
    customer_entities_count: number
    customers_before_stitching_count: number
  }> {
    return requestFactory("get", "/customers_count")
  },
  eventsPerCustomerCount(): Promise<{ events_per_customer_count: number }> {
    return requestFactory("get", "/events_per_customer_count")
  },
  customerEventsPerEventType(): Promise<{
    customer_events_per_event_type: {
      source_id: Source["id"]
      event_type: EventType
      count: number
    }[]
  }> {
    return requestFactory("get", "/customer_events_per_event_type")
  },
  customerEventsPerDate(
    start_date: DateString | null = null,
    end_date: DateString | null = null,
  ): Promise<{
    customer_events_per_date: {
      source_id: Source["id"]
      events_per_date: {
        date: DateString
        count: number
      }[]
    }[]
    resolution: "hour" | "day" | "week" | "month" | "year"
    selection_settings: {
      start_date: DateString | null
      end_date: DateString | null
    }
  }> {
    return requestFactory("get", "/customer_events_per_date", { start_date, end_date })
  },
  customersPerDate(
    start_date: DateString | null = null,
    end_date: DateString | null = null,
  ): Promise<{
    customers_per_date: {
      date: DateString
      count: number
    }[]
    resolution: "hour" | "day" | "week" | "month" | "year"
    selection_settings: {
      start_date: DateString | null
      end_date: DateString | null
    }
  }> {
    return requestFactory("get", "/customers_per_date", { start_date, end_date })
  },
  customersPerAttributeCounts(): Promise<{
    customers_per_attribute_counts: {
      attribute_id: Attribute["id"]
      count: number
    }[]
  }> {
    return requestFactory("get", "/customers_per_attribute_counts")
  },
  customersPerSource(): Promise<{
    customers_per_source: {
      source_id: Source["id"]
      count: number
    }[]
  }> {
    return requestFactory("get", "/customers_per_source")
  },
  customersPerSourcesCount(): Promise<{
    customers_per_sources_count: {
      source_count: number
      source_values: {
        source_ids: Source["id"][]
        customers_count: number
      }[]
    }[]
  }> {
    return requestFactory("get", "/customers_per_sources_count")
  },
  customerAttributesCount(): Promise<{
    customer_attributes_count: number
  }> {
    return requestFactory("get", "/customer_attributes_count")
  },
  eventTypesLastEventTimes(): Promise<{
    event_types_last_event_times: {
      source_id: Source["id"]
      event_type: EventType
      last_event_time: DBFormatDateTime
    }[]
  }> {
    return requestFactory("get", "/event_types_last_event_times")
  },
  customersLastUpdate(): Promise<{
    customer_last_update: {
      h12: number
      h24: number
      h48: number
      h96: number
      h168: number
      total: number
    }
  }> {
    return requestFactory("get", "/customer_last_update")
  },
  licenceUsage(): Promise<{
    customer_events_hidden_included_count: number | null
    customer_events_hidden_included_last_30_days_count: number | null
  }> {
    return requestFactory("get", "/licence_usage_counts")
  },
  cacheStatus(): Promise<{
    init_time: DBFormatDateTime
    refresh_init_time: DBFormatDateTime | null
    is_refresh_running: boolean
  }> {
    return requestFactory("get", "/cdp_cache_status")
  },
  authOptions(): Promise<{
    is_credentials_login_enabled: boolean
    is_okta_login_enabled: boolean
    okta_auth_link: string
  }> {
    return requestFactory("get", "/users/auth_options")
  },
  allDataAggregations<T extends Flag = 0>(
    load_full_structure: T = 0 as T,
  ): Promise<AllDataAggregationsResponse<T>> {
    return requestFactory(
      "get",
      "/all_data_aggregations",
      { load_full_structure },
      false,
      true,
      "",
      true,
    )
  },
  stitchingAttribute: {
    list<T extends Flag = 0>(
      offset = 0,
      limit = 50,
      load_full_structure: T = 0 as T,
    ): Promise<StitchingAttributeConfigListResponse<T>> {
      return requestFactory("get", "/stitching_attribute_configs", {
        offset,
        limit,
        load_full_structure,
      })
    },
    modify(
      attribute_id: Attribute["id"],
      data: StitchingAttributeConfigModifyPayload,
    ): Promise<{
      stitching_attribute_configs: StitchingAttributeConfig[]
    }> {
      return requestFactory(
        "patch",
        `/stitching_attribute_configs/attributes/${attribute_id}`,
        data,
      )
    },
  },
  label: {
    list(
      offset = 0,
      limit = 50,
      order_by: keyof Label = "name",
      order_dir: OrderDir = "ASC",
      // load_full_structure: Flag = 0
    ): Promise<LabelListResponse> {
      return requestFactory("get", "/cdm/tags", {
        offset,
        limit,
        order_by,
        order_dir,
        // load_full_structure
      })
    },
    create(data: LabelPayload): Promise<{ tag: Label }> {
      return requestFactory("post", "/cdm/tags", data)
    },
    modify(id: Label["id"], data: LabelPayload): Promise<{ tag: Label }> {
      return requestFactory("patch", `/cdm/tags/${id}`, data)
    },
    delete(id: Label["id"]): Promise<void> {
      return requestFactory("delete", `/cdm/tags/${id}`)
    },
  },
  systemInfo(): Promise<{ system_info: { version: string } }> {
    return requestFactory("get", "/system_info")
  },
  userCounts(): Promise<FetchUserCountsResponse> {
    return requestFactory("get", "/users/users_stats")
  },
  usersActivity({
    since,
    until,
    filterType,
    filterValue,
  }: UsersActivityFilters): Promise<FetchUsersActivityResponse> {
    const requestParams: FetchUsersActivityRequestParams =
      filterType === "all_users"
        ? { since, until }
        : { since, until, [filterType]: filterValue.join(",") }

    return requestFactory("get", "/users/user_activity", requestParams)
  },
  tools: {
    textToDatetime(text: string, type: "date" | "datetime"): Promise<Record<string, string>> {
      return requestFactory("post", "/tools/datetime_from_text", [{ text, type }])
    },
  },
}
