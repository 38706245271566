import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { withRouter } from "react-router"

import { getRoutePath } from "routes"
// administration pages
import SourcesDestinations from "components/Data/pages/SourcesDestinations"
import Settings from "components/Administration/pages/Settings"
import UsersList from "components/Administration/pages/Users"
import UserDetail from "components/Administration/pages/Users/UserDetail"
import RolesList from "components/Administration/pages/Roles"
// setup pages
import SetupSourcesList from "components/Setup/Sources/pages/SourcesList"
import SetupSourceCreate from "components/Setup/Sources/pages/SourceCreate"
import SetupSourceDetail from "components/Setup/Sources/pages/SourceDetail"
import SetupEventsList from "components/Setup/Events/pages/EventsList"
import SetupEventCreate from "components/Setup/Events/pages/EventCreate"
import SetupEventDetail from "components/Setup/Events/pages/EventDetail"
import SetupIdentityStitchingList from "components/Setup/IdentityStitching/pages/IdentityStitchingList"
import SetupIdentityStitchingDetail from "components/Setup/IdentityStitching/pages/IdentityStitchingDetail"
import SetupAttributesList from "components/Setup/Attributes/pages/AttributesList"
import SetupAttributeCreate from "components/Setup/Attributes/pages/AttributeCreate"
import SetupAttributeDetail from "components/Setup/Attributes/pages/AttributeDetail"
import SetupDestinationsList from "components/Setup/Destinations/pages/DestinationsList"
import SetupDestinationDetail from "components/Setup/Destinations/pages/DestinationDetail"
import SetupInsights from "components/Setup/Insights"
import SetupLabelsList from "components/Setup/Labels/pages/LabelsList"
import SetupReportsList from "components/Setup/Reports/ReportsList/ReportsList"
import SetupReportCreate from "components/Setup/Reports/ReportCreate/ReportCreate"
import SetupReportDetail from "components/Setup/Reports/ReportDetail/ReportDetail"
// customers pages
import CustomerDetail from "components/Customers/pages/CustomerDetail"
import CustomersList from "components/Customers/pages/CustomersList"
// data pages
import Attributes from "components/Data/pages/Attributes"
import Events from "components/Data/pages/Events"
import Analytics from "components/Data/pages/Analytics"
import Insights from "components/Data/pages/Insights"
// trash pages
import TrashList from "components/Trash/pages/TrashList"
// dropdown pages
import UserSettings from "components/DropDown/pages/UserSettings"
// 403/404 pages
import NotAuthorizedPage from "pages/NotAuthorized/NotAuthorized"
import NotFoundPage from "pages/NotFound/NotFound"
// homepage
import HomePage from "components/HomePage/HomePage"

// web banners
import PopupWebBannersList from "components/PopupWebBanners/PopupWebBannersList/PopupWebBannersList"
import PopupWebBannersDetail from "components/PopupWebBanners/PopupWebBannersDetail/PopupWebBannersDetail"
import PopupWebBannersCreate from "components/PopupWebBanners/PopupWebBannersCreate/PopupWebBannersCreate"
// reporting
import Reporting from "components/Reporting/Reporting/Reporting"

// codes
import PromoCodesList from "components/PromoCodes/PromoCodesLists/PromoCodesLists"

import { hasAccess } from "helpers/authenticatedUser.helper"
import MobilePushNotifications from "components/MobilePushNotifications/MobilePushNotifications"
import UsersActivity from "components/Administration/pages/UsersActivity/UsersActivity"
import EmbeddedWebBannersDetail from "components/EmbeddedWebBanners/EmbeddedWebBannersDetail/EmbeddedWebBannersDetail"
import EmbeddedWebBannersCreate from "components/EmbeddedWebBanners/EmbeddedWebBannersCreate/EmbeddedWebBannersCreate"
import EmbeddedWebBannersList from "components/EmbeddedWebBanners/EmbeddedWebBannersList/EmbeddedWebBannersList"
import Segments from "pages/Segments/SegmentsRoutes"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import SmartSegmentsSetup from "pages/Setup/SmartSegmentsSetup/SmartSegmentsSetupRoutes"

class AuthenticatedRoutesSwitch extends Component {
  redirectToAllowedAdministrationTab = () => {
    if (hasAccess.administration.usersActivity()) {
      return <Redirect to={getRoutePath("administration.users-activity")} />
    }
    if (hasAccess.administration.users()) {
      return <Redirect to={getRoutePath("administration.users")} />
    }
    if (hasAccess.administration.globalSettings()) {
      return <Redirect to={getRoutePath("administration.settings")} />
    }
    return null
  }

  redirectToAllowedSetupTab = () => {
    if (hasAccess.setup.implementation()) {
      return <Redirect to={getRoutePath("setup.sources")} />
    } else if (hasAccess.setup.smartSegments()) {
      return <Redirect to={getRoutePath("setup.segments.smart")} />
    } else if (hasAccess.setup.insights()) {
      return <Redirect to={getRoutePath("setup.insights")} />
    }
  }

  redirectToAllowedDataTab = () => {
    if (hasAccess.data.dashboard()) {
      return <Redirect to={getRoutePath("data.analytics")} />
    } else if (hasAccess.data.insights()) {
      return <Redirect to={getRoutePath("data.insights")} />
    } else if (hasAccess.data.attributes()) {
      return <Redirect to={getRoutePath("data.attributes")} />
    } else if (hasAccess.data.events()) {
      return <Redirect to={getRoutePath("data.events")} />
    } else if (hasAccess.data.sourcesAndDestinations()) {
      return <Redirect to={getRoutePath("data.sources-destinations")} />
    }
    return null
  }

  redirectToAllowedReportingTab = () => {
    return <Redirect to={getRoutePath("reporting.reporting")} />
  }

  redirectToAllowedPersonalizationTab = () => {
    return <Redirect to={getRoutePath("personalization.popup-web-banners")} />
  }

  redirectToNotAllowedPage = () => {
    return <Redirect to={getRoutePath("not-authorized")} />
  }

  renderRoute = (path, component, acl = null, render, exact = false, visible = true) => {
    if (!visible) return null
    if (component) {
      if (acl !== null && !acl) {
        return <Route path={path} render={this.redirectToNotAllowedPage} exact={exact} />
      }
      return <Route path={path} component={component} exact={exact} />
    } else {
      if (acl !== null && !acl) {
        return <Route path={path} render={this.redirectToNotAllowedPage} exact={exact} />
      }
      return <Route path={path} render={render} exact={exact} />
    }
  }

  render() {
    return (
      <Switch>
        {this.renderRoute(getRoutePath("home"), HomePage, null, null, true)}
        {this.renderRoute(getRoutePath("not-authorized"), NotAuthorizedPage)}
        {this.renderRoute(getRoutePath("not-found"), NotFoundPage)}

        {this.renderRoute(
          getRoutePath("administration"),
          null,
          hasAccess.administration.general(),
          this.redirectToAllowedAdministrationTab,
          true,
        )}
        {this.renderRoute(
          getRoutePath("administration.users.detail"),
          UserDetail,
          hasAccess.administration.users(),
        )}
        {this.renderRoute(
          getRoutePath("administration.users"),
          UsersList,
          hasAccess.administration.users(),
        )}
        {this.renderRoute(
          getRoutePath("administration.roles"),
          RolesList,
          hasAccess.administration.users(),
        )}
        {this.renderRoute(
          getRoutePath("administration.settings"),
          Settings,
          hasAccess.administration.globalSettings(),
        )}
        {this.renderRoute(
          getRoutePath("administration.users-activity"),
          UsersActivity,
          hasAccess.administration.usersActivity(),
        )}

        {this.renderRoute(
          getRoutePath("setup"),
          null,
          hasAccess.setup.general(),
          this.redirectToAllowedSetupTab,
          true,
        )}
        {this.renderRoute(
          getRoutePath("setup.sources.create"),
          SetupSourceCreate,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.sources.detail"),
          SetupSourceDetail,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.sources"),
          SetupSourcesList,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.events.create"),
          SetupEventCreate,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.events.detail"),
          SetupEventDetail,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.events"),
          SetupEventsList,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.identity-stitching.create"),
          SetupIdentityStitchingDetail,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.identity-stitching.detail"),
          SetupIdentityStitchingDetail,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.identity-stitching"),
          SetupIdentityStitchingList,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.attributes.create"),
          SetupAttributeCreate,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.attributes.detail"),
          SetupAttributeDetail,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.attributes"),
          SetupAttributesList,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.labels"),
          SetupLabelsList,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.destinations.detail"),
          SetupDestinationDetail,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.destinations"),
          SetupDestinationsList,
          hasAccess.setup.implementation(),
        )}
        {this.renderRoute(
          getRoutePath("setup.reports.create"),
          SetupReportCreate,
          hasAccess.setup.reports(),
        )}
        {this.renderRoute(
          getRoutePath("setup.reports.detail"),
          SetupReportDetail,
          hasAccess.setup.reports(),
        )}
        {this.renderRoute(
          getRoutePath("setup.reports"),
          SetupReportsList,
          hasAccess.setup.reports(),
        )}
        {this.renderRoute(
          getRoutePath("setup.insights"),
          SetupInsights,
          hasAccess.setup.insights(),
        )}
        <AuthorizedRoute
          hasAccess={hasAccess.setup.smartSegments()}
          path={getRoutePath("setup.segments.smart")}
          component={SmartSegmentsSetup}
        />
        {this.renderRoute(
          getRoutePath("data"),
          null,
          hasAccess.data.general(),
          this.redirectToAllowedDataTab,
          true,
        )}
        {this.renderRoute(getRoutePath("data.attributes"), Attributes, hasAccess.data.attributes())}
        {this.renderRoute(getRoutePath("data.events"), Events, hasAccess.data.events())}
        {this.renderRoute(getRoutePath("data.insights"), Insights, hasAccess.data.insights())}
        {this.renderRoute(getRoutePath("data.analytics"), Analytics, hasAccess.data.dashboard())}
        {this.renderRoute(
          getRoutePath("data.sources-destinations"),
          SourcesDestinations,
          hasAccess.data.sourcesAndDestinations(),
        )}

        {this.renderRoute(
          getRoutePath("reporting"),
          null,
          hasAccess.reports(),
          this.redirectToAllowedReportingTab,
          true,
        )}
        {this.renderRoute(getRoutePath("reporting.reporting"), Reporting, hasAccess.reports())}

        <Route path={getRoutePath("segments")} component={Segments} />

        {this.renderRoute(
          getRoutePath("customers.detail"),
          CustomerDetail,
          hasAccess.customers.detail(),
        )}
        {this.renderRoute(getRoutePath("customers"), CustomersList, hasAccess.customers.search())}

        {this.renderRoute(getRoutePath("trash"), TrashList)}

        {this.renderRoute(getRoutePath("user-settings"), UserSettings)}

        {this.renderRoute(
          getRoutePath("personalization"),
          null,
          null,
          this.redirectToAllowedPersonalizationTab,
          true,
        )}

        {this.renderRoute(
          getRoutePath("personalization.popup-web-banners.create"),
          PopupWebBannersCreate,
        )}
        {this.renderRoute(
          getRoutePath("personalization.popup-web-banners.detail"),
          PopupWebBannersDetail,
        )}
        {this.renderRoute(getRoutePath("personalization.popup-web-banners"), PopupWebBannersList)}

        {this.renderRoute(
          getRoutePath("personalization.embedded-web-banners.create"),
          EmbeddedWebBannersCreate,
        )}
        {this.renderRoute(
          getRoutePath("personalization.embedded-web-banners.detail"),
          EmbeddedWebBannersDetail,
        )}
        {this.renderRoute(
          getRoutePath("personalization.embedded-web-banners"),
          EmbeddedWebBannersList,
        )}

        {this.renderRoute(
          getRoutePath("personalization.mobile-push-notifications"),
          MobilePushNotifications,
        )}

        {this.renderRoute(getRoutePath("personalization.promo-codes"), PromoCodesList)}

        <Route component={NotFoundPage} />
      </Switch>
    )
  }
}

export default withRouter(AuthenticatedRoutesSwitch)
