import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Map } from "immutable"
import _isPlainObject from "lodash/isPlainObject"
import _isArray from "lodash/isArray"
import _get from "lodash/get"
import _has from "lodash/has"
import _toString from "lodash/toString"
import { formValueSelector } from "redux-form"
import moment from "moment"

// actions
import { retrieveSegment as retrieveSegmentDeprecated } from "resources/segment/segment/segmentActions.deprecated"
import { retrieveSegment } from "resources/segment/segment/segmentActions"
import { showLongLoadingBar, hideLongLoadingBar } from "actions/longLoadingBar.action"
import {
  setSegmentNumbers,
  initSegmentNumbers,
  dispatchSegmentNumbersError,
} from "resources/segment/segmentNumbers/segmentNumbersActions"
import { showToast } from "actions/toast.action"

// selectors
import { areAttributesFulfilled, getAttributesMapById } from "selectors/attributes.selector"
import { getSegmentsNumbers } from "resources/segment/segmentNumbers/segmentNumbersSelectors"

// ui components
import InsightTile from "components/UI/elements/InsightTile"
import ExpandedInsightTile from "components/UI/elements/InsightTile/ExpandedInsightTile"

// constants, helpers
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./SegmentInsights.scss"
import { SocketContext } from "context/socket"
import { TOAST } from "sharedConstants"
import { getCompoundAttributeSubAttribute } from "helpers/compoundAttribute.helper"

import {
  getStickyInsightsIds,
  reorderStickyInsights,
  toggleInsightsSticky,
} from "helpers/insight.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

class SegmentInsights extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      attributeAggregations: null,
      aggregationValues: null,
      expandedInsight: {
        dataReady: false,
        data: null,
        open: false,
      },
    }
    this.pendingPromises = new PendingPromise()
    this.loadingTimeout = null
  }

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout)
    this.loadingTimeout = null
    this.pendingPromises.cancelAll()
    // this.context.off("segment_counts_response")
    this.context.off("segment_aggregations_response")
  }

  componentDidMount() {
    const { hideLongLoadingBar, showToast, id } = this.props

    this.context.on("segment_aggregations_response", msg => {
      hideLongLoadingBar("insights")
      if (_has(msg, "error")) {
        showToast(msg.error, TOAST.TYPE.ERROR)
      } else {
        if (_toString(msg.segment_id) === id) {
          // so it's related to current segment
          if (Map.isMap(this.state.aggregationValues)) {
            this.setState(prevState => ({
              aggregationValues: prevState.aggregationValues.set(
                msg.segment_aggregation.attribute_aggregation_id.toString(),
                msg.segment_aggregation,
              ),
            }))
          } else {
            this.setState({
              aggregationValues: Map({
                [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
              }),
            })
          }
        }
      }
    })

    const attributesAggregationsRequest = this.pendingPromises.create(
      this.fetchAllAttributesAggregations(),
    )
    attributesAggregationsRequest.promise
      .then(response => {
        this.setState({
          attributeAggregations: response,
        })
        this.pendingPromises.remove(attributesAggregationsRequest)
      })
      .catch(error => {
        this.pendingPromises.remove(attributesAggregationsRequest)
      })

    this.fetchAllAggregationsValues()
  }

  toggleInsightModal = (insight, value, percentage, attributePercentage, subAttribute) => () => {
    const { attributesMapById } = this.props
    const { expandedInsight } = this.state
    if (expandedInsight.open) {
      this.setState({
        expandedInsight: {
          ...expandedInsight,
          open: false,
        },
      })
    } else if (insight) {
      this.setState({
        expandedInsight: {
          dataReady: true,
          data: {
            insight: insight,
            value,
            attribute: attributesMapById.get(insight.attribute_id),
            percentage,
            attributePercentage,
            subAttribute,
          },
          open: true,
        },
      })
    }
  }

  fetchAllAggregationsValues = () => {
    const { showLongLoadingBar, id } = this.props

    this.loadingTimeout = setTimeout(() => {
      if (this.state.aggregationValues === null) {
        showLongLoadingBar("insights")
      }
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = null
    }, 5000)
    this.context.emit("segment_aggregations", { segment_id: id })
  }

  fetchAllAttributesAggregations = async () => {
    const caller = new AllResourceItemsFetcher()
    const data = await caller
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api.attributesAggregations.list(offset, limit, "order_index", "ASC", loadFullStructure),
      )
      .setDataPath("attribute_aggregations")
      .setLoadFullStructure(0)
      .run()

    return List(data)
  }

  render() {
    const {
      areAttributesFulfilled,
      attributesMapById,
      segmentNumbers,
      searchTerm,
      filteredSource,
      authenticatedUser,
    } = this.props
    const { attributeAggregations, aggregationValues, expandedInsight } = this.state

    let resultAttributeAggregations = attributeAggregations
    if (searchTerm) {
      resultAttributeAggregations = attributeAggregations.filter(item => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase())
      })
    }

    if (filteredSource) {
      resultAttributeAggregations = resultAttributeAggregations.filter(item => {
        return attributesMapById.getIn([item.attribute_id, "source", "id"]) === filteredSource.value
      })
    }

    const stickyInsightsIds = getStickyInsightsIds(authenticatedUser.data.frontend_settings)
    resultAttributeAggregations = reorderStickyInsights(
      resultAttributeAggregations,
      stickyInsightsIds,
    )

    const dataSize = List.isList(attributeAggregations) ? attributeAggregations.size : 0

    return (
      <section className="segment-analytics">
        {!areAttributesFulfilled && <LoadingIndicator className="loading-indicator" />}
        {areAttributesFulfilled && dataSize > 0 && (
          <div className={`tiles-section`}>
            {resultAttributeAggregations.map((item, index) => {
              const aggregationValueObj = aggregationValues?.get(item.id.toString())
              let aggregationValue = null
              let percentage = null
              if (aggregationValueObj) {
                if (_isPlainObject(aggregationValueObj.result)) {
                  if (_has(aggregationValueObj, "result.value")) {
                    aggregationValue = aggregationValueObj.result.value
                  } else if (_has(aggregationValueObj, "result.count")) {
                    aggregationValue = aggregationValueObj.result.count
                  }
                  percentage = aggregationValueObj.result.segment_percentage
                } else {
                  aggregationValue = aggregationValueObj.result
                }
              }
              if (_isArray(aggregationValue) && aggregationValue.length) {
                if (_isPlainObject(aggregationValue[0])) {
                  const tmp = [...aggregationValue]
                  percentage = []
                  aggregationValue = []
                  tmp.forEach(obj => {
                    if (_has(obj, "value")) {
                      aggregationValue.push(obj.value)
                    } else if (_has(obj, "count")) {
                      aggregationValue.push(obj.count)
                    }
                    percentage.push(obj.segment_percentage)
                  })
                }
              }

              const attribute = attributesMapById.get(item.attribute_id)
              const subAttribute =
                attribute && item.sub_attribute_id
                  ? getCompoundAttributeSubAttribute(item.sub_attribute_id, attribute.data_type)
                  : null
              const compareValue =
                item.settings?.value_from && item.settings?.value_to
                  ? [item.settings.value_from, item.settings.value_to]
                  : item.settings?.value
              return (
                <InsightTile
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  value={aggregationValue}
                  compareValue={compareValue}
                  attribute={attributesMapById.get(item.attribute_id)}
                  subAttribute={subAttribute}
                  percentage={percentage}
                  funcType={item.function}
                  outOf={segmentNumbers.get("conditions_results_count")}
                  color={_get(item, "frontend_settings.color")}
                  attributePercentage={aggregationValueObj?.customers_with_attribute_percentage}
                  showNewBadge={moment().diff(item.created, "days") < 8}
                  displayType={_get(item, "frontend_settings.tile_type", "chart")}
                  onExpandClick={this.toggleInsightModal(
                    item,
                    aggregationValue,
                    percentage,
                    aggregationValueObj?.customers_with_attribute_percentage,
                    subAttribute,
                  )}
                  isLoading={!aggregationValueObj}
                  isSticky={stickyInsightsIds.includes(item.id)}
                  onStickyClick={toggleInsightsSticky(item)}
                />
              )
            })}
          </div>
        )}
        {areAttributesFulfilled && List.isList(attributeAggregations) && dataSize === 0 && (
          <div className="segment-analytics-no-content">
            {hasAccess.setup.insights() && (
              <p className="info-message">
                Insights not set. Go to Setup / Insights tab to create insights.
              </p>
            )}
            {!hasAccess.setup.insights() && (
              <p className="info-message">Insights not set. Contact admin to set insights.</p>
            )}
          </div>
        )}
        {dataSize > 0 && resultAttributeAggregations.size === 0 && (
          <div className="segment-analytics-no-content">
            <p className="info-message">Nothing found.</p>
          </div>
        )}
        <ExpandedInsightTile
          open={expandedInsight.open}
          dataReady={expandedInsight.dataReady}
          handleClose={this.toggleInsightModal()}
          attribute={_get(expandedInsight, "data.attribute")}
          subAttribute={_get(expandedInsight, "data.subAttribute")}
          name={_get(expandedInsight, "data.insight.name")}
          description={_get(expandedInsight, "data.insight.description")}
          showNewBadge={
            expandedInsight.data
              ? moment().diff(_get(expandedInsight, "data.insight.created"), "days") < 8
              : false
          }
          compareValue={
            _get(expandedInsight, "data.insight.settings.value_from") &&
            _get(expandedInsight, "data.insight.settings.value_to")
              ? [
                  _get(expandedInsight, "data.insight.settings.value_from"),
                  _get(expandedInsight, "data.insight.settings.value_to"),
                ]
              : _get(expandedInsight, "data.insight.settings.value")
          }
          color={_get(expandedInsight, "data.insight.frontend_settings.color")}
          attributePercentage={_get(expandedInsight, "data.attributePercentage")}
          funcType={_get(expandedInsight, "data.insight.function")}
          value={_get(expandedInsight, "data.value")}
          outOf={segmentNumbers.get("conditions_results_count")}
          percentage={_get(expandedInsight, "data.percentage")}
        />
      </section>
    )
  }
}

SegmentInsights.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapById: PropTypes.instanceOf(Map).isRequired,
  authenticatedUser: PropTypes.object.isRequired,
}

const selector = formValueSelector("InsigtsFilter")
const mapStateToProps = (state, ownProps) => ({
  searchTerm: selector(state, "search"),
  filteredSource: selector(state, "select"),
  areAttributesFulfilled: areAttributesFulfilled(state),
  attributesMapById: getAttributesMapById(state),
  segmentNumbers: getSegmentsNumbers(state, ownProps.id),
  authenticatedUser: state.authenticatedUser,
  setSegmentNumbers: PropTypes.func.isRequired,
  initSegmentNumbers: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  dispatchSegmentNumbersError: PropTypes.func.isRequired,
})

SegmentInsights = connect(mapStateToProps, {
  retrieveSegmentDeprecated,
  retrieveSegment,
  showLongLoadingBar,
  hideLongLoadingBar,
  setSegmentNumbers,
  initSegmentNumbers,
  showToast,
  dispatchSegmentNumbersError,
})(SegmentInsights)

export default SegmentInsights
