import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Map, List } from "immutable"
import _isEmpty from "lodash/isEmpty"
import _isNumber from "lodash/isNumber"
import _isString from "lodash/isString"
import _sumBy from "lodash/sumBy"
import _size from "lodash/size"
import _get from "lodash/get"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import TimeAgo from "react-timeago"
import { CSSTransition } from "react-transition-group"

// selectors
import {
  isDataSourcesFulfilled,
  getDataSourcesData,
} from "resources/dataSource/dataSourceSelectors"
import { areAttributesFulfilled, getAttributesMapById } from "selectors/attributes.selector"
import { getEventsIsFulfilled, getEventsMappedBySourceAndType } from "selectors/event.selector"
import {
  getGlobalSettingsByKey,
  isGlobalSettingsFulfilled,
} from "selectors/globalSettings.selector"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import IconButton from "components/UI/elements/IconButton"
import Duration from "components/UI/elements/Duration"
import InfoTooltip from "components/UI/elements/InfoTooltip"

// charts
import EventsOverTimeChart from "./charts/EventsOverTimeChart"
import CustomersOverTimeChart from "./charts/CustomersOverTimeChart"
import CustomersPerSourceChart from "./charts/CustomersPerSourceChart"
import CustomersAcrossXSourcesChart from "./charts/CustomersAcrossXSourcesChart"
import CustomersUpdatedChart from "./charts/CustomersUpdatedChart"
import VerticalChart from "./charts/VerticalChart"

// helpers, constants
import { api } from "api"
import { abbreviateNumber } from "helpers/number.helper"
import PendingPromise from "helpers/pendingPromise.helper"

import "./Analytics.scss"
import Tippy from "@tippyjs/react"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { timeAgoFormatter } from "helpers/timeAgo.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import { hasAccess } from "helpers/authenticatedUser.helper"
import { isNil } from "ramda"

class Analytics extends PureComponent {
  cacheIntervalId = null

  constructor(props) {
    super(props)
    this.state = {
      customerAttributesCount: {
        loading: true,
        value: null,
      },
      customerEventsCount: {
        loading: true,
        value: null,
      },
      customersCount: {
        loading: true,
        value: null,
      },
      customersCountTotal: {
        loading: true,
        value: null,
      },
      customerEventsPerEventType: {
        loading: true,
        data: null,
        order: "desc",
      },
      customerEventsPerDate: {
        loading: true,
        data: null,
        resolution: null,
      },
      customersUpdated: {
        loading: true,
        data: null,
      },
      customersPerDate: {
        loading: true,
        data: null,
        resolution: null,
      },
      customersPerAttributeCounts: {
        loading: true,
        data: null,
        order: "desc",
      },
      customersPerSource: {
        loading: true,
        data: null,
      },
      customersPerSourcesCount: {
        loading: true,
        data: null,
        totalCustomersCount: null,
      },
      eventTypesLastEventTimes: {
        loading: true,
        data: null,
        order: "desc",
      },
      licenceUsage: {
        loading: true,
        data: null,
      },
      cacheInitTime: null,
      cacheRefreshInitTime: "",
      chartsExpanded: Map(),
      chartsProportions: Map(),
    }

    this.chartRefs = {}

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp, false)

    const cacheInitTimePromise = this.pendingPromises.create(api.cacheStatus())
    cacheInitTimePromise.promise
      .then(response => {
        this.setState({
          cacheInitTime: response.init_time,
          cacheRefreshInitTime: _isString(response.refresh_init_time)
            ? response.refresh_init_time
            : "",
        })
        this.pendingPromises.remove(cacheInitTimePromise)
        if (response.refresh_init_time) {
          this.cacheIntervalId = setInterval(this.checkCacheRenewInProgress, 5000)
        }
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            cacheInitTime: null,
          })
        }
        this.pendingPromises.remove(cacheInitTimePromise)
      })

    const customerEventsCountPromise = this.pendingPromises.create(api.customerEventsCount())
    customerEventsCountPromise.promise
      .then(response => {
        this.setState({
          customerEventsCount: {
            loading: false,
            value: response.customer_events_count,
          },
        })
        this.pendingPromises.remove(customerEventsCountPromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customerEventsCount: {
              loading: false,
            },
          })
        }
        this.pendingPromises.remove(customerEventsCountPromise)
      })

    const customerAttributesCountRequest = this.pendingPromises.create(
      api.customerAttributesCount(),
    )
    customerAttributesCountRequest.promise
      .then(response => {
        this.setState({
          customerAttributesCount: {
            loading: false,
            value: response.customer_attributes_count,
          },
        })
        this.pendingPromises.remove(customerAttributesCountRequest)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customerAttributesCount: {
              loading: false,
            },
          })
        }
        this.pendingPromises.remove(customerAttributesCountRequest)
      })

    const customersCountPromise = this.pendingPromises.create(api.customersCount())
    customersCountPromise.promise
      .then(response => {
        this.setState({
          customersCount: {
            loading: false,
            value: response.customer_entities_count,
          },
          customersCountTotal: {
            loading: false,
            value: response.customers_before_stitching_count,
          },
        })
        this.pendingPromises.remove(customersCountPromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customersCount: {
              loading: false,
            },
            customersCountTotal: {
              loading: false,
            },
          })
        }
        this.pendingPromises.remove(customersCountPromise)
      })

    const customerEventsPerEventTypePromise = this.pendingPromises.create(
      api.customerEventsPerEventType(),
    )
    customerEventsPerEventTypePromise.promise
      .then(response => {
        this.setState({
          customerEventsPerEventType: {
            loading: false,
            data: response.customer_events_per_event_type.length
              ? List(
                  response.customer_events_per_event_type.map(item => ({
                    item_id: `${item.source_id}-${item.event_type}`,
                    value: item.count,
                    source_id: item.source_id,
                  })),
                )
              : null,
            order: "desc",
          },
        })
        this.pendingPromises.remove(customerEventsPerEventTypePromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customerEventsPerEventType: {
              loading: false,
              data: null,
              order: "desc",
            },
          })
        }
        this.pendingPromises.remove(customerEventsPerEventTypePromise)
      })

    const customerEventsPerDatePromise = this.pendingPromises.create(api.customerEventsPerDate())
    customerEventsPerDatePromise.promise
      .then(response => {
        this.setState({
          customerEventsPerDate: {
            loading: false,
            data: response.customer_events_per_date.length
              ? response.customer_events_per_date
              : null,
            resolution: response.resolution,
            startDate: response.selection_settings.start_date,
          },
        })
        this.pendingPromises.remove(customerEventsPerDatePromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customerEventsPerDate: {
              loading: false,
              data: null,
              resolution: null,
            },
          })
        }
        this.pendingPromises.remove(customerEventsPerDatePromise)
      })

    const customersUpdatedRequest = this.pendingPromises.create(api.customersLastUpdate())
    customersUpdatedRequest.promise
      .then(response => {
        this.setState({
          customersUpdated: {
            loading: false,
            data:
              response && _size(response.customer_last_update)
                ? response.customer_last_update
                : null,
          },
        })
        this.pendingPromises.remove(customersUpdatedRequest)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customersUpdated: {
              loading: false,
              data: null,
            },
          })
        }
        this.pendingPromises.remove(customersUpdatedRequest)
      })

    const customersPerDatePromise = this.pendingPromises.create(api.customersPerDate())
    customersPerDatePromise.promise
      .then(response => {
        this.setState({
          customersPerDate: {
            loading: false,
            data: response.customers_per_date.length ? response.customers_per_date : null,
            resolution: response.resolution,
          },
        })
        this.pendingPromises.remove(customersPerDatePromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customersPerDate: {
              loading: false,
              data: null,
              resolution: null,
            },
          })
        }
        this.pendingPromises.remove(customersPerDatePromise)
      })

    const customersPerAttributeCountsPromise = this.pendingPromises.create(
      api.customersPerAttributeCounts(),
    )
    customersPerAttributeCountsPromise.promise
      .then(response => {
        this.setState({
          customersPerAttributeCounts: {
            loading: false,
            data: response.customers_per_attribute_counts.length
              ? List(
                  response.customers_per_attribute_counts.map(item => ({
                    item_id: item.attribute_id,
                    value: item.count,
                  })),
                )
              : null,
            order: "desc",
          },
        })
        this.pendingPromises.remove(customersPerAttributeCountsPromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customersPerAttributeCounts: {
              loading: false,
              data: null,
              order: "desc",
            },
          })
        }
        this.pendingPromises.remove(customersPerAttributeCountsPromise)
      })

    const eventTypesLastEventTimesPromise = this.pendingPromises.create(
      api.eventTypesLastEventTimes(),
    )
    eventTypesLastEventTimesPromise.promise
      .then(res => {
        const now = moment.utc()
        const resultWithSecondsDiff = res.event_types_last_event_times.map(entry => ({
          item_id: `${entry.source_id}-${entry.event_type}`,
          value: now.diff(moment.utc(entry.last_event_time), "seconds"),
          source_id: entry.source_id,
        }))
        this.setState({
          eventTypesLastEventTimes: {
            loading: false,
            data: resultWithSecondsDiff.length ? List(resultWithSecondsDiff) : null,
            order: "desc",
          },
        })
        this.pendingPromises.remove(eventTypesLastEventTimesPromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            eventTypesLastEventTimes: {
              loading: false,
              data: null,
              order: "desc",
            },
          })
        }
        this.pendingPromises.remove(eventTypesLastEventTimesPromise)
      })

    const customersPerSourcePromise = this.pendingPromises.create(api.customersPerSource())
    customersPerSourcePromise.promise
      .then(response => {
        this.setState({
          customersPerSource: {
            loading: false,
            data: response.customers_per_source.length ? response.customers_per_source : null,
            totalCustomersCount: response.customers_per_source.length
              ? _sumBy(response.customers_per_source, "count")
              : null,
          },
        })
        this.pendingPromises.remove(customersPerSourcePromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customersPerSource: {
              loading: false,
              data: null,
              totalCustomersCount: null,
            },
          })
        }
        this.pendingPromises.remove(customersPerSourcePromise)
      })

    const customersPerSourcesCountPromise = this.pendingPromises.create(
      api.customersPerSourcesCount(),
    )
    customersPerSourcesCountPromise.promise
      .then(response => {
        this.setState({
          customersPerSourcesCount: {
            loading: false,
            data: response.customers_per_sources_count.length
              ? response.customers_per_sources_count
              : null,
          },
        })
        this.pendingPromises.remove(customersPerSourcesCountPromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            customersPerSourcesCount: {
              loading: false,
              data: null,
            },
          })
        }
        this.pendingPromises.remove(customersPerSourcesCountPromise)
      })

    if (hasAccess.data.licenceUsage()) {
      const licenceUsagePromise = this.pendingPromises.create(api.licenceUsage())
      licenceUsagePromise.promise
        .then(response => {
          this.setState({
            licenceUsage: {
              loading: false,
              data: response,
            },
          })
          this.pendingPromises.remove(licenceUsagePromise)
        })
        .catch(error => {
          if (!_get(error, "isCanceled")) {
            this.setState({
              licenceUsage: {
                loading: false,
                data: null,
              },
            })
          }
        })
    }
  }

  checkCacheRenewInProgress = () => {
    const cacheStatusRequest = this.pendingPromises.create(api.cacheStatus())
    cacheStatusRequest.promise
      .then(response => {
        if (!response.is_refresh_running) {
          this.setState({
            cacheRefreshInitTime: "",
            cacheInitTime: response.init_time,
          })
          clearInterval(this.cacheIntervalId)
          this.cacheIntervalId = null
        } else {
        }
        this.pendingPromises.remove(cacheStatusRequest)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            cacheRefreshInitTime: "",
          })
        }
        if (this.cacheIntervalId) {
          clearInterval(this.cacheIntervalId)
          this.cacheIntervalId = null
        }
        this.pendingPromises.remove(cacheStatusRequest)
      })
  }

  toggleDataOrder = statePath => () => {
    this.setState(prevState => ({
      [statePath]: {
        ...prevState[statePath],
        order: prevState[statePath].order === "asc" ? "desc" : "asc",
      },
    }))
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    clearInterval(this.cacheIntervalId)
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleDateValueChange = dateType => date => {
    this.setState({
      [dateType]: date,
    })
  }

  handleKeyUp = evt => {
    const keys = {
      27: () => {
        this.toggleChartsExpanded()()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  toggleChartsExpanded = chart => () => {
    const { chartsProportions, chartsExpanded } = this.state
    if (!chart) {
      chart = chartsExpanded.findKey(ch => ch === "expanded")
    }
    const chartElement = this.chartRefs[chart]
    if (chartElement) {
      const chartProportions = chartElement.getBoundingClientRect()

      if (chartsExpanded.get(chart) === "expanded") {
        this.setState(
          {
            chartsExpanded: chartsExpanded.set(chart, "collapsed-start"),
          },
          () => {
            setTimeout(() => {
              this.setState({
                chartsExpanded: chartsExpanded.set(chart, "collapsed-end"),
              })
              document.body.style["overflow"] = "auto"
              document.body.style["padding-right"] = "0px"
            }, 200)
          },
        )
      } else if (chartsExpanded.get(chart) === "collapsed-end" || !chartsExpanded.get(chart)) {
        const bodyWidth = document.body.clientWidth
        const windowWidth = window.innerWidth
        document.body.style["overflow"] = "hidden"
        document.body.style["padding-right"] = `${windowWidth - bodyWidth}px`

        this.setState(
          {
            chartsProportions: chartsProportions.set(
              chart,
              Map({
                xLeft: chartProportions.left,
                xTop: chartProportions.top,
                xWidth: chartProportions.width,
                xHeight: chartProportions.height,
              }),
            ),
          },
          () => {
            setTimeout(() => {
              const windowWidth = window.innerWidth
              const windowHeight = window.innerHeight
              const expandedWidth = windowWidth * 0.9 > 1160 ? 1160 : windowWidth * 0.9
              const expandedHeight = windowHeight * 0.9

              this.setState(prevState => ({
                chartsExpanded: chartsExpanded.set(chart, "expanded"),
                chartsProportions: prevState.chartsProportions
                  .setIn([chart, "lLeft"], (windowWidth - expandedWidth) / 2)
                  .setIn([chart, "lTop"], (windowHeight - expandedHeight) / 2)
                  .setIn([chart, "lWidth"], expandedWidth)
                  .setIn([chart, "lHeight"], expandedHeight),
              }))
            }, 50)
          },
        )
      }
    }
  }

  renderNAInfo = (vertical = false) => {
    return (
      <p className={`value error-value ${vertical ? "vertical" : ""}`}>
        <span className="chart-icon">
          <FontAwesomeIcon icon={["far", "chart-line-down"]} />
        </span>
        <span>
          Likely there is not enough data to render this graph or cache is not refreshed. It can be
          solved by an administrator.
        </span>
      </p>
    )
  }

  renderAbbreviateNumberWithTooltip = value => {
    if (value > 999) {
      return (
        <Tippy content={getUserFriendlyValueFormat(value, "int")}>
          <span>{abbreviateNumber(value)}</span>
        </Tippy>
      )
    } else return value
  }

  render() {
    const {
      isSourcesFulfilled,
      sources,
      areAttributesFulfilled,
      attributesMapById,
      isEventsFulfilled,
      eventsMapBySourceAndType,
      hiddenSourcesSettings,
      isGlobalSettingsFulfilled,
    } = this.props
    const {
      customerEventsCount,
      customersCount,
      customersCountTotal,
      customerEventsPerEventType,
      customerEventsPerDate,
      customersPerDate,
      customersUpdated,
      customersPerAttributeCounts,
      customersPerSource,
      customersPerSourcesCount,
      customerAttributesCount,
      eventTypesLastEventTimes,
      licenceUsage,
      cacheInitTime,
      chartsExpanded,
      chartsProportions,
      cacheRefreshInitTime,
    } = this.state

    const isSomeChartExpanded = chartsExpanded.some(chart => chart === "expanded")
    let sourcesSize = 0
    if (isSourcesFulfilled && isGlobalSettingsFulfilled) {
      if (List.isList(hiddenSourcesSettings.value) && hiddenSourcesSettings.value.size > 0) {
        sources.forEach(source => {
          if (!hiddenSourcesSettings.value.includes(source.id)) {
            sourcesSize++
          }
        })
      } else {
        sourcesSize = sources.size
      }
    }

    return (
      <React.Fragment>
        <section className="wrapper analytics-dashboard">
          <div className="dashboard-header">
            <PaperHeader className="page-title" size="small">
              <h3 className="title">Diagnostic Dashboard</h3>
              <Tippy
                placement="right"
                interactive
                content={
                  <>
                    <p>
                      The Diagnostic Dashboard is a{" "}
                      <strong>tool to get you to know your customer data</strong>. It shows how many
                      customers you have, which channels they are active on and what is the overall
                      quality of the data you have collected on every one of your customers.{" "}
                      <strong>
                        The dashboard does not provide insights into customer behaviour.
                      </strong>
                    </p>
                    <p>
                      To learn more about the Diagnostic Dashboard, please refer to{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        this article
                      </a>
                      .
                    </p>
                  </>
                }
              >
                <span className="info-tooltip-icon">
                  <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            </PaperHeader>
            <Paper className="diagnostic-values">
              <div
                className={`box customers-count ${
                  customersCount.loading || customersCountTotal.loading ? "loading" : ""
                }`}
              >
                <div className="box-chart-title small">
                  <h3>
                    No. of customers{" "}
                    <InfoTooltip placement="right" interactive className="no-of-customers-tooltip">
                      Number of customer entities before and after{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-knowledge-base/page/identity-stitching-and-how-customer-identity-appears"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        profile stitching
                      </a>
                      .
                    </InfoTooltip>
                  </h3>
                </div>
                {!customersCount.loading && !customersCountTotal.loading && (
                  <p className="value relative">
                    {customersCount.value === null
                      ? "N/A"
                      : this.renderAbbreviateNumberWithTooltip(customersCount.value)}
                    {_isNumber(customersCountTotal.value) && (
                      <span className="before-stitching">
                        <span className="val">
                          {this.renderAbbreviateNumberWithTooltip(customersCountTotal.value)}
                        </span>{" "}
                        before profile stitching
                      </span>
                    )}
                  </p>
                )}
              </div>
              <div className="delimiter" />
              <div
                className={`box sources-count ${
                  !isSourcesFulfilled || !isGlobalSettingsFulfilled ? "loading" : ""
                }`}
              >
                <div className="box-chart-title small">
                  <h3>Connected Sources</h3>
                </div>
                {isSourcesFulfilled && isGlobalSettingsFulfilled && (
                  <p className="value">{sourcesSize}</p>
                )}
              </div>
              <div className="delimiter" />
              <div className={`box attributes-count ${!areAttributesFulfilled ? "loading" : ""}`}>
                <div className="box-chart-title small">
                  <h3>Customer Attributes</h3>
                </div>
                {areAttributesFulfilled && <p className="value">{attributesMapById.size}</p>}
              </div>
              <div className="delimiter" />
              <div
                className={`box customer-attributes-count ${
                  customerAttributesCount.loading ? "loading" : ""
                }`}
              >
                <div className="box-chart-title small">
                  <h3>Customer Attributes Values</h3>
                </div>
                {!customerAttributesCount.loading && (
                  <p className="value">
                    {customerAttributesCount.value === null
                      ? "N/A"
                      : this.renderAbbreviateNumberWithTooltip(customerAttributesCount.value)}
                  </p>
                )}
              </div>
              <div className="delimiter" />
              <div className={`box events-count ${customerEventsCount.loading ? "loading" : ""}`}>
                <div className="box-chart-title small">
                  <h3>Total events</h3>
                </div>
                {!customerEventsCount.loading && (
                  <p className="value">
                    {customerEventsCount.value === null
                      ? "N/A"
                      : this.renderAbbreviateNumberWithTooltip(customerEventsCount.value)}
                  </p>
                )}
              </div>
            </Paper>
            <PaperHeader className="last-cache-update" size="small">
              <h4>Data updated</h4>
              {cacheInitTime && (
                <div className="cache-init-time">
                  {cacheRefreshInitTime.length === 0 && (
                    <TimeAgo
                      date={moment.utc(cacheInitTime).toISOString()}
                      formatter={timeAgoFormatter({ seconds: "min" })}
                    />
                  )}
                  {cacheRefreshInitTime.length !== 0 && (
                    <span className="refresh-in-progress">
                      Refresh is in progress{" "}
                      <strong>
                        (<Duration datetime={cacheRefreshInitTime} units={["d", "h", "m"]} />)
                      </strong>
                    </span>
                  )}
                </div>
              )}
            </PaperHeader>
          </div>
          {hasAccess.data.licenceUsage() && (
            <Paper className="licence-usage">
              <div className="title">Licence usage:</div>
              <div className="licence-usage-item">
                Monthly processed events&nbsp;&nbsp;
                <InfoTooltip>Total events processed in the last 30 days</InfoTooltip>
                <span className="value">
                  {licenceUsage.loading && <LoadingIndicator />}

                  {!isNil(licenceUsage.data?.customer_events_hidden_included_last_30_days_count) &&
                    abbreviateNumber(
                      licenceUsage.data.customer_events_hidden_included_last_30_days_count,
                    )}

                  {isNil(licenceUsage.data?.customer_events_hidden_included_last_30_days_count) &&
                    !licenceUsage.loading &&
                    "N/A"}
                </span>
              </div>
              <div className="delimiter" />
              <div className="licence-usage-item">
                Total events stored (as of now)&nbsp;&nbsp;
                <InfoTooltip>Total events in storage, including hidden events</InfoTooltip>
                <span className="value">
                  {licenceUsage.loading && <LoadingIndicator />}

                  {!isNil(licenceUsage.data?.customer_events_hidden_included_count) &&
                    abbreviateNumber(licenceUsage.data.customer_events_hidden_included_count)}

                  {isNil(licenceUsage.data?.customer_events_hidden_included_count) &&
                    !licenceUsage.loading &&
                    "N/A"}
                </span>
              </div>
              <div className="delimiter" />
              <div className="licence-usage-item">
                Total number of unified profiles&nbsp;&nbsp;
                <InfoTooltip>Total number of unified profiles after profile stitching</InfoTooltip>
                <span className="value">
                  {customersCount.loading && <LoadingIndicator />}

                  {!isNil(customersCount.value) && abbreviateNumber(customersCount.value)}

                  {isNil(customersCount.value) && !customersCount.loading && "N/A"}
                </span>
              </div>
            </Paper>
          )}
          <div className="grid">
            <Paper
              ref={el => {
                this.chartRefs["customerEventsPerDate"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customerEventsPerDate") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerDate", "lLeft"])
                    : chartsProportions.getIn(["customerEventsPerDate", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customerEventsPerDate") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerDate", "lTop"])
                    : chartsProportions.getIn(["customerEventsPerDate", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customerEventsPerDate") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerDate", "lWidth"])
                    : chartsProportions.getIn(["customerEventsPerDate", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customerEventsPerDate") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerDate", "lHeight"])
                    : chartsProportions.getIn(["customerEventsPerDate", "xHeight"], "100%"),
              }}
              className={`box events-over-time ${customerEventsPerDate.loading ? "loading" : ""} ${
                chartsExpanded.get("customerEventsPerDate")
                  ? chartsExpanded.get("customerEventsPerDate")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content="This chart shows the number of events collected by your project over the last #
                    days. Watch out for extreme dips or spikes as they might indicate irregularity
                    in the data."
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>
                  <h3>New events over time</h3>
                </div>
                <IconButton
                  className="expand-button"
                  iconStyle="far"
                  iconName={
                    chartsExpanded.get("customerEventsPerDate") !== "expanded"
                      ? "expand-alt"
                      : "times"
                  }
                  tooltip={
                    chartsExpanded.get("customerEventsPerDate") !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customerEventsPerDate")}
                  disabled={!customerEventsPerDate.data || customerEventsPerDate.loading}
                />
              </div>
              {!_isEmpty(customerEventsPerDate.data) && isSourcesFulfilled && (
                <EventsOverTimeChart
                  sources={sources}
                  rawData={customerEventsPerDate.data}
                  color="#68B3A1"
                  dataResolution={customerEventsPerDate.resolution}
                  className="horizontal-chart"
                  startDate={customerEventsPerDate.startDate}
                />
              )}
              {!customerEventsPerDate.data && !customerEventsPerDate.loading && this.renderNAInfo()}
            </Paper>

            <Paper
              ref={el => {
                this.chartRefs["customersPerDate"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customersPerDate") === "expanded"
                    ? chartsProportions.getIn(["customersPerDate", "lLeft"])
                    : chartsProportions.getIn(["customersPerDate", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customersPerDate") === "expanded"
                    ? chartsProportions.getIn(["customersPerDate", "lTop"])
                    : chartsProportions.getIn(["customersPerDate", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customersPerDate") === "expanded"
                    ? chartsProportions.getIn(["customersPerDate", "lWidth"])
                    : chartsProportions.getIn(["customersPerDate", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customersPerDate") === "expanded"
                    ? chartsProportions.getIn(["customersPerDate", "lHeight"])
                    : chartsProportions.getIn(["customersPerDate", "xHeight"], "100%"),
              }}
              className={`box identified-customers-over-time ${
                customersPerDate.loading ? "loading" : ""
              } ${
                chartsExpanded.get("customersPerDate")
                  ? chartsExpanded.get("customersPerDate")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content="This chart shows the number of identified customers for your brand for the last
                    # days. Watch out for extreme dips or spikes as they might indicate irregularity
                    in the data and/or customer identification process."
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>No. of identified customers over time</h3>
                </div>
                <IconButton
                  className="expand-button"
                  iconStyle="far"
                  iconName={
                    chartsExpanded.get("customersPerDate") !== "expanded" ? "expand-alt" : "times"
                  }
                  tooltip={
                    chartsExpanded.get("customersPerDate") !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customersPerDate")}
                  disabled={!customersPerDate.data || customersPerDate.loading}
                />
              </div>
              {!_isEmpty(customersPerDate.data) && (
                <CustomersOverTimeChart
                  rawData={customersPerDate.data}
                  color="#19309F"
                  dataResolution={customersPerDate.resolution}
                  className="horizontal-chart"
                />
              )}
              {!customersPerDate.data && !customersPerDate.loading && this.renderNAInfo()}
            </Paper>

            <Paper
              ref={el => {
                this.chartRefs["customersUpdated"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customersUpdated") === "expanded"
                    ? chartsProportions.getIn(["customersUpdated", "lLeft"])
                    : chartsProportions.getIn(["customersUpdated", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customersUpdated") === "expanded"
                    ? chartsProportions.getIn(["customersUpdated", "lTop"])
                    : chartsProportions.getIn(["customersUpdated", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customersUpdated") === "expanded"
                    ? chartsProportions.getIn(["customersUpdated", "lWidth"])
                    : chartsProportions.getIn(["customersUpdated", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customersUpdated") === "expanded"
                    ? chartsProportions.getIn(["customersUpdated", "lHeight"])
                    : chartsProportions.getIn(["customersUpdated", "xHeight"], "100%"),
              }}
              className={`box customers-updated ${customersUpdated.loading ? "loading" : ""} ${
                chartsExpanded.get("customersUpdated")
                  ? chartsExpanded.get("customersUpdated")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content={
                      <span>
                        This chart shows if customer's attributes are kept up to date. Contact Meiro
                        support if you see a significant amount of customers in the &gt;96h section.
                      </span>
                    }
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>Customers' attributes updated</h3>
                </div>
                <IconButton
                  className="expand-button"
                  iconStyle="far"
                  iconName={
                    chartsExpanded.get("customersUpdated") !== "expanded" ? "expand-alt" : "times"
                  }
                  tooltip={
                    chartsExpanded.get("customersUpdated") !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customersUpdated")}
                  disabled={!customersUpdated.data || customersUpdated.loading}
                />
              </div>
              {!_isEmpty(customersUpdated.data) && (
                <CustomersUpdatedChart
                  rawData={customersUpdated.data}
                  color="#19309F"
                  className="horizontal-chart"
                />
              )}
              {!customersUpdated.data && !customersUpdated.loading && this.renderNAInfo()}
            </Paper>

            <Paper
              ref={el => {
                this.chartRefs["customersPerSourcesCount"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customersPerSourcesCount") === "expanded"
                    ? chartsProportions.getIn(["customersPerSourcesCount", "lLeft"])
                    : chartsProportions.getIn(["customersPerSourcesCount", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customersPerSourcesCount") === "expanded"
                    ? chartsProportions.getIn(["customersPerSourcesCount", "lTop"])
                    : chartsProportions.getIn(["customersPerSourcesCount", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customersPerSourcesCount") === "expanded"
                    ? chartsProportions.getIn(["customersPerSourcesCount", "lWidth"])
                    : chartsProportions.getIn(["customersPerSourcesCount", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customersPerSourcesCount") === "expanded"
                    ? chartsProportions.getIn(["customersPerSourcesCount", "lHeight"])
                    : chartsProportions.getIn(["customersPerSourcesCount", "xHeight"], "100%"),
              }}
              className={`box customers-per-sources-count ${
                customersPerSourcesCount.loading || !isSourcesFulfilled ? "loading" : ""
              } ${
                chartsExpanded.get("customersPerSourcesCount")
                  ? chartsExpanded.get("customersPerSourcesCount")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content="This chart shows the number of customers that are identified on 1 through x
                    sources. The maximum of x is the total number of data sources. The higher the
                    number of identified customers in the higher cross-data sources, the better the
                    performance of the customer profiling process, and the better the quality of the
                    customer profile output."
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>No. of unique customers identified across X sources</h3>
                </div>
                <IconButton
                  className="expand-button"
                  iconStyle="far"
                  iconName={
                    chartsExpanded.get("customersPerSourcesCount") !== "expanded"
                      ? "expand-alt"
                      : "times"
                  }
                  tooltip={
                    chartsExpanded.get("customersPerSourcesCount") !== "expanded"
                      ? "Expand"
                      : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customersPerSourcesCount")}
                  disabled={
                    !customersPerSourcesCount.data ||
                    customersPerSourcesCount.loading ||
                    !isSourcesFulfilled
                  }
                />
              </div>
              {customersPerSourcesCount.data && isSourcesFulfilled && (
                <CustomersAcrossXSourcesChart
                  rawData={customersPerSourcesCount.data}
                  className="horizontal-chart"
                  sources={sources}
                />
              )}
              {!customersPerSourcesCount.data &&
                !customersPerSourcesCount.loading &&
                this.renderNAInfo()}
            </Paper>
            <Paper
              ref={el => {
                this.chartRefs["customersPerSource"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customersPerSource") === "expanded"
                    ? chartsProportions.getIn(["customersPerSource", "lLeft"])
                    : chartsProportions.getIn(["customersPerSource", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customersPerSource") === "expanded"
                    ? chartsProportions.getIn(["customersPerSource", "lTop"])
                    : chartsProportions.getIn(["customersPerSource", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customersPerSource") === "expanded"
                    ? chartsProportions.getIn(["customersPerSource", "lWidth"])
                    : chartsProportions.getIn(["customersPerSource", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customersPerSource") === "expanded"
                    ? chartsProportions.getIn(["customersPerSource", "lHeight"])
                    : chartsProportions.getIn(["customersPerSource", "xHeight"], "100%"),
              }}
              className={`box customers-per-source ${
                customersPerSource.loading || !isSourcesFulfilled ? "loading" : ""
              } ${
                chartsExpanded.get("customersPerSource")
                  ? chartsExpanded.get("customersPerSource")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title expand-functionality">
                <div className="union-elements">
                  <Tippy content="This chart shows the number of identified customers per data source.">
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>No. of customers identified per source</h3>
                </div>
                <IconButton
                  className="expand-button"
                  iconStyle="far"
                  iconName={
                    chartsExpanded.get("customersPerSource") !== "expanded" ? "expand-alt" : "times"
                  }
                  tooltip={
                    chartsExpanded.get("customersPerSource") !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customersPerSource")}
                  disabled={
                    !customersPerSource.data || customersPerSource.loading || !isSourcesFulfilled
                  }
                />
              </div>
              {customersPerSource.data && isSourcesFulfilled && (
                <CustomersPerSourceChart
                  rawData={customersPerSource.data}
                  sourceMapById={sources}
                  className="horizontal-chart"
                />
              )}
              {!customersPerSource.data && !customersPerSource.loading && this.renderNAInfo()}
            </Paper>

            <Paper
              ref={el => {
                this.chartRefs["customerEventsPerEventType"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customerEventsPerEventType") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerEventType", "lLeft"])
                    : chartsProportions.getIn(["customerEventsPerEventType", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customerEventsPerEventType") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerEventType", "lTop"])
                    : chartsProportions.getIn(["customerEventsPerEventType", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customerEventsPerEventType") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerEventType", "lWidth"])
                    : chartsProportions.getIn(["customerEventsPerEventType", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customerEventsPerEventType") === "expanded"
                    ? chartsProportions.getIn(["customerEventsPerEventType", "lHeight"])
                    : chartsProportions.getIn(["customerEventsPerEventType", "xHeight"], "100%"),
              }}
              className={`box events-per-event-type ${
                customerEventsPerEventType.loading || !isSourcesFulfilled || !isEventsFulfilled
                  ? "loading"
                  : ""
              } ${
                chartsExpanded.get("customerEventsPerEventType")
                  ? chartsExpanded.get("customerEventsPerEventType")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title sort-functionality expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content="This chart shows customer engagements across different types of events captured
                    in your project. Check out which channels and/or activities your customer
                    engages in the most with your brand."
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>No. of events per event type</h3>
                </div>
                <div className="control-buttons">
                  <IconButton
                    className="sort-button"
                    iconStyle="far"
                    iconName={
                      customerEventsPerEventType.order === "desc"
                        ? "sort-amount-down"
                        : "sort-amount-up"
                    }
                    tooltip={
                      customerEventsPerEventType.order === "desc"
                        ? "Sort ascending"
                        : "Sort descending"
                    }
                    onClick={this.toggleDataOrder("customerEventsPerEventType")}
                  />
                  <IconButton
                    className="expand-button"
                    iconStyle="far"
                    iconName={
                      chartsExpanded.get("customerEventsPerEventType") !== "expanded"
                        ? "expand-alt"
                        : "times"
                    }
                    tooltip={
                      chartsExpanded.get("customerEventsPerEventType") !== "expanded"
                        ? "Expand"
                        : "Close"
                    }
                    onClick={this.toggleChartsExpanded("customerEventsPerEventType")}
                    disabled={
                      !customerEventsPerEventType.data ||
                      customerEventsPerEventType.loading ||
                      !isSourcesFulfilled ||
                      !isEventsFulfilled
                    }
                  />
                </div>
              </div>
              {customerEventsPerEventType.data && isSourcesFulfilled && isEventsFulfilled && (
                <VerticalChart
                  sourcesLegend={sources}
                  data={customerEventsPerEventType.data}
                  order={customerEventsPerEventType.order}
                  labels={eventsMapBySourceAndType}
                  type="integer"
                  uniqueTooltipIdPrefix="events-per-event-type"
                  expanded={chartsExpanded.get("customerEventsPerEventType") === "expanded"}
                />
              )}
              {!customerEventsPerEventType.data &&
                !customerEventsPerEventType.loading &&
                this.renderNAInfo(true)}
            </Paper>
            <Paper
              ref={el => {
                this.chartRefs["eventTypesLastEventTimes"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("eventTypesLastEventTimes") === "expanded"
                    ? chartsProportions.getIn(["eventTypesLastEventTimes", "lLeft"])
                    : chartsProportions.getIn(["eventTypesLastEventTimes", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("eventTypesLastEventTimes") === "expanded"
                    ? chartsProportions.getIn(["eventTypesLastEventTimes", "lTop"])
                    : chartsProportions.getIn(["eventTypesLastEventTimes", "xTop"], "auto"),
                width:
                  chartsExpanded.get("eventTypesLastEventTimes") === "expanded"
                    ? chartsProportions.getIn(["eventTypesLastEventTimes", "lWidth"])
                    : chartsProportions.getIn(["eventTypesLastEventTimes", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("eventTypesLastEventTimes") === "expanded"
                    ? chartsProportions.getIn(["eventTypesLastEventTimes", "lHeight"])
                    : chartsProportions.getIn(["eventTypesLastEventTimes", "xHeight"], "100%"),
              }}
              className={`box time-since-last-received-event ${
                eventTypesLastEventTimes.loading || !isEventsFulfilled || !isSourcesFulfilled
                  ? "loading"
                  : ""
              } ${
                chartsExpanded.get("eventTypesLastEventTimes")
                  ? chartsExpanded.get("eventTypesLastEventTimes")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title sort-functionality expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content="This chart shows the elapsed time since the last event of each event type.
                    Events are usually collected daily. If the event data reception time is
                    exceeded, it may indicate that your data is not collected frequently enough. In
                    some cases, this may indicate that there are no new events available (for
                    example there have not been transactions for that particular day made)."
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>Time since the last event received</h3>
                </div>
                <div className="control-buttons">
                  <IconButton
                    className="sort-button"
                    iconStyle="far"
                    iconName={
                      eventTypesLastEventTimes.order === "desc"
                        ? "sort-amount-down"
                        : "sort-amount-up"
                    }
                    tooltip={
                      eventTypesLastEventTimes.order === "desc"
                        ? "Sort ascending"
                        : "Sort descending"
                    }
                    onClick={this.toggleDataOrder("eventTypesLastEventTimes")}
                  />
                  <IconButton
                    className="expand-button"
                    iconStyle="far"
                    iconName={
                      chartsExpanded.get("eventTypesLastEventTimes") !== "expanded"
                        ? "expand-alt"
                        : "times"
                    }
                    tooltip={
                      chartsExpanded.get("eventTypesLastEventTimes") !== "expanded"
                        ? "Expand"
                        : "Close"
                    }
                    onClick={this.toggleChartsExpanded("eventTypesLastEventTimes")}
                    disabled={
                      !eventTypesLastEventTimes.data ||
                      eventTypesLastEventTimes.loading ||
                      !isEventsFulfilled ||
                      !isSourcesFulfilled
                    }
                  />
                </div>
              </div>
              {eventTypesLastEventTimes.data && isEventsFulfilled && isSourcesFulfilled && (
                <VerticalChart
                  sourcesLegend={sources}
                  data={eventTypesLastEventTimes.data}
                  order={eventTypesLastEventTimes.order}
                  labels={eventsMapBySourceAndType}
                  type="time"
                  uniqueTooltipIdPrefix="events-last-received"
                  expanded={chartsExpanded.get("eventTypesLastEventTimes") === "expanded"}
                />
              )}
              {!eventTypesLastEventTimes.data &&
                !eventTypesLastEventTimes.loading &&
                this.renderNAInfo(true)}
            </Paper>

            <Paper
              ref={el => {
                this.chartRefs["customersPerAttributeCounts"] = el
              }}
              style={{
                left:
                  chartsExpanded.get("customersPerAttributeCounts") === "expanded"
                    ? chartsProportions.getIn(["customersPerAttributeCounts", "lLeft"])
                    : chartsProportions.getIn(["customersPerAttributeCounts", "xLeft"], "auto"),
                top:
                  chartsExpanded.get("customersPerAttributeCounts") === "expanded"
                    ? chartsProportions.getIn(["customersPerAttributeCounts", "lTop"])
                    : chartsProportions.getIn(["customersPerAttributeCounts", "xTop"], "auto"),
                width:
                  chartsExpanded.get("customersPerAttributeCounts") === "expanded"
                    ? chartsProportions.getIn(["customersPerAttributeCounts", "lWidth"])
                    : chartsProportions.getIn(["customersPerAttributeCounts", "xWidth"], "100%"),
                height:
                  chartsExpanded.get("customersPerAttributeCounts") === "expanded"
                    ? chartsProportions.getIn(["customersPerAttributeCounts", "lHeight"])
                    : chartsProportions.getIn(["customersPerAttributeCounts", "xHeight"], "100%"),
              }}
              className={`box customers-per-attribute ${
                customersPerAttributeCounts.loading ||
                !areAttributesFulfilled ||
                !isSourcesFulfilled
                  ? "loading"
                  : ""
              } ${
                chartsExpanded.get("customersPerAttributeCounts")
                  ? chartsExpanded.get("customersPerAttributeCounts")
                  : "collapsed-end"
              }`}
            >
              <div className="box-chart-title sort-functionality expand-functionality">
                <div className="union-elements">
                  <Tippy
                    content="This chart shows the number of identified customers by each attribute in your
                    project."
                  >
                    <span className="dashboard-tooltip-icon">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>

                  <h3>No. of customers per attribute</h3>
                </div>
                <div className="control-buttons">
                  <IconButton
                    className="sort-button"
                    onClick={this.toggleDataOrder("customersPerAttributeCounts")}
                    iconStyle="far"
                    iconName={
                      customersPerAttributeCounts.order === "desc"
                        ? "sort-amount-down"
                        : "sort-amount-up"
                    }
                    tooltip={
                      customersPerAttributeCounts.order === "desc"
                        ? "Sort ascending"
                        : "Sort descending"
                    }
                  />
                  <IconButton
                    className="expand-button"
                    iconStyle="far"
                    iconName={
                      chartsExpanded.get("customersPerAttributeCounts") !== "expanded"
                        ? "expand-alt"
                        : "times"
                    }
                    tooltip={
                      chartsExpanded.get("customersPerAttributeCounts") !== "expanded"
                        ? "Expand"
                        : "Close"
                    }
                    onClick={this.toggleChartsExpanded("customersPerAttributeCounts")}
                    disabled={
                      !customersPerAttributeCounts.data ||
                      customersPerAttributeCounts.loading ||
                      !areAttributesFulfilled ||
                      !isSourcesFulfilled
                    }
                  />
                </div>
              </div>
              {customersPerAttributeCounts.data && areAttributesFulfilled && isSourcesFulfilled && (
                <VerticalChart
                  sourcesLegend={sources}
                  data={customersPerAttributeCounts.data}
                  order={customersPerAttributeCounts.order}
                  labels={attributesMapById}
                  type="integer"
                  uniqueTooltipIdPrefix="customer-per-attribute"
                  expanded={chartsExpanded.get("customersPerAttributeCounts") === "expanded"}
                />
              )}
              {!customersPerAttributeCounts.data &&
                !customersPerAttributeCounts.loading &&
                this.renderNAInfo(true)}
            </Paper>
          </div>
        </section>
        <CSSTransition in={isSomeChartExpanded} timeout={200} classNames="fade" unmountOnExit>
          <div className="expand-overlay" onClick={this.toggleChartsExpanded()} />
        </CSSTransition>
      </React.Fragment>
    )
  }
}

Analytics.propTypes = {
  isSourcesFulfilled: PropTypes.bool.isRequired,
  areAttributesFulfilled: PropTypes.bool.isRequired,
  isEventsFulfilled: PropTypes.bool.isRequired,
  sources: PropTypes.instanceOf(Map).isRequired,
  attributesMapById: PropTypes.instanceOf(Map).isRequired,
  eventsMapBySourceAndType: PropTypes.instanceOf(Map).isRequired,
  isGlobalSettingsFulfilled: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isSourcesFulfilled: isDataSourcesFulfilled(state),
  sources: getDataSourcesData(state),
  areAttributesFulfilled: areAttributesFulfilled(state),
  attributesMapById: getAttributesMapById(state),
  isEventsFulfilled: getEventsIsFulfilled(state),
  eventsMapBySourceAndType: getEventsMappedBySourceAndType(state),
  hiddenSourcesSettings: getGlobalSettingsByKey(
    state,
    "hidden_data_sources_from_diagnostic_dashboard",
  ),
  isGlobalSettingsFulfilled: isGlobalSettingsFulfilled(state),
})

export default connect(mapStateToProps)(Analytics)
