import {
  EmbeddedWebBannersAction,
  fetchAllEmbeddedWebBannersFulfilledType,
  createEmbeddedWebBannerFulfilledType,
  modifyEmbeddedWebBannerFulfilledType,
  deleteEmbeddedWebBannerFulfilledType,
  retrieveEmbeddedWebBannerFulfilledType,
  sortEmbeddedWebBannerType,
  copyEmbeddedWebBannerFulfilledType,
} from "./embeddedWBActions"
import { prop, sort } from "ramda"
import { OrderDir } from "types/util"
import { EmbeddedWebBanner, EmbeddedWebBannerSort } from "./embeddedWBTypes"
import { ascend } from "utilities/comparators"

type EmbeddedWebBannersState = {
  initialFetching: boolean
  webBanners: EmbeddedWebBanner[]
  initialSettingsFetching: boolean
  orderBy: EmbeddedWebBannerSort
  orderDir: OrderDir
}
const initialState: EmbeddedWebBannersState = {
  initialFetching: true,
  webBanners: [],
  initialSettingsFetching: true,
  orderBy: "name",
  orderDir: "ASC",
}

export default function embeddedWebBanners(
  state: EmbeddedWebBannersState = initialState,
  action: EmbeddedWebBannersAction,
) {
  switch (action.type) {
    case sortEmbeddedWebBannerType: {
      return {
        ...state,
        orderBy: action.payload.orderBy,
        orderDir: action.payload.orderDir,
      }
    }
    case fetchAllEmbeddedWebBannersFulfilledType: {
      return {
        ...state,
        initialFetching: false,
        webBanners: action.payload,
      }
    }
    case createEmbeddedWebBannerFulfilledType:
    case copyEmbeddedWebBannerFulfilledType: {
      return {
        ...state,
        webBanners: sort(ascend(prop("name")), [...state.webBanners, action.payload.web_banner]),
      }
    }
    case retrieveEmbeddedWebBannerFulfilledType: {
      let webBanners
      if (state.webBanners.find(banner => banner.id === action.payload.web_banner.id)) {
        webBanners = state.webBanners.map(banner =>
          banner.id === action.payload.web_banner.id ? action.payload.web_banner : banner,
        )
      } else {
        webBanners = sort(ascend(prop("name")), [...state.webBanners, action.payload.web_banner])
      }
      return {
        ...state,
        webBanners,
      }
    }
    case modifyEmbeddedWebBannerFulfilledType: {
      return {
        ...state,
        webBanners: state.webBanners.map(webBanner => {
          if (webBanner.id === action.payload.web_banner.id) {
            return action.payload.web_banner
          }
          return webBanner
        }),
      }
    }
    case deleteEmbeddedWebBannerFulfilledType: {
      return {
        ...state,
        webBanners: state.webBanners.filter(webBanner => webBanner.id !== action.meta.webBannerId),
      }
    }

    default:
      return state
  }
}
