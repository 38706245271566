import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import PropTypes from "prop-types"
import moment from "moment"
import TimeAgo from "react-timeago"
import _get from "lodash/get"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _noop from "lodash/noop"
import { Map, List, Record } from "immutable"
import Waypoint from "react-waypoint"

import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { SearchFormDestroyable } from "components/UI/components/SearchForm"
import IconButton, { SIZE, COLOR } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"

import {
  fetchTrashItems,
  restoreTrashSegment,
  restoreTrashUser,
  restoreExportDestination,
  restoreRole,
} from "actions/trash.action"
import { showToast } from "actions/toast.action"

import {
  getTrashData,
  isTrashDataFulfilled,
  getHasMoreItems,
  getSelectionSettings,
} from "selectors/trash.selector"
import { getUsersData } from "selectors/user.selector"
import { hasAccess, usersPermission } from "helpers/authenticatedUser.helper"

// constants, helpers
import { MODAL, TRASH, TOAST, PERMISSION } from "sharedConstants"
import { getRoutePath } from "routes"

import "./TrashList.scss"

class TrashList extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      filterType: null,
      restoreConfirmationModal: Map({
        trashItem: null,
        open: false,
        isRestoring: false,
      }),
      isLoading: false,
    }
  }

  componentDidMount() {
    const { fetchTrashItems } = this.props

    fetchTrashItems(0, TRASH.LOADING_LIMIT).catch(_noop)
  }

  onSearchSubmit = searchText => {
    const { fetchTrashItems } = this.props
    const { filterType } = this.state

    fetchTrashItems(0, TRASH.LOADING_LIMIT, searchText, filterType).catch(_noop)
  }

  getTrashedItemAuthor = trashItem => {
    const { users } = this.props

    if (trashItem.type !== "user") {
      const author = users.find(user => {
        return user.id === trashItem.getIn(["entity", "user_id"])
      })

      if (author) {
        return author.name
      } else {
        return "–"
      }
    }

    return "–"
  }

  restoreConfirmationModalToggle =
    (trashItem = null) =>
    () => {
      this.setState(prevState => ({
        restoreConfirmationModal: prevState.restoreConfirmationModal
          .set("open", !prevState.restoreConfirmationModal.get("open"))
          .set("trashItem", trashItem)
          .set("isRestoring", false),
      }))
    }

  restoreTrashItem = () => {
    const {
      showToast,
      restoreRole,
      restoreTrashUser,
      restoreTrashSegment,
      restoreExportDestination,
    } = this.props
    const { restoreConfirmationModal } = this.state

    if (!restoreConfirmationModal.get("isRestoring")) {
      this.setState({
        restoreConfirmationModal: restoreConfirmationModal.set("isRestoring", true),
      })

      if (restoreConfirmationModal.getIn(["trashItem", "type"]) === "user") {
        restoreTrashUser(restoreConfirmationModal.getIn(["trashItem", "entity", "id"]))
          .then(() => {
            showToast("User restored.", TOAST.TYPE.SUCCESS)
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal
                .set("open", false)
                .set("isRestoring", false),
            })
          })
          .catch(() => {
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal.set("isRestoring", false),
            })
          })
      } else if (restoreConfirmationModal.getIn(["trashItem", "type"]) === "segment") {
        restoreTrashSegment(restoreConfirmationModal.getIn(["trashItem", "entity", "id"]))
          .then(response => {
            const isSmart = response.value.prebuilt
            showToast(
              `${isSmart ? "Smart segment" : "Segment"} restored.`,
              TOAST.TYPE.SUCCESS,
              getRoutePath(isSmart ? "segments.smart.detail" : "segments.detail", {
                id: response.value.id,
              }),
            )
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal
                .set("open", false)
                .set("isRestoring", false),
            })
          })
          .catch(() => {
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal.set("isRestoring", false),
            })
          })
      } else if (restoreConfirmationModal.getIn(["trashItem", "type"]) === "export_destination") {
        restoreExportDestination(restoreConfirmationModal.getIn(["trashItem", "entity", "id"]))
          .then(() => {
            showToast("Destination restored.", TOAST.TYPE.SUCCESS)
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal
                .set("open", false)
                .set("isRestoring", false),
            })
          })
          .catch(() => {
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal.set("isRestoring", false),
            })
          })
      } else if (restoreConfirmationModal.getIn(["trashItem", "type"]) === "user_role") {
        restoreRole(restoreConfirmationModal.getIn(["trashItem", "entity", "id"]))
          .then(() => {
            showToast("Role restored.", TOAST.TYPE.SUCCESS)
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal
                .set("open", false)
                .set("isRestoring", false),
            })
          })
          .catch(() => {
            this.setState({
              restoreConfirmationModal: restoreConfirmationModal.set("isRestoring", false),
            })
          })
      }
    }
  }

  toggleFilterType = filterType => () => {
    this.setState(
      prevState => ({
        filterType: prevState.filterType === filterType ? null : filterType,
      }),
      () => {
        const { filterValues, fetchTrashItems } = this.props
        const { filterType } = this.state
        const searchText = _get(filterValues, "search", "")

        fetchTrashItems(0, TRASH.LOADING_LIMIT, searchText, filterType).catch(_noop)
      },
    )
  }

  loadMoreTrashItems = () => {
    this.setState({ isLoading: true })
    const { fetchTrashItems, selectionSettings, filterValues } = this.props
    const { filterType } = this.state
    const searchText = _get(filterValues, "search", "")

    fetchTrashItems(
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.limit,
      searchText,
      filterType,
    )
      .then(() => {
        this.setState({ isLoading: false })
      })
      .catch(_noop)
  }

  renderWaypoint = () => {
    const { isLoading } = this.state
    const { hasMoreItems } = this.props

    if (!isLoading && hasMoreItems) {
      return <Waypoint onEnter={this.loadMoreTrashItems} bottomOffset={-300} />
    } else if (isLoading && hasMoreItems) {
      return <LoadingIndicator />
    }
  }

  renderUserName = user => {
    return (
      <div className="username">
        <div className="user-name">{user.get("name")}</div>
        <div className="user-email">{user.get("email")}</div>
      </div>
    )
  }

  isUserAbleToRestore = item => {
    if (item.type === "segment") {
      if (
        hasAccess.segments.editForeign() ||
        usersPermission(item.getIn(["entity", "id"])) === PERMISSION.WRITE
      ) {
        return true
      }
      return false
    }
    return true
  }

  render() {
    const { filterType, restoreConfirmationModal } = this.state
    const { isTrashDataFulfilled } = this.props

    const trashItems = this.props.getTrashData

    return (
      <div className="trash-container wrapper">
        <PaperHeader>
          <div className="filter-container">
            <div className="label">trash</div>
            <div className="search-box">
              <SearchFormDestroyable
                placeholder="Search for name"
                className="trash-search"
                onSubmit={this.onSearchSubmit}
                initialValues={{ search: "" }}
                form="SearchTrashForm"
              />
            </div>
            {(hasAccess.setup.implementation() || hasAccess.administration.users()) && (
              <div className="filter-type">
                <div className="label">filter</div>
                {hasAccess.setup.implementation() && (
                  <Button
                    data-testid="destination-filter-button"
                    type="button"
                    color={filterType === "export_destination" ? "primary" : "white"}
                    size="large"
                    onClick={this.toggleFilterType("export_destination")}
                    className="destination-button"
                  >
                    <FontAwesomeIcon className="icon" icon={["far", "bullseye-pointer"]} />
                    Export destination
                  </Button>
                )}
                <Button
                  data-testid="segment-filter-button"
                  type="button"
                  color={filterType === "segment" ? "primary" : "white"}
                  size="large"
                  onClick={this.toggleFilterType("segment")}
                  className="segment-button"
                >
                  <FontAwesomeIcon className="icon" icon={["fas", "filter"]} />
                  Segment
                </Button>
                {hasAccess.administration.users() && (
                  <React.Fragment>
                    <Button
                      data-testid="role-filter-button"
                      type="button"
                      color={filterType === "user_role" ? "primary" : "white"}
                      size="large"
                      onClick={this.toggleFilterType("user_role")}
                      className="role-button"
                    >
                      <FontAwesomeIcon className="icon" icon={["far", "user-tag"]} />
                      Role
                    </Button>
                    <Button
                      data-testid="user-filter-button"
                      type="button"
                      color={filterType === "user" ? "primary" : "white"}
                      size="large"
                      onClick={this.toggleFilterType("user")}
                      className="user-button"
                    >
                      <FontAwesomeIcon className="icon" icon={["far", "user"]} />
                      User
                    </Button>
                  </React.Fragment>
                )}
              </div>
            )}
            {!hasAccess.setup.implementation() && !hasAccess.administration.users() && (
              <span>&nbsp;</span>
            )}
          </div>
        </PaperHeader>
        <Paper hasHeader noPaddingTop>
          {isTrashDataFulfilled && trashItems.size === 0 && (
            <RowMessage className="no-items-found">Nothing found.</RowMessage>
          )}
          {isTrashDataFulfilled && trashItems.size > 0 && (
            <Table className="trash-table">
              <Thead stickyHeader>
                <Th className="item-name-col">Name</Th>
                <Th className="author-col">Author</Th>
                <Th className="type-col">Type</Th>
                <Th textAlignRight className="deleted-date-col">
                  Deleted
                </Th>
                <Th className="restore-col">&nbsp;</Th>
              </Thead>
              <Tbody>
                {trashItems.map(trashItem => (
                  <Tr key={`${trashItem.type}_${trashItem.getIn(["entity", "id"])}`}>
                    <Td
                      className="trash-name"
                      textBold
                      textBigger
                      textBlack
                      data-testid="trash-cell-entity-name"
                    >
                      {trashItem.get("type") === "user" &&
                        this.renderUserName(trashItem.get("entity"))}
                      {trashItem.get("type") !== "user" && trashItem.getIn(["entity", "name"])}
                    </Td>
                    <Td className="trash-author">{this.getTrashedItemAuthor(trashItem)}</Td>
                    <Td className="trash-type" data-testid="trash-cell-entity-type">
                      <span className={`chip ${trashItem.type}`}>{trashItem.type}</span>
                    </Td>
                    <Td textAlignRight className="trash-deleted">
                      <TimeAgo
                        date={moment
                          .utc(trashItem.getIn(["entity", "created"]))
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      />
                    </Td>
                    <Td textAlignRight className="trash-icon">
                      <IconButton
                        onClick={this.restoreConfirmationModalToggle(trashItem)}
                        type="button"
                        color={COLOR.GREEN}
                        disabled={!this.isUserAbleToRestore(trashItem)}
                        withBackground
                        size={SIZE.TAG}
                        iconName="redo"
                        iconStyle="far"
                        tooltip="Restore"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}

          {this.renderWaypoint()}
        </Paper>
        <ConfirmModal
          open={restoreConfirmationModal.get("open")}
          type={MODAL.TYPE.SUCCESS}
          handleClose={this.restoreConfirmationModalToggle()}
          handleConfirm={() => this.restoreTrashItem()}
          title="Restore item"
          action="restore"
          what={restoreConfirmationModal.getIn(["trashItem", "type"], "")}
          item={restoreConfirmationModal.getIn(["trashItem", "entity", "name"], "")}
          isLoading={restoreConfirmationModal.get("isRestoring")}
        />
      </div>
    )
  }
}

TrashList.propTypes = {
  users: PropTypes.instanceOf(Map),
  getTrashData: PropTypes.instanceOf(List),
  isTrashDataFulfilled: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  selectionSettings: PropTypes.instanceOf(Record),
}

const mapStateToProps = state => ({
  filterValues: getFormValues("SearchTrashForm")(state),
  users: getUsersData(state),
  getTrashData: getTrashData(state),
  isTrashDataFulfilled: isTrashDataFulfilled(state),
  hasMoreItems: getHasMoreItems(state),
  selectionSettings: getSelectionSettings(state),
})

export default connect(mapStateToProps, {
  fetchTrashItems,
  restoreTrashSegment,
  restoreTrashUser,
  restoreExportDestination,
  restoreRole,
  showToast,
})(TrashList)
