import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { formValueSelector } from "redux-form"
import {
  areAttributesFulfilled,
  getAttributesData,
  getAttributesTagsList,
  areAttributesFetching,
} from "selectors/attributes.selector"
import { List } from "immutable"
import _toLower from "lodash/toLower"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import { setSortingOptions, setFilterByTags } from "actions/table.action"
import { fetchAttributesList, modifyAttribute } from "actions/attribute.action"
import moment from "moment"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "helpers/compoundAttribute.helper"
import { getIconSrc } from "helpers/image.helper"
import { getRoutePath } from "routes"
import { showToast } from "actions/toast.action"
import AttributesFilterForm from "components/UI/components/AttributesFilterForm"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  RowMessage,
  SortButton,
} from "components/UI/elements/Table"

import "./AttributesList.scss"

class AttributesList extends PureComponent {
  componentDidMount() {
    const { fetchAttributesList, areAttributesFetching } = this.props
    if (!areAttributesFetching) {
      fetchAttributesList(1)
    }
  }

  deleteFilterLabel = labelId => () => {
    const { setFilterByTags, selectedLabels } = this.props
    setFilterByTags(
      "SetupSearchAttributeForm",
      selectedLabels.filter(sLabelId => sLabelId !== labelId),
    )
  }

  selectFilterLabel = labelId => {
    const { setFilterByTags, selectedLabels = [] } = this.props
    if (!selectedLabels.includes(labelId)) {
      setFilterByTags("SetupSearchAttributeForm", [...selectedLabels, labelId])
    }
  }

  sortAttributesBy = column => () => {
    const { setSortingOptions, orderDir } = this.props
    setSortingOptions("SetupSearchAttributeForm", column, orderDir === "ASC" ? "DESC" : "ASC")
  }

  renderSelectedLabels = () => {
    const { selectedLabels, attributesLabels } = this.props

    if (!selectedLabels || selectedLabels.langth === 0) {
      return null
    }

    return selectedLabels.map(labelId => {
      const label = attributesLabels.find(label => label.id === labelId)
      if (label) {
        return (
          <Tag key={label.id} clickable color="primary" onClick={this.deleteFilterLabel(label.id)}>
            {label.name}
          </Tag>
        )
      }
      return null
    })
  }

  someSelectedTagFound = attribute => {
    const { selectedLabels } = this.props

    if (attribute.tags) {
      return attribute.tags.some(tag => {
        return selectedLabels.includes(tag.get("id"))
      })
    }
    return false
  }

  getSortedAttributes(attributes) {
    const { orderBy = "name", orderDir = "ASC" } = this.props

    if (orderBy === "name") {
      if (orderDir === "ASC") {
        return attributes.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        return attributes.sort((a, b) => b.name.localeCompare(a.name))
      }
    } else if (orderBy === "dataSource") {
      if (orderDir === "ASC") {
        return attributes.sort((a, b) => a.source.get("name").localeCompare(b.source.get("name")))
      } else {
        return attributes.sort((a, b) => b.source.get("name").localeCompare(a.source.get("name")))
      }
    } else if (orderBy === "labels") {
      if (orderDir === "ASC") {
        return attributes.sort((a, b) => {
          let aLowestLabel = "ZZZ"
          if (List.isList(a.tags) && a.tags.size > 0) {
            let tmpLowest = a.tags.getIn([0, "name"])
            a.tags.forEach(tag => {
              tmpLowest =
                tmpLowest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpLowest
            })
            aLowestLabel = tmpLowest
          }
          let bLowestLabel = "ZZZ"
          if (List.isList(b.tags) && b.tags.size > 0) {
            let tmpLowest = b.tags.getIn([0, "name"])
            b.tags.forEach(tag => {
              tmpLowest =
                tmpLowest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpLowest
            })
            bLowestLabel = tmpLowest
          }
          return aLowestLabel.localeCompare(bLowestLabel)
        })
      } else {
        return attributes.sort((a, b) => {
          let aHighestLabel = ""
          if (List.isList(a.tags) && a.tags.size > 0) {
            let tmpHighest = a.tags.getIn([0, "name"])
            a.tags.forEach(tag => {
              tmpHighest =
                tmpHighest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpHighest
            })
            aHighestLabel = tmpHighest
          }
          let bHighestLabel = ""
          if (List.isList(b.tags) && b.tags.size > 0) {
            let tmpHighest = b.tags.getIn([0, "name"])
            b.tags.forEach(tag => {
              tmpHighest =
                tmpHighest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpHighest
            })
            bHighestLabel = tmpHighest
          }
          return bHighestLabel.localeCompare(aHighestLabel)
        })
      }
    }
  }

  goToAttributeDetail = attributeId => () => {
    this.props.history.push(getRoutePath("setup.attributes.detail", { id: attributeId }))
  }

  toggleHiddenAttribute = attribute => async () => {
    const { modifyAttribute, showToast } = this.props
    const changedIsHidden = attribute.is_hidden === 1 ? 0 : 1
    await modifyAttribute(attribute.id, { is_hidden: changedIsHidden })
    showToast(`Attribute ${changedIsHidden === 1 ? "hidden" : "visible"}.`)
  }

  renderAttributeRow = attribute => {
    let tags

    if (attribute.tags) {
      tags = attribute.tags.map(tag => {
        return (
          <Tag
            key={tag.get("id")}
            color="primary"
            onClick={() => this.selectFilterLabel(tag.get("id"))}
            className="attribute-label"
          >
            {tag.get("name")}
          </Tag>
        )
      })
    }

    return (
      <Tr key={attribute.id} className={attribute.is_hidden ? "is-hidden" : ""}>
        <Td
          textBold
          textBlack
          textBigger
          withNewBadge={moment().diff(attribute.created, "days") < 8}
          className="attribute-name"
        >
          <span>{attribute.name}</span>
          {isAttributeCompound(attribute.data_type) && (
            <ul className="sub-attrs">
              {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
                <li key={subAttr.id}>{subAttr.name}</li>
              ))}
            </ul>
          )}
        </Td>
        <Td className="attribute-description">{attribute.description}</Td>
        <Td className="attribute-source">
          <div className="attribute-source-flex-wrapper">
            <div className="image-wrapper">
              <img
                src={getIconSrc(
                  {
                    primary: attribute.source.getIn(["frontend_settings", "icon"]),
                    secondary: _toLower(attribute.source.get("type")),
                  },
                  attribute.source.getIn(["frontend_settings", "alt_icon"]),
                )}
                alt="icon"
              />
            </div>
            <div className="source">
              <div>{attribute.source.get("name")}</div>
            </div>
          </div>
        </Td>
        <Td className="attribute-tags">{tags}</Td>
        <Td className="actions" textAlignRight>
          <IconButton
            color={COLOR.BLACK}
            withBackground
            size={SIZE.TAG}
            onClick={this.goToAttributeDetail(attribute.id)}
            iconName="pencil-alt"
            tooltip="Edit"
          />
          <IconButton
            color={COLOR.RED}
            size={SIZE.TAG}
            withBackground
            onClick={this.toggleHiddenAttribute(attribute)}
            className="left-margin"
            disabled={attribute.getIn(["source", "is_hidden"]) === 1}
            iconStyle="far"
            iconName={attribute.is_hidden ? "eye" : "eye-slash"}
            tooltip={
              attribute.getIn(["source", "is_hidden"]) === 1
                ? "Hidden source"
                : attribute.is_hidden
                ? "Show"
                : "Hide"
            }
          />
        </Td>
      </Tr>
    )
  }

  getFilteredAttributes = () => {
    const { selectedLabels = [], searchText = "", source = null, attributes } = this.props

    const attributesToRender = attributes.filter(attribute => {
      if (searchText && !_toLower(attribute.name).includes(_toLower(searchText))) {
        return false
      }
      if (source && attribute.source.get("id") !== source.value) {
        return false
      }
      if (selectedLabels.length > 0 && !this.someSelectedTagFound(attribute)) {
        return false
      }
      return true
    })

    return this.getSortedAttributes(attributesToRender).map(attribute =>
      this.renderAttributeRow(attribute),
    )
  }

  render() {
    const {
      attributes,
      areAttributesFulfilled,
      selectedLabels = [],
      attributesLabels,
      orderBy = "name",
      orderDir = "ASC",
      history,
    } = this.props

    const filteredAttributes = this.getFilteredAttributes()
    return (
      <section className="wrapper setup-attributes">
        <PaperHeader size="small" titleText="Attributes" className="setup-attributes-header">
          <AttributesFilterForm
            destroyOnUnmount={false}
            formName="SetupSearchAttributeForm"
            showHiddenSources={true}
          />
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(getRoutePath("setup.attributes.create"))
            }}
          >
            + Create attribute
          </Button>
        </PaperHeader>
        {areAttributesFulfilled && attributes.size > 0 && (
          <Paper hasHeader={true}>
            <div className="tag-filter">
              <div className="label-tags">
                <span className="selected-tags">Filter by:</span>
                <span>{this.renderSelectedLabels()}</span>
              </div>
              <TagPicker
                selectedTagIds={selectedLabels}
                allTags={attributesLabels}
                onTagSelect={this.selectFilterLabel}
                className="selected-labels-picker"
                type="label"
              />
            </div>
            {filteredAttributes.size > 0 && (
              <Table className="attributes-table">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortAttributesBy("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="description-col">Description</Th>
                  <Th className="source-col">
                    <SortButton
                      column="dataSource"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortAttributesBy("dataSource")}
                      label="Data Source"
                    />
                  </Th>
                  <Th className="labels-col">
                    <SortButton
                      column="labels"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={this.sortAttributesBy("labels")}
                      label="Labels"
                    />
                  </Th>
                  <Th className="actions" />
                </Thead>
                <Tbody>{filteredAttributes}</Tbody>
              </Table>
            )}
            {filteredAttributes.size === 0 && (
              <RowMessage className="attributes-not-found">Nothing found.</RowMessage>
            )}
          </Paper>
        )}
        {areAttributesFulfilled && attributes.size === 0 && (
          <p className="info-message">Click on the "Create Attribute" to get started.</p>
        )}
      </section>
    )
  }
}

AttributesList.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributes: PropTypes.instanceOf(List).isRequired,
  selectedLabels: PropTypes.array,
  attributesLabels: PropTypes.instanceOf(List).isRequired,
  setSortingOptions: PropTypes.func.isRequired,
  setFilterByTags: PropTypes.func.isRequired,
  fetchAttributesList: PropTypes.func.isRequired,
  areAttributesFetching: PropTypes.bool.isRequired,
  searchText: PropTypes.string,
  modifyAttribute: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
}

const selector = formValueSelector("SetupSearchAttributeForm")
const mapStateToProps = state => ({
  areAttributesFulfilled: areAttributesFulfilled(state),
  attributes: getAttributesData(state, true),
  selectedLabels: selector(state, "selectedTags"),
  orderBy: selector(state, "orderBy"),
  orderDir: selector(state, "orderDir"),
  searchText: selector(state, "search"),
  source: selector(state, "select"),
  attributesLabels: getAttributesTagsList(state),
  areAttributesFetching: areAttributesFetching(state),
})

export default connect(mapStateToProps, {
  setSortingOptions,
  setFilterByTags,
  fetchAttributesList,
  modifyAttribute,
  showToast,
})(AttributesList)
