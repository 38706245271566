import { Record } from "immutable"

const GlobalSettings = Record({
  id: 0,
  // can be scalar or object
  value: null,
  key: "",
})

export default GlobalSettings
