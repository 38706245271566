import { api } from "api"
import { USER } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchUsersList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: USER.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit) => api.user.list(offset, limit))
          .setDataPath("users")
          .run()
        return data
      })(),
    },
  })
}

export const fetchTrashedUserList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: USER.TRASH.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit) => api.user.trash.list(offset, limit))
          .setDataPath("users")
          .setLoadFullStructure(1)
          .run()
        return data
      })(),
    },
  })
}

export const createUser = (userData, segmentsAcl) => async dispatch => {
  dispatch({
    type: USER.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const user = await api.user.create(userData)
        if (Object.keys(segmentsAcl).length) {
          await api.acl.user.create(user.id, segmentsAcl)
        }
        return user
      })(),
    },
  })
}

export const inviteUser = (userData, segmentsAcl) => async dispatch => {
  dispatch({
    type: USER.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const user = await api.user.sendInvitation(userData)
        if (Object.keys(segmentsAcl).length) {
          await api.acl.user.create(user.id, segmentsAcl)
        }
        return user
      })(),
    },
  })
}

export const modifyUser = (id, data) => async dispatch =>
  dispatch({
    type: USER.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        return await api.user.modify(id, data)
      })(),
    },
  })

export const deleteUser = id => async dispatch =>
  dispatch({
    type: USER.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.user.delete(id)
        return id
      })(),
    },
  })
