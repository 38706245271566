import React, { useState, useCallback, useEffect } from "react"
import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Table, { RowMessage, Tbody, Td, Th, Thead, Tr } from "components/UI/elements/Table"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import styles from "./PromoCodesLists.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsersMap } from "selectors/user.selector"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { hasAccess } from "helpers/authenticatedUser.helper"
import CreatePromoCodesListModal, {
  CreatePromoCodesListFormValues,
} from "../components/CreatePromoCodesListModal/CreatePromoCodesListModal"
import { api } from "api"
import { showToast } from "actions/toast.action"
import ConfirmModal from "components/UI/components/ConfirmModal"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { SearchFormDestroyable } from "components/UI/components/SearchForm"
import UpdatePromoCodesListModal from "../components/UpdatePromoCodesListModal/UpdatePromoCodesListModal"
import { copyStringToClipboard } from "helpers/string.helper"
import { prop, sort } from "ramda"
import { ascend } from "utilities/comparators"

export const MAX_UPLOADED_FILE_SIZE = 2097152

export default function PromoCodesLists() {
  const dispatch = useDispatch()
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [updateModalItem, setUpdateModalItem] = useState<PromoCodesList | null>(null)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteModalIsDeleting, setDeleteModalIsDeleting] = useState(false)
  const [deleteModalItem, setDeleteModalItem] = useState<PromoCodesList | null>(null)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState<PromoCodesList[]>([])
  const allUsers = useSelector(getAllUsersMap)
  const searchFormValue: string | undefined = useSelector(
    // @ts-ignore
    state => state.form?.PromoCodesListSearchForm?.values?.search,
  )

  const fetchAllPromoCodesLists = useCallback(async () => {
    setIsFetching(true)
    const caller = new AllResourceItemsFetcher()
    const promoCodesLists = await caller
      .setEndpointCall((offset: number, limit: number) =>
        api.promoCodesList.list(offset, limit, "name", "ASC"),
      )
      .setDataPath("promo_codes_lists")
      .run()

    setData(promoCodesLists)
    setIsFetching(false)
  }, [])

  useEffect(() => {
    fetchAllPromoCodesLists()
    // eslint-disable-next-line
  }, [])

  const toggleCreateModalIsOpen = useCallback(() => {
    setCreateModalIsOpen(s => !s)
  }, [])

  const uploadMoreCodesToPromoCodesList = useCallback(
    async (id: PromoCodesList["id"], file: FileList) => {
      const formData = new FormData()
      formData.append("file", file[0])
      const { promo_codes_list: promoCodesList } = await api.promoCodesList.uploadFile(id, formData)
      setData(data => data.map(list => (list.id === promoCodesList.id ? promoCodesList : list)))
      setUpdateModalItem(promoCodesList)
      dispatch(showToast("Promo codes added to the list."))
    },
    [dispatch],
  )

  const createPromoCodesList = useCallback(
    async ({ name, file }: CreatePromoCodesListFormValues) => {
      const { promo_codes_list: createdPromoCodesList } = await api.promoCodesList.create({ name })
      const formData = new FormData()
      formData.append("file", file[0])
      try {
        const { promo_codes_list: filledPromoCodesList } = await api.promoCodesList.uploadFile(
          createdPromoCodesList.id,
          formData,
        )
        setData(data => sort(ascend(prop("name")), data.concat(filledPromoCodesList)))
      } catch (err) {
        setData(data => sort(ascend(prop("name")), data.concat(createdPromoCodesList)))
      }
      dispatch(showToast("Promo codes list created."))
      return
    },
    [dispatch],
  )

  const changePromoCodesListName = useCallback(
    async (id: PromoCodesList["id"], name: PromoCodesList["name"]) => {
      const { promo_codes_list: promoCodesList } = await api.promoCodesList.modify(id, { name })
      setUpdateModalItem(promoCodesList)
      setData(data => data.map(list => (list.id === promoCodesList.id ? promoCodesList : list)))
      dispatch(showToast("Promo codes list renamed."))
    },
    [dispatch],
  )

  const closeDeleteModal = useCallback(() => {
    setDeleteModalIsOpen(false)
    setDeleteModalIsDeleting(false)
  }, [])

  const deletePromoCodesList = useCallback(async () => {
    if (!deleteModalIsDeleting && deleteModalItem) {
      setDeleteModalIsDeleting(true)
      await api.promoCodesList.delete(deleteModalItem.id)
      setData(data => data.filter(list => list.id !== deleteModalItem.id))
      closeDeleteModal()
      dispatch(showToast("Promo codes list deleted."))
    }
  }, [closeDeleteModal, dispatch, deleteModalItem, deleteModalIsDeleting])

  const copyListIdToClipboard = useCallback(
    id => () => {
      copyStringToClipboard(id)
      dispatch(showToast("ID copied to clipboard."))
    },
    [dispatch],
  )

  const filteredData = searchFormValue
    ? data.filter(
        list =>
          list.name.toLowerCase().includes(searchFormValue.toLowerCase()) ||
          list.id.toLowerCase().includes(searchFormValue.toLowerCase()),
      )
    : data

  return (
    <div className={styles.wrapper}>
      <PaperHeader size="small" className={styles.codesHeader} titleText="Promo codes lists">
        <SearchFormDestroyable
          placeholder="Search for id or name"
          form="PromoCodesListSearchForm"
        />
        <Button
          color="primary"
          type="button"
          size="small"
          onClick={toggleCreateModalIsOpen}
          disabled={!hasAccess.webBanners.edit()}
        >
          + Create list
        </Button>
      </PaperHeader>
      <Paper hasHeader className={styles.content}>
        {isFetching ? (
          <LoadingIndicator />
        ) : filteredData.length === 0 ? (
          <RowMessage>No promo codes lists found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th className={styles.idColumn}>ID</Th>
              <Th>Name</Th>
              <Th className={styles.availablePromoCodesColumn}>Available promo codes</Th>
              <Th className={styles.createdByColumn}>Created by</Th>
              <Th className={styles.dateAddedColumn}>Date added</Th>
              <Th className={styles.dateModifiedColumn}>Date modified</Th>
              <Th className={styles.actionButtonsColumn}>&nbsp;</Th>
            </Thead>
            <Tbody>
              {filteredData.map(promoCodesList => (
                <Tr key={promoCodesList.id}>
                  <Td>
                    {/* @ts-ignore */}
                    <IconButton
                      iconName="clone"
                      iconStyle="fas"
                      type="button"
                      color={COLOR.GREY}
                      className={styles.copyButton}
                      tooltip={`Copy id to a clipboard.`}
                      onClick={copyListIdToClipboard(promoCodesList.id)}
                    />
                  </Td>
                  <Td textBigger textBlack textBold>
                    {promoCodesList.name}
                  </Td>
                  <Td>{promoCodesList.promo_codes_count}</Td>
                  <Td>{allUsers.getIn([promoCodesList.author_id.toString(), "name"])}</Td>
                  <Td>
                    {promoCodesList.created_at
                      ? format(new Date(promoCodesList.created_at), DATEFNS.DATE_FORMAT)
                      : "—"}
                  </Td>
                  <Td>
                    {promoCodesList.last_uploaded_at
                      ? format(new Date(promoCodesList.last_uploaded_at), DATEFNS.DATE_FORMAT)
                      : "—"}
                  </Td>
                  <Td textAlignRight>
                    <IconButton
                      color={COLOR.BLACK}
                      size={SIZE.TAG}
                      onClick={() => {
                        setUpdateModalIsOpen(true)
                        setUpdateModalItem(promoCodesList)
                      }}
                      withBackground
                      iconName="pencil-alt"
                      tooltip="Edit"
                      disabled={!hasAccess.webBanners.edit()}
                    />
                    <IconButton
                      color={COLOR.RED}
                      size={SIZE.TAG}
                      onClick={() => {
                        setDeleteModalIsOpen(true)
                        setDeleteModalIsDeleting(false)
                        setDeleteModalItem(promoCodesList)
                      }}
                      withBackground
                      iconName="trash-alt"
                      tooltip="Delete"
                      className={styles.actionButtonMargin}
                      disabled={!hasAccess.webBanners.edit()}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Paper>
      <CreatePromoCodesListModal
        open={createModalIsOpen}
        handleClose={toggleCreateModalIsOpen}
        onSubmit={createPromoCodesList}
      />
      <UpdatePromoCodesListModal
        open={updateModalIsOpen}
        promoCodesList={updateModalItem}
        handleClose={() => {
          setUpdateModalIsOpen(false)
        }}
        onFileUpload={uploadMoreCodesToPromoCodesList}
        onNameChange={changePromoCodesListName}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalIsOpen}
        type="delete"
        text={`Do you really want to delete promo codes list: ${deleteModalItem?.name}?`}
        handleClose={closeDeleteModal}
        handleConfirm={deletePromoCodesList}
        isLoading={deleteModalIsDeleting}
      />
    </div>
  )
}
