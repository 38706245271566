import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { formValueSelector } from "redux-form"
import { List, Map } from "immutable"
import _get from "lodash/get"
import _toLower from "lodash/toLower"
import _toString from "lodash/toString"
import _forEach from "lodash/forEach"
import _isNil from "lodash/isNil"
import _orderBy from "lodash/orderBy"
import _isEmpty from "lodash/isEmpty"
import _isNumber from "lodash/isNumber"
import { getFormValues } from "redux-form"
import moment from "moment"

// actions
import { setUiAttribute } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"
import { resetCustomersIterator } from "actions/customersIterator.action"

// models
import SelectionSettings from "models/selectionSettings.model"

// selectors
import {
  getDataSourcesData,
  isDataSourcesFulfilled,
} from "resources/dataSource/dataSourceSelectors"
import {
  getEventsMappedBySourceId,
  getEventsFilterFormInitialValues,
  getEventsMappedById,
  getEventsIsFulfilled,
} from "selectors/event.selector"
import {
  getGlobalSettingsValueByKey,
  isGlobalSettingsFulfilled,
} from "selectors/globalSettings.selector"
import {
  areAttributesFulfilled,
  getAttributesMapBySourceIdSortedByOrderIndex,
  getAttributesMapById,
  getStitchingAttributesList,
} from "selectors/attributes.selector"
import { getCustomerSearchesData } from "selectors/customerSearch.selector"

// helpers, api, constants
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { ITEMS_PER_PAGE, MOMENT, TOAST } from "sharedConstants"
import PendingPromise from "helpers/pendingPromise.helper"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { timeResolutionRangeText } from "helpers/date.helper"
import { getIconSrc } from "helpers/image.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "helpers/compoundAttribute.helper"
import { getCustomerSourceIdentification } from "resources/dataSource/getCustomerSourceIdentification"
import { isJSONString } from "helpers/validators.helper"

// ui components
import Header from "./Header"
import CustomerDetailFilterForm from "./CustomerDetailFilterForm"
import TimelineFilterForm from "./TimelineFilterForm"
import EventGroupsCountsChart from "./EventGroupsCountsChart"
import Paper from "components/UI/elements/Paper"
import { ToggleSwitchMultiple } from "components/UI/elements/ToggleSwitch"
import ToggleButton from "components/UI/elements/ToggleButton"
import CustomerEvent from "./CustomerEvent"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable"
import AttributeBadge from "components/UI/elements/AttributeBadge"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

import IdentityGraph from "./IdentityGraph"

import "./CustomerDetail.scss"

const ATTRIBUTE_LOADING_LIMIT = 10

const initialState = {
  customerAttributesLoading: true,
  // all customer attributes in Map by attribute id
  customerAttributes: Map(),
  // view attributes/timeline switch
  showScreen: "attributes",
  // events
  events: Map(),
  eventGroups: Map({
    isFetching: false,
    isFulfilled: false,
    data: List(),
    selectionSettings: null,
  }),
  identityGraph: {
    isFetching: false,
    isFulfilled: false,
    data: null,
    error: {},
  },
  eventChartsData: [],
  openedGroups: List(),
  showMoreAttributes: Map(),
  // sourceId: true/false
  showEmptyAttributes: Map(),
  // sourceId: true/false
  collapsedAttributesBoxes: Map(),
  // sourceId: attributesBoxHeight
  attributesBoxesHeights: Map(),
  customerDataReady: false,
  isLoading: true,
}

class CustomerDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = initialState

    this.pendingPromises = new PendingPromise()
    this.scrollToRef = React.createRef()
    this.scrollToSwitcher = React.createRef()
    this.attributeBoxesRefs = {}
    this.attributeBoxesHeightsTmp = {}
    this.attributeBoxesContentRefs = {}
  }

  componentDidMount() {
    this.initCustomer()
  }

  initCustomer = () => {
    this.setState({
      customerDataReady: false,
    })
    this.fetchAllCustomerAttributes()
      .then(response => {
        let customerAttributes = Map().withMutations(map => {
          response.forEach(attr => {
            map.set(
              attr.attribute_id,
              List.isList(map.get(attr.attribute_id))
                ? map.get(attr.attribute_id).push(attr.value)
                : List([attr.value]),
            )
          })
        })
        let showMoreAttributes = Map().withMutations(map => {
          customerAttributes.forEach((list, key) => {
            if (List.isList(list) && list.size === ATTRIBUTE_LOADING_LIMIT) {
              map.set(key, Map({ offset: 0, hasMoreItems: true, page: 1 }))
            }
          })
        })
        this.setState({
          customerAttributesLoading: false,
          customerAttributes,
          showMoreAttributes,
          events: Map(),
          showScreen: "attributes",
          eventGroups: Map({
            isFetching: false,
            isFulfilled: false,
            data: List(),
            selectionSettings: null,
          }),
          identityGraph: {
            isFetching: false,
            isFulfilled: false,
            data: null,
            error: {},
          },
          eventChartsData: [],
          openedGroups: List(),
          customerDataReady: true,
          attributesBoxesHeights: Map(),
        })
      })
      .catch()
      .finally(() => this.setState({ isLoading: false }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.pendingPromises.cancelAll()
      this.attributeBoxesRefs = {}
      this.attributeBoxesHeightsTmp = {}
      this.attributeBoxesContentRefs = {}
      this.initCustomer()
    }
  }

  componentWillUnmount() {
    this.props.resetCustomersIterator()
    this.pendingPromises.cancelAll()
  }

  filterAttributesByTagId = (attributes, tagId, hideSourceEngagement = false) => {
    let filteredAttributes = List()
    const { isGlobalSettingsFulfilled, hiddenChannelEngagementSourceIds } = this.props
    if (hideSourceEngagement && !isGlobalSettingsFulfilled) {
      return filteredAttributes
    }

    attributes.forEach(source => {
      source.forEach(attr => {
        if (List.isList(attr.tags) && attr.tags.some(tag => tag.get("id") === tagId)) {
          if (hideSourceEngagement && List.isList(hiddenChannelEngagementSourceIds)) {
            if (!hiddenChannelEngagementSourceIds.includes(attr.getIn(["source", "id"]))) {
              filteredAttributes = filteredAttributes.push(attr)
            }
          } else {
            filteredAttributes = filteredAttributes.push(attr)
          }
        }
      })
    })

    return filteredAttributes
  }

  fetchAllCustomerAttributes = async () => {
    const { id } = this.props.match.params

    return await new AllResourceItemsFetcher()
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api.customer.attribute.list(id, offset, limit, loadFullStructure, ATTRIBUTE_LOADING_LIMIT),
      )
      .setLoadFullStructure(0)
      .setDataPath("customer_attributes")
      .run()
  }

  fetchCustomerAttributeValues =
    attributeId =>
    (page = null) =>
    () => {
      const { id } = this.props.match.params
      const { showMoreAttributes } = this.state
      let offset = showMoreAttributes.getIn([attributeId, "offset"]) + ATTRIBUTE_LOADING_LIMIT
      if (page !== null) {
        const previousPage = showMoreAttributes.getIn([attributeId, "page"])
        if (page < previousPage)
          // go back
          offset = showMoreAttributes.getIn([attributeId, "offset"]) - ATTRIBUTE_LOADING_LIMIT
      }

      this.setState(prevState => ({
        showMoreAttributes: prevState.showMoreAttributes.setIn([attributeId, "loading"], true),
      }))
      api.customer.attribute
        .retrieve(id, attributeId, offset, ATTRIBUTE_LOADING_LIMIT)
        .then(response => {
          if (page === null) {
            this.setState(prevState => ({
              customerAttributes: prevState.customerAttributes.set(
                attributeId,
                prevState.customerAttributes
                  .get(attributeId)
                  .concat(response.customer_attribute_values),
              ),
              showMoreAttributes: prevState.showMoreAttributes
                .setIn([attributeId, "loading"], false)
                .setIn([attributeId, "offset"], response.selection_settings.offset)
                .setIn(
                  [attributeId, "hasMoreItems"],
                  response.customer_attribute_values.length === ATTRIBUTE_LOADING_LIMIT,
                ),
            }))
          } else {
            this.setState(prevState => ({
              customerAttributes: prevState.customerAttributes.set(
                attributeId,
                List(response.customer_attribute_values),
              ),
              showMoreAttributes: prevState.showMoreAttributes
                .setIn([attributeId, "loading"], false)
                .setIn([attributeId, "offset"], response.selection_settings.offset)
                .setIn([attributeId, "page"], page)
                .setIn(
                  [attributeId, "lastPage"],
                  response.customer_attribute_values.length < ATTRIBUTE_LOADING_LIMIT ? page : null,
                ),
            }))
          }
        })
        .catch(() => {
          this.setState(prevState => ({
            showMoreAttributes: prevState.showMoreAttributes.setIn([attributeId, "loading"], false),
          }))
        })
    }

  fetchTimelineEvents = (startDate, endDate) => {
    const { customerDetailFilterValues } = this.props
    const { id } = this.props.match.params
    const { events, openedGroups } = this.state
    const eventsSelector = `${startDate}/${endDate}`

    if (
      openedGroups.findIndex(value => value === eventsSelector) === -1 ||
      events.getIn([eventsSelector, "isFetching"])
    ) {
      return
    }

    this.setState(prevState => ({
      events: prevState.events.setIn([eventsSelector, "isFetching"], true),
    }))

    const orderBy = "event_time",
      orderDir = "DESC",
      loadFullStructure = 0
    const offset = _isNil(events.getIn([eventsSelector, "selectionSettings", "offset"]))
      ? 0
      : events.getIn([eventsSelector, "selectionSettings", "offset"]) + ITEMS_PER_PAGE

    const filteredSources = _get(customerDetailFilterValues, "sources", {})
    let sourceIds = []
    let allUnchecked = true
    let allSourceIds = true
    _forEach(filteredSources, (value, key) => {
      if (value) {
        sourceIds.push(key)
        allUnchecked = false
      } else {
        allSourceIds = false
      }
    })
    if (allSourceIds) {
      // api returns all results by default without filter option,
      // we don't need to specify all checked sources
      sourceIds = []
    }
    if (allUnchecked) {
      let eventsState = events
      openedGroups.forEach(key => {
        eventsState = eventsState
          .setIn([key, "isFetching"], false)
          .setIn([key, "isFulfilled"], true)
          .setIn([key, "data"], List())
          .setIn([key, "selectionSettings", "offset"], null)
          .setIn([key, "hasMoreEvents"], false)
      })
      this.setState({
        events: eventsState,
      })
      return
    }

    const filteredEventTypes = _get(customerDetailFilterValues, "eventTypes", {})
    let eventIds = []
    let allEventIds = true
    _forEach(filteredEventTypes, values => {
      _forEach(values, (value, key) => {
        if (value) {
          eventIds.push(key)
        } else {
          allEventIds = false
        }
      })
    })
    if (allEventIds === true) {
      // api return all results by default without filter option,
      // we don't need to specidy all checked event types
      eventIds = []
    }

    const timelinePromise = this.pendingPromises.create(
      api.customer.event.list(
        id,
        startDate,
        endDate,
        offset,
        ITEMS_PER_PAGE,
        orderBy,
        orderDir,
        loadFullStructure,
        sourceIds,
        eventIds,
      ),
    )

    timelinePromise.promise
      .then(response => {
        this.setState(prevState => {
          if (prevState.openedGroups.findIndex(value => value === eventsSelector) === -1) {
            return {
              events: prevState.events.setIn([eventsSelector, "isFetching"], false),
            }
          } else {
            return {
              events: prevState.events
                .setIn([eventsSelector, "isFetching"], false)
                .setIn([eventsSelector, "isFulfilled"], true)
                .setIn(
                  [eventsSelector, "data"],
                  offset === 0
                    ? List(response.customer_events)
                    : prevState.events
                        .getIn([eventsSelector, "data"])
                        .concat(response.customer_events),
                )
                .setIn(
                  [eventsSelector, "selectionSettings"],
                  new SelectionSettings(response.selection_settings),
                )
                .setIn(
                  [eventsSelector, "hasMoreEvents"],
                  response.customer_events.length === ITEMS_PER_PAGE,
                ),
            }
          }
        })
        this.pendingPromises.remove(timelinePromise)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState(prevState => ({
            events: prevState.events.setIn([eventsSelector, "isFetching"], false),
          }))
        }
        this.pendingPromises.remove(timelinePromise)
      })
  }

  fetchIdentityGraph = () => {
    const { identityGraph } = this.state
    if (!identityGraph.isFulfilled && !identityGraph.isFetching) {
      // fetch only once
      this.setState(prevState => ({
        identityGraph: {
          ...prevState.identityGraph,
          isFetching: true,
        },
      }))
      const {
        match: {
          params: { id },
        },
      } = this.props
      const identityGraphRequest = this.pendingPromises.create(api.customer.identityGraph(id))

      identityGraphRequest.promise
        .then(response => {
          this.setState(prevState => ({
            identityGraph: {
              ...prevState.identityGraph,
              isFulfilled: true,
              isFetching: false,
              data: response,
              error: {},
            },
          }))
          this.pendingPromises.remove(identityGraphRequest)
        })
        .catch(error => {
          const status = _get(error, "response.status")
          const message = _get(error, "response.data.message", "Something went wrong.")
          this.setState(prevState => ({
            identityGraph: {
              ...prevState.identityGraph,
              isFulfilled: true,
              isFetching: false,
              error: {
                status,
                message:
                  status === 406
                    ? "Graph not available due to the high volume of data. To investigate contact administrator."
                    : message,
              },
            },
          }))
          this.pendingPromises.remove(identityGraphRequest)
        })
    }
  }

  fetchEventGroups = startDate => {
    if (!this.state.eventGroups.get("isFetching")) {
      this.setState(prevState => ({
        eventGroups: prevState.eventGroups.set("isFetching", true),
      }))
      const {
        showToast,
        match: {
          params: { id },
        },
      } = this.props
      if (!startDate) {
        startDate = moment().subtract(355, "days").format(MOMENT.DB_DATE_FORMAT)
      }
      const endDate = moment(startDate).add(355, "days").format(MOMENT.DB_DATE_FORMAT)

      const eventGroupsRequest = this.pendingPromises.create(
        api.customer.event.group.list(id, startDate, endDate),
      )
      eventGroupsRequest.promise
        .then(response => {
          const { eventGroups } = this.state
          this.setState(prevState => ({
            eventGroups: Map({
              isFetching: false,
              isFulfilled: true,
              data: prevState.eventGroups.get("data").concat(List(response.customer_event_groups)),
              selectionSettings: Map({
                startDate: response.selection_params.start_date,
                endDate: response.selection_params.end_date,
              }),
            }),
          }))
          if (response.customer_event_groups.length === 0 && eventGroups.get("data").size > 0) {
            showToast(
              `No events found for the customer since ${moment(
                response.selection_params.start_date,
              ).format(MOMENT.DATE_FORMAT)} until ${moment(
                response.selection_params.end_date,
              ).format(MOMENT.DATE_FORMAT)}`,
              TOAST.TYPE.SUCCESS,
              "",
              true,
            )
          }

          if (response.customer_event_groups.length > 0) {
            const eventChartsData = [...this.state.eventChartsData]
            response.customer_event_groups.forEach(group => {
              if (eventChartsData.length === 0) {
                eventChartsData.push({
                  events_count: group.events_count,
                  start_date: group.start_date,
                  date: timeResolutionRangeText(group.start_date, group.end_date, "month"),
                })
              } else {
                let lastEntry = eventChartsData[eventChartsData.length - 1]
                const date = timeResolutionRangeText(group.start_date, group.end_date, "month")
                if (lastEntry.date === date) {
                  // sum
                  eventChartsData[eventChartsData.length - 1].events_count += group.events_count
                } else {
                  // fill gaps ... append
                  while (date !== lastEntry.date) {
                    eventChartsData.push({
                      events_count: 0,
                      start_date: moment(lastEntry.start_date)
                        .subtract(1, "month")
                        .format("YYYY-MM-DD"),
                      date: moment(lastEntry.start_date).subtract(1, "month").format("MMM YYYY"),
                    })
                    lastEntry = eventChartsData[eventChartsData.length - 1]
                  }
                  eventChartsData[eventChartsData.length - 1].events_count += group.events_count
                }
              }
            })
            const monthStart = moment(startDate).format("MMM YYYY")
            let lastEntry = eventChartsData[eventChartsData.length - 1]
            if (lastEntry.date !== monthStart) {
              // fill empty month
              while (monthStart !== lastEntry.date) {
                eventChartsData.push({
                  events_count: 0,
                  start_date: moment(lastEntry.start_date)
                    .subtract(1, "month")
                    .format("YYYY-MM-DD"),
                  date: moment(lastEntry.start_date).subtract(1, "month").format("MMM YYYY"),
                })
                lastEntry = eventChartsData[eventChartsData.length - 1]
              }
            }
            this.setState({
              eventChartsData,
            })
          } else if (this.state.eventChartsData.length !== 0) {
            const monthStart = moment(startDate).format("MMM YYYY")
            let lastEntry = this.state.eventChartsData[this.state.eventChartsData.length - 1]
            if (lastEntry.date !== monthStart) {
              // fill empty month
              const eventChartsData = [...this.state.eventChartsData]
              while (monthStart !== lastEntry.date) {
                eventChartsData.push({
                  events_count: 0,
                  start_date: moment(lastEntry.start_date)
                    .subtract(1, "month")
                    .format("YYYY-MM-DD"),
                  date: moment(lastEntry.start_date).subtract(1, "month").format("MMM YYYY"),
                })
                lastEntry = eventChartsData[eventChartsData.length - 1]
              }
              this.setState({
                eventChartsData,
              })
            }
          }

          this.pendingPromises.remove(eventGroupsRequest)
        })
        .catch(error => {
          if (!_get(error, "isCanceled")) {
            this.setState(prevState => ({
              eventGroups: prevState.eventGroups.set("isFetching", false),
            }))
          }
          this.pendingPromises.remove(eventGroupsRequest)
        })
    }
  }

  switchScreen = screen => {
    const { eventGroups } = this.state
    this.setState({
      showScreen: screen,
    })
    if (screen === "timeline" && !eventGroups.get("selectionSettings")) {
      this.fetchEventGroups()
    }
    if (screen === "identity") {
      this.fetchIdentityGraph()
    }
  }

  filterChanged = () => {
    const { showScreen } = this.state
    if (showScreen !== "identity") {
      // reset selection settings & fetch
      let allUnchecked = true
      const filteredSources = _get(this.props.customerDetailFilterValues, "sources", {})
      _forEach(filteredSources, source => {
        if (source) {
          allUnchecked = false
        }
      })

      if (showScreen === "attributes") {
        this.setState({
          events: Map(),
          openedGroups: List(),
        })
      } else {
        const { events, openedGroups } = this.state
        if (allUnchecked) {
          let eventsState = events
          openedGroups.forEach(key => {
            eventsState = eventsState
              .setIn([key, "isFetching"], false)
              .setIn([key, "isFulfilled"], true)
              .setIn([key, "data"], List())
              .setIn([key, "selectionSettings", "offset"], null)
              .setIn([key, "hasMoreEvents"], false)
          })
          this.setState({
            events: eventsState,
          })
        } else {
          openedGroups.forEach(key => {
            const dates = key.split("/")
            this.setState(
              prevState => ({
                events: prevState.events.setIn([key, "selectionSettings", "offset"], null),
              }),
              () => this.fetchTimelineEvents(_get(dates, "[0]"), _get(dates, "[1]")),
            )
          })
        }
      }
    }
  }

  toggleSourcesHiddenEmptyAttributes = sourceId => () => {
    this.setState(prevState => ({
      showEmptyAttributes: prevState.showEmptyAttributes.set(
        sourceId,
        !prevState.showEmptyAttributes.get(sourceId),
      ),
    }))
  }

  toggleAttributesBoxCollapsed = sourceId => () => {
    const { collapsedAttributesBoxes } = this.state
    const element = this.attributeBoxesContentRefs[sourceId]
    if (element) {
      const height = element.scrollHeight
      if (collapsedAttributesBoxes.get(sourceId)) {
        // expand
        element.style.height = `${height}px`
        element.style["border-color"] = null
        element.addEventListener("transitionend", function callback() {
          element.removeEventListener("transitionend", callback)
          element.style.height = null
        })
      } else {
        // collapse
        requestAnimationFrame(() => {
          element.style.height = `${height}px`
          requestAnimationFrame(() => {
            element.style.height = "0px"
            element.style["border-color"] = "transparent"
          })
        })
      }

      this.setState({
        collapsedAttributesBoxes: collapsedAttributesBoxes.set(
          sourceId,
          !collapsedAttributesBoxes.get(sourceId),
        ),
      })
    }
  }

  _renderCustomerAttributesSourceBox = (source, invisible = false, isLast = false) => {
    const {
      customerAttributes,
      showMoreAttributes,
      showEmptyAttributes,
      collapsedAttributesBoxes,
    } = this.state
    const { customerDetailFilterValues, attributesMapBySourceId } = this.props
    const sourceAttributes = attributesMapBySourceId.get(source.id)
    const showEmptySourceAttributes = showEmptyAttributes.get(source.id)
      ? showEmptyAttributes.get(source.id)
      : false

    const attributeName = _get(customerDetailFilterValues, "attributeName", "")
    let filteredSourceAttributes = List()

    if (List.isList(sourceAttributes)) {
      filteredSourceAttributes = sourceAttributes.filter(
        attribute =>
          _toLower(attribute.name).includes(_toLower(attributeName)) && !attribute.is_hidden,
      )
    }

    const hasFilledAttribute = filteredSourceAttributes.some(attribute => {
      return List.isList(customerAttributes.get(attribute.id))
    })

    const isBoxCollapsed = collapsedAttributesBoxes.get(source.id)
      ? collapsedAttributesBoxes.get(source.id)
      : false

    return (
      <Paper
        key={source.id}
        className={`customer-attributes-box ${invisible ? "invisible" : ""} ${source.getIn(
          ["frontend_settings", "color"],
          "primary",
        )}`}
        ref={el => {
          this.attributeBoxesRefs[source.id] = el
        }}
        onInit={() => {
          if (!_isNumber(this.attributeBoxesHeightsTmp[source.id])) {
            this.attributeBoxesHeightsTmp[source.id] =
              this.attributeBoxesRefs[source.id].getBoundingClientRect().height
          }
          if (isLast && this.state.attributesBoxesHeights.size === 0) {
            this.setState({
              attributesBoxesHeights: Map(this.attributeBoxesHeightsTmp),
            })
          }
        }}
      >
        <div className="box-header">
          <h3>{source.name}</h3>
          <div className="box-header-actions">
            <div className="hide-empty-fields">
              <span className={`label ${showEmptySourceAttributes ? "orange" : ""}`}>
                Show empty fields
              </span>
              <ToggleButton
                value={showEmptySourceAttributes}
                handleToggle={this.toggleSourcesHiddenEmptyAttributes(source.id)}
                size="xs"
              />
            </div>
            <IconButton
              color={COLOR.BLACK}
              className="caret"
              onClick={this.toggleAttributesBoxCollapsed(source.id)}
              iconName={isBoxCollapsed ? "caret-up" : "caret-down"}
              faded
            />
          </div>
        </div>
        <div
          className="box-content"
          ref={el => {
            this.attributeBoxesContentRefs[source.id] = el
          }}
        >
          {filteredSourceAttributes.size > 0 && (
            <div className="customer-attributes-values-list">
              {!hasFilledAttribute && !showEmptySourceAttributes && (
                <div className="row no-filled-attributes">No filled attributes in data source.</div>
              )}
              {filteredSourceAttributes
                .map(attribute => {
                  const hasValue = List.isList(customerAttributes.get(attribute.id))
                  if (!hasValue && !showEmptySourceAttributes) {
                    return null
                  }

                  const compoundAttributeView = isAttributeCompound(attribute.data_type)
                  const firstCompoundAttributeValue = customerAttributes.getIn([attribute.id, 0])
                  let valueDOM = null
                  if (compoundAttributeView && isJSONString(firstCompoundAttributeValue)) {
                    const subAttributes = List(
                      getCompoundAttributeSubAttributes(attribute.data_type),
                    )
                    const values =
                      hasValue && customerAttributes.get(attribute.id).map(val => JSON.parse(val))
                    valueDOM = (
                      <React.Fragment>
                        {hasValue && (
                          <CompoundAttributeValuesTable
                            subAttributes={subAttributes}
                            values={values}
                            page={showMoreAttributes.getIn([attribute.id, "page"])}
                            lastPage={showMoreAttributes.getIn([attribute.id, "lastPage"])}
                            changePage={this.fetchCustomerAttributeValues(attribute.id)}
                            loading={showMoreAttributes.getIn([attribute.id, "loading"])}
                          />
                        )}
                        {!hasValue && "—"}
                      </React.Fragment>
                    )
                  } else {
                    valueDOM = (
                      <div className="value-wrapper">
                        {hasValue &&
                          customerAttributes
                            .get(attribute.id)
                            .map((customerAttributeValue, key) => (
                              <span className="customer-attribute-value" key={key}>
                                {getUserFriendlyValueFormat(
                                  customerAttributeValue,
                                  attribute.data_type,
                                )}
                              </span>
                            ))}
                        {hasValue && showMoreAttributes.getIn([attribute.id, "hasMoreItems"]) && (
                          <Button
                            size="small"
                            color="white"
                            className={`show-more-attributes ${
                              showMoreAttributes.getIn([attribute.id, "loading"]) ? "loading" : ""
                            }`}
                            onClick={this.fetchCustomerAttributeValues(attribute.id)()}
                          >
                            Show more
                          </Button>
                        )}
                        {!hasValue && "—"}
                      </div>
                    )
                  }

                  const hasAdvancedTag =
                    List.isList(attribute.tags) &&
                    attribute.tags.findIndex(tag => tag.get("id") === "advanced") !== -1
                  return (
                    <div
                      className={`row ${compoundAttributeView ? "compound-attr-view" : ""}`}
                      key={attribute.id}
                    >
                      <div className={!hasValue ? "no-value attrname" : "attrname"}>
                        <div className="name-tags">
                          <span className="name-wrapper">
                            <span className="name">{attribute.name}</span>
                            {moment().diff(attribute.created, "days") < 8 && (
                              <AttributeBadge text="new" className={hasAdvancedTag ? "mr" : ""} />
                            )}
                            {hasAdvancedTag && (
                              <AttributeBadge text="advanced" className="advanced-tag" />
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`attrvalue text-grey align-right ${!hasValue ? "no-value" : ""}`}
                      >
                        {valueDOM}
                      </div>
                    </div>
                  )
                })
                .toArray()}
            </div>
          )}
          {filteredSourceAttributes.size === 0 && (
            <p className="not-found">No attribute was found.</p>
          )}
        </div>
      </Paper>
    )
  }

  renderCustomerAttributesBoxes = () => {
    const { customerDetailFilterValues, dataSources, attributesMapBySourceId, authenticatedUser } =
      this.props
    const { customerAttributes, attributesBoxesHeights } = this.state
    const filteredSources = _get(customerDetailFilterValues, "sources", {})
    const identifiedInSources = getCustomerSourceIdentification(
      attributesMapBySourceId,
      customerAttributes,
      dataSources,
    )
    const filteredIdentifiedInSources = identifiedInSources.filter((val, key) => {
      if (_isEmpty(filteredSources)) {
        return val
      } else {
        return filteredSources[key] && val
      }
    })
    const twoCols = _get(authenticatedUser, "ui.customerDetailLayout", "two-cols") === "two-cols"

    if (filteredIdentifiedInSources.size === 0) {
      return (
        <Paper className="no-results-found">
          <p>No source selected.</p>
        </Paper>
      )
    }

    const filteredResultSources = dataSources.filter(source =>
      filteredIdentifiedInSources.get(source.id),
    )

    // invisible rendering to detect boxes heights for 2-col layout purposes
    const invisible = attributesBoxesHeights.size === 0
    if (invisible) {
      let i = 0
      return filteredResultSources
        .map(source => {
          i++
          return this._renderCustomerAttributesSourceBox(
            source,
            invisible,
            i === filteredResultSources.size,
          )
        })
        .toArray()
    } else {
      if (twoCols) {
        let leftHeight = 0,
          rightHeight = 0
        const left = [],
          right = []
        filteredResultSources.forEach(source => {
          if (leftHeight <= rightHeight) {
            left.push(source)
            leftHeight += attributesBoxesHeights.get(source.id)
          } else {
            right.push(source)
            rightHeight += attributesBoxesHeights.get(source.id)
          }
        })
        return (
          <div className="two-cols">
            <div className="left">
              {left.map(source => this._renderCustomerAttributesSourceBox(source))}
            </div>
            <div className="right">
              {right.map(source => this._renderCustomerAttributesSourceBox(source))}
            </div>
          </div>
        )
      } else {
        return filteredResultSources
          .map(source => {
            return this._renderCustomerAttributesSourceBox(source)
          })
          .toArray()
      }
    }
  }

  renderEvents = (startDate, endDate) => {
    const { events } = this.state
    const { dataSources, eventsById } = this.props
    const key = `${startDate}/${endDate}`

    if (events.getIn([key, "isFulfilled"]) && events.getIn([key, "data"]).size === 0) {
      return (
        <Paper className="no-results-found events-not-found">
          <p>No results found, consider to modify filter settings.</p>
        </Paper>
      )
    }

    return (
      <React.Fragment>
        {events
          .getIn([key, "data"])
          .map(event => {
            return (
              <div key={event.id} className="event">
                <CustomerEvent
                  dataSource={dataSources.get(event.source_id)}
                  customerEvent={event}
                  event={eventsById.get(event.event_id)}
                />
              </div>
            )
          })
          .toArray()}
        {events.getIn([key, "hasMoreEvents"]) && (
          <div className="load-more-wrapper">
            <Button
              color="white"
              size="small"
              onClick={() => this.fetchTimelineEvents(startDate, endDate)}
              className={`load-more ${events.getIn([key, "isFetching"]) ? "loading" : ""}`}
            >
              Show more events
            </Button>
          </div>
        )}
      </React.Fragment>
    )
  }

  toggleEventGroup = (startDate, endDate) => () => {
    const { openedGroups } = this.state
    const key = `${startDate}/${endDate}`
    const index = openedGroups.findIndex(value => value === key)
    if (index === -1) {
      // opened different group, stack size can be max 2
      this.setState(
        prevState => {
          if (prevState.openedGroups.size > 1) {
            const removeKey = prevState.openedGroups.get(0)
            return {
              events: prevState.events
                .set(
                  key,
                  Map({
                    isFetching: false,
                    isFulfilled: false,
                    data: List(),
                    selectionSettings: Map(),
                    hasMoreEvents: false,
                  }),
                )
                .delete(removeKey),
              openedGroups: prevState.openedGroups.slice(-1).push(key),
            }
          } else {
            return {
              events: prevState.events.set(
                key,
                Map({
                  isFetching: false,
                  isFulfilled: false,
                  data: List(),
                  selectionSettings: Map(),
                  hasMoreEvents: false,
                }),
              ),
              openedGroups: prevState.openedGroups.push(key),
            }
          }
        },
        () => {
          this.fetchTimelineEvents(startDate, endDate)
          if (!_isNil(this.scrollToRef.current)) {
            this.scrollToRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        },
      )
    } else {
      // close
      this.setState(prevState => ({
        events: prevState.events.delete(key),
        openedGroups: prevState.openedGroups.splice(index, 1),
      }))
    }
  }

  _renderGroupSource = (source, totalCount) => {
    const { sourcesFormState } = this.props
    const sourceEnabled = sourcesFormState[source.id]
    const color = source.getIn(["frontend_settings", "color"], "")
    return (
      <div className="count-entry" key={source.id}>
        <div className={`icon-wrapper ${sourceEnabled === false ? "greyscale" : ""} ${color}`}>
          <img
            src={getIconSrc(
              {
                primary: source.getIn(["frontend_settings", "icon"]),
                secondary: _toLower(source.type),
              },
              source.getIn(["frontend_settings", "alt_icon"]),
              true,
            )}
            alt="icon"
            className={`source-icon ${sourceEnabled === false ? "greyscale" : ""}`}
          />
        </div>
        <div className="count-info">
          <span className={`count ${sourceEnabled === false ? "zero-value" : ""}`}>
            {sourceEnabled === false && (
              <span className="zero-x">
                0x <span>out of</span>{" "}
              </span>
            )}
            {totalCount}x
          </span>
          <span className="source-name">{source.name}</span>
        </div>
      </div>
    )
  }

  loadMoreEventGroups = () => {
    const { eventGroups } = this.state
    if (!eventGroups.get("isFetching")) {
      const startDate = moment(eventGroups.getIn(["selectionSettings", "startDate"]))
        .subtract(356, "day")
        .format(MOMENT.DB_DATE_FORMAT)
      this.fetchEventGroups(startDate)
    }
  }

  renderGroupsPagination = () => {
    const { eventGroups } = this.state
    return (
      <nav className="pagination">
        <Button
          size="small"
          color="white"
          onClick={this.loadMoreEventGroups}
          className={eventGroups.get("isFetching") ? "loading" : ""}
        >
          Show previous year
        </Button>
      </nav>
    )
  }

  renderGroups = () => {
    const { eventGroups, openedGroups, events } = this.state
    const { dataSources } = this.props

    if (eventGroups.get("isFulfilled") && eventGroups.get("data").size === 0) {
      return (
        <Paper className="no-results-found">
          <p className="timeline-message">
            No events found for the customer{" "}
            <strong>
              since{" "}
              {moment(eventGroups.getIn(["selectionSettings", "startDate"])).format(
                MOMENT.DATE_FORMAT,
              )}{" "}
              until now
            </strong>
            . To see more data click on the button below.
          </p>
        </Paper>
      )
    }

    const timeResolution = "day"
    return eventGroups
      .get("data")
      .map(group => {
        const key = `${group.start_date}/${group.end_date}`
        const groupOpened = openedGroups.findIndex(value => value === key) !== -1
        const isLastOpened = openedGroups.last() === key
        const timelineText = timeResolutionRangeText(
          group.start_date,
          group.end_date,
          timeResolution,
        )
        const orderedActiveSources = _orderBy(group.sources_events_counts, ["count"], ["desc"])

        const sourcesToRender = []
        orderedActiveSources.forEach(sourceCount => {
          const source = dataSources.get(_toString(sourceCount.source_id))
          if (source) {
            sourcesToRender.push(this._renderGroupSource(source, sourceCount.count))
          }
        })

        return (
          <div
            key={group.start_date}
            className={`group-row ${groupOpened ? "opened" : ""}`}
            ref={isLastOpened ? this.scrollToRef : null}
          >
            <div className="opened-sticky">
              <span className={`timeline-text ${timeResolution}`}>{timelineText}</span>
              <Paper className="group-box">
                <div className="counts-wrapper">{sourcesToRender}</div>
                <div className="more-wrapper">
                  <IconButton
                    color={COLOR.GREY}
                    onClick={this.toggleEventGroup(group.start_date, group.end_date)}
                    className={`group-action-button ${
                      events.getIn([key, "isFetching"]) && groupOpened ? "loading" : ""
                    }`}
                    iconStyle="far"
                    iconName={groupOpened ? "angle-double-up" : "angle-double-down"}
                  />
                </div>
              </Paper>
            </div>
            {groupOpened && this.renderEvents(group.start_date, group.end_date)}
          </div>
        )
      })
      .toArray()
  }

  setLayout = type => () => {
    const { setUiAttribute, authenticatedUser } = this.props
    const setLayout = _get(authenticatedUser, "ui.customerDetailLayout", "two-cols")
    if (type !== setLayout) {
      setUiAttribute("customerDetailLayout", type)
    }
  }

  render() {
    const {
      dataSources,
      eventTypesBySourceId,
      eventTypesInitialFormObject,
      areAttributesFulfilled,
      channelEngagementTagId,
      contactInfoTagId,
      attributesMapBySourceId,
      isGlobalSettingsFulfilled,
      isDataSourcesFulfilled,
      authenticatedUser,
      attributesMapById,
      areEventsFulfilled,
      eventsById,
      customerDetailFilterValues,
      stitchingAttributes,
      match: {
        params: { id: customerId },
      },
    } = this.props
    const {
      customerAttributesLoading,
      customerAttributes,
      showScreen,
      events,
      eventChartsData,
      customerDataReady,
      identityGraph,
    } = this.state

    const initialGraphIdentifiers = {}
    if (identityGraph.data) {
      identityGraph.data.nodes.forEach(node => {
        initialGraphIdentifiers[node.attribute_id] = true
      })
    }

    let contactInfoAttributes = this.filterAttributesByTagId(
      attributesMapBySourceId,
      contactInfoTagId,
    )
    if (List.isList(contactInfoAttributes) && contactInfoAttributes.size) {
      contactInfoAttributes = contactInfoAttributes.sort((a, b) => {
        if (a.order_index < b.order_index) {
          return -1
        } else if (a.order_index > b.order_index) {
          return 1
        } else {
          return _toLower(a.name).localeCompare(_toLower(b.name))
        }
      })
    }

    const channelEngagementAttributes = this.filterAttributesByTagId(
      attributesMapBySourceId,
      channelEngagementTagId,
      true,
    )

    const currentLayout = _get(authenticatedUser, "ui.customerDetailLayout", "two-cols")

    const searchIdentifier = _get(customerDetailFilterValues, "searchIdentifier", "")
    const graphIdentifiersSelection = _get(customerDetailFilterValues, "graphIdentifiers", {})

    return (
      <React.Fragment>
        <section className="customer-detail wrapper">
          {customerAttributesLoading && <LoadingIndicator className="loading-indicator" />}
          {!customerAttributesLoading && areAttributesFulfilled && (
            <React.Fragment>
              <div>
                <Header
                  customerId={customerId}
                  customerAttributes={customerAttributes}
                  contactInfoAttributes={contactInfoAttributes}
                  channelEngagementAttributes={channelEngagementAttributes}
                  isGlobalSettingsFulfilled={isGlobalSettingsFulfilled}
                  customerDataReady={customerDataReady}
                />
              </div>
              <div ref={this.scrollToSwitcher}>
                <div className="switch-row">
                  <hr />
                  <div className="switch-background">
                    <ToggleSwitchMultiple
                      width="280px"
                      name="view-switch"
                      buttons={[
                        { value: "attributes" },
                        { value: "timeline" },
                        { value: "identity" },
                      ]}
                      checked={showScreen}
                      handleToggle={this.switchScreen}
                    />
                  </div>
                </div>
                <div
                  className={`customer-detail-content ${
                    showScreen === "timeline" ? "timeline-content" : ""
                  }`}
                >
                  <CustomerDetailFilterForm
                    currentLayout={currentLayout}
                    setLayout={this.setLayout}
                    attributesMapBySourceId={attributesMapBySourceId}
                    customerAttributes={customerAttributes}
                    dataSources={dataSources}
                    eventTypesBySourceId={eventTypesBySourceId}
                    eventsFetching={events.some(value => value.get("isFetching"))}
                    initialValues={{
                      sources: dataSources.map(() => true).toJS(),
                      eventTypes: eventTypesInitialFormObject,
                      graphIdentifiers: initialGraphIdentifiers,
                    }}
                    screenType={showScreen}
                    formChanged={this.filterChanged}
                    stitchingAttributes={stitchingAttributes}
                  />
                  {showScreen === "attributes" && customerDataReady && (
                    <div className="customer-attributes-boxes">
                      {this.renderCustomerAttributesBoxes()}
                    </div>
                  )}
                  {showScreen === "timeline" && isDataSourcesFulfilled && (
                    <div className="timeline-content">
                      <div className="left-panel">
                        <TimelineFilterForm
                          attributesMapBySourceId={attributesMapBySourceId}
                          customerAttributes={customerAttributes}
                          dataSources={dataSources}
                          eventTypesBySourceId={eventTypesBySourceId}
                          eventsFetching={events.some(value => value.get("isFetching"))}
                          initialValues={{
                            sources: dataSources.map(() => true).toJS(),
                            eventTypes: eventTypesInitialFormObject,
                          }}
                          formChanged={this.filterChanged}
                        />
                      </div>
                      <div className="right-panel">
                        {eventChartsData.length > 1 && (
                          <EventGroupsCountsChart data={eventChartsData} />
                        )}
                        <div className="customer-timeline">
                          {this.renderGroups()}
                          {this.renderGroupsPagination()}
                        </div>
                      </div>
                    </div>
                  )}
                  {showScreen === "identity" && (
                    <Paper className="identity-graph">
                      <IdentityGraph
                        isLoading={
                          identityGraph.isFetching ||
                          !areAttributesFulfilled ||
                          !areEventsFulfilled ||
                          !isDataSourcesFulfilled
                        }
                        data={identityGraph.data}
                        attributes={attributesMapById}
                        dataSources={dataSources}
                        events={eventsById}
                        searchIdentifier={searchIdentifier}
                        graphIdentifiersSelection={graphIdentifiersSelection}
                        errorMessage={_get(identityGraph, "error.message", "")}
                      />
                    </Paper>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </section>
      </React.Fragment>
    )
  }
}

CustomerDetail.propTypes = {
  customers: PropTypes.instanceOf(List).isRequired,
  dataSources: PropTypes.instanceOf(Map).isRequired,
  isDataSourcesFulfilled: PropTypes.bool.isRequired,
  customerDetailFilterValues: PropTypes.object,
  eventTypesBySourceId: PropTypes.instanceOf(Map).isRequired,
  eventTypesInitialFormObject: PropTypes.object.isRequired,
  channelEngagementTagId: PropTypes.string,
  contactInfoTagId: PropTypes.string,
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapBySourceId: PropTypes.instanceOf(Map).isRequired,
  attributesMapById: PropTypes.instanceOf(Map).isRequired,
  eventsById: PropTypes.instanceOf(Map).isRequired,
  isGlobalSettingsFulfilled: PropTypes.bool.isRequired,
  hiddenChannelEngagementSourceIds: PropTypes.instanceOf(List),
  authenticatedUser: PropTypes.object.isRequired,
  setUiAttribute: PropTypes.func.isRequired,
  resetCustomersIterator: PropTypes.func.isRequired,
  areEventsFulfilled: PropTypes.bool.isRequired,
  stitchingAttributes: PropTypes.instanceOf(List).isRequired,
}

const selector = formValueSelector("CustomerDetailFilterForm")
const mapStateToProps = state => ({
  dataSources: getDataSourcesData(state, true),
  eventTypesBySourceId: getEventsMappedBySourceId(state),
  eventTypesInitialFormObject: getEventsFilterFormInitialValues(state),
  isDataSourcesFulfilled: isDataSourcesFulfilled(state),
  customerDetailFilterValues: getFormValues("CustomerDetailFilterForm")(state),
  channelEngagementTagId: getGlobalSettingsValueByKey(state, "channel_engagement_tag_id"),
  contactInfoTagId: getGlobalSettingsValueByKey(state, "contact_info_tag_id"),
  hiddenChannelEngagementSourceIds: getGlobalSettingsValueByKey(
    state,
    "hidden_channel_engagement_source_ids",
  ),
  isGlobalSettingsFulfilled: isGlobalSettingsFulfilled(state),
  areAttributesFulfilled: areAttributesFulfilled(state),
  attributesMapBySourceId: getAttributesMapBySourceIdSortedByOrderIndex(state, true),
  attributesMapById: getAttributesMapById(state, true),
  eventsById: getEventsMappedById(state, true),
  areEventsFulfilled: getEventsIsFulfilled(state),
  customers: getCustomerSearchesData(state),
  authenticatedUser: state.authenticatedUser,
  sourcesFormState: selector(state, "sources"),
  stitchingAttributes: getStitchingAttributesList(state, true),
})

export default connect(mapStateToProps, {
  setUiAttribute,
  showToast,
  resetCustomersIterator,
})(CustomerDetail)
