import _get from "lodash/get"

import { api } from "api"
import { TRASH } from "sharedConstants"

export const fetchTrashItems =
  (offset, limit, searched_text = null, item_type = null, order_dir) =>
  async dispatch => {
    const load_full_structure = 0

    return dispatch({
      type: TRASH.ACTION.LIST,
      payload: {
        promise: (async () => {
          return await api.trash.list(
            offset,
            limit,
            load_full_structure,
            searched_text,
            item_type,
            order_dir,
          )
        })(),
      },
    })
  }

export const restoreTrashSegment = segment_id => async dispatch =>
  dispatch({
    type: TRASH.ACTION.RESTORE_SEGMENT,
    payload: {
      promise: (async () => {
        const response = await api.trash.segments.restore(segment_id)
        return _get(response, "segment", {})
      })(),
    },
  })

export const restoreTrashUser = user_id => async dispatch =>
  dispatch({
    type: TRASH.ACTION.RESTORE_USER,
    payload: {
      promise: (async () => {
        return await api.trash.users.restore(user_id)
      })(),
    },
  })

export const restoreExportDestination = destination_id => async dispatch =>
  dispatch({
    type: TRASH.ACTION.RESTORE_EXPORT_DESTINATION,
    payload: {
      promise: (async () => {
        const response = await api.trash.exportDestinations.restore(destination_id)
        return _get(response, "segment_export_destination", {})
      })(),
    },
  })

export const restoreRole = role_id => async dispatch =>
  dispatch({
    type: TRASH.ACTION.RESTORE_USER_ROLE,
    payload: {
      promise: (async () => {
        return await api.trash.roles.restore(role_id)
      })(),
    },
  })
