import React, { ReactNode } from "react"
import PropTypes from "prop-types"

import Modal from "../../elements/Modal"
import Button from "../../elements/Button/Button"
import { MODAL } from "sharedConstants"

import styles from "./ConfirmModal.module.scss"

type ConfirmModalProps = {
  open: boolean
  type: "success" | "delete" | "cancel"
  handleClose: () => void
  handleConfirm: () => void
  title: string
  text?: ReactNode
  action?: string
  what?: string
  item?: string
  isLoading?: boolean
  customButtonText?: string
  className?: string
}
const ConfirmModal = ({
  open,
  type,
  handleClose,
  handleConfirm,
  title,
  text,
  action,
  what,
  item,
  isLoading = false,
  customButtonText = "",
  className = "",
}: ConfirmModalProps) => (
  <Modal open={open} handleClose={handleClose} title={title} className={className}>
    <div className={styles.confirmModal}>
      {text && <p>{text}</p>}
      {item && !text && (
        <p>
          {`Do you really want to ${action} ${what}: `}
          <strong>{item}</strong>?
        </p>
      )}
      <div className={styles.actionButtons}>
        <Button
          color="transparent-grey"
          className={styles.cancelButton}
          size="big"
          onClick={handleClose}
        >
          Cancel
        </Button>
        {type === MODAL.TYPE.DELETE && (
          <Button
            color="red"
            size="big"
            onClick={handleConfirm}
            className={isLoading ? "loading" : ""}
          >
            {customButtonText !== "" ? customButtonText : "Delete"}
          </Button>
        )}
        {(type === "success" || type === "cancel") && (
          <Button
            color={type === "success" ? "primary" : "red"}
            size="big"
            onClick={handleConfirm}
            className={isLoading ? "loading" : ""}
          >
            {type === "success" || type === "cancel" ? "confirm" : type}
          </Button>
        )}
      </div>
    </div>
  </Modal>
)

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string,
  what: PropTypes.string,
  item: PropTypes.string,
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  customButtonText: PropTypes.string,
}

export default ConfirmModal
