import React, { useCallback, useContext, useEffect, useState } from "react"
import styles from "./SegmentDetail.module.scss"
import { ToggleSwitchMultiple } from "components/UI/elements/ToggleSwitch"
import { Link, Prompt, useHistory, useParams } from "react-router-dom"
import { getRoutePath } from "routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/UI/elements/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { getSegmentById } from "resources/segment/segment/segmentSelectors"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import useTypedSelector from "hooks/useTypedSelector"
import Paper from "components/UI/elements/Paper"
import { View } from "./types"
import { getNewPathName } from "./utils"
import classNames from "classnames"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import {
  deleteSegment as deleteSegmentAction,
  modifySegment,
  retrieveSegment,
} from "resources/segment/segment/segmentActions"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import SegmentConditionComponent, {
  getNewCondition,
} from "./components/SegmentCondition/SegmentCondition"
import PaperHeader from "components/UI/elements/PaperHeader"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { dec, equals, inc, without } from "ramda"
import { showToast } from "actions/toast.action"
import EditableName from "./components/EditableName/EditableName"
import { getSegmentsAclsList } from "selectors/acl.selector"
import { getAllUsersMap, areUsersFulfilled } from "selectors/user.selector"
import { fetchSegmentsAclList, fetchUsersAclList } from "actions/acl.action"
import { UserFull } from "types/users"
import { SegmentUser } from "types/segmentUsers"
import { areTagsAlreadyFetched, getTagsSortedByName } from "resources/tag/tagSelectors"
import { Tag } from "resources/tag/tagTypes"
import TagPicker from "components/UI/components/TagPicker"
import TagComponent from "components/UI/elements/Tag"
import SegmentUsersModal from "./components/SegmentUsersModal/SegmentUsersModal"
import { noop } from "lodash"
import {
  dispatchSegmentNumbersError,
  initSegmentNumbers,
  setSegmentNumbers,
} from "resources/segment/segmentNumbers/segmentNumbersActions"
import { SocketContext } from "context/socket"
import SegmentInsights from "./components/SegmentInsights/SegmentInsights"
import SegmentExports from "./components/SegmentExports/SegmentExports"
import { hasAccess, usersPermission } from "helpers/authenticatedUser.helper"
import { PERMISSION, SEGMENT, TOAST } from "sharedConstants"
import { hideLongLoadingBar } from "actions/longLoadingBar.action"
import { initSegmentCustomers } from "resources/segment/segmentCustomers/segmentCustomersActions"
import { getSegmentsNumbers } from "resources/segment/segmentNumbers/segmentNumbersSelectors"
import SegmentNotes from "./components/SegmentNotes/SegmentNotes"
import SmartSegmentSettings from "./components/SmartSegmentSettings/SmartSegmentSettings"
import ConfirmModal from "components/UI/components/ConfirmModal"
import CopyModal from "./components/CopyModal/CopyModal"
import { api } from "api"
import SegmentSummary from "./components/SegmentSummary/SegmentSummary"
import { ConditionNumbersContext } from "./conditionNumbersContext"
import {
  SegmentCondition,
  SegmentConditionError,
} from "resources/segment/segment/segmentConditionsTypes"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateSegmentCondition } from "./components/SegmentCondition/validation"
import { ConditionTree } from "types/conditionTree"
import { modifyAuthUser } from "actions/authenticatedUser.action"
import { CSSTransition } from "react-transition-group"
import SegmentGuide from "./components/SegmentGuide/SegmentGuide"
import SegmentCustomersPreview from "./components/SegmentCustomersPreview/SegmentCustomersPreview"
import SegmentNumbers from "./components/SegmentNumbers/SegmentNumbers"
import SegmentUsers from "./components/SegmentUsers/SegmentUsers"
import InsightsFilterForm from "components/UI/components/InsightsFilterForm"
import { areAttributesFulfilled } from "selectors/attributes.selector"

const validateConditionTree = makeConditionTreeValidator(validateSegmentCondition)

type SegmentDetailProps = {
  type?: SegmentType
  view?: View
  isSmartSetup?: boolean
}

export default function SegmentDetail({
  type = "regular",
  view = "builder",
  isSmartSetup,
}: SegmentDetailProps) {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)
  const authenticatedUser = useTypedSelector(state => state.authenticatedUser.data)!

  // SEGMENT

  useEffect(() => {
    dispatch(retrieveSegment(+id, type))
      // @ts-ignore
      .then(({ value: { segment } }) => {
        if (segment.prebuilt && type !== "smart") {
          history.push(getRoutePath("segments.smart.detail", { id }))
        } else if (segment.featured && type !== "featured") {
          history.push(getRoutePath("segments.featured.detail", { id }))
        }
      })
      .catch(noop)
  }, [dispatch, history, id, type])

  // @ts-ignore
  const segmentImmutable = useSelector(state => getSegmentById(state, id, type))
  const segment: Segment | undefined = segmentImmutable?.toJS()

  // CONDITIONS

  const conditionsFromStore = segment?.settings?.conditions_operation ?? null
  const [conditions, setConditions] = useState(conditionsFromStore)
  const [prevConditionsFromStore, setPrevConditionsFromStore] = useState(conditionsFromStore) // TODO: won't be needed when immutable is removed from store!

  useEffect(() => {
    // If conditions change on the BE…
    if (!equals(prevConditionsFromStore, conditionsFromStore)) {
      setPrevConditionsFromStore(conditionsFromStore)

      // Set local conditions to BE value
      setConditions(conditionsFromStore)

      // Force refresh segment numbers
      dispatch(initSegmentNumbers(id, true))
      socket.emit("segment_counts", { segment_id: id })

      // Force refresh customer preview
      dispatch(initSegmentCustomers(id, true))
      socket.emit("segment_customer_entities", {
        segment_id: id,
        offset: 0,
        limit: SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
      })
      setCustomersListOffset(0)
    }
  }, [dispatch, id, conditionsFromStore, socket]) // eslint-disable-line react-hooks/exhaustive-deps

  const [shouldValidate, setShouldValidate] = useState(false)
  const [error, setError] = useState<ConditionTree<SegmentConditionError | null>>(null)

  const setConditionsAndValidate = useCallback(
    conditions => {
      setConditions(conditions)
      if (shouldValidate) {
        setError(validateConditionTree(conditions))
      }
    },
    [shouldValidate],
  )

  const [isSavingConditions, setIsSavingConditions] = useState(false)

  const saveConditions = useCallback(async () => {
    setShouldValidate(true)
    const error = validateConditionTree(conditions)
    setError(error)
    if (error) {
      return
    } else {
      setShouldValidate(false)
    }

    setIsSavingConditions(true)
    const settings = segment?.settings ?? {}
    try {
      await dispatch(
        modifySegment(
          +id,
          { settings: { ...settings, conditions_operation: conditions ?? undefined } },
          type,
        ),
      )
      dispatch(showToast("Conditions saved."))
      dispatch(initSegmentNumbers(id, true))
      socket.emit("segment_counts", { segment_id: id })
    } catch {
    } finally {
      setIsSavingConditions(false)
    }
  }, [conditions, dispatch, id, segment?.settings, socket, type])

  const hasUnsavedConditions = !equals(conditionsFromStore, conditions)

  const undoChanges = useCallback(() => {
    setConditions(conditionsFromStore)
    setShouldValidate(false)
    setError(null)
    dispatch(initSegmentNumbers(id, true))
    socket.emit("segment_counts", { segment_id: id })
  }, [conditionsFromStore, dispatch, id, socket])

  // NAME

  const editName = useCallback(
    async newName => {
      try {
        await dispatch(modifySegment(+id, { name: newName }, type))
        dispatch(showToast("Segment name edited."))
      } catch {}
    },
    [dispatch, id, type],
  )

  // ACL

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSegmentsAclList(id)).catch(noop)
  }, [dispatch, id, type])

  const acl: SegmentUser[] | undefined = useTypedSelector(state =>
    getSegmentsAclsList(state, +id),
  )?.toJS()
  const allUsers: Record<UserFull["id"], UserFull> = useSelector(getAllUsersMap).toJS()

  // TAGS

  const allTagsImmutable = useSelector(getTagsSortedByName)
  const allTags: Tag[] = allTagsImmutable.toJS()

  const assignTag = useCallback(
    async tagId => {
      try {
        await dispatch(modifySegment(+id, { tag_ids: segment!.tag_ids.concat(tagId) }))
        dispatch(showToast("Tag assigned."))
      } catch {}
    },
    [dispatch, id, segment],
  )

  const unassignTag = useCallback(
    async tagId => {
      try {
        await dispatch(modifySegment(+id, { tag_ids: without([tagId], segment!.tag_ids) }))
        dispatch(showToast("Tag unassigned."))
      } catch {}
    },
    [dispatch, id, segment],
  )

  // SEGMENT NUMBERS

  const segmentNumbersImmutable = useSelector(state => getSegmentsNumbers(state, id))
  const segmentNumbers = segmentNumbersImmutable.toJS()

  useEffect(() => {
    socket.on("segment_counts_response", (msg: any) => {
      if (msg.error) {
        dispatch(dispatchSegmentNumbersError(id))
        dispatch(showToast(msg.error, TOAST.TYPE.ERROR))
        dispatch(hideLongLoadingBar())
      } else {
        dispatch(setSegmentNumbers(id, msg))
      }
    })

    dispatch(initSegmentNumbers(id))
    socket.emit("segment_counts", { segment_id: id })

    return () => {
      socket.off("segment_counts_response")
    }
  }, [dispatch, id, socket])

  const { customers_total_count, conditions_results_count, conditions_numbers } = segmentNumbers

  // SUMMARY

  // NOTES

  // SEGMENT CUSTOMERS

  const [customersListOffset, setCustomersListOffset] = useState(0)

  // IS EDITABLE

  const [isEditable, setIsEditable] = useState(true)

  useEffect(() => {
    setIsEditable(
      isSmartSetup ||
        (type !== "smart" && usersPermission(id, type === "featured") === PERMISSION.WRITE),
    )
    // TODO: ACL is a new array every re-render bc it's converted from immutable; remove immutable
  }, [acl, id, isSmartSetup, type])

  // UI STATE

  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false)
  const [isSummaryOpen, setIsSummaryOpen] = useState(true)
  const [isNotesOpen, setIsNotesOpen] = useState(false)
  const [isCustomersOpen, setIsCustomersOpen] = useState(false)

  // Reset UI state when id changes
  useEffect(() => {
    setIsUsersModalOpen(false)
    setIsSummaryOpen(true)
    setIsNotesOpen(false)
    setIsCustomersOpen(false)
    setCustomersListOffset(0)
  }, [id])

  // ROUTING
  useEffect(() => {
    if (view !== "builder") {
      if (hasUnsavedConditions) {
        undoChanges()
      }
    }
  }, [view]) // eslint-disable-line react-hooks/exhaustive-deps

  const goToView = (newView: View) => {
    history.push(getRoutePath(getNewPathName(newView, type, isSmartSetup), { id }))
  }

  const backLink = getRoutePath(
    isSmartSetup
      ? "setup.segments.smart"
      : type === "featured"
      ? "segments.featured"
      : type === "smart"
      ? "segments.smart"
      : "segments",
  )

  const toggleViewOptions = isSmartSetup
    ? [{ value: "settings" }, { value: "builder" }, { value: "exports" }]
    : [{ value: "builder" }, { value: "insights" }, { value: "exports" }]

  // DELETE

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteSegment = useCallback(async () => {
    if (isDeleting) return

    setIsDeleting(true)

    try {
      await dispatch(deleteSegmentAction(+id, type))
      dispatch(showToast("Segment deleted.", TOAST.TYPE.SUCCESS))
      history.push(backLink)
    } catch {
      setIsDeleting(false)
    }
  }, [backLink, dispatch, history, id, isDeleting, type])

  // COPY

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const [isCopyingToFeatured, setIsCopyingToFeatured] = useState(false)

  const copyToCustomSegments = useCallback(async () => {
    if (isCopying) return

    setIsCopying(true)
    try {
      const response = await api.segment.clone(+id, { featured: 0 })
      // @ts-ignore
      dispatch(fetchUsersAclList(authenticatedUser.id)).catch(noop)
      dispatch(
        showToast(
          "Segment copied.",
          TOAST.TYPE.SUCCESS,
          getRoutePath("segments.detail", { id: response.segment.id }),
        ),
      )
      setIsCopyModalOpen(false)
    } catch {
    } finally {
      setIsCopying(false)
    }
  }, [authenticatedUser.id, dispatch, id, isCopying])

  const copyToFeaturedSegments = useCallback(async () => {
    if (isCopyingToFeatured) return

    setIsCopyingToFeatured(true)
    try {
      const response = await api.segment.clone(+id, { featured: 1 })
      // @ts-ignore
      dispatch(fetchUsersAclList(authenticatedUser.id)).catch(noop)
      dispatch(
        showToast(
          "Segment copied.",
          TOAST.TYPE.SUCCESS,
          getRoutePath("segments.featured.detail", { id: response.segment.id }),
        ),
      )
      setIsCopyModalOpen(false)
    } catch {
    } finally {
      setIsCopyingToFeatured(false)
    }
  }, [authenticatedUser.id, dispatch, id, isCopyingToFeatured])

  // SMART SEGMENT FE SETTINGS

  const changeSmartSegmentFESettings = useCallback(
    async newFESettings => {
      // TODO: indication of loading?
      try {
        dispatch(modifySegment(+id, { frontend_settings: newFESettings }, type))
        dispatch(showToast("Segment's visual attributes were updated."))
      } catch {}
    },
    [dispatch, id, type],
  )

  // ONBOARDING GUIDE

  const { frontend_settings, id: userId } = authenticatedUser

  const [isGuideOpen, setIsGuideOpen] = useState(!frontend_settings?.hasSeenSegmentGuide)
  const [guideStep, setGuideStep] = useState(1)

  const guideStepBack = useCallback(() => setGuideStep(dec), [])
  const guideStepForward = useCallback(() => setGuideStep(inc), [])

  const closeGuide = useCallback(() => {
    setIsGuideOpen(false) // Hide immediately, don't wait for the BE response
    dispatch(
      modifyAuthUser(userId, {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          hasSeenSegmentGuide: true,
        },
      }),
    )
  }, [dispatch, frontend_settings, userId])

  const areAttributesLoaded = useSelector(areAttributesFulfilled)
  const areAllUsersLoaded = useSelector(areUsersFulfilled)
  const areAllTagsLoaded = useSelector(areTagsAlreadyFetched)

  if (!segment || !acl || !areAllUsersLoaded || !areAllTagsLoaded || !areAttributesLoaded) {
    return <LoadingIndicator />
  }

  const { name, tag_ids, author_id } = segment

  const reducedOpacity = { opacity: 0.3 }

  return (
    <div className={styles.container}>
      <Prompt
        when={hasUnsavedConditions}
        message="Changes to conditions you made will not be saved."
      />

      <CSSTransition
        in={isGuideOpen && guideStep === 1}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <SegmentGuide
          onClose={closeGuide}
          onPrev={guideStepBack}
          onNext={guideStepForward}
          step={1}
          style={{ top: "50%", left: "50%", transform: "translate(-50%)", position: "fixed" }}
        />
      </CSSTransition>

      {type !== "smart" && (
        <SegmentUsersModal
          isOpen={isUsersModalOpen}
          onClose={() => setIsUsersModalOpen(false)}
          segmentId={+id}
          authorId={author_id}
          acl={acl}
          allUsers={allUsers}
          isEditable={isEditable}
          segmentType={type}
        />
      )}

      <CopyModal
        open={isCopyModalOpen}
        handleClose={() => setIsCopyModalOpen(false)}
        type={type}
        onCopyClick={copyToCustomSegments}
        onCopyToFeaturedClick={copyToFeaturedSegments}
        copying={isCopying}
        copyingToFeatured={isCopyingToFeatured}
      />

      <ConfirmModal
        open={isDeleteModalOpen}
        type="delete"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteSegment}
        title="delete segment"
        isLoading={isDeleting}
        text="Do you really want to delete the current segment?"
      />

      <div
        className={styles.viewSwitchWrapper}
        style={isGuideOpen && guideStep !== 4 ? reducedOpacity : undefined}
      >
        <CSSTransition
          in={isGuideOpen && guideStep === 4}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <SegmentGuide
            onClose={closeGuide}
            onPrev={guideStepBack}
            onNext={guideStepForward}
            step={4}
            style={{ top: "calc(100% + 10px)", left: "50%", transform: "translateX(-50%)" }}
          />
        </CSSTransition>

        <div className={styles.line}></div>
        <ToggleSwitchMultiple
          width="280px"
          name="view-switch"
          buttons={toggleViewOptions}
          checked={view}
          handleToggle={goToView}
          className={styles.viewSwitch}
        />
        <div className={styles.line}></div>
      </div>

      <div className={styles.topBar}>
        <div className={styles.backLinkWrapper} style={isGuideOpen ? reducedOpacity : undefined}>
          <Link to={backLink} className={styles.link}>
            <FontAwesomeIcon icon={["fas", "chevron-left"]} className={styles.icon} /> Back to{" "}
            {type === "regular" ? "custom" : type} segments {isSmartSetup && "setup"}
          </Link>
        </div>

        <div
          className={styles.buttonsWrapper}
          style={
            isGuideOpen && (guideStep !== 3 || view !== "builder") ? reducedOpacity : undefined
          }
        >
          {isEditable && view === "builder" && (
            <Button
              className={styles.button}
              color="white"
              size="small"
              disabled={!hasUnsavedConditions || isSavingConditions}
              onClick={undoChanges}
            >
              undo changes
            </Button>
          )}
          {isEditable && (
            <Button
              className={styles.button}
              color="white-red"
              size="small"
              onClick={() => setIsDeleteModalOpen(true)}
              disabled={!isEditable}
            >
              delete
            </Button>
          )}
          <Button
            className={styles.button}
            color="white"
            size="small"
            onClick={() => setIsCopyModalOpen(true)}
            disabled={!hasAccess.segments.create() && !hasAccess.segments.featured.edit()}
          >
            copy
          </Button>
          {isEditable && view === "builder" && (
            <Button
              className={styles.button}
              color="green"
              size="small"
              isLoading={isSavingConditions}
              onClick={saveConditions}
              disabled={!hasUnsavedConditions}
            >
              save conditions
            </Button>
          )}
        </div>
      </div>

      <div className={styles.mainContent}>
        <div
          className={styles.leftPanel}
          style={isGuideOpen && guideStep !== 2 ? reducedOpacity : undefined}
        >
          <CSSTransition
            in={isGuideOpen && guideStep === 2}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <SegmentGuide
              onClose={closeGuide}
              onPrev={guideStepBack}
              onNext={guideStepForward}
              step={2}
              style={{ top: "0", left: "calc(100% + 10px)" }}
            />
          </CSSTransition>

          <Paper className={styles.card}>
            <div className={styles.nameWrapper}>
              <div className={styles.title}>name</div>
              {isEditable ? (
                <EditableName initValue={name} onChange={editName} className={styles.name} />
              ) : (
                <div className={styles.name}>{name}</div>
              )}
              <div className={styles.stretch}></div>
              <div className={styles.segmentId}>ID: {id}</div>
            </div>
            {type !== "smart" && (
              <div className={styles.usersWrapper}>
                <div className={styles.title}>users</div>
                <SegmentUsers
                  acl={acl}
                  allUsers={allUsers}
                  authorId={author_id}
                  openUsersModal={() => setIsUsersModalOpen(true)}
                />
              </div>
            )}
          </Paper>

          <Paper className={styles.tagsCard}>
            <div className={styles.title}>tags</div>
            <div className={styles.tagPickerWrapper}>
              {tag_ids.map(tagId => {
                const tag = allTags.find(({ id }) => id === tagId)

                return tag ? (
                  <TagComponent
                    key={tag.id}
                    clickable={isEditable}
                    color={tag.color ? tag.color : "primary"}
                    onClick={isEditable ? () => unassignTag(tagId) : noop}
                  >
                    {tag.name}
                  </TagComponent>
                ) : null
              })}
              <TagPicker
                className={styles.tagPicker}
                selectedTagIds={tag_ids}
                allTags={allTagsImmutable}
                onTagSelect={assignTag}
                disabled={!isEditable}
              />
            </div>
          </Paper>

          <Paper className={styles.card}>
            <div className={styles.title}>segmented</div>
            <SegmentNumbers hasUnsavedConditions={hasUnsavedConditions} />
          </Paper>

          <Paper className={styles.card}>
            <div className={styles.flexWrapper}>
              <div className={classNames(styles.title, styles.stretch)}>summary</div>
              <button className={styles.openButton} onClick={() => setIsSummaryOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isSummaryOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentSummary
              conditionTree={conditions}
              segmentedCustomers={conditions_results_count ?? null}
              totalCustomers={customers_total_count ?? null}
              hasUnsavedConditions={hasUnsavedConditions}
              isOpen={isSummaryOpen}
            />
          </Paper>

          <Paper className={styles.notesCard}>
            <div className={styles.notesCardHeader}>
              <div className={classNames(styles.title, styles.stretch)}>notes</div>
              <button className={styles.openButton} onClick={() => setIsNotesOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isNotesOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentNotes
              segmentId={+id}
              isEditable={isEditable}
              toggleNotes={() => {}}
              isOpen={isNotesOpen}
            />
          </Paper>

          <Paper className={styles.card}>
            <div className={styles.flexWrapper}>
              <div className={classNames(styles.title, styles.stretch)}>
                preview customer profiles
              </div>
              <button className={styles.openButton} onClick={() => setIsCustomersOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isCustomersOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentCustomersPreview
              hasUnsavedConditions={hasUnsavedConditions}
              customersListOffset={customersListOffset}
              setCustomersListOffset={setCustomersListOffset}
              isOpen={isCustomersOpen}
            />
          </Paper>
        </div>

        <div
          className={styles.viewWrapper}
          style={isGuideOpen && guideStep !== 3 ? reducedOpacity : undefined}
        >
          <CSSTransition
            in={isGuideOpen && guideStep === 3}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <SegmentGuide
              onClose={closeGuide}
              onPrev={guideStepBack}
              onNext={guideStepForward}
              step={3}
              style={{ top: "0", right: "calc(100% + 10px)" }}
            />
          </CSSTransition>

          {view === "settings" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              <SmartSegmentSettings
                id={id}
                handleSmartSegmentFeSettingsChange={changeSmartSegmentFESettings}
              />
            </div>
          )}
          {view === "builder" && (
            <>
              <PaperHeader
                titleText="conditions"
                size="small"
                className={styles.conditionsOverviewWrapper}
              >
                <ConditionTreeOverview conditionTree={conditions} />
              </PaperHeader>
              <Paper className={styles.conditionBuilderWrapper}>
                <ConditionNumbersContext.Provider
                  value={{
                    totalCustomers: customers_total_count,
                    conditionNumbers: conditions_numbers ?? [],
                    isInvalidNumbers: hasUnsavedConditions,
                  }}
                >
                  <ConditionBuilder<SegmentCondition>
                    conditionTree={conditions}
                    onChange={setConditionsAndValidate}
                    conditionComponent={SegmentConditionComponent}
                    getNewCondition={getNewCondition}
                    isEditable={isEditable}
                    error={error}
                  />
                </ConditionNumbersContext.Provider>
              </Paper>
            </>
          )}
          {view === "insights" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              <PaperHeader titleText="insights" size="small" className={styles.insightsHeader}>
                <div className={styles.insightsSearchWrapper}>
                  <InsightsFilterForm />
                </div>
              </PaperHeader>
              <Paper>
                {hasAccess.segments.insights() ? (
                  <SegmentInsights id={id} />
                ) : (
                  <div className={styles.noAccessMessage}>
                    You don't have permission to view segment insights.
                  </div>
                )}
              </Paper>
            </div>
          )}
          {view === "exports" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              {hasAccess.segments.export() ? (
                <SegmentExports
                  segment={segmentImmutable}
                  segmentsNumbers={segmentNumbersImmutable}
                  isEditable={isEditable}
                  isSegmentDeleting={isDeleting}
                  isSmartSegment={type === "smart"}
                  isFeaturedSegment={type === "featured"}
                />
              ) : (
                <>
                  <PaperHeader titleText="exports" size="small" />
                  <Paper className={styles.noAccessMessage}>
                    You don't have permission to view segment exports.
                  </Paper>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
