import { TRASH } from "sharedConstants"
import TrashItem from "models/trashItem.model"
import _map from "lodash/map"
import { Map, fromJS, List } from "immutable"
import SelectionSettings from "models/selectionSettings.model"

const initialState = Map(
  fromJS({
    trashData: [],
    isTrashDataFulfilled: false,
  }),
)

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${TRASH.ACTION.LIST}_FULFILLED`:
      if (payload.selection_settings.offset === 0) {
        return state
          .set("isTrashDataFulfilled", true)
          .set("trashData", List(_map(payload.trashed_items, item => new TrashItem(fromJS(item)))))
          .set("selectionSettings", new SelectionSettings(payload.selection_settings))
          .set("hasMoreItems", payload.trashed_items.length === TRASH.LOADING_LIMIT)
      } else {
        return state
          .set("isTrashDataFulfilled", true)
          .set(
            "trashData",
            state
              .get("trashData")
              .concat(_map(payload.trashed_items, item => new TrashItem(fromJS(item)))),
          )
          .set("selectionSettings", new SelectionSettings(payload.selection_settings))
          .set("hasMoreItems", payload.trashed_items.length === TRASH.LOADING_LIMIT)
      }
    case `${TRASH.ACTION.RESTORE_SEGMENT}_FULFILLED`:
      return state.set(
        "trashData",
        state
          .get("trashData")
          .filterNot(
            item => item.type === "segment" && item.getIn(["entity", "id"]) === payload.id,
          ),
      )
    case `${TRASH.ACTION.RESTORE_USER}_FULFILLED`:
      return state.set(
        "trashData",
        state
          .get("trashData")
          .filterNot(item => item.type === "user" && item.getIn(["entity", "id"]) === payload.id),
      )
    case `${TRASH.ACTION.RESTORE_EXPORT_DESTINATION}_FULFILLED`: {
      return state.set(
        "trashData",
        state
          .get("trashData")
          .filterNot(
            item =>
              item.type === "export_destination" && item.getIn(["entity", "id"]) === payload.id,
          ),
      )
    }
    case `${TRASH.ACTION.RESTORE_USER_ROLE}_FULFILLED`: {
      return state.set(
        "trashData",
        state
          .get("trashData")
          .filterNot(
            item => item.type === "user_role" && item.getIn(["entity", "id"]) === payload.id,
          ),
      )
    }
    default:
      return state
  }
}
