import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _noop from "lodash/noop"
import moment from "moment"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button/Button"
import IconButton, { SIZE, COLOR } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LabelForm from "../../LabelForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"

// actions
import { fetchLabelsList, createLabel, modifyLabel, deleteLabel } from "actions/label.action"
import { showToast } from "actions/toast.action"
import { fetchAttributesList } from "actions/attribute.action"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

// selectors
import { getLabelsArray, areLabelsFulfilled } from "selectors/label.selector"

// constants, helpers
import { MOMENT, MODAL } from "sharedConstants"

import "./LabelsList.scss"

class LabelsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      labelForm: {
        show: false,
        label: null,
        loading: false,
      },
      deleteModal: {
        open: false,
        label: null,
      },
    }
  }

  componentDidMount() {
    this.props.fetchLabelsList().catch(_noop)
  }

  toggleLabelForm =
    (label = null) =>
    () => {
      this.setState(prevState => ({
        labelForm: {
          show: !prevState.labelForm.show,
          label,
          loading: false,
        },
      }))
    }

  submitLabelForm = values => {
    const { labelForm } = this.state
    const { createLabel, modifyLabel, showToast, fetchAttributesList } = this.props
    if (!labelForm.loading) {
      this.setState(prevState => ({
        labelForm: {
          ...prevState.labelForm,
          loading: true,
        },
      }))

      if (labelForm.label) {
        // modify
        modifyLabel(labelForm.label.id, values)
          .then(() => {
            // refetch attributes with updated label
            fetchAttributesList(1).catch(_noop)
            showToast("Label edited.")
            this.toggleLabelForm()()
          })
          .catch(() => {
            this.setState(prevState => ({
              labelForm: {
                ...prevState.labelForm,
                loading: false,
              },
            }))
          })
      } else {
        // create
        createLabel(values)
          .then(() => {
            showToast("Label created.")
            this.toggleLabelForm()()
          })
          .catch(() => {
            this.setState(prevState => ({
              labelForm: {
                ...prevState.labelForm,
                loading: false,
              },
            }))
          })
      }
    }
  }

  toggleDeleteModal = label => () => {
    this.setState(prevState => ({
      deleteModal: {
        open: !prevState.deleteModal.open,
        label: label ? label : prevState.deleteModal.label,
      },
    }))
  }

  deleteLabel = () => {
    const { deleteLabel, showToast } = this.props
    const { deleteModal, labelForm } = this.state

    deleteLabel(deleteModal.label.id)
      .then(() => {
        this.toggleDeleteModal()()
        if (labelForm.show) {
          this.toggleLabelForm()()
        }
        showToast("Label deleted.")
      })
      .catch(this.toggleDeleteModal())
  }

  render() {
    const { labelForm, deleteModal } = this.state
    const { areLabelsFulfilled, labels } = this.props

    return (
      <React.Fragment>
        <section className="wrapper setup-labels">
          <PaperHeader className="setup-labels-header" titleText="Labels" size="small">
            {!labelForm.show && (
              <Button size="small" color="primary" onClick={this.toggleLabelForm()}>
                + Create label
              </Button>
            )}
            {labelForm.show && (
              <div className="buttons">
                {labelForm.label && (
                  <Button
                    size="small"
                    color="white-red"
                    onClick={this.toggleDeleteModal(labelForm.label)}
                  >
                    <FontAwesomeIcon icon={["fas", "trash-alt"]} className="icon" /> Delete
                  </Button>
                )}

                <Button size="small" color="white" onClick={this.toggleLabelForm()}>
                  Cancel
                </Button>
                <RemoteSubmitButton isLoading={labelForm.loading} formName="LabelForm" />
              </div>
            )}
          </PaperHeader>
          {areLabelsFulfilled && labels.length > 0 && !labelForm.show && (
            <Paper hasHeader={true} noPaddingTop>
              <Table className="labels-table">
                <Thead stickyHeader>
                  <Th className="label-name">Name</Th>
                  <Th className="label-created">Created</Th>
                  <Th className="actions" />
                </Thead>
                <Tbody>
                  {labels.map(label => (
                    <Tr key={label.id}>
                      <Td className="label-name">
                        <Tag color="primary">{label.name}</Tag>
                      </Td>
                      <Td className="label-created">
                        {moment.utc(label.created).local().format(MOMENT.DATE_FORMAT)}
                      </Td>
                      <Td textAlignRight className="actions">
                        <IconButton
                          color={COLOR.BLACK}
                          withBackground
                          size={SIZE.TAG}
                          onClick={this.toggleLabelForm(label)}
                          iconName="pencil-alt"
                          tooltip="Edit"
                        />
                        <IconButton
                          color={COLOR.RED}
                          withBackground
                          size={SIZE.TAG}
                          onClick={this.toggleDeleteModal(label)}
                          className="left-margin"
                          iconName="trash-alt"
                          tooltip="Delete"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Paper>
          )}
          {areLabelsFulfilled && labels.length === 0 && !labelForm.show && (
            <p className="info-message">Click on the "Create Label" to get started.</p>
          )}
          {labelForm.show && (
            <Paper hasHeader={true} className="label-form-wrapper">
              <LabelForm
                onFormClose={this.toggleLabelForm()}
                onLabelDelete={this.toggleDeleteModal(labelForm.label)}
                onSubmit={this.submitLabelForm}
                initialValues={labelForm.label ? labelForm.label : {}}
                type={labelForm.label ? "EDIT" : "CREATE"}
              />
            </Paper>
          )}
          <ConfirmModal
            open={deleteModal.open}
            type={MODAL.TYPE.DELETE}
            handleClose={this.toggleDeleteModal()}
            handleConfirm={this.deleteLabel}
            title="Delete label"
            action="delete"
            what="label"
            item={_get(deleteModal, "label.name", "")}
          />
        </section>
      </React.Fragment>
    )
  }
}

LabelsList.propTypes = {
  labels: PropTypes.array.isRequired,
  areLabelsFulfilled: PropTypes.bool.isRequired,
  fetchLabelsList: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  createLabel: PropTypes.func.isRequired,
  modifyLabel: PropTypes.func.isRequired,
  deleteLabel: PropTypes.func.isRequired,
  fetchAttributesList: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  labels: getLabelsArray(state),
  areLabelsFulfilled: areLabelsFulfilled(state),
})

export default connect(mapStateToProps, {
  fetchLabelsList,
  showToast,
  createLabel,
  modifyLabel,
  deleteLabel,
  fetchAttributesList,
})(LabelsList)
