import { List } from "immutable"
import { createSelector } from "reselect"
import {
  isGlobalSettingsFulfilled,
  getGlobalSettingsMap,
} from "../../selectors/globalSettings.selector"

export const getDataSourcesData = (state, showHidden = false) => {
  if (showHidden) {
    return state.dataSources.get("data")
  } else {
    return state.dataSources.get("data").filterNot(source => source.is_hidden)
  }
}

export const isDataSourcesFulfilled = state => state.dataSources.get("isFulfilled")

export const areDataSourcesFetching = state => state.dataSources.get("isFetching")

export const getDataSourceById = (state, id) => state.dataSources.getIn(["data", id])

export const getDataSourcesArrayForSelect = createSelector(getDataSourcesData, sources =>
  sources
    .map(source => ({ value: source.id, label: source.name, hidden: source.is_hidden === 1 }))
    .toList()
    .toJS(),
)

export const sourcesCountSelector = createSelector(
  [isDataSourcesFulfilled, isGlobalSettingsFulfilled, getDataSourcesData, getGlobalSettingsMap],
  (isDataSourcesFulfilled, isGlobalSettingsFulfilled, sources, globalSettings) => {
    if (!isDataSourcesFulfilled || !isGlobalSettingsFulfilled) return null

    const hiddenSourcesSettings = globalSettings.find(
      ({ key }) => key === "hidden_data_sources_from_diagnostic_dashboard",
    )
    if (List.isList(hiddenSourcesSettings?.value) && hiddenSourcesSettings.value.size > 0) {
      return sources.filter(source => !hiddenSourcesSettings.value.includes(source.id)).size
    }
    return sources.size
  },
)
