import requestFactory from "api/request"
import { Flag, OrderDir } from "types/util"
import {
  SegmentExportDestination,
  SegmentExportDestinationListResponse,
  SegmentExportDestinationModifyPayload,
  SegmentExportDestinationReturnType,
} from "./exportDestinationTypes"

const exportDestination = {
  list<T extends Flag = 0>(
    offset = 0,
    limit = 50,
    order_by: keyof SegmentExportDestinationReturnType<T> = "id",
    order_dir: OrderDir = "ASC",
    load_full_structure: T = 0 as T,
  ): Promise<SegmentExportDestinationListResponse<T>> {
    return requestFactory("get", "/segments/export/destinations", {
      offset,
      limit,
      order_by,
      order_dir,
      load_full_structure,
    })
  },
  modify(
    destinationId: SegmentExportDestination["id"],
    data: SegmentExportDestinationModifyPayload,
  ): Promise<{ segment_export_destination: SegmentExportDestination }> {
    return requestFactory("patch", `/segments/export/destinations/${destinationId}`, data)
  },
  delete(destinationId: SegmentExportDestination["id"]): Promise<void> {
    return requestFactory("delete", `/segments/export/destinations/${destinationId}`)
  },
}

export default exportDestination
