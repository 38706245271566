import React, { useCallback, useState } from "react"
import styles from "./WebBannerCondition.module.scss"
import {
  WBCondition,
  CookieCondition,
  GTMCondition,
  HTTPCondition,
  LeafCondition,
  LSCondition,
  TypedCondition,
  WBConditionError,
} from "resources/webBanner/webBannerConditionTypes"
import SelectField from "components/UI/elements/SelectField"
import {
  getOperatorOptions,
  getStorageTypeOption,
  getSubjectOption,
  OperatorDropdownOption,
  StorageTypeOption,
  storageTypeOptions,
  SubjectDropdownOption,
  subjectOptions,
} from "./dropdownOptions"
import ConditionValue from "./WebBannerConditionValue"
import {
  areOperatorsSameKind,
  getConditionWithNewSubject,
  getConditionWithNullValues,
  isCookieCondition,
  isGTMCondition,
  isHTTPCondition,
  isLSCondition,
  isOperatorAllowed,
  isTypedCondition,
} from "./utils"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import classNames from "classnames"
import TextField from "components/UI/elements/TextField/HookFormTextField"
import { DraggableSyntheticListeners } from "@dnd-kit/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConditionSymbol } from "types/conditionTree"
import { getSymbolStyle } from "components/ConditionBuilder/treeSymbols"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"
import InfoTooltip from "components/UI/elements/InfoTooltip"

type WebBannerConditionProps = {
  symbol: ConditionSymbol
  condition: WBCondition
  onChange: (condition: WBCondition) => void
  removeSelf?: () => void
  isEditable?: boolean
  error?: WBConditionError
  dragListeners?: DraggableSyntheticListeners
}

const touchedInitialState = {
  subject: true,
  operator: true,
  name: true,
  key: true,
  dl_name: true,
  dl_key: true,
  url_template: true,
  response_value_path: true,
}

export function getNewWebBannerCondition(): WBCondition {
  return { subject: null, operator: null }
}

export default function WebBannerConditionField({
  symbol,
  condition,
  onChange,
  removeSelf,
  isEditable,
  error,
  dragListeners,
}: WebBannerConditionProps) {
  const negation = condition.operator === "negation"
  const leafCondition = condition.operator === "negation" ? condition.operand : condition

  const onChangeWrappingNegation = useCallback(
    (leafCondition: LeafCondition) => {
      onChange(negation ? { operator: "negation", operand: leafCondition } : leafCondition)
    },
    [negation, onChange],
  )

  const setSubject = useCallback<(option: SubjectDropdownOption) => void>(
    ({ value: newSubject }) => {
      setTouched(s => ({ ...s, subject: true }))
      onChangeWrappingNegation(getConditionWithNewSubject(leafCondition, newSubject))
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setCookieName = useCallback(
    e => {
      setTouched(s => ({ ...s, name: true }))
      onChangeWrappingNegation({
        ...(leafCondition as CookieCondition),
        name: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setLSKey = useCallback(
    e => {
      setTouched(s => ({ ...s, key: true }))
      onChangeWrappingNegation({ ...(leafCondition as LSCondition), key: e.target.value || null })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setGTMName = useCallback(
    e => {
      setTouched(s => ({ ...s, dl_name: true }))
      onChangeWrappingNegation({
        ...(leafCondition as GTMCondition),
        dl_name: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setGTMKey = useCallback(
    e => {
      setTouched(s => ({ ...s, dl_key: true }))
      onChangeWrappingNegation({
        ...(leafCondition as GTMCondition),
        dl_key: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setHttpUrl = useCallback(
    e => {
      setTouched(s => ({ ...s, url_template: true }))
      onChangeWrappingNegation({
        ...(leafCondition as HTTPCondition),
        url_template: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setHttpPath = useCallback(
    e => {
      setTouched(s => ({ ...s, response_value_path: true }))
      onChangeWrappingNegation({
        ...(leafCondition as HTTPCondition),
        response_value_path: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setStorageType = useCallback<(option: StorageTypeOption | null) => void>(
    option => {
      const newType = option?.value ?? undefined
      const { subject, operator } = leafCondition as TypedCondition
      const newOperator = isOperatorAllowed(operator, subject, newType) ? operator : null
      const restObj = isCookieCondition(leafCondition)
        ? { name: leafCondition.name }
        : isLSCondition(leafCondition)
        ? { key: leafCondition.key }
        : isGTMCondition(leafCondition)
        ? { dl_name: leafCondition.dl_name, dl_key: leafCondition.dl_key }
        : isHTTPCondition(leafCondition)
        ? {
            url_template: leafCondition.url_template,
            response_value_path: leafCondition.response_value_path,
          }
        : {}

      return onChangeWrappingNegation(
        getConditionWithNullValues({
          subject,
          operator: newOperator,
          type: newType,
          ...restObj,
        } as LeafCondition),
      )
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setOperator = useCallback<(option: OperatorDropdownOption) => void>(
    ({ value: newOperator, bool_value }) => {
      setTouched(s => ({ ...s, operator: true }))

      if (bool_value !== undefined) {
        return onChangeWrappingNegation({
          ...leafCondition,
          operator: "equals",
          value: bool_value,
        } as LeafCondition)
      }

      let newCondition = { ...leafCondition, operator: newOperator } as LeafCondition

      // remove values if needed
      if (!areOperatorsSameKind(leafCondition.operator, newOperator)) {
        newCondition = getConditionWithNullValues(newCondition)
      }

      return onChangeWrappingNegation(newCondition)
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const subjectOption = getSubjectOption(leafCondition.subject)
  const type = isTypedCondition(leafCondition) ? leafCondition.type : undefined
  const operatorOptions = getOperatorOptions(leafCondition.subject, type)
  const operatorOption =
    operatorOptions.find(({ value, bool_value }) => {
      if (isTypedCondition(leafCondition) && leafCondition.type === "boolean") {
        if (bool_value === undefined) return false
        return bool_value === leafCondition.value
      }
      return value === leafCondition.operator
    }) ?? null

  const [touched, setTouched] = useState(touchedInitialState)

  const subjectSelect = (
    <SelectField
      meta={{ touched: touched.subject, error: error?.subject }}
      label="Condition"
      input={{ value: subjectOption, onChange: setSubject }}
      placeholder="Type to search…"
      options={subjectOptions}
      className={classNames(styles.subjectSelect, styles.select, {
        [styles.readOnly]: !isEditable,
      })}
      disabled={!isEditable}
    />
  )

  const operatorSelect = (
    <SelectField
      meta={{ touched: touched.operator, error: error?.operator }}
      label="Operator"
      input={{ value: operatorOption, onChange: setOperator }}
      placeholder="Select…"
      options={operatorOptions}
      disabled={subjectOption === null || !isEditable}
      className={classNames(styles.operatorSelect, styles.select, {
        [styles.readOnly]: !isEditable,
      })}
      // @ts-ignore
      isOptionSelected={() => false}
    />
  )

  return (
    <div className={classNames(styles.container, { [styles.hasError]: error })}>
      {dragListeners && (
        <DelayedTooltip content="Move condition">
          <div className={styles.dragHandle} {...dragListeners}>
            <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
          </div>
        </DelayedTooltip>
      )}
      <div className={styles.symbol} style={getSymbolStyle(symbol.color)}>
        {symbol.text}
      </div>
      {isTypedCondition(leafCondition) ? (
        <div className={styles.typedCond}>
          <div className={styles.typedCondRow}>
            {subjectSelect}
            {isCookieCondition(leafCondition) && (
              <TextField
                error={(touched.name && error?.name) || undefined}
                label="Cookie name"
                value={leafCondition.name ?? ""}
                onChange={setCookieName}
                disabled={!isEditable}
                className={styles.keyInput}
                fullWidth
              />
            )}
            {isLSCondition(leafCondition) && (
              <TextField
                error={(touched.key && error?.key) || undefined}
                label="Local storage key"
                value={leafCondition.key ?? ""}
                onChange={setLSKey}
                disabled={!isEditable}
                className={styles.keyInput}
                fullWidth
              />
            )}
            {isGTMCondition(leafCondition) && (
              <>
                <TextField
                  error={(touched.dl_name && error?.dl_name) || undefined}
                  label="Name of GTM DL object"
                  value={leafCondition.dl_name ?? ""}
                  onChange={setGTMName}
                  disabled={!isEditable}
                  className={styles.keyInput}
                  fullWidth
                />
                <TextField
                  error={(touched.dl_key && error?.dl_key) || undefined}
                  label="GTM DL key"
                  value={leafCondition.dl_key ?? ""}
                  onChange={setGTMKey}
                  disabled={!isEditable}
                  className={styles.keyInput}
                  fullWidth
                />
              </>
            )}
            {isHTTPCondition(leafCondition) && (
              <>
                <TextField
                  error={(touched.url_template && error?.url_template) || undefined}
                  label="URL Template"
                  className={styles.valueInput}
                  type="text"
                  value={leafCondition.url_template || ""}
                  onChange={setHttpUrl}
                  disabled={!isEditable}
                  fullWidth
                />
                <div className={styles.tooltipWrapper}>
                  <InfoTooltip placement="left" className={styles.info}>
                    <p>
                      Use placeholder in the format <strong>{"{{cookie:some_cookie_name}}"}</strong>{" "}
                      or <strong>{"{{ls:some_storage_key}}"}</strong> to insert value from the
                      website's cookies or local storage in the URL before making the request.
                    </p>
                  </InfoTooltip>
                </div>
              </>
            )}
            <SelectField
              label="Data type"
              input={{
                value: getStorageTypeOption((leafCondition as TypedCondition).type),
                onChange: setStorageType,
              }}
              placeholder="Select…"
              options={storageTypeOptions}
              disabled={!isEditable}
              className={classNames(styles.typeSelect, styles.select, {
                [styles.readOnly]: !isEditable,
              })}
              isClearable
            />
            {operatorSelect}
          </div>
          {leafCondition.operator && leafCondition.operator !== "is_ok" && (
            <div className={styles.typedCondRow}>
              {isHTTPCondition(leafCondition) && (
                <TextField
                  error={(touched.response_value_path && error?.response_value_path) || undefined}
                  label="Path in response body"
                  className={styles.pathInput}
                  type="text"
                  value={leafCondition.response_value_path || ""}
                  onChange={setHttpPath}
                  disabled={!isEditable}
                  fullWidth
                />
              )}
              <ConditionValue
                condition={leafCondition}
                onChange={onChangeWrappingNegation}
                isEditable={isEditable}
                error={error}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.simpleCondInputs}>
          {subjectSelect}
          {leafCondition.subject && operatorSelect}
          <ConditionValue
            condition={leafCondition}
            onChange={onChangeWrappingNegation}
            isEditable={isEditable}
            error={error}
          />
        </div>
      )}
      {removeSelf && isEditable && (
        <IconButton
          className={styles.deleteButton}
          onClick={removeSelf}
          iconName="trash-alt"
          color={COLOR.RED}
          tooltip="Delete"
          withBackground
          size={SIZE.TAG}
        />
      )}
    </div>
  )
}
