import { createSelector } from "reselect"
import { List, Map, Record, OrderedMap } from "immutable"

import _toLower from "lodash/toLower"

import { Tag } from "resources/tag/tagReducer"

// attribute record for usage in custom UI picker
const PickerAttribute = Record({
  name: "",
  id: "",
  data_type: "",
  created: "",
  attribute_source: Map(),
  is_hidden: 0,
  examples: List(),
  tags: List(),
})

const createPickerAttribute = attribute => {
  return new PickerAttribute({
    id: attribute.id,
    name: attribute.name,
    data_type: attribute.data_type,
    created: attribute.created,
    attribute_source: attribute.get("source", Map()),
    is_hidden: attribute.is_hidden,
    examples: attribute.examples,
    tags: attribute.tags,
  })
}

export const getAttributesData = (state, showHidden = false) => {
  if (showHidden) {
    return state.attributes.get("data")
  } else {
    return state.attributes.get("data").filterNot(attribute => attribute.is_hidden)
  }
}

export const areAttributesFetching = state => state.attributes.get("isFetching")
export const areAttributesFulfilled = state => state.attributes.get("isFulfilled")

export const getAttributesMappedBySourceId = createSelector(getAttributesData, attributes => {
  return Map().withMutations(map => {
    attributes.forEach(attr => {
      if (map.has(attr.getIn(["source", "id"]))) {
        map.set(attr.getIn(["source", "id"]), map.get(attr.getIn(["source", "id"])).push(attr))
      } else {
        map.set(attr.getIn(["source", "id"]), List([attr]))
      }
    })
  })
})

export const getAttributeById = (state, id) =>
  state.attributes.get("data").find(attr => attr.id === id)

export const getAttributesMapBySourceIdSortedByOrderIndex = createSelector(
  getAttributesData,
  attributes => {
    const tmpAttributes = attributes.sort((a, b) => {
      if (a.order_index < b.order_index) {
        return -1
      } else if (a.order_index > b.order_index) {
        return 1
      } else {
        return _toLower(a.name).localeCompare(_toLower(b.name))
      }
    })
    return Map().withMutations(map => {
      tmpAttributes.forEach(attr => {
        if (map.has(attr.getIn(["source", "id"]))) {
          map.set(attr.getIn(["source", "id"]), map.get(attr.getIn(["source", "id"])).push(attr))
        } else {
          map.set(attr.getIn(["source", "id"]), List([attr]))
        }
      })
    })
  },
)

export const getAttributesMapByIdForSelect = createSelector(getAttributesData, attributes => {
  const tmpAttributes = attributes.sort((a, b) => {
    return _toLower(a.name).localeCompare(_toLower(b.name))
  })
  return tmpAttributes.reduce(
    (map, attr) => map.set(attr.id, createPickerAttribute(attr)),
    OrderedMap(),
  )
})

export const getAttributesMapById = createSelector(getAttributesData, attributes => {
  return attributes.reduce((map, attr) => map.set(attr.id, attr), Map())
})

export const getAttributesMapBySourceName = createSelector(getAttributesData, attributes => {
  return Map().withMutations(map => {
    attributes.forEach(attr => {
      const sourceName = attr.getIn(["source", "name"])
      if (map.has(sourceName)) {
        // attributes Map already exists, push
        map.set(sourceName, map.get(sourceName).push(createPickerAttribute(attr)))
      } else {
        map.set(sourceName, List([createPickerAttribute(attr)]))
      }
    })
  })
})

export const getAttributesTagsList = createSelector(getAttributesData, attributes =>
  Map()
    .withMutations(map => {
      attributes.forEach(attr => {
        if (List.isList(attr.tags)) {
          attr.tags.forEach(tag => map.set(tag.get("id"), Tag(tag)))
        }
      })
    })
    .toList()
    .sortBy(tag => _toLower(tag.name)),
)

export const getStitchingAttributesList = createSelector(getAttributesData, attributes => {
  return attributes.filter(attribute => attribute.is_used_in_stitching)
})

export const attributesCountSelector = createSelector(
  [areAttributesFulfilled, getAttributesMapById],
  (areAttributesFulfilled, attributes) => (areAttributesFulfilled ? attributes.size : null),
)
