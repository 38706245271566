import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  fetchNotifications,
  markNotificationsAsRead,
} from "resources/notification/notificationActions"
import classNames from "classnames"
import NotificationCard from "components/UI/components/Notification/Notification"
import Avatar from "components/UI/elements/Avatar"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import { hasAccess } from "helpers/authenticatedUser.helper"
import { abbreviateNumber } from "helpers/number.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import useTypedSelector from "hooks/useTypedSelector"
import { partition } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import { attributesCountSelector } from "selectors/attributes.selector"
import { sourcesCountSelector } from "resources/dataSource/dataSourceSelectors"
import getDashboardData from "./getDashboardData"
import TimeAgo from "react-timeago"
import moment from "moment"
import timeAgoFormatter from "./timeAgoFormatter"
import Duration from "components/UI/elements/Duration"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import styles from "./HomePage.module.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { Awaited } from "types/util"

type DashboardData = Awaited<ReturnType<typeof getDashboardData>>

export default function HomePage() {
  const { notifications, hasNextPage, isMarkingAsRead, oldestId } = useTypedSelector(
    state => state.notifications,
  )
  const { data: user } = useTypedSelector(state => state.authenticatedUser)
  const { name, email } = user!
  const sourcesCount = useSelector(sourcesCountSelector)
  const attributesCount = useSelector(attributesCountSelector)

  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dashboardData, setDashboardData] = useState<Partial<DashboardData>>({})

  const { customerCounts, customerAttributesValuesCount, totalEvents, cacheStatus } = dashboardData

  const promiseHandler = useRef(new PendingPromise())

  useEffect(() => {
    if (hasAccess.data.dashboard()) {
      const dashboardDataPromise = promiseHandler.current.create(getDashboardData())
        .promise as Promise<DashboardData>

      dashboardDataPromise
        .then(setDashboardData)
        .catch(error => {
          // noop
        })
        .finally(() => {
          setIsDataLoading(false)
          promiseHandler.current.remove(dashboardDataPromise)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => promiseHandler.current.cancelAll()
  }, [])

  const dispatch = useDispatch()

  const markAllAsRead = useCallback(() => dispatch(markNotificationsAsRead()), [dispatch])

  const fetchMore = useCallback(() => {
    if (hasNextPage) dispatch(fetchNotifications({ untilId: oldestId }))
  }, [dispatch, hasNextPage, oldestId])

  const [readNotifs, unreadNotifs] = partition(notifications, notif => notif.read)
  const noOfScrolledElements =
    +(unreadNotifs.length > 0) + +(readNotifs.length > 0) + notifications.length

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {hasAccess.data.dashboard() && (
          <div className={styles.dashboardSection}>
            <Paper className={styles.dashboardHeaderCard}>
              <div className={styles.left}>
                <Avatar name={name} email={email} className={styles.avatar} />
                <div>
                  <div>Welcome</div>
                  <div className={styles.name}>{name}!</div>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.label}>latest data update</div>

                {isDataLoading ? (
                  <LoadingIndicator className={styles.spinner} />
                ) : cacheStatus?.refresh_init_time ? (
                  <>
                    <div className={styles.refreshLabel}>Refresh is in progress</div>
                    <div className={styles.refreshValue}>
                      <Duration datetime={cacheStatus?.refresh_init_time} units={["d", "h", "m"]} />
                    </div>
                  </>
                ) : cacheStatus?.init_time ? (
                  <TimeAgo
                    date={moment.utc(cacheStatus.init_time).toISOString()}
                    formatter={timeAgoFormatter}
                  />
                ) : (
                  <div className={styles.mainValue}>N/A</div>
                )}
              </div>
            </Paper>
            <Paper className={styles.dashboardCard}>
              <div className={styles.left}>
                <div className={styles.label}>
                  total number of customers{" "}
                  <InfoTooltip placement="right" interactive>
                    Number of customer entities before and after{" "}
                    <a
                      href="https://docs.meiro.io/books/meiro-knowledge-base/page/identity-stitching-and-how-customer-identity-appears"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      profile stitching
                    </a>
                    .
                  </InfoTooltip>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.mainValue}>
                  {isDataLoading ? (
                    <LoadingIndicator className={styles.spinner} />
                  ) : customerCounts?.customer_entities_count ? (
                    abbreviateNumber(customerCounts.customer_entities_count)
                  ) : (
                    "N/A"
                  )}
                </div>
                {!isDataLoading && (
                  <div className={styles.beforeStitching}>
                    <span className={styles.uppercase}>
                      {customerCounts?.customers_before_stitching_count
                        ? abbreviateNumber(customerCounts.customers_before_stitching_count)
                        : "N/A"}
                    </span>{" "}
                    before stitching
                  </div>
                )}
              </div>
            </Paper>
            <div className={styles.row}>
              <Paper className={styles.dashboardCard}>
                <div className={styles.left}>
                  <div className={styles.label}>all connected sources</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.mainValue}>
                    {isDataLoading ? (
                      <LoadingIndicator className={styles.spinner} />
                    ) : (
                      sourcesCount ?? "N/A"
                    )}
                  </div>
                </div>
              </Paper>
              <Paper className={styles.dashboardCard}>
                <div className={styles.left}>
                  <div className={styles.label}>total number of customer attributes</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.mainValue}>
                    {isDataLoading ? (
                      <LoadingIndicator className={styles.spinner} />
                    ) : (
                      attributesCount ?? "N/A"
                    )}
                  </div>
                </div>
              </Paper>
            </div>
            <div className={styles.row}>
              <Paper className={styles.dashboardCard}>
                <div className={styles.left}>
                  <div className={styles.label}>total number of customer attributes values</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.mainValue}>
                    {isDataLoading ? (
                      <LoadingIndicator className={styles.spinner} />
                    ) : customerAttributesValuesCount ? (
                      abbreviateNumber(customerAttributesValuesCount)
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              </Paper>
              <Paper className={styles.dashboardCard}>
                <div className={styles.left}>
                  <div className={styles.label}>total number of events</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.mainValue}>
                    {isDataLoading ? (
                      <LoadingIndicator className={styles.spinner} />
                    ) : totalEvents ? (
                      abbreviateNumber(totalEvents)
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              </Paper>
            </div>
            <Link className={styles.linkToAnalytics} to={getRoutePath("data.analytics")}>
              <FontAwesomeIcon icon={["fas", "tachometer"]} className={styles.icon} />
              go to diagnostic dashboard
            </Link>
          </div>
        )}
        <div className={styles.notificationsSection}>
          <PaperHeader
            titleText="Notifications"
            size="small"
            className={styles.notificationsHeader}
          >
            <InfoTooltip className={styles.notificationsTooltip} placement="right">
              <p>
                You will get notifications whenever your colleagues make changes to{" "}
                <strong>attributes</strong>, <strong>events</strong>, or{" "}
                <strong>segments you collaborate on</strong>.
              </p>
              <p>Notifications for other features will be coming soon.</p>
            </InfoTooltip>
          </PaperHeader>
          <Paper hasHeader>
            {unreadNotifs.length + readNotifs.length === 0 ? (
              <div className={styles.emptyMessage}>There are no notifications.</div>
            ) : (
              <InfiniteScroll
                dataLength={noOfScrolledElements}
                next={fetchMore}
                hasMore={hasNextPage}
                scrollThreshold="100px"
                hasChildren={noOfScrolledElements > 0}
                loader={
                  <div className={styles.loadingMore}>
                    <LoadingIndicator className={styles.spinner} />
                  </div>
                }
              >
                {unreadNotifs.length > 0 && (
                  <>
                    <div className={styles.header}>
                      <div className={styles.sectionHeader}>
                        {unreadNotifs.length}
                        {readNotifs.length === 0 && hasNextPage && "+"} unread
                      </div>
                      <button
                        onClick={markAllAsRead}
                        className={classNames(styles.markAsReadButton, {
                          [styles.isLoading]: isMarkingAsRead,
                        })}
                      >
                        <div className={styles.text}>mark all as read</div>
                        <div className={styles.spinnerWrapper}>
                          <LoadingIndicator className={styles.smallSpinner} />
                        </div>
                      </button>
                    </div>
                    <div>
                      {unreadNotifs.map(notif => (
                        <NotificationCard notification={notif} key={notif.id} />
                      ))}
                    </div>
                  </>
                )}
                {readNotifs.length > 0 && (
                  <>
                    <div className={styles.header}>
                      <div className={styles.sectionHeader}>read</div>
                    </div>
                    <div>
                      {readNotifs.map(notif => (
                        <NotificationCard notification={notif} key={notif.id} />
                      ))}
                    </div>
                  </>
                )}
              </InfiniteScroll>
            )}
          </Paper>
        </div>
      </div>
    </div>
  )
}
