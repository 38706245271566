import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import useTypedSelector from "hooks/useTypedSelector"
import { showToast } from "actions/toast.action"
import {
  deleteEmbeddedWebBanner,
  modifyEmbeddedWebBanner,
  retrieveEmbeddedWebBanner,
} from "resources/webBanner/embeddedWebBanner/embeddedWBActions"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerRequestPayload,
} from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import EmbeddedWebBannersForm, {
  PRIORITY_OPTIONS,
  EmbeddedWebBannersFormData,
  EmbeddedWebBannersFormValues,
} from "../components/EmbeddedWebBannersForm/EmbeddedWebBannersForm"
import _isEmpty from "lodash/isEmpty"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { getImgSrcFromHtml } from "resources/webBanner/embeddedWebBanner/getImgSrcFromHtml"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

export default function EmbeddedWebBannersDetail() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: EmbeddedWebBanner["id"] }>()
  const webBanner = useTypedSelector(state =>
    state.embeddedWebBanners.webBanners.find(banner => banner.id === id),
  )

  useEffect(() => {
    if (!webBanner && !isDeleting) {
      dispatch(retrieveEmbeddedWebBanner(id))
    }
  }, [dispatch, id, webBanner, isDeleting])

  const editWebBanner = useCallback(
    async ({
      name,
      element_id,
      condition,
      priority,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      disabled,
    }: EmbeddedWebBannersFormData) => {
      // destination_url needs to be present in image contents, otherwise it will be nullified :(
      const content: EmbeddedWebBannerRequestPayload["settings"]["content"] = image_local
        ? {
            data: image_local,
            image_name: image_name!,
            type: "local_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : image_remote
        ? {
            image_url: image_remote,
            type: "remote_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : destination_url !== null
        ? {
            destination_url: destination_url === "" ? null : destination_url,
            type: webBanner?.settings.content.type,
          }
        : html
        ? { html, type: "html" }
        : {}
      const data: EmbeddedWebBannerRequestPayload = {
        name,
        element_id,
        settings: {
          condition,
          priority,
          fe_settings: { width },
        },
        disabled,
      }
      if (content && !_isEmpty(content)) {
        // needs to be added optionally only when something changes, otherwise it removes
        // image from the storage ...
        data.settings.content = content
      }
      await dispatch(modifyEmbeddedWebBanner(id, data))
      history.push(getRoutePath("personalization.embedded-web-banners"))
      dispatch(showToast("Web Banner modified."))
    },
    [dispatch, id, history, webBanner],
  )

  const deleteBanner = useCallback(async () => {
    setIsDeleting(true)
    try {
      await dispatch(deleteEmbeddedWebBanner(id))
      history.push(getRoutePath("personalization.embedded-web-banners"))
      dispatch(showToast("Web Banner has been deleted."))
    } catch (err) {
      setIsDeleting(false)
    }
  }, [dispatch, history, id])

  const html = webBanner?.settings.content.type !== "html" ? "" : webBanner?.settings.content.html

  const initialValues = webBanner
    ? ({
        element_id: webBanner.element_id,
        name: webBanner.name,
        condition: webBanner.settings.condition,
        priority: PRIORITY_OPTIONS.find(prio => prio.value === webBanner.settings.priority),
        destination_url: webBanner.settings.content.destination_url,
        content_type: webBanner.settings.content.type,
        html,
        image_url: getImgSrcFromHtml(webBanner),
        width: webBanner.settings.fe_settings?.width,
        disabled: webBanner.disabled,
      } as EmbeddedWebBannersFormValues)
    : undefined

  if (!webBanner) return <LoadingIndicator />
  return (
    <>
      <EmbeddedWebBannersForm
        key={id}
        onDelete={() => {
          setDeleteModalOpen(true)
        }}
        initialValues={initialValues}
        onSubmit={editWebBanner}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete web banner?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={deleteBanner}
      />
    </>
  )
}
