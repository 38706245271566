import requestFactory from "api/request"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerListResponse,
  EmbeddedWebBannerModifyPayload,
} from "./embeddedWBTypes"

const embeddedWebBanner = {
  list(offset = 0, limit = 50): Promise<EmbeddedWebBannerListResponse> {
    return requestFactory("get", "/embedded_web_banners", { offset, limit })
  },
  retrieve(id: EmbeddedWebBanner["id"]): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("get", `/embedded_web_banners/${id}`)
  },
  create(data: EmbeddedWebBannerCreatePayload): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("post", "/embedded_web_banners", data)
  },
  copy(id: EmbeddedWebBanner["id"]): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("post", `/embedded_web_banners/${id}`)
  },
  modify(
    id: EmbeddedWebBanner["id"],
    data: EmbeddedWebBannerModifyPayload,
  ): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("patch", `/embedded_web_banners/${id}`, data)
  },
  delete(id: EmbeddedWebBanner["id"]): Promise<void> {
    return requestFactory("delete", `/embedded_web_banners/${id}`)
  },
}

export default embeddedWebBanner
