import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { Dispatch } from "redux"
import { OrderDir } from "types/util"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerModifyPayload,
  EmbeddedWebBannerSort,
} from "./embeddedWBTypes"

export const EMBEDDED_WEB_BANNERS = {
  ACTION: {
    FETCH_ALL: "EMBEDDED_WEB_BANNERS_FETCH_ALL",
    RETRIEVE: "EMBEDDED_WEB_BANNER_RETRIEVE",
    CREATE: "EMBEDDED_WEB_BANNER_CREATE",
    COPY: "EMBEDDED_WEB_BANNER_COPY",
    MODIFY: "EMBEDDED_WEB_BANNER_MODIFY",
    DELETE: "EMBEDDED_WEB_BANNER_DELETE",
    FETCH_SETTINGS: "EMBEDDED_WEB_BANNERS_FETCH_SETTINGS",
    MODIFY_SETTINGS: "EMBEDDED_WEB_BANNERS_MODIFY_SETTINGS",
    EMBEDDED_WEB_BANNER_SORT: "EMBEDDED_WEB_BANNER_SORT",
  },
} as const

export const fetchAllEmbeddedWebBanners = () => async (dispatch: Dispatch) => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: EMBEDDED_WEB_BANNERS.ACTION.FETCH_ALL,
    payload: {
      promise: caller
        .setEndpointCall((offset: number, limit: number) =>
          api.embeddedWebBanner.list(offset, limit),
        )
        .setDataPath("web_banners")
        .run(),
    },
  })
}

export const retrieveEmbeddedWebBanner =
  (id: EmbeddedWebBanner["id"]) => async (dispatch: Dispatch) => {
    return dispatch({
      type: EMBEDDED_WEB_BANNERS.ACTION.RETRIEVE,
      payload: {
        promise: api.embeddedWebBanner.retrieve(id),
      },
    })
  }

export const createEmbeddedWebBanner =
  (data: EmbeddedWebBannerCreatePayload) => async (dispatch: Dispatch) => {
    return dispatch({
      type: EMBEDDED_WEB_BANNERS.ACTION.CREATE,
      payload: {
        promise: api.embeddedWebBanner.create(data),
      },
    })
  }

export const copyEmbeddedWebBanner =
  (id: EmbeddedWebBanner["id"]) => async (dispatch: Dispatch) => {
    return dispatch({
      type: EMBEDDED_WEB_BANNERS.ACTION.COPY,
      payload: {
        promise: api.embeddedWebBanner.copy(id),
      },
    })
  }

export const modifyEmbeddedWebBanner =
  (id: EmbeddedWebBanner["id"], data: EmbeddedWebBannerModifyPayload) =>
  async (dispatch: Dispatch) => {
    return dispatch({
      type: EMBEDDED_WEB_BANNERS.ACTION.MODIFY,
      payload: {
        promise: api.embeddedWebBanner.modify(id, data),
      },
    })
  }

export const deleteEmbeddedWebBanner =
  (id: EmbeddedWebBanner["id"]) => async (dispatch: Dispatch) => {
    return dispatch({
      type: EMBEDDED_WEB_BANNERS.ACTION.DELETE,
      meta: {
        webBannerId: id,
      },
      payload: {
        promise: api.embeddedWebBanner.delete(id),
      },
    })
  }

export const sortEmbeddedWebBanners =
  (orderBy: EmbeddedWebBannerSort, orderDir: OrderDir) => (dispatch: Dispatch) =>
    dispatch({
      type: sortEmbeddedWebBannerType,
      payload: {
        orderBy,
        orderDir,
      },
    })

export const sortEmbeddedWebBannerType = EMBEDDED_WEB_BANNERS.ACTION.EMBEDDED_WEB_BANNER_SORT
type SortEmbeddedWebBannerListAction = {
  type: typeof sortEmbeddedWebBannerType
  payload: {
    orderBy: EmbeddedWebBannerSort
    orderDir: OrderDir
  }
}

export const deleteEmbeddedWebBannerFulfilledType =
  `${EMBEDDED_WEB_BANNERS.ACTION.DELETE}_FULFILLED` as const
type DeleteEmbeddedWebBannerAction = {
  type: typeof deleteEmbeddedWebBannerFulfilledType
  meta: {
    webBannerId: EmbeddedWebBanner["id"]
  }
  payload: { message: "OK" }
}

export const modifyEmbeddedWebBannerFulfilledType =
  `${EMBEDDED_WEB_BANNERS.ACTION.MODIFY}_FULFILLED` as const
type ModifyEmbeddedWebBannerAction = {
  type: typeof modifyEmbeddedWebBannerFulfilledType
  payload: {
    web_banner: EmbeddedWebBanner
  }
}

export const createEmbeddedWebBannerFulfilledType =
  `${EMBEDDED_WEB_BANNERS.ACTION.CREATE}_FULFILLED` as const
type CreateEmbeddedWebBannerAction = {
  type: typeof createEmbeddedWebBannerFulfilledType
  payload: {
    web_banner: EmbeddedWebBanner
  }
}

export const copyEmbeddedWebBannerFulfilledType =
  `${EMBEDDED_WEB_BANNERS.ACTION.COPY}_FULFILLED` as const
type CopyEmbeddedWebBannerAction = {
  type: typeof copyEmbeddedWebBannerFulfilledType
  payload: {
    web_banner: EmbeddedWebBanner
  }
}

export const retrieveEmbeddedWebBannerFulfilledType =
  `${EMBEDDED_WEB_BANNERS.ACTION.RETRIEVE}_FULFILLED` as const
type RetrieveEmbeddedWebBannerAction = {
  type: typeof retrieveEmbeddedWebBannerFulfilledType
  payload: {
    web_banner: EmbeddedWebBanner
  }
}

export const fetchAllEmbeddedWebBannersFulfilledType =
  `${EMBEDDED_WEB_BANNERS.ACTION.FETCH_ALL}_FULFILLED` as const
type FetchAllEmbeddedWebBannersAction = {
  type: typeof fetchAllEmbeddedWebBannersFulfilledType
  payload: EmbeddedWebBanner[]
}

export type EmbeddedWebBannersAction =
  | FetchAllEmbeddedWebBannersAction
  | CreateEmbeddedWebBannerAction
  | ModifyEmbeddedWebBannerAction
  | DeleteEmbeddedWebBannerAction
  | RetrieveEmbeddedWebBannerAction
  | CopyEmbeddedWebBannerAction
  | SortEmbeddedWebBannerListAction
