import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import { Record } from "immutable"
import PropTypes from "prop-types"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm from "../../AttributeForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { connect } from "react-redux"
import { areAttributesFulfilled, getAttributeById } from "selectors/attributes.selector"
import { modifyAttribute } from "actions/attribute.action"
import { showToast } from "actions/toast.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getInitialAttributeFormValues } from "../../AttributeForm/attributeInitialValues"

import "./AttributeDetail.scss"
import { getEventsIsFulfilled } from "selectors/event.selector"
import { isDataSourcesFulfilled } from "resources/dataSource/dataSourceSelectors"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

class AttributeDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
    }
  }

  updateAttribute = async data => {
    if (!this.state.updating) {
      this.setState({ updating: true })
      const { history, modifyAttribute, attribute, showToast } = this.props
      try {
        await modifyAttribute(attribute.id, data)
        showToast("Attribute edited.")
        history.push(getRoutePath("setup.attributes"))
      } catch {
        this.setState({ updating: false })
      }
    }
  }

  toggleHiddenAttribute = async () => {
    const { attribute, modifyAttribute, showToast } = this.props
    await modifyAttribute(attribute.id, { is_hidden: attribute.is_hidden === 1 ? 0 : 1 })
    showToast(`Attribute ${attribute.is_hidden ? "visible" : "hidden"}.`)
  }

  cloneAttribute = () => {
    const { history, attribute } = this.props
    history.push({
      pathname: getRoutePath("setup.attributes.create"),
      state: { attribute },
    })
  }

  render() {
    const { history, areAttributesFulfilled, attribute, areEventsFulfilled, areSourcesFulfilled } =
      this.props
    const { updating } = this.state

    const formInitValues = getInitialAttributeFormValues(attribute)

    return (
      <section className="detail-attribute wrapper">
        <PaperHeader size="small" titleText="Edit attribute" className="detail-attribute-header">
          <div className="header-buttons">
            {attribute && (
              <React.Fragment>
                <Button size="small" color="white-red" onClick={this.toggleHiddenAttribute}>
                  {attribute.is_hidden === 1 && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={["far", "eye"]} className="icon" /> Show
                    </React.Fragment>
                  )}
                  {attribute.is_hidden === 0 && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={["far", "eye-slash"]} className="icon" /> Hide
                    </React.Fragment>
                  )}
                </Button>
                <Button size="small" color="white" onClick={this.cloneAttribute}>
                  <FontAwesomeIcon icon={["fas", "clone"]} className="icon" /> Copy
                </Button>
              </React.Fragment>
            )}
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.attributes"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={updating} formName="AttributeForm" />
          </div>
        </PaperHeader>
        {areAttributesFulfilled && areEventsFulfilled && areSourcesFulfilled ? (
          <Paper hasHeader className="detail-attribute-content">
            <AttributeForm
              onSubmit={this.updateAttribute}
              initialValues={formInitValues}
              disabledFields={["id", "source_id", "is_unique", "data_type"]}
            />
          </Paper>
        ) : (
          <LoadingIndicator />
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  attribute: getAttributeById(state, ownProps.match.params.id),
  areAttributesFulfilled: areAttributesFulfilled(state),
  areEventsFulfilled: getEventsIsFulfilled(state),
  areSourcesFulfilled: isDataSourcesFulfilled(state),
})

AttributeDetail.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  source: PropTypes.instanceOf(Record),
  modifyAttribute: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { modifyAttribute, showToast })(AttributeDetail)
