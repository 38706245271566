import React, { useCallback, useContext, useEffect, useState } from "react"
import styles from "./SegmentCustomersPreview.module.scss"
import { hasAccess } from "helpers/authenticatedUser.helper"
import { useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { getSegmentCustomers } from "resources/segment/segmentCustomers/segmentCustomersSelectors"
import { SocketContext } from "context/socket"
import {
  dispatchSegmentCustomersError,
  initSegmentCustomers,
  storeSegmentCustomers,
} from "resources/segment/segmentCustomers/segmentCustomersActions"
import { SEGMENT, TOAST } from "sharedConstants"
import { showToast } from "actions/toast.action"
import { hideLongLoadingBar } from "actions/longLoadingBar.action"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { setCustomersIterator } from "actions/customersIterator.action"

type SegmentCustomersPreviewProps = {
  hasUnsavedConditions: boolean
  customersListOffset: number
  setCustomersListOffset: (offset: number | ((offset: number) => number)) => void
  isOpen: boolean
}

type SegmentCustomers =
  | {
      data?: { id: string }[]
      error?: boolean
      selectionSettings?: { offset: number }
    }
  | undefined

const MAX_OFFSET = 300

export default function SegmentCustomersPreview({
  hasUnsavedConditions,
  customersListOffset,
  setCustomersListOffset,
  isOpen,
}: SegmentCustomersPreviewProps) {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)

  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false)
  const segmentCustomers: SegmentCustomers = useSelector(state =>
    getSegmentCustomers(state, id),
  ).toJS()
  const canSeeCustomers = hasAccess.customers.detail()

  useEffect(() => {
    if (!canSeeCustomers) {
      return
    }

    socket.on("segment_customer_entities_response", (msg: any) => {
      setIsLoadingCustomers(false)

      if (msg.error) {
        dispatch(dispatchSegmentCustomersError(id))
        dispatch(showToast(msg.error, TOAST.TYPE.ERROR))
        dispatch(hideLongLoadingBar())
      } else {
        dispatch(storeSegmentCustomers(id, msg))
      }
    })

    setIsLoadingCustomers(true)

    dispatch(initSegmentCustomers(id))
    socket.emit("segment_customer_entities", {
      segment_id: id,
      offset: 0,
      limit: SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
    })

    return () => {
      socket.off("segment_customer_entities_response")
    }
  }, [dispatch, id, socket, canSeeCustomers])

  const loadMoreCustomers = useCallback(async () => {
    setIsLoadingCustomers(true)
    dispatch(initSegmentCustomers(+id))
    socket.emit("segment_customer_entities", {
      segment_id: id,
      offset: segmentCustomers!.selectionSettings!.offset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
      limit: SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
    })
  }, [dispatch, id, segmentCustomers, socket])

  const goToPrevPage = useCallback(() => {
    setCustomersListOffset(s => s - SEGMENT.CUSTOMER.ITEMS_PER_PAGE)
  }, [setCustomersListOffset])

  const goToNextPage = useCallback(() => {
    if (
      segmentCustomers!.data!.length <= customersListOffset + 2 * SEGMENT.CUSTOMER.ITEMS_PER_PAGE &&
      customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE <= MAX_OFFSET
    ) {
      loadMoreCustomers()
    }

    setCustomersListOffset(s => s + SEGMENT.CUSTOMER.ITEMS_PER_PAGE)
  }, [customersListOffset, loadMoreCustomers, segmentCustomers, setCustomersListOffset])

  if (!isOpen) {
    return null
  }

  return (
    <div className={styles.container}>
      {!hasAccess.customers.detail() ? (
        <div className={styles.message}>You don't have permission to view customers.</div>
      ) : hasUnsavedConditions ? (
        <div className={styles.message}>Save segment conditions to see the customers preview.</div>
      ) : isLoadingCustomers ? (
        <LoadingIndicator />
      ) : segmentCustomers?.error ? (
        <div className={styles.message}>Error while loading.</div>
      ) : segmentCustomers?.data?.length === 0 ? (
        <div className={styles.message}>No customers found.</div>
      ) : (
        <>
          {segmentCustomers?.data
            ?.slice(customersListOffset, customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE)
            .map((customer, index) => (
              <div className={styles.customerRow} key={customer.id}>
                <Link
                  className={styles.customerLink}
                  onClick={() => {
                    // @ts-ignore
                    dispatch(setCustomersIterator(index + customersListOffset, id))
                  }}
                  to={{
                    pathname: getRoutePath("customers.detail", { id: customer.id }),
                    state: { previous: true },
                  }}
                >
                  {customer.id}
                </Link>
              </div>
            ))}
          <div className={styles.customersListFooter}>
            <Button color="white" disabled={customersListOffset === 0} onClick={goToPrevPage}>
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </Button>
            <div className={styles.pageNumber}>
              Page {customersListOffset / SEGMENT.CUSTOMER.ITEMS_PER_PAGE + 1}
            </div>
            <Button
              color="white"
              disabled={
                (segmentCustomers?.data?.length ?? 0) <
                  customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE ||
                customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE > MAX_OFFSET
              }
              onClick={goToNextPage}
            >
              <FontAwesomeIcon icon={["fas", "chevron-right"]} />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
