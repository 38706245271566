import { LONG_LOADING_BAR } from "sharedConstants"
import { api } from "api"

export const showLongLoadingBar = loadingThing => dispatch =>
  dispatch({
    type: LONG_LOADING_BAR.ACTION.SHOW,
    payload: loadingThing,
  })

export const hideLongLoadingBar = (loadingThing = "all") => dispatch =>
  dispatch({
    type: LONG_LOADING_BAR.ACTION.HIDE,
    payload: loadingThing,
  })

export const fetchCustomersPerAttributeCounts = () => async dispatch =>
  dispatch({
    type: LONG_LOADING_BAR.ACTION.LIST_CUSTOMERS_PER_ATTRIBUTE_COUNTS,
    payload: {
      promise: (async () => {
        return await api.customersPerAttributeCounts()
      })(),
    },
  })

export const fetchTotalCustomersCount = () => async dispatch =>
  dispatch({
    type: LONG_LOADING_BAR.ACTION.FETCH_TOTAL_CUSTOMERS_COUNT,
    payload: {
      promise: (async () => {
        return await api.customersCount()
      })(),
    },
  })
