import PaperHeader from "components/UI/elements/PaperHeader"
import TextField from "components/UI/elements/TextField/HookFormTextField"
import {
  validateUrl,
  required,
  validImageFile,
  validateDomElementId,
} from "helpers/validators.helper"
import { SubmitHandler, useForm, Controller, FieldError } from "react-hook-form"
import {
  EmbeddedWebBanner,
  EWBContentType,
} from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import styles from "./EmbeddedWebBannersForm.module.scss"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import React, { ChangeEvent, useCallback, useState } from "react"
import { hasAccess } from "helpers/authenticatedUser.helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Prompt, useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import classnames from "classnames"
import _forEach from "lodash/forEach"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-tomorrow"
import ToggleButton from "components/UI/elements/ToggleButton"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { WBCondition } from "resources/webBanner/webBannerConditionTypes"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import Condition, {
  getNewWebBannerCondition,
} from "../../../UI/components/WebBannerCondition/WebBannerCondition"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateWebBannerCondition } from "../../../UI/components/WebBannerCondition/validation"
import classNames from "classnames"
import FileField from "components/UI/elements/FileField/FileField"
import { showToast } from "actions/toast.action"
import { TOAST } from "sharedConstants"
import { useDispatch } from "react-redux"
import { copyEmbeddedWebBanner } from "resources/webBanner/embeddedWebBanner/embeddedWBActions"
import { isEmpty } from "ramda"
import SelectField from "components/UI/elements/SelectField"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import BannerPreview from "./BannerPreview/BannerPreview"

type PriorityOption = { label: string; value: number }
export const PRIORITY_OPTIONS: PriorityOption[] = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
]

export type EmbeddedWebBannersFormValues = {
  name: EmbeddedWebBanner["name"]
  condition: EmbeddedWebBanner["settings"]["condition"] | null
  priority: PriorityOption
  html: EmbeddedWebBanner["settings"]["content"]["html"] | null
  destination_url: EmbeddedWebBanner["settings"]["content"]["destination_url"] | null
  image_url?: string
  image?: FileList
  width: EmbeddedWebBanner["settings"]["fe_settings"]["width"] | null
  disabled: boolean
  content_type?: EWBContentType
  element_id: string
}
export type EmbeddedWebBannersFormData = Omit<
  EmbeddedWebBannersFormValues,
  "image_url" | "image" | "priority"
> & {
  image_local: string | null
  image_name: string | null
  image_remote: string | null
  priority: EmbeddedWebBanner["settings"]["priority"]
}

type EmbeddedWebBannersFormProps = {
  initialValues?: EmbeddedWebBannersFormValues
  onSubmit: (data: EmbeddedWebBannersFormData) => void
  onDelete?: () => void
}

type WebBannerFormType = "create" | "edit"

export type WebBannerType = "image" | "html"

const validateConditionTree = makeConditionTreeValidator(validateWebBannerCondition)

export default function EmbeddedWebBannersForm({
  initialValues,
  onSubmit,
  onDelete,
}: EmbeddedWebBannersFormProps) {
  const dispatch = useDispatch()
  const type: WebBannerFormType = initialValues ? "edit" : "create"
  const history = useHistory()
  const { id } = useParams<{ id: EmbeddedWebBanner["id"] }>()

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm<EmbeddedWebBannersFormValues>({
    defaultValues: initialValues ?? {
      disabled: true,
      priority: PRIORITY_OPTIONS.find(item => item.value === 0)!,
      condition: null,
    },
  })
  const [submitting, setSubmitting] = useState(false)
  const [bannerType, setBannerType] = useState<WebBannerType>(
    !initialValues ||
      (initialValues.content_type &&
        ["local_image", "remote_image"].includes(initialValues.content_type))
      ? "image"
      : "html",
  )
  const [base64BannerImage, setBase64BannerImage] = useState<string | null>(null)
  const [isCopying, setIsCopying] = useState(false)

  const isEditable = hasAccess.webBanners.edit()

  // @ts-ignore
  const imageUrl = watch("image_url")
  const html = watch("html")
  const width = watch("width")

  const submitForm: SubmitHandler<EmbeddedWebBannersFormValues> = useCallback(
    async ({
      name,
      condition,
      priority,
      element_id,
      html,
      image_url,
      image,
      destination_url,
      width,
      disabled,
    }) => {
      if (!submitting) {
        setSubmitting(true)

        if (bannerType === "image" && (!image || !image[0]) && !image_url) {
          dispatch(
            showToast("One of 'Image upload' or 'Image URL' must be filled.", TOAST.TYPE.ERROR),
          )
          setSubmitting(false)
          return
        }

        const sendImagePayloadData =
          bannerType === "image" &&
          (dirtyFields["image"] || dirtyFields["image_url"] || type === "create")

        const values: EmbeddedWebBannersFormData = {
          name,
          condition,
          priority: priority.value,
          image_local:
            sendImagePayloadData && image && image[0] ? base64BannerImage!.split(",")[1] : null,
          image_name: sendImagePayloadData && image && image[0] ? image[0].name : null,
          image_remote: sendImagePayloadData && image_url ? image_url : null,
          html: bannerType === "html" ? html : null,
          destination_url:
            bannerType === "image" &&
            (dirtyFields["destination_url"] || sendImagePayloadData || type === "create")
              ? destination_url
              : null,
          width: width ? (typeof width === "string" ? parseInt(width) : width) : null,
          disabled: Boolean(disabled),
          element_id: element_id.trim(),
        }
        try {
          await onSubmit(values)
        } catch (err: any) {
          // TODO: typescript here, validate everything??
          // @ts-ignore
          setSubmitting(false)
          const errors = err.response?.data?.errors
          if (errors) {
            if (errors.name) {
              setError("name", { type: "manual", message: errors.name[0] })
            }
            if (errors.settings) {
              _forEach(errors.settings, (error: string[], key: string) => {
                setError(key as keyof EmbeddedWebBannersFormValues, {
                  type: "manual",
                  message: error[0],
                })
              })
            }
          }
        }
      }
    },
    [bannerType, onSubmit, setError, submitting, base64BannerImage, dispatch, dirtyFields, type],
  )

  const toggleBannerType = useCallback(() => {
    setBannerType(bannerType === "image" ? "html" : "image")
  }, [bannerType])

  const copyBanner = useCallback(async () => {
    if (!isCopying) {
      setIsCopying(true)
      try {
        const response = await dispatch(copyEmbeddedWebBanner(id))
        history.push(
          getRoutePath("personalization.embedded-web-banners.detail", {
            // @ts-ignore
            id: response.value.web_banner.id,
          }),
        )
        dispatch(showToast("Web Banner has been copied."))
      } catch {
        setIsCopying(false)
      }
    }
  }, [id, history, dispatch, isCopying])

  const encodeFileToBase64 = useCallback(
    (file: Blob) =>
      new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      }),
    [],
  )

  const verifyAndEncodeFile = useCallback(
    (field: "image") => async (evt: ChangeEvent) => {
      clearErrors(field)
      const target = evt.target as HTMLInputElement
      const { files } = target
      if (files && files[0]) {
        try {
          const encodedFile = await encodeFileToBase64(files[0])
          if (typeof encodedFile === "string") {
            setBase64BannerImage(encodedFile)
            setValue(field, files, { shouldDirty: true })
            setValue("image_url", "", {
              shouldDirty: true,
            })
          }
        } catch (err) {}
      }
    },
    [encodeFileToBase64, setValue, clearErrors],
  )

  const clearImageSelection = useCallback(
    (field: "image") => () => {
      clearErrors(field)
      if (base64BannerImage && field === "image") {
        setValue(field, undefined)
        setBase64BannerImage(null)
      }
    },
    [base64BannerImage, setValue, clearErrors],
  )

  return (
    <div className={styles.wrapper}>
      <Prompt
        when={!isEmpty(dirtyFields) && !submitting}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(submitForm)}>
        <Paper hasHeader className={classnames(styles.content, styles.triggerSettings)}>
          <PaperHeader size="small" className={styles.header}>
            <TextField
              label="Name"
              error={errors.name?.message}
              placeholder="Name"
              className={styles.bannerNameInput}
              disabled={!isEditable}
              {...register("name", { validate: required })}
            />
            <div className={styles.buttons}>
              <Controller
                name="disabled"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.disabledButtonToggleWrapper}>
                      <label>Active</label>
                      <ToggleButton
                        value={!field.value}
                        handleToggle={() => {
                          field.onChange(!field.value)
                        }}
                      />
                    </div>
                  )
                }}
              />
              {type === "edit" && (
                <>
                  <Button
                    type="button"
                    size="small"
                    color="white-red"
                    onClick={onDelete}
                    disabled={!isEditable}
                    className={styles.buttonMargin}
                  >
                    <FontAwesomeIcon className="icon" icon={["fas", "trash-alt"]} /> Delete
                  </Button>
                  <Button
                    size="small"
                    color="white"
                    onClick={copyBanner}
                    className={classnames(styles.buttonMargin, { loading: isCopying })}
                  >
                    <FontAwesomeIcon icon={["fas", "clone"]} className="icon" /> Copy
                  </Button>
                </>
              )}
              <Button
                type="submit"
                color="primary"
                size="small"
                disabled={!isEditable}
                className={submitting ? "loading" : ""}
              >
                {type === "create" ? "Create" : "Save"}
              </Button>
            </div>
          </PaperHeader>
          <div className={styles.conditionBuilderWrapper}>
            <Controller
              name="condition"
              control={control}
              rules={{
                validate: tree => {
                  const result = validateConditionTree(tree)
                  // Validator returns a stringified tree of errors because react-hook-forms only
                  // works with string errors
                  return result === null ? true : JSON.stringify(result)
                },
              }}
              render={({ field }) => {
                const stringifiedError = (errors.condition as FieldError | undefined)?.message
                return (
                  <>
                    <PaperHeader
                      size="small"
                      titleText="Conditions"
                      className={classNames(styles.conditionsHeader, {
                        [styles.empty]: !field.value,
                      })}
                    >
                      <ConditionTreeOverview<WBCondition> conditionTree={field.value} />
                    </PaperHeader>
                    <ConditionBuilder<WBCondition>
                      conditionTree={field.value}
                      onChange={field.onChange}
                      isEditable={hasAccess.webBanners.edit()}
                      error={stringifiedError ? JSON.parse(stringifiedError) : null}
                      conditionComponent={Condition}
                      getNewCondition={getNewWebBannerCondition}
                    />
                  </>
                )
              }}
            />
          </div>
          <div className={styles.freqPosPrioRow}>
            <div className={classnames(styles.greyBox, styles.priorityBox)}>
              <h4 className={styles.greyBoxTitle}>Priority</h4>
              <InfoTooltip placement="top" className={styles.tooltip}>
                Select priority from 0-10. Web banner with higher priority will appear first. 10 is
                the highest.
              </InfoTooltip>
              <div className={styles.positionPrioFieldsRow}>
                <div className={classnames(styles.fieldWithTooltip, styles.priorityFieldWrapper)}>
                  <Controller
                    name="priority"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <SelectField
                        input={field}
                        meta={{
                          touched: true,
                          // @ts-ignore
                          error: errors.priority?.message,
                        }}
                        options={PRIORITY_OPTIONS}
                        className={styles.prioritySelect}
                        disabled={!isEditable}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <Paper
          className={classnames(
            styles.content,
            styles.nextBox,
            styles.bannerBox,
            styles.bannerContent,
          )}
        >
          <div className={styles.leftPart}>
            <div className={styles.paperInnerHeader}>
              <h3>Banner</h3>
              <div className={styles.bannerToggleBox}>
                <ToggleSwitch
                  name="banner_type"
                  leftValue="image"
                  rightValue="html"
                  checked={bannerType}
                  handleToggle={toggleBannerType}
                  width="120px"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <div
              className={classnames(styles.bannerContent, {
                [styles.htmlContent]: bannerType === "html",
                [styles.imageContent]: bannerType === "image",
              })}
            >
              {bannerType === "image" && (
                <>
                  <TextField
                    className={styles.linkInput}
                    label="Destination URL"
                    error={errors.destination_url?.message}
                    placeholder="https://www.example.com/promotion"
                    fullWidth
                    disabled={!isEditable}
                    {...register("destination_url", {
                      validate: value => validateUrl(value),
                    })}
                  />
                  <div className={styles.imageUploadRow}>
                    <div className={styles.imageUploadRowContent}>
                      <FileField
                        label="Image upload"
                        error={errors.image?.message}
                        disabled={!isEditable}
                        {...register("image", {
                          validate: value => validImageFile(value),
                        })}
                        onChange={verifyAndEncodeFile("image")}
                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                        onClearClick={clearImageSelection("image")}
                        className={styles.fileInput}
                      />
                      <p className={styles.imageUploadDescription}>
                        Image type: APNG, AVIF, GIF, JPG, PNG, SVG, WebP; Max size: 500 kB
                      </p>
                    </div>
                    <TextField
                      label="Image URL"
                      error={errors.image_url?.message}
                      placeholder="https://www.example.com/image.jpg"
                      fullWidth
                      className={styles.imageUrlField}
                      disabled={!isEditable || base64BannerImage !== null}
                      {...register("image_url", {
                        validate: value => validateUrl(value),
                      })}
                    />
                  </div>
                </>
              )}
              {bannerType === "html" && (
                <div>
                  <Controller
                    name="html"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <div className={`ace-editor-wrapper ${errors.html?.message ? "error" : ""}`}>
                        <p className="label-like">Banner HTML *</p>
                        <AceEditor
                          mode="html"
                          theme="tomorrow"
                          onChange={field.onChange}
                          name="html"
                          width="808px"
                          height="398px"
                          editorProps={{ $blockScrolling: true }}
                          wrapEnabled={true}
                          className="ace-editor"
                          defaultValue={field.value ?? undefined}
                          setOptions={{
                            tabSize: 2,
                            showPrintMargin: false,
                          }}
                        />
                        {errors.html?.message && (
                          <p className="error-message">{errors.html.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightPart}>
            <div className={styles.tabs}>General settings</div>
            <p className={styles.labelLike}>General settings:</p>
            <div className={styles.tabContent}>
              <div className={classnames(styles.fieldWithTooltip)}>
                {/* hack to prevent showing 1Password (id: search) */}
                <TextField
                  id="search"
                  label="Element id *"
                  error={errors.element_id?.message}
                  fullWidth
                  {...register("element_id", {
                    validate: value => {
                      const trimmedValue = value ? value.trim() : ""
                      return required(trimmedValue) || validateDomElementId(trimmedValue)
                    },
                  })}
                  disabled={!isEditable}
                  maxLength={100}
                  autoComplete="off"
                />
                <InfoTooltip placement="top" className={styles.tooltip}>
                  ID of HTML element on a website where the banner will be placed.
                </InfoTooltip>
              </div>

              <div className={classnames(styles.fieldWithTooltip)}>
                <TextField
                  label="Banner width in pixels"
                  fullWidth
                  {...register("width")}
                  min="1"
                  step="1"
                  disabled={!isEditable}
                />
                <InfoTooltip placement="top" className={styles.tooltip}>
                  If not set, banner width will be 100% of the container specified by element ID.
                </InfoTooltip>
              </div>
            </div>
          </div>
        </Paper>

        <BannerPreview
          type={bannerType}
          html={html}
          imageUrl={imageUrl}
          base64Image={base64BannerImage}
          width={width}
        />
      </form>
    </div>
  )
}
