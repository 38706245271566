import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, reduxForm, Field } from "redux-form"
import _get from "lodash/get"

import SearchField from "../../elements/SearchField"

import "./SearchForm.scss"

class SearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typingTimeout: 0,
    }
  }

  onSubmit = values => {
    const { onSubmit } = this.props
    if (onSubmit) {
      // submit field only when onSubmit is passed to props
      const filterTerm = _get(values, "search", "")
      onSubmit(filterTerm)
    }
  }

  onSearchFieldChange = () => {
    const { typingTimeout } = this.state
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    this.setState({
      typingTimeout: setTimeout(this.props.handleSubmit(this.onSubmit), 400),
    })
  }

  handleClear = () => {
    const { handleSubmit, change } = this.props
    const submitter = handleSubmit(() => this.onSubmit({ search: "" }))
    change("search", "")
    submitter()
  }

  render() {
    const { placeholder, className, handleSubmit, size } = this.props
    return (
      <Form
        name="search-form"
        className={`search-form ${className ? className : ""}`}
        autoComplete="off"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Field
          name="search"
          component={SearchField}
          placeholder={placeholder}
          handleClear={this.handleClear}
          handleChange={this.onSearchFieldChange}
          size={size}
        />
      </Form>
    )
  }
}

SearchForm.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  form: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default reduxForm({
  destroyOnUnmount: false,
})(SearchForm)

export const SearchFormDestroyable = reduxForm({
  destroyOnUnmount: true,
})(SearchForm)
