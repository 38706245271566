/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import cytoscape from "cytoscape"
import coseBilkent from "cytoscape-cose-bilkent"
import popper from "cytoscape-popper"
import * as textMetrics from "text-metrics"
import moment from "moment"
import { MOMENT } from "sharedConstants"
import { getIconSrc } from "helpers/image.helper"
import _toLower from "lodash/toLower"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import fuzzysort from "fuzzysort"
import { copyStringToClipboard } from "helpers/string.helper"
import copy from "./copy.png"
import { showToast } from "actions/toast.action"
import Help from "./Help/Help"

import "./IdentityGraph.scss"

var MIN_NODE_WIDTH = 100

const metrics = textMetrics.init({
  fontSize: "10px",
  fontFamily: "sans-serif",
  lineHeight: "11px",
  fontWeight: 400,
  width: 200,
})

cytoscape.use(coseBilkent)
cytoscape.use(popper)

export default function ({
  isLoading,
  data,
  attributes,
  dataSources,
  events,
  searchIdentifier,
  graphIdentifiersSelection,
  errorMessage,
}) {
  const dispatch = useDispatch()
  const container = React.useRef(null)
  const graph = React.useRef()
  const activePopper = React.useRef({})

  let graphHeight = 798
  if (window.innerHeight < 798 + 40) {
    graphHeight = window.innerHeight < 500 + 40 ? 490 : window.innerHeight - 40
  }

  const destroyPopperIfExists = () => {
    if (activePopper.current.edgeId) {
      activePopper.current.ref.destroy()
      activePopper.current = {}
    }
  }

  const showPopper = evt => {
    const element = evt.target
    if (activePopper.current.edgeId !== element.data("id")) {
      if (activePopper.current.edgeId) {
        // hide previous popper
        activePopper.current.ref.destroy()
      }
      const popper = element.popper({
        content: () => {
          const source = dataSources.get(events.getIn([element.data("eventId"), "source_id"]))
          let sourceHtml = "<p class='not-available-source'>N/A</p>"
          if (source) {
            sourceHtml = `<div class="identifier-source">\
                <div class="logo-wrapper"><img src="${getIconSrc(
                  {
                    primary: source.getIn(["frontend_settings", "icon"]),
                    secondary: _toLower(source.type),
                  },
                  source.getIn(["frontend_settings", "alt_icon"]),
                )}" alt="" /></div>\
                <span class='source-name'>${source.name}</span>\
              </div>`
          }

          const div = document.createElement("div")
          div.className = "node-popper"
          div.innerHTML = `<div class='row'><p class='label'>Customer event id:</p>\
            <p class='value'>${element.data("customerEventId")}</p></div>\
            <div class='second-row'><p class='label'>Event:</p>\
            <p class='event-name'>${events.getIn([element.data("eventId"), "name"])}</p></div>\
            <table class='second-row'><tbody><tr><td class='source-td'><p class='label'>Data source:</p>\
            ${sourceHtml}</td>\
            <td class='timestamp-td'><p class='label'>Timestamp:</p><p class='datetime'>${moment
              .utc(element.data("timestamp"))
              .local()
              .format(MOMENT.DATETIME_FORMAT)}</p></td>\
            </tr></tbody></table>`
          document.body.appendChild(div)
          return div
        },
        popper: {
          placement: "bottom-start",
          removeOnDestroy: true,
        },
      })
      activePopper.current = {
        edgeId: element.data("id"),
        ref: popper,
      }
    }
  }

  const updatePopper = () => {
    if (activePopper.current.ref) {
      activePopper.current.ref.scheduleUpdate()
    }
  }

  const centerGraph = () => {
    if (graph.current && typeof graph.current.fit === "function") {
      graph.current.fit()
    }
  }

  const zoomInGraph = () => {
    if (graph.current && typeof graph.current.zoom === "function") {
      graph.current.zoom(graph.current.zoom() + 0.1)
    }
  }

  const zoomOutGraph = () => {
    if (graph.current && typeof graph.current.zoom === "function") {
      graph.current.zoom(graph.current.zoom() - 0.1)
    }
  }

  const filterNodes = () => {
    if (graph.current && typeof graph.current.nodes === "function") {
      const searchData = graph.current.nodes().map(node => {
        return { id: node.data("id"), value: node.data("label") }
      })
      const results = fuzzysort.go(searchIdentifier, searchData, { key: "value", allowTypo: true })

      if (searchIdentifier && results.total === 0) {
        graph.current.nodes().data("transparent", true)
      } else if (searchIdentifier && results.total) {
        graph.current.nodes().forEach(node => {
          if (results.find(item => item.target === node.data("label"))) {
            node.data("transparent", graphIdentifiersSelection[node.data("attributeId")] === false)
          } else {
            node.data("transparent", true)
          }
        })
      } else {
        graph.current.nodes().forEach(node => {
          node.data("transparent", graphIdentifiersSelection[node.data("attributeId")] === false)
        })
      }
    }
  }

  useEffect(() => {
    if (!container.current || isLoading) {
      return
    }
    if (!errorMessage) {
      try {
        if (!graph.current) {
          const elements = [
            ...data.nodes.map(node => {
              const nodeText = metrics.lines(node.value).join("\n")
              const textWidth = metrics.width(nodeText)
              const textHeight = metrics.height(nodeText)
              const color = attributes.getIn([node.attribute_id, "identity_graph_color"], "fe7f66")
              return {
                data: {
                  id: node.node_id,
                  label: nodeText,
                  rawValue: node.value,
                  type: "node",
                  width: textWidth < MIN_NODE_WIDTH ? MIN_NODE_WIDTH : textWidth + 30,
                  height: textHeight + 20,
                  color,
                  attributeId: node.attribute_id,
                },
              }
            }),
            ...data.edges.map(edge => {
              return {
                data: {
                  id: `${edge.a}${edge.b}`,
                  source: edge.a,
                  target: edge.b,
                  color: "#cccccc",
                  eventId: edge.event_id,
                  timestamp: edge.timestamp,
                  customerEventId: edge.customer_event_id,
                },
              }
            }),
          ]
          graph.current = cytoscape({
            container: container.current,
            elements: elements,
            maxZoom: 1.5,
            boxSelectionEnabled: false,
            selectionType: "single",
            userZoomingEnabled: false,
            layout: {
              name: "cose-bilkent",
              quality: "proof",
              fit: true,
              padding: 20,
              nodeDimensionsIncludeLabels: true,
              nodeRepulsion: 1000000,
              idealEdgeLength: 130,
              edgeElasticity: 0.25,
              numIter: 10000,
            },
            style: [
              {
                selector: "node[type='node']",
                style: {
                  label: "data(label)",
                  shape: "round-rectangle",
                  width: "data(width)",
                  height: "data(height)",
                  "text-valign": "center",
                  "text-halign": "center",
                  "font-size": "10px",
                  "font-family": "sans-serif",
                  "line-height": "11px",
                  color: "data(color)",
                  "border-width": "2px",
                  "border-style": "solid",
                  "border-color": "data(color)",
                  "background-color": "#ffffff",
                  "transition-property": "color background-color",
                  "transition-duration": "0.15s",
                  "transition-timing-function": "ease-in-out",
                  "overlay-opacity": 0,
                },
              },
              {
                selector: "node[?transparent]",
                style: {
                  opacity: 0.3,
                },
              },
              {
                selector: "edge",
                style: {
                  "curve-style": "straight",
                  width: 1,
                  "line-color": "data(color)",
                  "source-arrow-color": "data(color)",
                  "target-arrow-color": "data(color)",
                  "source-arrow-shape": "circle",
                  "target-arrow-shape": "circle",
                  "arrow-scale": 0.4,
                  "transition-property": "line-color source-arrow-color target-arrow-color",
                  "transition-duration": "0.15s",
                  "transition-timing-function": "ease-in-out",
                },
              },
              {
                selector: "edge:selected",
                style: {
                  width: 2,
                  "line-color": "#999999",
                  "source-arrow-color": "#999999",
                  "target-arrow-color": "#999999",
                },
              },
              {
                selector: "edge.highlighted",
                style: {
                  "line-color": "data(color)",
                  width: 2,
                  "source-arrow-color": "data(color)",
                  "target-arrow-color": "data(color)",
                },
              },
              {
                selector: "edge.hover",
                style: {
                  "line-color": "#999999",
                  "source-arrow-color": "#999999",
                  "target-arrow-color": "#999999",
                },
              },
              {
                selector: "#copy-node",
                style: {
                  "background-color": "#ffffff",
                  width: 20,
                  height: 20,
                  shape: "round-rectangle",
                  "border-width": "1px",
                  "border-style": "solid",
                  "border-color": "#CCCCCC",
                  "background-image": copy,
                  "background-height": "50%",
                  "background-width": "40%",
                  "overlay-padding": 0,
                },
              },
            ],
          })

          graph.current.on("tap", "#copy-node", evt => {
            const element = evt.target
            const value = element.data("value")
            copyStringToClipboard(value)
            dispatch(showToast("Copied to clipboard."))
          })

          graph.current.on("mouseover", "node[type='node']", evt => {
            const element = evt.target
            graph.current.edges().removeClass("highlighted")
            element.connectedEdges().addClass("highlighted").data("color", element.data("color"))
            graph.current.remove("#copy-node")
            graph.current.add({
              group: "nodes",
              data: {
                id: "copy-node",
                value: element.data("rawValue"),
                label: "",
              },
              position: {
                x: element.bb().x2 - 5,
                y: element.bb().y2 - 5,
              },
              locked: true,
            })
          })

          graph.current.on("mousemove", evt => {
            const element = evt.target
            if (element === graph.current) {
              graph.current.remove("#copy-node")
            }
          })

          graph.current.on("mouseout", "node[type='node']", evt => {
            graph.current.edges().removeClass("highlighted").data("color", "#cccccc")
          })

          graph.current.on("mouseover", "edge", evt => {
            const element = evt.target
            graph.current.edges().removeClass("hover")
            element.addClass("hover")
          })

          graph.current.on("mouseout", "edge", () => {
            graph.current.edges().removeClass("hover")
          })

          graph.current.on("select", "edge", evt => {
            showPopper(evt)
          })

          graph.current.on("unselect", "edge", () => {
            destroyPopperIfExists()
          })

          graph.current.on("drag", "node[type='node']", () => {
            graph.current.edges().unselect()
            destroyPopperIfExists()
          })

          graph.current.on("position", "node[type='node']", updatePopper)
          graph.current.on("pan zoom resize", updatePopper)

          filterNodes()
        }
      } catch {}
    }
    return () => {
      if (graph.current) {
        graph.current.destroy()
      }
      if (activePopper.current.ref) {
        activePopper.current.ref.destroy()
      }
    }
  }, [isLoading, errorMessage])

  useEffect(() => {
    const onKeyDown = evt => {
      if (evt.metaKey || evt.ctrlKey) {
        if (graph.current && typeof graph.current.userZoomingEnabled === "function") {
          graph.current.userZoomingEnabled(true)
        }
      }
    }
    const onKeyUp = () => {
      if (graph.current && typeof graph.current.userZoomingEnabled === "function") {
        if (graph.current.userZoomingEnabled() === true) {
          graph.current.userZoomingEnabled(false)
        }
      }
    }

    window.addEventListener("keydown", onKeyDown)
    window.addEventListener("keyup", onKeyUp)

    return () => {
      window.removeEventListener("keydown", onKeyDown)
      window.removeEventListener("keyup", onKeyUp)
    }
  }, [])

  useEffect(() => {
    filterNodes()
  }, [searchIdentifier, graphIdentifiersSelection])

  return (
    <div className="identity-graph-wrapper">
      <div
        className={`identity-graph ${isLoading ? "loading" : ""}`}
        style={{ width: "1198px", height: `${graphHeight}px`, display: "block" }}
        ref={container}
      />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {!isLoading && !errorMessage && (
        <React.Fragment>
          <Help />
          <div className="action-buttons">
            <IconButton
              color={COLOR.PRIMARY}
              faded
              className="zoom-in-button"
              onClick={zoomInGraph}
              iconStyle="far"
              iconName="plus"
              tooltip="Zoom in"
            />
            <IconButton
              color={COLOR.PRIMARY}
              faded
              className="zoom-out-button"
              onClick={zoomOutGraph}
              iconStyle="far"
              iconName="minus"
              tooltip="Zoom out"
            />
            <IconButton
              color={COLOR.PRIMARY}
              faded
              className="center-button"
              onClick={centerGraph}
              iconStyle="far"
              iconName="location"
              tooltip="Center"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
