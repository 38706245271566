import React, { useEffect, useState } from "react"
import styles from "./UsersActivity.module.scss"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import { useDispatch, useSelector } from "react-redux"
import { getEmailDomainsForSelect, getUsersForSelect } from "selectors/user.selector"
import { prop } from "ramda"
import ActivityChart from "./components/ActivityChart/ActivityChart"
import { api } from "api"
import { FetchUsersActivityResponse, FilterType } from "types/usersActivity"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import SelectField from "components/UI/elements/SelectField"
import useTypedSelector from "hooks/useTypedSelector"
import {
  setEndDate,
  setFilterType,
  setFilterValue,
  setStartDate,
} from "actions/usersActivityFilters.action"
import { SelectOption } from "types/util"
import { UserRole } from "types/userRoles"
import { SYSTEM_ROLE_ID } from "sharedConstants"
import Button from "components/UI/elements/Button/Button"
import ActivityDatePicker from "./components/ActivityDatePicker/ActivityDatePicker"
import { User } from "types/users"
import { makeDataLabels } from "./makeDataLabels"
import UserCounts from "./components/UserCounts/UserCounts"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

const filterTypeOptions: SelectOption<FilterType>[] = [
  { label: "Users", value: "user_ids" },
  { label: "Roles", value: "user_role_ids" },
  { label: "Email domains", value: "domains" },
]

export default function UsersActivity() {
  const dispatch = useDispatch()

  const userOptions: SelectOption<User["id"]>[] = useSelector(getUsersForSelect)
  const domainOptions = useSelector(getEmailDomainsForSelect)

  const [roleOptions, setRoleOptions] = useState<SelectOption<UserRole["id"]>[]>([])

  async function fetchRoles() {
    try {
      const data = await new AllResourceItemsFetcher()
        .setEndpointCall((offset: number, limit: number) => api.role.list(offset, limit))
        .setDataPath("roles")
        .run()

      setRoleOptions(
        data
          .filter(({ id }) => id !== SYSTEM_ROLE_ID)
          .map(({ id, name }) => ({ label: name, value: id })),
      )
    } catch {
      // noop
    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  const filters = useTypedSelector(state => state.usersActivityFilters)
  const { since, until, filterType, filterValue } = filters

  const selectedFilterType =
    filterType === "all_users" ? null : filterTypeOptions.find(({ value }) => value === filterType)

  const filterValueOptions =
    filterType === "all_users"
      ? []
      : filterType === "user_ids"
      ? userOptions
      : filterType === "user_role_ids"
      ? roleOptions
      : domainOptions

  const selectedFilterValue = filterValue.map(
    value => filterValueOptions.find(option => option.value === value)!,
  )

  const changeFilterType = (option: SelectOption<FilterType> | null) => {
    dispatch(setFilterType(option?.value ?? "all_users"))
    dispatch(setFilterValue([]))
    setIsValidationError(false)
  }

  const changeFilterValue = (options: SelectOption<string | number>[]) => {
    dispatch(setFilterValue(options.map(prop("value"))))
    setIsValidationError(false)
  }

  type State = {
    data: FetchUsersActivityResponse | null
    isLoading: boolean
  }

  const [usersActivity, setUsersActivity] = useState<State>({ data: null, isLoading: false })
  const [isValidationError, setIsValidationError] = useState(false)

  async function fetchUsersActivity() {
    if (filterType !== "all_users" && filterValue.length === 0) {
      setIsValidationError(true)
      return
    }

    setUsersActivity(s => ({ ...s, isLoading: true }))

    try {
      const data = await api.usersActivity(filters)
      setUsersActivity({ data, isLoading: false })
    } catch {
      setUsersActivity({ data: null, isLoading: false })
    }
  }

  useEffect(() => {
    fetchUsersActivity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isLoading } = usersActivity

  const { user_ids, user_role_ids, domains } = data?.selection_settings ?? {}
  const responseFilterType: FilterType = user_ids
    ? "user_ids"
    : user_role_ids
    ? "user_role_ids"
    : domains
    ? "domains"
    : "all_users"

  const labels = data ? makeDataLabels(data.activities.labels, data.activities.resolution) : []

  return (
    <div className={styles.container}>
      <PaperHeader size="small" titleText="users activity" className={styles.header} />

      <UserCounts />

      <Paper className={styles.filters}>
        <ActivityDatePicker
          startDate={since}
          endDate={until}
          setStartDate={date => dispatch(setStartDate(date))}
          setEndDate={date => dispatch(setEndDate(date))}
        />
        <SelectField
          input={{
            value: selectedFilterType,
            onChange: changeFilterType,
          }}
          options={filterTypeOptions}
          className={styles.filterTypeSelect}
          label="Filter by (optional)"
          isClearable
        />
        {filterType !== "all_users" && (
          <SelectField
            input={{
              value: selectedFilterValue,
              onChange: changeFilterValue,
            }}
            options={filterValueOptions}
            className={styles.filterValueSelect}
            label={filterTypeOptions.find(({ value }) => value === filterType)!.label}
            isMulti
            meta={{
              touched: true,
              error: isValidationError && "Please fill in the field",
            }}
          />
        )}
        <Button
          color="primary"
          size="medium"
          onClick={fetchUsersActivity}
          className={styles.applyButton}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Apply filters
        </Button>
      </Paper>

      {isLoading && <LoadingIndicator className={styles.pageSpinner} />}

      {!isLoading && data && (
        <div className={styles.chartsGrid}>
          {data.activities.category_data.map(category => (
            <ActivityChart
              key={category.id}
              categoryData={category}
              xAxisLabels={labels}
              userOptions={userOptions}
              roleOptions={roleOptions}
              filterType={responseFilterType}
            />
          ))}
        </div>
      )}
    </div>
  )
}
