import React, { Component } from "react"
import { connect } from "react-redux"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm from "../../AttributeForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { api } from "api"
import { showToast } from "actions/toast.action"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { getInitialAttributeFormValues } from "../../AttributeForm/attributeInitialValues"

import "./AttributeCreate.scss"

class AttributeCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creating: false,
    }
  }

  createAttribute = async data => {
    if (!this.state.creating) {
      this.setState({ creating: true })
      const { history, showToast } = this.props
      try {
        await api.attribute.create(data)
        showToast("Attribute created.")
        history.push(getRoutePath("setup.attributes"))
      } catch {
        this.setState({ creating: false })
      }
    }
  }

  render() {
    const { history, location } = this.props
    const { creating } = this.state

    // prefilled values from copy
    const attribute = _get(location, "state.attribute")
    const formInitValues = getInitialAttributeFormValues(attribute)

    return (
      <section className="create-attribute wrapper">
        <PaperHeader size="small" titleText="Create attribute" className="create-attribute-header">
          <div className="header-buttons">
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.attributes"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={creating} formName="AttributeForm" />
          </div>
        </PaperHeader>
        <Paper hasHeader className="create-attribute-content">
          <AttributeForm onSubmit={this.createAttribute} initialValues={formInitValues} />
        </Paper>
      </section>
    )
  }
}

AttributeCreate.propTypes = {
  showToast: PropTypes.func.isRequired,
}

export default connect(null, { showToast })(AttributeCreate)
