import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _toLower from "lodash/toLower"
import _get from "lodash/get"
import { getFormValues } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Map } from "immutable"
import moment from "moment"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable"
import AttributesFilterForm from "components/UI/components/AttributesFilterForm"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  SortButton,
  RowMessage,
} from "components/UI/elements/Table"
import InfoTooltip from "components/UI/elements/InfoTooltip"

// actions
import { fetchCustomerAttributesCountsList } from "actions/customerAttribute.action"

// selectors
import {
  getAttributesData,
  areAttributesFulfilled,
  getAttributesTagsList,
} from "selectors/attributes.selector"
import { getCustomerAttributesCounts } from "selectors/customerAttributeCount.selector"

// helpers
import { getIconSrc } from "helpers/image.helper"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "helpers/compoundAttribute.helper"

import "./Attributes.scss"
import Tippy from "@tippyjs/react"

class Attributes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTags: List(),
      dataSourceListForSelect: [],
      sort: {
        type: "name",
        isAsc: true,
      },
    }
  }

  componentDidMount() {
    this.props.fetchCustomerAttributesCountsList()
  }

  onAddFilterTag = tagId => {
    const { selectedTags } = this.state

    if (!selectedTags.includes(tagId)) {
      this.setState({
        selectedTags: selectedTags.push(tagId),
      })
    }
  }

  onDeleteFilterTag = tagId => {
    this.setState(prevState => ({
      selectedTags: prevState.selectedTags.filterNot(selectedTagId => selectedTagId === tagId),
    }))
  }

  renderAttributesRow = attribute => {
    const { customerAttributesCountsList } = this.props
    let tags = null,
      attribute_count = null

    if (attribute.tags) {
      tags = attribute.tags.map(tag => {
        if (tag) {
          return (
            <Tag
              key={tag.get("id")}
              color="primary"
              onClick={event => this.onAddFilterTag(tag.get("id"))}
              className="attribute-label"
            >
              {tag.get("name")}
            </Tag>
          )
        } else {
          return null
        }
      })
    }

    const customerAttributeCount = customerAttributesCountsList.find(
      ca => ca.attribute_id === attribute.id,
    )
    if (customerAttributeCount) {
      attribute_count = customerAttributeCount.count
    }

    let attributeExamples = null
    if (isAttributeCompound(attribute.data_type) && Map.isMap(attribute.examples)) {
      const attrExamples = attribute.examples
      const subAttributes = List(getCompoundAttributeSubAttributes(attribute.data_type))
      let maxSize = 0
      const examples = subAttributes.map(subAttribute => {
        if (List.isList(attrExamples.get(subAttribute.id))) {
          if (maxSize < attrExamples.get(subAttribute.id).size) {
            maxSize = attrExamples.get(subAttribute.id).size
          }
          return attrExamples.get(subAttribute.id)
        }
        return List()
      })
      if (maxSize > 3) {
        maxSize = 3
      }
      const values = []
      for (let i = 0; i < maxSize; i++) {
        values.push([])
        examples.forEach(examplesList => {
          values[i].push(examplesList.get(i))
        })
      }
      if (values.some(arr => arr.length > 0)) {
        attributeExamples = (
          <CompoundAttributeValuesTable subAttributes={subAttributes} values={List(values)} />
        )
      }
    } else {
      attributeExamples = attribute.examples.take(3).map((value, key) => (
        <Tag
          key={key}
          color="white"
          size="small"
          clickable={false}
          className="attribute-example-value"
          maxCharacters={30}
          uniqueTooltipId={`tooltip-${attribute.id}_${key}`}
        >
          {getUserFriendlyValueFormat(value, attribute.data_type)}
        </Tag>
      ))
    }

    return (
      <Tr key={attribute.id}>
        <Td
          className="attribute-name"
          withNewBadge={moment().diff(attribute.created, "days") < 8}
          textBigger
          textBlack
          textBold
        >
          <span>{attribute.name}</span>
          {isAttributeCompound(attribute.data_type) && (
            <ul className="sub-attrs">
              {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
                <li key={subAttr.id}>{subAttr.name}</li>
              ))}
            </ul>
          )}
        </Td>
        <Td className="attribute-description">{attribute.description}</Td>
        <Td className="attribute-source">
          <div className="attribute-source-flex-wrapper">
            <div>
              <img
                src={getIconSrc(
                  {
                    primary: attribute.source.getIn(["frontend_settings", "icon"]),
                    secondary: _toLower(attribute.source.get("type")),
                  },
                  attribute.source.getIn(["frontend_settings", "alt_icon"]),
                )}
                alt="icon"
              />
            </div>
            <div className="source-n-count">
              <div>{attribute.source.get("name")}</div>
              <div className="count">
                {attribute_count !== null ? `${attribute_count} filled` : ""}
              </div>
            </div>
          </div>
        </Td>
        <Td className="attribute-tags">{tags}</Td>
        <Td className="attribute-examples">
          <div className="examples-wrapper">{attributeExamples}</div>
        </Td>
      </Tr>
    )
  }

  someSelectedTagFound = attribute => {
    const { selectedTags } = this.state

    if (attribute.tags) {
      return attribute.tags.some(tag => {
        return selectedTags.includes(tag.get("id"))
      })
    }
    return false
  }

  getAttributeList = (searchText, dataSourceId) => {
    const { attributesList, areAttributesFulfilled } = this.props
    const { selectedTags } = this.state

    let attributeListForRender = attributesList

    if (areAttributesFulfilled) {
      if (searchText) {
        attributeListForRender = attributeListForRender.filter(attr =>
          _toLower(attr.name).includes(_toLower(searchText)),
        )
      }
      if (dataSourceId) {
        attributeListForRender = attributeListForRender.filter(
          attr => attr.source.get("id") === dataSourceId,
        )
      }
      if (selectedTags.size > 0) {
        attributeListForRender = attributeListForRender.filter(attr =>
          this.someSelectedTagFound(attr),
        )
      }
    }

    return this.getSortedAttributeList(attributeListForRender).map(attr =>
      this.renderAttributesRow(attr),
    )
  }

  getSortedAttributeList(attributeList) {
    const { sort } = this.state

    if (sort && sort.type) {
      if (sort.type === "name") {
        if (sort.isAsc) {
          return attributeList.sort((a, b) => a.name.localeCompare(b.name))
        } else {
          return attributeList.sort((a, b) => b.name.localeCompare(a.name))
        }
      } else if (sort.type === "dataSource") {
        if (sort.isAsc) {
          return attributeList.sort((a, b) =>
            a.source.get("name").localeCompare(b.source.get("name")),
          )
        } else {
          return attributeList.sort((a, b) =>
            b.source.get("name").localeCompare(a.source.get("name")),
          )
        }
      } else if (sort.type === "labels") {
        if (sort.isAsc) {
          return attributeList.sort((a, b) => {
            let aLowestLabel = "ZZZ"
            if (List.isList(a.tags) && a.tags.size > 0) {
              let tmpLowest = a.tags.getIn([0, "name"])
              a.tags.forEach(tag => {
                tmpLowest =
                  tmpLowest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpLowest
              })
              aLowestLabel = tmpLowest
            }
            let bLowestLabel = "ZZZ"
            if (List.isList(b.tags) && b.tags.size > 0) {
              let tmpLowest = b.tags.getIn([0, "name"])
              b.tags.forEach(tag => {
                tmpLowest =
                  tmpLowest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpLowest
              })
              bLowestLabel = tmpLowest
            }
            return aLowestLabel.localeCompare(bLowestLabel)
          })
        } else {
          return attributeList.sort((a, b) => {
            let aHighestLabel = ""
            if (List.isList(a.tags) && a.tags.size > 0) {
              let tmpHighest = a.tags.getIn([0, "name"])
              a.tags.forEach(tag => {
                tmpHighest =
                  tmpHighest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpHighest
              })
              aHighestLabel = tmpHighest
            }
            let bHighestLabel = ""
            if (List.isList(b.tags) && b.tags.size > 0) {
              let tmpHighest = b.tags.getIn([0, "name"])
              b.tags.forEach(tag => {
                tmpHighest =
                  tmpHighest.localeCompare(tag.get("name")) === -1 ? tag.get("name") : tmpHighest
              })
              bHighestLabel = tmpHighest
            }
            return bHighestLabel.localeCompare(aHighestLabel)
          })
        }
      }
    }
  }

  sortAttributes = type => {
    const currentSortObj = this.state.sort

    if (currentSortObj.type === type) {
      this.setState({
        sort: {
          type: type,
          isAsc: !currentSortObj.isAsc,
        },
      })
    } else {
      this.setState({
        sort: {
          type: type,
          isAsc: true,
        },
      })
    }
  }

  render() {
    const { filterValues, attributesTagsList, areAttributesFulfilled } = this.props
    const { selectedTags, sort } = this.state

    const attributes = this.getAttributeList(
      _get(filterValues, "search", ""),
      _get(filterValues, "select.value", null),
    )

    return (
      <React.Fragment>
        <div className="attributes-container wrapper">
          <PaperHeader size="small">
            <h3 className="title">
              Attributes
              <Tippy
                interactive
                content={
                  <>
                    <p>
                      Attributes are <strong>characteristics of a customer</strong> that help to
                      build a more holistic picture of a customer (e.g. information about personal
                      data, revenue spent, opened campaigns etc).
                    </p>
                    <p>
                      Attributes are extracted from events.{" "}
                      <strong>When a customer action happens</strong> (e.g. customer visits a web
                      page, makes a payment transaction, etc.){" "}
                      <strong>
                        it is recorded as an event. Meiro is able to extract data from the events
                        and based on it create attributes
                      </strong>{" "}
                      like "Date of the last visit to the web" or "Total revenue from all
                      transactions".
                    </p>
                    <p>
                      To learn more about Attributes tab, please refer to{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        this article
                      </a>
                      .
                    </p>
                  </>
                }
              >
                <span className="info-tooltip-icon">
                  <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            </h3>
            <AttributesFilterForm />
          </PaperHeader>
          <Paper hasHeader>
            <div className="tag-filter">
              <div className="label-tags">
                <span className="selected-tags">Filter by:</span>
                <span>
                  {selectedTags.map(tagId => {
                    const tag = attributesTagsList.find(val => val.id === tagId)
                    if (tag) {
                      return (
                        <Tag
                          key={tagId}
                          clickable={true}
                          color="primary"
                          onClick={() => this.onDeleteFilterTag(tagId)}
                        >
                          {tag.name}
                        </Tag>
                      )
                    }
                    return null
                  })}
                </span>
              </div>
              <TagPicker
                selectedTagIds={selectedTags}
                allTags={attributesTagsList}
                onTagSelect={this.onAddFilterTag}
                className="selected-tags-picker"
                type="label"
              />
            </div>
            {attributes.size > 0 && (
              <Table className="attributes-table">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={sort.type}
                      orderDir={sort.isAsc ? "asc" : "desc"}
                      onClick={() => this.sortAttributes("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="description-col">Description</Th>
                  <Th className="source-col">
                    <SortButton
                      column="dataSource"
                      orderBy={sort.type}
                      orderDir={sort.isAsc ? "asc" : "desc"}
                      onClick={() => this.sortAttributes("dataSource")}
                      label="Data Source"
                    />
                  </Th>
                  <Th className="labels-col">
                    <SortButton
                      column="labels"
                      orderBy={sort.type}
                      orderDir={sort.isAsc ? "asc" : "desc"}
                      onClick={() => this.sortAttributes("labels")}
                      label="Labels"
                    />
                    <InfoTooltip className="labels-info-tooltip">
                      Labels help to manage attributes. Administrators can assign attributes with a
                      certain label to a particular feature.
                    </InfoTooltip>
                  </Th>
                  <Th className="examples-col">Examples</Th>
                </Thead>
                <Tbody>{attributes}</Tbody>
              </Table>
            )}
            {attributes.size === 0 && areAttributesFulfilled && (
              <RowMessage className="no-attributes-found">Nothing found.</RowMessage>
            )}
          </Paper>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    attributesList: getAttributesData(state),
    attributesTagsList: getAttributesTagsList(state),
    areAttributesFulfilled: areAttributesFulfilled(state),
    customerAttributesCountsList: getCustomerAttributesCounts(state),
    filterValues: getFormValues("SearchAttributeForm")(state),
  }
}

Attributes.propTypes = {
  attributesTagsList: PropTypes.instanceOf(List).isRequired,
}

Attributes = connect(mapStateToProps, { fetchCustomerAttributesCountsList })(Attributes)

export default Attributes
