import {
  PopupWebBannersAction,
  fetchAllPopupWebBannersFulfilledType,
  createPopupWebBannerFulfilledType,
  modifyPopupWebBannerFulfilledType,
  deletePopupWebBannerFulfilledType,
  retrievePopupWebBannerFulfilledType,
  fetchPopupWebBannersSettingsType,
  modifyPopupWebBannerSettingsType,
  copyPopupWebBannerFulfilledType,
  sortPopupWebBannerType,
} from "./popupWBActions"
import { prop, sort } from "ramda"
import { OrderDir } from "types/util"
import { PopupWebBanner, PopupWebBannerSettingsResponse, PopupWebBannerSort } from "./popupWBTypes"
import { ascend } from "utilities/comparators"

type PopupWebBannersState = {
  initialFetching: boolean
  webBanners: PopupWebBanner[]
  initialSettingsFetching: boolean
  settings: PopupWebBannerSettingsResponse | null
  orderBy: PopupWebBannerSort
  orderDir: OrderDir
}
const initialState: PopupWebBannersState = {
  initialFetching: true,
  webBanners: [],
  initialSettingsFetching: true,
  settings: null,
  orderBy: "name",
  orderDir: "ASC",
}

export default function popupWebBanners(
  state: PopupWebBannersState = initialState,
  action: PopupWebBannersAction,
) {
  switch (action.type) {
    case sortPopupWebBannerType: {
      return {
        ...state,
        orderBy: action.payload.orderBy,
        orderDir: action.payload.orderDir,
      }
    }
    case fetchPopupWebBannersSettingsType:
    case modifyPopupWebBannerSettingsType: {
      return {
        ...state,
        initialSettingsFetching: false,
        settings: action.payload,
      }
    }
    case fetchAllPopupWebBannersFulfilledType: {
      return {
        ...state,
        initialFetching: false,
        webBanners: action.payload,
      }
    }
    case createPopupWebBannerFulfilledType:
    case copyPopupWebBannerFulfilledType: {
      return {
        ...state,
        webBanners: sort(ascend(prop("name")), [...state.webBanners, action.payload.web_banner]),
      }
    }
    case retrievePopupWebBannerFulfilledType: {
      let webBanners
      if (state.webBanners.find(banner => banner.id === action.payload.web_banner.id)) {
        webBanners = state.webBanners.map(banner =>
          banner.id === action.payload.web_banner.id ? action.payload.web_banner : banner,
        )
      } else {
        webBanners = sort(ascend(prop("name")), [...state.webBanners, action.payload.web_banner])
      }
      return {
        ...state,
        webBanners,
      }
    }
    case modifyPopupWebBannerFulfilledType: {
      return {
        ...state,
        webBanners: state.webBanners.map(webBanner => {
          if (webBanner.id === action.payload.web_banner.id) {
            return action.payload.web_banner
          }
          return webBanner
        }),
      }
    }
    case deletePopupWebBannerFulfilledType: {
      return {
        ...state,
        webBanners: state.webBanners.filter(webBanner => webBanner.id !== action.meta.webBannerId),
      }
    }

    default:
      return state
  }
}
