import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Map } from "immutable"
import _isPlainObject from "lodash/isPlainObject"
import _isArray from "lodash/isArray"
import _get from "lodash/get"
import _has from "lodash/has"
import { formValueSelector } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import _isNumber from "lodash/isNumber"

// actions
import { showLongLoadingBar, hideLongLoadingBar } from "actions/longLoadingBar.action"
import { showToast } from "actions/toast.action"

// selectors
import { areAttributesFulfilled, getAttributesMapById } from "selectors/attributes.selector"
import { getCustomersCount } from "selectors/longLoadingBar.selector"

// ui components
import InsightsFilterForm from "components/UI/components/InsightsFilterForm"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import InsightTile from "components/UI/elements/InsightTile"
import ExpandedInsightTile from "components/UI/elements/InsightTile/ExpandedInsightTile"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"

// constants, helpers
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./Insights.scss"
import { SocketContext } from "context/socket"
import { TOAST } from "sharedConstants"
import { getCompoundAttributeSubAttribute } from "helpers/compoundAttribute.helper"
import {
  getStickyInsightsIds,
  reorderStickyInsights,
  toggleInsightsSticky,
} from "helpers/insight.helper"
import Tippy from "@tippyjs/react"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

class Insights extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      attributeAggregations: null,
      aggregationValues: null,
      customers: {
        loading: true,
        count: null,
      },
      expandedInsight: {
        dataReady: false,
        data: null,
        open: false,
      },
    }
    this.pendingPromises = new PendingPromise()
    this.loadingTimeout = null
  }

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout)
    this.loadingTimeout = null
    this.pendingPromises.cancelAll()
    this.context.off("segment_aggregations_response")
  }

  componentDidMount() {
    const { hideLongLoadingBar, showToast } = this.props

    // wss
    this.context.on("segment_aggregations_response", msg => {
      hideLongLoadingBar("insights")
      if (_has(msg, "error")) {
        showToast(msg.error, TOAST.TYPE.ERROR)
      } else {
        if (msg.segment_id === null) {
          // so it's data insight
          if (Map.isMap(this.state.aggregationValues)) {
            this.setState(prevState => ({
              aggregationValues: prevState.aggregationValues.set(
                msg.segment_aggregation.attribute_aggregation_id.toString(),
                msg.segment_aggregation,
              ),
            }))
          } else {
            this.setState({
              aggregationValues: Map({
                [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
              }),
            })
          }
        }
      }
    })

    const attributesAggregationsRequest = this.pendingPromises.create(
      this.fetchAllAttributesAggregations(),
    )
    attributesAggregationsRequest.promise
      .then(response => {
        this.setState({
          attributeAggregations: response,
        })
        this.pendingPromises.remove(attributesAggregationsRequest)
      })
      .catch(error => {
        this.pendingPromises.remove(attributesAggregationsRequest)
      })

    this.fetchAllAggregationsValues()

    const customersCountRequest = this.pendingPromises.create(api.customersCount())

    customersCountRequest.promise
      .then(response => {
        this.setState({
          customers: {
            loading: false,
            count: response.customer_entities_count,
          },
        })
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState(prevState => ({
            customers: {
              ...prevState.customers,
              loading: false,
            },
          }))
        }
        this.pendingPromises.remove(customersCountRequest)
      })
  }

  fetchAllAggregationsValues = () => {
    const { showLongLoadingBar } = this.props

    this.loadingTimeout = setTimeout(() => {
      if (this.state.aggregationValues === null) {
        showLongLoadingBar("insights")
      }
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = null
    }, 5000)

    this.context.emit("segment_aggregations", { segment_id: null })
  }

  fetchAllAttributesAggregations = async () => {
    const caller = new AllResourceItemsFetcher()
    const data = await caller
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api.attributesAggregations.list(offset, limit, "order_index", "ASC", loadFullStructure),
      )
      .setDataPath("attribute_aggregations")
      .setLoadFullStructure(0)
      .run()
    if (data.length === 0) {
      this.setState({
        aggregationValues: [],
      })
    }
    return List(data)
  }

  toggleInsightModal = (insight, value, percentage, attributePercentage, subAttribute) => () => {
    const { attributesMapById } = this.props
    const { expandedInsight } = this.state
    if (expandedInsight.open) {
      this.setState({
        expandedInsight: {
          ...expandedInsight,
          open: false,
        },
      })
    } else if (insight) {
      this.setState({
        expandedInsight: {
          dataReady: true,
          data: {
            insight: insight,
            value,
            attribute: attributesMapById.get(insight.attribute_id),
            percentage,
            attributePercentage,
            subAttribute,
          },
          open: true,
        },
      })
    }
  }

  render() {
    const {
      areAttributesFulfilled,
      attributesMapById,
      searchTerm,
      filteredSource,
      customersCount,
      authenticatedUser,
    } = this.props
    const { attributeAggregations, aggregationValues, customers, expandedInsight } = this.state

    let resultAttributeAggregations = attributeAggregations
    if (searchTerm) {
      resultAttributeAggregations = attributeAggregations.filter(item => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase())
      })
    }

    if (filteredSource) {
      resultAttributeAggregations = resultAttributeAggregations.filter(item => {
        return attributesMapById.getIn([item.attribute_id, "source", "id"]) === filteredSource.value
      })
    }

    const stickyInsightsIds = getStickyInsightsIds(authenticatedUser.data.frontend_settings)
    resultAttributeAggregations = reorderStickyInsights(
      resultAttributeAggregations,
      stickyInsightsIds,
    )

    const dataSize = List.isList(attributeAggregations) ? attributeAggregations.size : 0
    return (
      <React.Fragment>
        <section className="wrapper insights">
          <PaperHeader className="insights-header" size="small">
            <h3 className="title">
              Insights
              <Tippy
                content="The Insights tab shows you a summary of the audience. Only an administrator can
                  edit how the attributes and conditions are calculated within the Insights tab."
              >
                <span className="info-tooltip-icon">
                  <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            </h3>
            <div className="insights-filter-form">
              <InsightsFilterForm />
            </div>
            <div className="layout-customers-count-wrapper">
              {_isNumber(customersCount) && (
                <SegmentationNumbers
                  totalNumber={customersCount}
                  segmentedNumber={customersCount}
                />
              )}
            </div>
          </PaperHeader>
          {!areAttributesFulfilled && <LoadingIndicator className="loading-indicator" />}
          {areAttributesFulfilled && dataSize > 0 && (
            <div className="tiles-section">
              {resultAttributeAggregations.map((item, index) => {
                const aggregationValueObj = aggregationValues?.get(item.id.toString())
                let aggregationValue = null
                let percentage = null
                if (aggregationValueObj) {
                  if (_isPlainObject(aggregationValueObj.result)) {
                    if (_has(aggregationValueObj, "result.value")) {
                      aggregationValue = aggregationValueObj.result.value
                    } else if (_has(aggregationValueObj, "result.count")) {
                      aggregationValue = aggregationValueObj.result.count
                    }
                    percentage = aggregationValueObj.result.segment_percentage
                  } else {
                    aggregationValue = aggregationValueObj.result
                  }
                }
                if (_isArray(aggregationValue) && aggregationValue.length) {
                  if (_isPlainObject(aggregationValue[0])) {
                    const tmp = [...aggregationValue]
                    percentage = []
                    aggregationValue = []
                    tmp.forEach(obj => {
                      if (_has(obj, "value")) {
                        aggregationValue.push(obj.value)
                      } else if (_has(obj, "count")) {
                        aggregationValue.push(obj.count)
                      }
                      percentage.push(obj.segment_percentage)
                    })
                  }
                }
                const attribute = attributesMapById.get(item.attribute_id)
                const subAttribute =
                  attribute && item.sub_attribute_id
                    ? getCompoundAttributeSubAttribute(item.sub_attribute_id, attribute.data_type)
                    : null
                const compareValue =
                  item.settings?.value_from && item.settings?.value_to
                    ? [item.settings.value_from, item.settings.value_to]
                    : item.settings?.value
                return (
                  <InsightTile
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    value={aggregationValue}
                    compareValue={compareValue}
                    attribute={attribute}
                    subAttribute={subAttribute}
                    percentage={percentage}
                    funcType={item.function}
                    outOf={customers.count}
                    className="insights-tile"
                    color={_get(item, "frontend_settings.color")}
                    attributePercentage={aggregationValueObj?.customers_with_attribute_percentage}
                    showNewBadge={moment().diff(item.created, "days") < 8}
                    displayType={_get(item, "frontend_settings.tile_type", "chart")}
                    onExpandClick={this.toggleInsightModal(
                      item,
                      aggregationValue,
                      percentage,
                      aggregationValueObj?.customers_with_attribute_percentage,
                      subAttribute,
                    )}
                    isLoading={!aggregationValueObj}
                    isSticky={stickyInsightsIds.includes(item.id)}
                    onStickyClick={toggleInsightsSticky(item)}
                  />
                )
              })}
            </div>
          )}
          {areAttributesFulfilled && List.isList(attributeAggregations) && dataSize === 0 && (
            <Paper hasHeader className="insights-no-content">
              {hasAccess.setup.insights() && (
                <p className="info-message">
                  Insights not set. Go to Setup / Insights tab to create insights.
                </p>
              )}
              {!hasAccess.setup.insights() && (
                <p className="info-message">Insights not set. Contact admin to set insights.</p>
              )}
            </Paper>
          )}
          {dataSize > 0 && resultAttributeAggregations.size === 0 && (
            <Paper hasHeader className="insights-no-content">
              <p className="info-message">Nothing found.</p>
            </Paper>
          )}
        </section>
        <ExpandedInsightTile
          open={expandedInsight.open}
          dataReady={expandedInsight.dataReady}
          handleClose={this.toggleInsightModal()}
          attribute={_get(expandedInsight, "data.attribute")}
          subAttribute={_get(expandedInsight, "data.subAttribute")}
          name={_get(expandedInsight, "data.insight.name")}
          description={_get(expandedInsight, "data.insight.description")}
          showNewBadge={
            expandedInsight.data
              ? moment().diff(_get(expandedInsight, "data.insight.created"), "days") < 8
              : false
          }
          compareValue={
            _get(expandedInsight, "data.insight.settings.value_from") &&
            _get(expandedInsight, "data.insight.settings.value_to")
              ? [
                  _get(expandedInsight, "data.insight.settings.value_from"),
                  _get(expandedInsight, "data.insight.settings.value_to"),
                ]
              : _get(expandedInsight, "data.insight.settings.value")
          }
          color={_get(expandedInsight, "data.insight.frontend_settings.color")}
          attributePercentage={_get(expandedInsight, "data.attributePercentage")}
          funcType={_get(expandedInsight, "data.insight.function")}
          value={_get(expandedInsight, "data.value")}
          outOf={customers.count}
          percentage={_get(expandedInsight, "data.percentage")}
        />
      </React.Fragment>
    )
  }
}

Insights.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapById: PropTypes.instanceOf(Map).isRequired,
  customersCount: PropTypes.number,
  showToast: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.object.isRequired,
}

const selector = formValueSelector("InsigtsFilter")
const mapStateToProps = (state, ownProps) => ({
  searchTerm: selector(state, "search"),
  filteredSource: selector(state, "select"),
  areAttributesFulfilled: areAttributesFulfilled(state),
  attributesMapById: getAttributesMapById(state),
  customersCount: getCustomersCount(state),
  authenticatedUser: state.authenticatedUser,
})

Insights = connect(mapStateToProps, {
  showLongLoadingBar,
  hideLongLoadingBar,
  showToast,
})(Insights)

export default Insights
