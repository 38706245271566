import { LONG_LOADING_BAR } from "sharedConstants"
import { Map, List, fromJS } from "immutable"
import _mapKeys from "lodash/mapKeys"
import _isNumber from "lodash/isNumber"

const initialState = Map({
  isVisible: false,
  loadingThings: List(),
  customersPerAttributeCounts: Map(),
  customersPerAttributeCountsFetching: true,
  customersCount: null,
  customersCountFetching: false,
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LONG_LOADING_BAR.ACTION.SHOW: {
      return state
        .set("isVisible", true)
        .set(
          "loadingThings",
          state.get("loadingThings").includes(payload)
            ? state.get("loadingThings")
            : state.get("loadingThings").push(payload),
        )
    }
    case LONG_LOADING_BAR.ACTION.HIDE: {
      const loadingThings =
        payload === "all"
          ? List()
          : state.get("loadingThings").filterNot(thing => thing === payload)
      return state.set("isVisible", loadingThings.size !== 0).set("loadingThings", loadingThings)
    }
    case `${LONG_LOADING_BAR.ACTION.LIST_CUSTOMERS_PER_ATTRIBUTE_COUNTS}_PENDING`: {
      return state.set("customersPerAttributeCountsFetching", true)
    }
    case `${LONG_LOADING_BAR.ACTION.LIST_CUSTOMERS_PER_ATTRIBUTE_COUNTS}_FULFILLED`: {
      if (payload.customers_per_attribute_counts && payload.customers_per_attribute_counts.length) {
        return state
          .set("customersPerAttributeCountsFetching", false)
          .set(
            "customersPerAttributeCounts",
            Map(fromJS(_mapKeys(payload.customers_per_attribute_counts, "attribute_id"))),
          )
      } else {
        return state.set("customersPerAttributeCountsFetching", false)
      }
    }
    case `${LONG_LOADING_BAR.ACTION.FETCH_TOTAL_CUSTOMERS_COUNT}_PENDING`: {
      return state.set("customersCountFetching", true)
    }
    case `${LONG_LOADING_BAR.ACTION.FETCH_TOTAL_CUSTOMERS_COUNT}_FULFILLED`: {
      if (_isNumber(payload.customer_entities_count)) {
        return state
          .set("customersCount", payload.customer_entities_count)
          .set("customersCountFetching", false)
      }
      return state.set("customersCountFetching", false)
    }
    default:
      return state
  }
}
