import React, { Component } from "react"
import { connect } from "react-redux"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import IdentityStitchingForm from "../../IdentityStitchingForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { showToast } from "actions/toast.action"
import PropTypes from "prop-types"
import {
  updateStitchingAttribute,
  fetchStitchingAttributeList,
  deleteStitchingAttribute,
} from "actions/stitchingAttribute.action"
import {
  areStitchingAttributesFulfilled,
  getExistingStitchingAttributesIds,
  getStitchingAttributeById,
} from "selectors/stitchingAttribute.selector"
import _noop from "lodash/noop"
import { getEventsArrayForSelect } from "selectors/event.selector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"

import "./IdentityStitchingDetail.scss"

class IdentityStitchingDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
      deleteModal: {
        loading: false,
        open: false,
      },
    }
  }

  componentDidMount() {
    const { areStitchingAttributesFulfilled, fetchStitchingAttributeList } = this.props
    if (!areStitchingAttributesFulfilled) {
      fetchStitchingAttributeList().catch(_noop)
    }
  }

  updateAttributeStitching = async data => {
    if (!this.state.updating) {
      this.setState({ updating: true })
      const {
        history,
        showToast,
        updateStitchingAttribute,
        deleteStitchingAttribute,
        match: {
          params: { id },
        },
      } = this.props
      try {
        const { attribute_id, ...postData } = data
        if (attribute_id !== id && id !== undefined) {
          await deleteStitchingAttribute(id)
        }
        await updateStitchingAttribute(attribute_id, postData)
        showToast("Stitching rule set.")
        history.push(getRoutePath("setup.identity-stitching"))
      } catch {
        this.setState({ updating: false })
      }
    }
  }

  toggleDeleteModal = () => {
    if (!this.state.deleteModal.loading) {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: !prevState.deleteModal.open,
        },
      }))
    }
  }

  deleteStitchingAttribute = async () => {
    const { stitchingAttribute, deleteStitchingAttribute, history, showToast } = this.props
    this.setState(prevState => ({
      deleteModal: {
        ...prevState.deleteModal,
        loading: true,
      },
    }))
    try {
      await deleteStitchingAttribute(stitchingAttribute.attribute_id)
      showToast("Stitching deleted.")
      history.push(getRoutePath("setup.identity-stitching"))
    } catch {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          loading: false,
        },
      }))
    }
  }

  getCurrentStitchingAttributeFormValues = () => {
    const { areStitchingAttributesFulfilled, stitchingAttribute, history, eventsArray } = this.props
    if (areStitchingAttributesFulfilled) {
      if (stitchingAttribute) {
        return {
          ...stitchingAttribute,
          configs: stitchingAttribute.configs.map(config => ({
            ...config,
            event_id: eventsArray.find(({ value }) => value === config.event_id),
          })),
        }
      } else {
        // not found
        if (!this.state.deleteModal.open && !this.state.updating) {
          history.push(getRoutePath("not-found"))
        }
      }
    }
    return null
  }

  render() {
    const {
      history,
      match: {
        params: { id },
      },
      existingStitchingAttributesIds,
    } = this.props
    const { updating, deleteModal } = this.state

    const isCreate = id === undefined
    const formInitialValues = isCreate
      ? { configs: [{}] }
      : this.getCurrentStitchingAttributeFormValues()
    let disabledAttributeIds = existingStitchingAttributesIds
    if (!isCreate) {
      // filter out current displaying from disabled
      disabledAttributeIds = existingStitchingAttributesIds.filter(attrId => attrId !== id)
    }

    return (
      <section className="identity-stitching-detail wrapper">
        <PaperHeader
          size="small"
          titleText={`${isCreate ? "Create" : "Modify"} stitching rule`}
          className="identity-stitching-detail-header"
        >
          {formInitialValues !== null && (
            <div className="header-buttons">
              {!isCreate && (
                <Button size="small" color="white-red" onClick={this.toggleDeleteModal}>
                  <FontAwesomeIcon icon={["fas", "trash-alt"]} className="icon" /> Delete
                </Button>
              )}
              <Button
                size="small"
                color="white"
                onClick={() => {
                  history.push(getRoutePath("setup.identity-stitching"))
                }}
              >
                Cancel
              </Button>
              <RemoteSubmitButton isLoading={updating} formName="IdentityStitchingForm" />
            </div>
          )}
        </PaperHeader>
        {formInitialValues !== null && (
          <Paper hasHeader className="identity-stitching-detail-content">
            <IdentityStitchingForm
              onSubmit={this.updateAttributeStitching}
              initialValues={formInitialValues}
              disabledAttributeIds={disabledAttributeIds}
            />
            <ConfirmModal
              open={deleteModal.open}
              type={MODAL.TYPE.DELETE}
              handleClose={this.toggleDeleteModal}
              handleConfirm={this.deleteStitchingAttribute}
              title="Are you sure?"
              action="delete"
              text="Do you really want to delete these identinty stitching rules?"
              isLoading={deleteModal.loading}
            />
          </Paper>
        )}
      </section>
    )
  }
}

IdentityStitchingDetail.propTypes = {
  showToast: PropTypes.func.isRequired,
  updateStitchingAttribute: PropTypes.func.isRequired,
  fetchStitchingAttributeList: PropTypes.func.isRequired,
  areStitchingAttributesFulfilled: PropTypes.bool.isRequired,
  existingStitchingAttributesIds: PropTypes.array.isRequired,
  stitchingAttribute: PropTypes.object,
  deleteStitchingAttribute: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  areStitchingAttributesFulfilled: areStitchingAttributesFulfilled(state),
  existingStitchingAttributesIds: getExistingStitchingAttributesIds(state),
  stitchingAttribute: getStitchingAttributeById(state, ownProps.match.params.id),
  eventsArray: getEventsArrayForSelect(state),
})

export default connect(mapStateToProps, {
  showToast,
  updateStitchingAttribute,
  fetchStitchingAttributeList,
  deleteStitchingAttribute,
})(IdentityStitchingDetail)
