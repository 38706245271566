import React from "react"
import PropTypes from "prop-types"
import { Record } from "immutable"
import { getIconSrc } from "helpers/image.helper"
import Modal from "../../Modal"
import _toLower from "lodash/toLower"
import _round from "lodash/round"
import _sum from "lodash/sum"
import { getFunctionName, getPercentageText } from "helpers/insight.helper"
import { SEGMENT_ANALYTICS_FUNCTIONS } from "sharedConstants"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { abbreviateNumber } from "helpers/number.helper"
import _maxBy from "lodash/maxBy"
import _isArray from "lodash/isArray"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { shortenText } from "helpers/string.helper"

import HorizontalChart from "./parts/HorizontalChart"
import AttributePercentageChart from "./parts/AttributePercentageChart"

import "./ExpandedInsightTile.scss"
import Tippy from "@tippyjs/react"

const ExpandedInsightTile = ({
  open,
  dataReady,
  handleClose,
  attribute,
  subAttribute,
  name,
  description,
  showNewBadge,
  color,
  attributePercentage,
  funcType,
  outOf,
  value,
  percentage,
  compareValue,
}) => {
  let dataType = ""
  if (attribute) {
    dataType = subAttribute ? subAttribute.data_type : attribute.data_type
  }
  let title = "",
    content = <div />,
    footer = <div />
  if (dataReady) {
    const _valueFontSizeEvaluate = val => {
      const length = val ? val.length : 1000
      if (length <= 17) {
        return "fs-56"
      } else if (length <= 20) {
        return "fs-48"
      } else if (length <= 23) {
        return "fs-40"
      } else if (length <= 27) {
        return "fs-32"
      } else if (length <= 33) {
        return "fs-26"
      } else if (length <= 40) {
        return "fs-22"
      } else if (length <= 50) {
        return "fs-18"
      } else {
        return "fs-14"
      }
    }

    const _valuesFontSizeEvaluate = values => {
      const maxValue = _maxBy(values, v => v.length)
      let fs = _valueFontSizeEvaluate(maxValue)
      return fs
    }

    const _shortenTextInMiddleWithTooltip = text => {
      if (text.length <= 65) {
        return text
      }
      const start = text.substr(0, 30)
      const end = text.substr(-30)
      return (
        <React.Fragment>
          {start}
          <Tippy content={text} placement="bottom">
            <span className="shortening">[...]</span>
          </Tippy>

          {end}
        </React.Fragment>
      )
    }

    const _renderValue = () => {
      if (
        ["date", "datetime"].includes(dataType) &&
        [
          SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST.value,
          SEGMENT_ANALYTICS_FUNCTIONS.LATEST.value,
        ].includes(funcType)
      ) {
        const userFriendlyValue = getUserFriendlyValueFormat(value, dataType)
        const fs = _valueFontSizeEvaluate(userFriendlyValue)
        return <p className={`value ${fs} result-value`}>{userFriendlyValue}</p>
      } else if (
        [
          SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
          SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MIN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MAX.value,
          SEGMENT_ANALYTICS_FUNCTIONS.AVG.value,
          SEGMENT_ANALYTICS_FUNCTIONS.SUM.value,
          SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_TRUE_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_FALSE_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR.value,
          SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value,
        ].includes(funcType)
      ) {
        return <p className="value fs-56 result-value">{abbreviateNumber(value)}</p>
      } else if ([SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value].includes(funcType)) {
        const userFriendlyValues = getUserFriendlyValueFormat(value, dataType)
        const fs = _valuesFontSizeEvaluate(userFriendlyValues)
        return (
          <div className={`unique-values ${fs} result-value`}>
            {_isArray(userFriendlyValues) &&
              userFriendlyValues.map((v, idx) => {
                const val = _shortenTextInMiddleWithTooltip(v, idx)
                if (idx > 0 && userFriendlyValues.length !== idx) {
                  return <React.Fragment key={idx}>, {val}</React.Fragment>
                } else {
                  return <React.Fragment key={idx}>{val}</React.Fragment>
                }
              })}
          </div>
        )
      } else if (
        [
          SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
          SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
        ].includes(funcType)
      ) {
        if (_isArray(value) && value.length) {
          if (value.length === 1) {
            const userFriendlyValue = getUserFriendlyValueFormat(value[0], dataType)
            const fs = _valueFontSizeEvaluate(userFriendlyValue)
            return (
              <p className={`value ${fs} result-value`}>
                {_shortenTextInMiddleWithTooltip(userFriendlyValue)}
              </p>
            )
          } else {
            const toRender = value.map((val, index) => {
              const userFriendlyValue = getUserFriendlyValueFormat(val, dataType)
              const percentageValue = percentage[index]
              const absoluteValue = _round((outOf * percentageValue) / 100)

              return (
                <HorizontalChart
                  key={index}
                  outOf={outOf}
                  percentage={percentageValue}
                  attributePercentage={attributePercentage}
                  value={absoluteValue}
                  color={color}
                  className="chart-list-item"
                  label={userFriendlyValue}
                  tooltipId={index}
                  size="wide"
                />
              )
            })
            if (value.length > 1) {
              const percentageSum = _sum(percentage)
              toRender.push(<hr key="hrline" className="delimiter" />)
              toRender.push(
                <HorizontalChart
                  key="total"
                  outOf={outOf}
                  percentage={percentageSum}
                  attributePercentage={attributePercentage}
                  value={_round((outOf * percentageSum) / 100)}
                  color={color}
                  className="chart-list-item"
                  label="Total"
                  tooltipId={-1}
                  size="wide"
                />,
              )
            }
            return toRender
          }
        }
      }
      return null
    }

    const _renderCompareValue = () => {
      if (compareValue) {
        if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
            SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
            SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
            SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
          ].includes(funcType)
        ) {
          if (["int", "float"].includes(dataType)) {
            return ` ${abbreviateNumber(compareValue)}`
          } else if (["date", "datetime"].includes(dataType)) {
            const dateValueToDisplay = getUserFriendlyValueFormat(compareValue, dataType)
            return dateValueToDisplay !== "Invalid date"
              ? ` ${dateValueToDisplay}`
              : ` ${compareValue}`
          } else if (dataType === "string") {
            if (compareValue.length > 42) {
              return (
                <span>
                  {" "}
                  <Tippy content={compareValue}>
                    <span>{shortenText(compareValue, 42)}</span>
                  </Tippy>
                </span>
              )
            } else {
              return <span> {compareValue}</span>
            }
          }
        } else if (
          SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value === funcType &&
          compareValue[0] &&
          compareValue[1]
        ) {
          if (["int", "float"].includes(dataType)) {
            return (
              <span>
                {" "}
                {abbreviateNumber(compareValue[0])} and {abbreviateNumber(compareValue[1])}
              </span>
            )
          }
          if (["date", "datetime"].includes(dataType)) {
            const leftValue = getUserFriendlyValueFormat(compareValue[0], dataType)
            const rightValue = getUserFriendlyValueFormat(compareValue[1], dataType)
            return (
              <span>
                {" "}
                {leftValue === "Invalid date" ? compareValue[0] : leftValue} and{" "}
                {rightValue === "Invalid date" ? compareValue[1] : rightValue}
              </span>
            )
          }
        }
      }
      return null
    }

    const _renderPercentageLine = () => {
      if (
        outOf &&
        [
          SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_TRUE_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.IS_FALSE_COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR.value,
          SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value,
        ].includes(funcType)
      ) {
        const percentage = (value / outOf) * 100
        return (
          <p className="out-of">
            {getPercentageText(percentage)} out of {abbreviateNumber(outOf)}
          </p>
        )
      } else if (
        outOf &&
        [
          SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
          SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
          SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
        ].includes(funcType)
      ) {
        let percentageValue = (value / outOf) * 100
        let absoluteValue = value
        if (
          [
            SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
            SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
          ].includes(funcType)
        ) {
          if (!_isArray(value) || value.length !== 1) {
            return null
          } else {
            percentageValue = percentage[0]
            absoluteValue = _round((outOf * percentageValue) / 100)
          }
        }
        return (
          <div>
            <p className="out-of">
              {getPercentageText(percentageValue)} out of {abbreviateNumber(outOf)}
            </p>
            <HorizontalChart
              outOf={outOf}
              percentage={percentageValue}
              attributePercentage={attributePercentage}
              value={absoluteValue}
              color={color}
              className="chart-margin"
            />
          </div>
        )
      }
      return null
    }

    const _renderAttributeValue = () => {
      const exactCount = _round(outOf * (attributePercentage / 100))
      return `${abbreviateNumber(exactCount)} (${getPercentageText(attributePercentage)})`
    }

    const source = attribute.source
    const functionName = funcType && attribute ? getFunctionName(funcType, dataType) : ""

    if (!color) {
      if (source) {
        color = source.getIn(["frontend_settings", "color"], "primary")
      } else {
        color = "primary"
      }
    }

    title = (
      <span className={`tile-title ${color}`}>
        {source && (
          <img
            src={getIconSrc(
              {
                primary: source.getIn(["frontend_settings", "icon"]),
                secondary: _toLower(source.get("type")),
              },
              source.getIn(["frontend_settings", "alt_icon"]),
            )}
            alt="icon"
            className="source-icon"
          />
        )}
        <span className="name-wrapper">
          <span className="name">{name}</span>
          {showNewBadge && <span className="new">New</span>}
        </span>
        {description && (
          <Tippy content={description}>
            <span className="description-icon">
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </span>
          </Tippy>
        )}
      </span>
    )

    content = (
      <div className="expanded-insight-content">
        <div className="attribute-percentage-chart-part">
          <AttributePercentageChart percentage={attributePercentage} color={color} />
        </div>
        <div className="values-part">
          {attributePercentage === 0 && (
            <p className="no-sufficient-data">There is insufficient data to view this insight.</p>
          )}
          {attributePercentage !== 0 && (
            <React.Fragment>
              <p className="func-name">
                {functionName}
                {_renderCompareValue()}
              </p>
              {_renderValue()}
              {_renderPercentageLine()}
            </React.Fragment>
          )}
        </div>
      </div>
    )

    footer = (
      <div className="attribute-info">
        <p>
          <span className={color}>{attribute.name}</span> is known for{" "}
          <span className={`attribute-percentage ${color}`}>{_renderAttributeValue()}</span> out of{" "}
          <span className="black">{abbreviateNumber(outOf)} (100%)</span>.
        </p>
      </div>
    )
  }

  return (
    <Modal open={open} handleClose={handleClose} className="expanded-insight-tile" title={title}>
      {content}
      {footer}
    </Modal>
  )
}

ExpandedInsightTile.propTypes = {
  open: PropTypes.bool.isRequired,
  dataReady: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  attribute: PropTypes.instanceOf(Record),
  subAttribute: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.string,
  showNewBadge: PropTypes.bool,
  color: PropTypes.string,
  attributePercentage: PropTypes.number,
  outOf: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
  funcType: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  compareValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default ExpandedInsightTile
