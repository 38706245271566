const mapping = {
  home: {
    path: "/",
    title: "Homepage",
  },
  login: {
    path: "/login",
    title: "Login",
  },
  "okta.callback": {
    path: "/okta_login",
    title: "Okta Callback Page",
  },
  logout: {
    path: "/logout",
    title: "Logout",
  },
  "password.reset": {
    path: "/password-reset",
    title: "Password reset",
  },
  "password.set": {
    path: "/set_password",
    title: "Set your password",
  },
  customers: {
    path: "/customers",
    title: "Customers",
  },
  "customers.detail": {
    path: "/customers/:id",
    title: "Detail - Customers",
  },
  segments: {
    path: "/segments",
    title: "Segments",
  },
  "segments.tags": {
    path: "/segments/tags",
    title: "Tags - Segments",
  },
  "segments.featured": {
    path: "/segments/featured",
    title: "Featured segments",
  },
  "segments.featured.detail": {
    path: "/segments/featured/:id",
    title: "Detail - Featured segments",
  },
  "segments.featured.detail.insights": {
    path: "/segments/featured/:id/insights",
    title: "Segment Analytics - Featured segments",
  },
  "segments.featured.detail.exports": {
    path: "/segments/featured/:id/exports",
    title: "Segment Exports - Featured segments",
  },
  "segments.featured.export.detail": {
    path: "/segments/featured/:id/exports/:eid",
    title: "Export detail - Featured segments",
  },
  "segments.smart": {
    path: "/segments/smart",
    title: "Smart segments",
  },
  "segments.smart.detail": {
    path: "/segments/smart/:id",
    title: "Detail - Smart segments",
  },
  "segments.smart.detail.insights": {
    path: "/segments/smart/:id/insights",
    title: "Segment Analytics - Smart segments",
  },
  "segments.smart.detail.exports": {
    path: "/segments/smart/:id/exports",
    title: "Segment Exports - Smart segments",
  },
  "segments.smart.export.detail": {
    path: "/segments/smart/:id/exports/:eid/detail",
    title: "Detail - Smart segment export",
  },
  "segments.detail": {
    path: "/segments/:id",
    title: "Detail - Segments",
  },
  "segments.detail.insights": {
    path: "/segments/:id/insights",
    title: "Segment Analytics - Segments",
  },
  "segments.detail.exports": {
    path: "/segments/:id/exports",
    title: "Segment Exports - Segments",
  },
  "segments.export.detail": {
    path: "/segments/:id/exports/:eid/detail",
    title: "Detail - Segment Export",
  },
  reporting: {
    path: "/reporting",
    title: "Reporting",
  },
  "reporting.reporting": {
    path: "/reporting/reporting",
    title: "Reporting",
  },
  data: {
    path: "/data",
    title: "Data",
  },
  "data.attributes": {
    path: "/data/attributes",
    title: "Attributes - Data",
  },
  "data.events": {
    path: "/data/events",
    title: "Events - Data",
  },
  "data.analytics": {
    path: "/data/diagnostic-dashboard",
    title: "Analytics - Data",
  },
  "data.insights": {
    path: "/data/insights",
    title: "Insights - Data",
  },
  "data.sources-destinations": {
    path: "/data/sources-destinations",
    title: "Sources & Destinations - Data",
  },
  trash: {
    path: "/trash",
    title: "Trash",
  },
  administration: {
    path: "/administration",
    title: "Admin",
  },
  "administration.users": {
    path: "/administration/users",
    title: "Users - Administration",
  },
  "administration.users.detail": {
    path: "/administration/users/:id",
    title: "Detail - Users - Administration",
  },
  "administration.roles": {
    path: "/administration/roles",
    title: "Roles - Administration",
  },
  "administration.settings": {
    path: "/administration/settings",
    title: "Settings - Administration",
  },
  "administration.users-activity": {
    path: "/administration/users-activity",
    title: "Users Activity - Administration",
  },
  setup: {
    path: "/setup",
    title: "Setup",
  },
  "setup.sources": {
    path: "/setup/sources",
    title: "Sources - Setup",
  },
  "setup.sources.create": {
    path: "/setup/sources/create",
    title: "Create source - Setup",
  },
  "setup.sources.detail": {
    path: "/setup/sources/:id",
    title: "Source detail - Setup",
  },
  "setup.events": {
    path: "/setup/events",
    title: "Events - Setup",
  },
  "setup.events.create": {
    path: "/setup/events/create",
    title: "Event create - Setup",
  },
  "setup.events.detail": {
    path: "/setup/events/:id",
    title: "Event detail - Setup",
  },
  "setup.identity-stitching": {
    path: "/setup/identity-stitching",
    title: "Identity Stitching - Setup",
  },
  "setup.identity-stitching.create": {
    path: "/setup/identity-stitching/create",
    title: "Identity Stitching create - Setup",
  },
  "setup.identity-stitching.detail": {
    path: "/setup/identity-stitching/:id",
    title: "Identity Stitching detail - Setup",
  },
  "setup.attributes": {
    path: "/setup/attributes",
    title: "Attributes - Setup",
  },
  "setup.attributes.detail": {
    path: "/setup/attributes/:id",
    title: "Attribute detail - Setup",
  },
  "setup.attributes.create": {
    path: "/setup/attributes/create",
    title: "Attribute create - Setup",
  },
  "setup.labels": {
    path: "/setup/labels",
    title: "Labels - Setup",
  },
  "setup.segments.smart.detail.builder": {
    path: "/setup/smart-segments/:id/builder",
    title: "Smart Segment Detail - Setup",
  },
  "setup.segments.smart.detail.exports": {
    path: "/setup/smart-segments/:id/exports",
    title: "Smart Segment Detail - Setup",
  },
  "setup.segments.smart.detail": {
    path: "/setup/smart-segments/:id",
    title: "Smart Segment Detail - Setup",
  },
  "setup.segments.smart": {
    path: "/setup/smart-segments",
    title: "Smart Segments - Setup",
  },
  "setup.insights": {
    path: "/setup/insights",
    title: "Insights - Setup",
  },
  "setup.destinations": {
    path: "/setup/destinations",
    title: "Destinations - Setup",
  },
  "setup.destinations.detail": {
    path: "/setup/destinations/:id",
    title: "Destination detail - Setup",
  },
  "setup.reports": {
    path: "/setup/reports",
    title: "Reports - Setup",
  },
  "setup.reports.create": {
    path: "/setup/reports/create",
    title: "Report create - Setup",
  },
  "setup.reports.detail": {
    path: "/setup/reports/:id",
    title: "Report detail - Setup",
  },
  personalization: {
    path: "/personalization",
    title: "Personalization",
  },
  "personalization.popup-web-banners": {
    path: "/personalization/popup-web-banners",
    title: "Popup Web Banners",
  },
  "personalization.popup-web-banners.create": {
    path: "/personalization/popup-web-banners/create",
    title: "Create - Popup Web Banners",
  },
  "personalization.popup-web-banners.detail": {
    path: "/personalization/popup-web-banners/:id",
    title: "Detail - Popup Web Banners",
  },
  "personalization.embedded-web-banners": {
    path: "/personalization/embedded-web-banners",
    title: "Embedded Web Banners",
  },
  "personalization.embedded-web-banners.create": {
    path: "/personalization/embedded-web-banners/create",
    title: "Create - Embedded Web Banners",
  },
  "personalization.embedded-web-banners.detail": {
    path: "/personalization/embedded-web-banners/:id",
    title: "Detail - Embedded Web Banners",
  },
  "personalization.mobile-push-notifications": {
    path: "/personalization/mobile-push-notifications",
    title: "Mobile Push Notifications",
  },
  "personalization.promo-codes": {
    path: "/personalization/codes",
    title: "Promo Codes",
  },
  "user-settings": {
    path: "/user-settings",
    title: "User Settings",
  },
  "not-found": {
    path: "/not-found",
    title: "404 - Not found",
  },
  "not-authorized": {
    path: "/not-authorized",
    title: "403 - Not authorized",
  },
}

export const getRoutesMapping = () => mapping

export const getRoutePath = (...args: Parameters<typeof getRoute>) => getRoute(...args).path

// export const getRouteRegex = (name: Parameters<typeof getRoute>[0]) => getRoute(name).regex

export const getRoute = (name: keyof typeof mapping, params = {}, prefix = "") => {
  let route = { ...mapping[name] }
  route.path = replaceParamsInPath(route.path, params)
  route.path = applyPrefixToPath(route.path, prefix)
  return route
}

export const replaceParamsInPath = (path: string, params: { [key: string]: string } = {}) => {
  const paramsKeys = Object.keys(params)
  if (!paramsKeys.length) {
    return path
  }

  paramsKeys.forEach(property => {
    const reg = new RegExp(`:${property}(?![\\w\\d])`, "i")
    path = path.replace(reg, params[property])
  })

  return path
}

export const applyPrefixToPath = (path: string, prefix = "") => {
  if (!prefix.length) {
    return path
  }

  return `${prefix}${path}`
}
