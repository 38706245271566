type FrequencyCapTypeName = "session" | "hours" | "days"
type FrequencyCapType<T extends FrequencyCapTypeName> = {
  label: T
  value: T
}
export type FrequencyCapTypeOption = FrequencyCapType<"session" | "hours" | "days">
export const FREQUENCY_CAP_TYPE_OPTIONS: FrequencyCapTypeOption[] = [
  { label: "session", value: "session" },
  { label: "hours", value: "hours" },
  { label: "days", value: "days" },
]
