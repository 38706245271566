import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Record, Map } from "immutable"
import Tag from "components/UI/elements/Tag"
import { getAttributesMapById, areAttributesFulfilled } from "selectors/attributes.selector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Modal from "components/UI/elements/Modal"
import _isNil from "lodash/isNil"
import SimpleBar from "simplebar-react"

import "./DestinationAttributes.scss"

class DestinationAttributes extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  toggleModalOpen = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }))
  }

  renderModalAttributes = attributeIds => {
    const { attributes } = this.props
    if (_isNil(attributeIds) || attributeIds.size === 0) {
      return "—"
    }
    return (
      <div className="modal-attributes">
        {attributeIds.map(attrId => {
          const attribute = attributes.get(attrId)
          if (!attribute) {
            return (
              <Tag color="white-red" key={attrId}>
                N/A attribute: {attrId}
              </Tag>
            )
          } else {
            return (
              <Tag color={attribute.is_hidden === 1 ? "white-red" : "white"} key={attribute.id}>
                {attribute.is_hidden === 1 && (
                  <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye-icon" />
                )}
                {attribute.getIn(["source", "name"])}:{attribute.get("name")}
              </Tag>
            )
          }
        })}
      </div>
    )
  }

  renderMandatoryOperator = () => {
    const { destination } = this.props
    const operatorValue = destination.getIn(["settings", "mandatory_attributes_operator"], "or")
    return (
      <div className="operator-vis">
        <span className={`and ${operatorValue === "and" ? "checked" : ""}`}>and</span>
        <span className={`or ${operatorValue === "or" ? "checked" : ""}`}>or</span>
      </div>
    )
  }

  render() {
    const { destination, showAttributes, attributes, areAttributesFulfilled } = this.props
    const { modalOpen } = this.state
    if (!areAttributesFulfilled) {
      return null
    }
    const attributeIdsToShow =
      showAttributes === "exported"
        ? destination.attribute_ids
        : destination.mandatory_attribute_ids

    if (!attributeIdsToShow || attributeIdsToShow.size === 0) {
      return "—"
    } else {
      const firstAttribute = attributes.get(attributeIdsToShow.get(0))
      const elements = []
      if (!firstAttribute) {
        elements.push(
          <Tag color="white-red" key={attributeIdsToShow.get(0)} onClick={this.toggleModalOpen}>
            N/A attribute: {attributeIdsToShow.get(0)}
          </Tag>,
        )
      } else {
        elements.push(
          <Tag
            color={firstAttribute.is_hidden === 1 ? "white-red" : "white"}
            key={firstAttribute.id}
            onClick={this.toggleModalOpen}
          >
            {firstAttribute.is_hidden === 1 && (
              <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye-icon" />
            )}
            {firstAttribute.getIn(["source", "name"])}:{firstAttribute.get("name")}
          </Tag>,
        )
      }
      if (attributeIdsToShow.size > 1) {
        elements.push(
          <Tag color="white" key="more_attributes" onClick={this.toggleModalOpen}>
            + {attributeIdsToShow.size - 1} more
          </Tag>,
        )
      }
      return (
        <div className="destination-attributes">
          {elements}
          <Modal
            open={modalOpen}
            handleClose={this.toggleModalOpen}
            size="large"
            title={destination.name}
            className="destination-attributes-modal"
          >
            <SimpleBar className="scrollable">
              <h4>Exported attributes</h4>
              {this.renderModalAttributes(destination.attribute_ids)}
              <h4 className="mandatory-title">Mandatory attributes</h4>
              <div className="mandatory-attributes-wrapper">
                {destination.mandatory_attribute_ids &&
                  destination.mandatory_attribute_ids.size > 1 &&
                  this.renderMandatoryOperator()}
                {this.renderModalAttributes(destination.mandatory_attribute_ids)}
              </div>
            </SimpleBar>
          </Modal>
        </div>
      )
    }
  }
}

DestinationAttributes.propTypes = {
  destination: PropTypes.instanceOf(Record).isRequired,
  showAttributes: PropTypes.string.isRequired,
  attributes: PropTypes.instanceOf(Map).isRequired,
  areAttributesFulfilled: PropTypes.bool.isRequired,
  showHiddenAttributes: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => ({
  attributes: getAttributesMapById(state, ownProps.showHiddenAttributes ? true : false),
  areAttributesFulfilled: areAttributesFulfilled(state),
})

export default connect(mapStateToProps)(DestinationAttributes)
