import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Map, List, Record } from "immutable"
import moment from "moment"
import PropTypes from "prop-types"
import _noop from "lodash/noop"
import _isNil from "lodash/isNil"
import _includes from "lodash/includes"
import _get from "lodash/get"
import _findIndex from "lodash/findIndex"
import Linkify from "react-linkify"
import Waypoint from "react-waypoint"

// selectors
import { getAttributesMapById, areAttributesFulfilled } from "selectors/attributes.selector"
import { getSegmentExportData } from "resources/segment/segmentExport/segmentExportSelectors"

// ui
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import StatusElement from "components/UI/elements/StatusElement"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

// actions
import { retrieveSegmentExport } from "resources/segment/segmentExport/segmentExportActions"

// models
import SelectionSettingsModel from "models/selectionSettings.model"

// helpers, constants
import { MOMENT } from "sharedConstants"
import { OPERATION_LABEL_MAPPER } from "resources/segment/segment/utilities/segmentOperationsConstants"
import { SEGMENT } from "sharedConstants"
import { goBackInHistory } from "helpers/backButton.helper"
import { getRoutePath } from "routes"
import { api } from "api"
import {
  parseConditionsOperationObject,
  getSegmentsConditionsDescription,
} from "resources/segment/segment/utilities/segmentConditionsUtils"
import PendingPromise from "helpers/pendingPromise.helper"
import Username from "helpers/Username.helper"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { isAttributeCompound } from "helpers/compoundAttribute.helper"
import { charactersMap } from "helpers/charactersMap.helper"

import "./SegmentExportLog.scss"

class SegmentExportLog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: new List(),
      operations: new List(),
      conditionsDescription: "",
      logs: Map({
        isLoading: false,
      }),
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      segmentExport,
      retrieveSegmentExport,
      match: {
        params: { id, eid },
      },
    } = this.props

    if (_isNil(segmentExport)) {
      retrieveSegmentExport(id, eid)
        .then(response => {
          if (_includes(["waiting", "running"], _get(response, "value.status"))) {
            this.intervalId = setInterval(this.refreshPageData, SEGMENT.EXPORT.REFRESH_INTERVAL)
          }
        })
        .catch(_noop)
    } else {
      if (_includes(["waiting", "running"], segmentExport.status)) {
        this.intervalId = setInterval(this.refreshPageData, SEGMENT.EXPORT.REFRESH_INTERVAL)
      }
      if (this.props.areAttributesFulfilled) {
        this._parseConditionsOperationObject(
          segmentExport.getIn(["settings", "conditions_operation"]),
        )
      }
    }

    // retrieve logs
    this.setState({
      logs: Map({ isLoading: true }),
    })
    const logsRequest = this.pendingPromises.create(
      this.fetchExportLogs(0, SEGMENT.EXPORT.LOG.ITEMS_PER_PAGE, true),
    )
    logsRequest.promise
      .then(response => {
        this.setState({
          logs: Map({
            data: List(response.segment_export_logs),
            selectionSettings: new SelectionSettingsModel(response.selection_settings),
            hasMoreItems: response.segment_export_logs.length === SEGMENT.EXPORT.LOG.ITEMS_PER_PAGE,
            isLoading: false,
          }),
        })
        this.pendingPromises.remove(logsRequest)
      })
      .catch(() => {
        this.pendingPromises.remove(logsRequest)
      })
  }

  componentDidUpdate(prevProps) {
    const { segmentExport, areAttributesFulfilled } = this.props
    if (
      (prevProps.segmentExport === null && segmentExport && areAttributesFulfilled) ||
      (!prevProps.areAttributesFulfilled && areAttributesFulfilled && segmentExport)
    ) {
      this._parseConditionsOperationObject(
        segmentExport.getIn(["settings", "conditions_operation"]),
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.intervalId = null
    this.pendingPromises.cancelAll()
  }

  fetchExportLogs = async (offset, limit, showLoading = false) => {
    const {
      match: {
        params: { id, eid },
      },
    } = this.props

    const response = await api.segment.export.logs
      .list(id, eid, offset, limit, 0, "id", "DESC")
      .catch(err => {
        throw err
      })

    return response
  }

  refreshPageData = () => {
    const {
      retrieveSegmentExport,
      match: {
        params: { id, eid },
      },
    } = this.props
    if (!this.state.logs.get("isLoading")) {
      retrieveSegmentExport(id, eid, 0)
        .then(response => {
          this.refreshLogsData()
          if (!_includes(["waiting", "running"], _get(response, "value.status"))) {
            clearInterval(this.intervalId)
            this.intervalId = null
            return
          }
        })
        .catch(_noop)
    }
  }

  refreshLogsData = () => {
    if (!this.state.logs.get("isLoading")) {
      this.setState(prevState => ({
        logs: prevState.logs.set("isLoading", true),
      }))

      const logsRequest = this.pendingPromises.create(this.fetchExportLogs(0, 10, false))
      logsRequest.promise
        .then(response => {
          const lastLogItem = this.state.logs.getIn(["data", 0])
          if (lastLogItem) {
            const index = _findIndex(response.segment_export_logs, {
              id: lastLogItem.id,
            })
            if (index === -1) {
              this.setState(prevState => ({
                logs: prevState.logs
                  .set("data", prevState.logs.get("data").unshift(...response.segment_export_logs))
                  .set(
                    "selectionSettings",
                    prevState.logs
                      .get("selectionSettings")
                      .set(
                        "offset",
                        prevState.logs.getIn(["selectionSettings", "offset"]) +
                          response.segment_export_logs.length,
                      ),
                  )
                  .set("isLoading", false),
              }))
            } else {
              this.setState(prevState => ({
                logs: prevState.logs
                  .set(
                    "data",
                    prevState.logs
                      .get("data")
                      .unshift(...response.segment_export_logs.slice(0, index)),
                  )
                  .set(
                    "selectionSettings",
                    prevState.logs
                      .get("selectionSettings")
                      .set("offset", prevState.logs.getIn(["selectionSettings", "offset"]) + index),
                  )
                  .set("isLoading", false),
              }))
            }
          } else {
            this.setState(prevState => ({
              logs: prevState.logs
                .set("data", List(response.segment_export_logs))
                .set(
                  "selectionSettings",
                  prevState.logs
                    .get("selectionSettings")
                    .set(
                      "offset",
                      prevState.logs.getIn(["selectionSettings", "offset"]) +
                        response.segment_export_logs.length,
                    ),
                )
                .set("isLoading", false),
            }))
          }
          this.pendingPromises.remove(logsRequest)
        })
        .catch(err => {
          if (_get(err, "isCanceled", false) === false) {
            clearInterval(this.intervalId)
            this.intervalId = null
          }
          this.pendingPromises.remove(logsRequest)
        })
    }
  }

  loadMoreLogs = () => {
    if (!this.state.logs.get("isLoading")) {
      this.setState(prevState => ({
        logs: prevState.logs.set("isLoading", true),
      }))

      const { logs } = this.state
      const logsRequest = this.pendingPromises.create(
        this.fetchExportLogs(
          logs.getIn(["selectionSettings", "offset"]) + logs.getIn(["selectionSettings", "limit"]),
          SEGMENT.EXPORT.LOG.ITEMS_PER_PAGE,
          true,
        ),
      )
      logsRequest.promise
        .then(response => {
          const lastLogItem = logs.get("data").last()
          const index = _findIndex(response.segment_export_logs, {
            id: lastLogItem.id,
          })

          this.setState(prevState => ({
            logs: prevState.logs
              .set(
                "data",
                prevState.logs
                  .get("data")
                  .concat(
                    response.segment_export_logs.slice(
                      index + 1,
                      response.segment_export_logs.length,
                    ),
                  ),
              )
              .set(
                "selectionSettings",
                new SelectionSettingsModel({
                  ...response.selection_settings,
                  offset: response.selection_settings.offset + index + 1,
                }),
              )
              .set(
                "hasMoreItems",
                response.segment_export_logs.length === SEGMENT.EXPORT.LOG.ITEMS_PER_PAGE,
              )
              .set("isLoading", false),
          }))
          this.pendingPromises.remove(logsRequest)
        })
        .catch(() => {
          this.pendingPromises.remove(logsRequest)
        })
    }
  }

  renderWaypoint = () => {
    const { logs } = this.state

    if (logs.get("hasMoreItems") && !logs.get("isLoading")) {
      return <Waypoint onEnter={this.loadMoreLogs} bottomOffset={-300} />
    } else if (logs.get("isLoading") && logs.get("hasMoreItems")) {
      return <LoadingIndicator />
    }
  }

  _parseConditionsOperationObject = conditionsOperation => {
    if (conditionsOperation?.size > 0 && this.props.areAttributesFulfilled) {
      const { segmentExport, attributesMapById } = this.props
      const filtersAndOperations = parseConditionsOperationObject(
        conditionsOperation,
        attributesMapById,
        segmentExport.getIn(["frontend_settings", "operations_between_conditions"], List()),
      )
      const conditionsDescription = getSegmentsConditionsDescription(
        filtersAndOperations.filters,
        filtersAndOperations.operations,
      )
      this.setState({
        ...filtersAndOperations,
        conditionsDescription,
      })
    }
  }

  addOperationsNode = () => {
    if (this.props.areAttributesFulfilled) {
      const { filters, operations } = this.state
      const { attributesMapById } = this.props

      return filters.map((filter, index) => {
        const conditionCharacter = charactersMap[index]
        const attribute = attributesMapById.find(function (obj) {
          return obj.get("id") === filter.get("attribute_id")
        })
        const operation = operations.get(index)
        let attributeNode = null

        if (attribute && isAttributeCompound(attribute.data_type)) {
          const subFilters = filter.get("subFilters")
          const operationsBetweenSubFilters = filter.get("operations")

          const attributeText = `${attribute.source.get("name")}: ${attribute.name}`
          const attributeSubFiltersNode = subFilters.map((subFilter, subFilterIndex) => {
            const subFilterConditions = subFilter.get("conditions")
            const subFilterOperations = subFilter.get("operations")
            const subAttribute = subFilter.get("subattribute")

            const subFilterNode = subFilterConditions.map((condition, conditionIndex) => {
              const value = condition.get("value")
              let valueText = "-"
              if (_isNil(value)) {
                valueText = "–"
              } else {
                const valueType = subAttribute.get("data_type")
                if (List.isList(value)) {
                  valueText = value.map(v => getUserFriendlyValueFormat(v, valueType)).join(", ")
                } else {
                  valueText = getUserFriendlyValueFormat(value)
                }
              }

              const subAttributeRow = (
                <tr>
                  <td
                    className={`sub-attribute-name ${
                      conditionIndex !== 0 ? "no-sub-attribute" : ""
                    }`}
                  >
                    {conditionIndex === 0 ? subAttribute.get("name") : null}
                  </td>
                  <td className="sub-attribute-operation">
                    {OPERATION_LABEL_MAPPER[condition.get("operation")]}
                  </td>
                  <td className="sub-attribute-value">{valueText}</td>
                </tr>
              )

              const lineRow =
                conditionIndex > 0 ? (
                  <tr>
                    <td className="sub-attribute-name no-sub-attribute" />
                    <td className="operation sub-operation" colSpan="2">
                      <div className="operation-container">
                        <hr className="dashed-line" />
                        <div className="operation-lbl">
                          {subFilterOperations.get(conditionIndex - 1)}
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null

              return (
                <React.Fragment key={`sub-attr-condition-${conditionIndex}`}>
                  {lineRow}
                  {subAttributeRow}
                </React.Fragment>
              )
            })

            const lineRow =
              subFilterIndex > 0 ? (
                <tr>
                  <td colSpan="3" className="operation sub-filter-operation">
                    <div className="operation-container">
                      <hr className="dashed-line" />
                      <div className="operation-lbl">
                        {operationsBetweenSubFilters.get(subFilterIndex - 1)}
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null

            return (
              <React.Fragment key={`sub-attr-${subFilterIndex}`}>
                {lineRow}
                {subFilterNode}
              </React.Fragment>
            )
          })

          if (operation) {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan="3" className="attribute-name compound">
                    <span>{conditionCharacter}</span> {attributeText}
                  </td>
                </tr>
                {attributeSubFiltersNode}
                <tr>
                  <td colSpan="3" className="operation">
                    <div className="operation-container">
                      <hr className="dashed-line" />
                      <div className="operation-lbl">{operation.get("value")}</div>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan="3" className="attribute-name">
                    <span>{conditionCharacter}</span>
                    {attributeText}
                  </td>
                </tr>
                {attributeSubFiltersNode}
              </React.Fragment>
            )
          }
        } else {
          const conditions = filter.get("conditions")
          const conditionSizeArray = Array.from(Array(conditions ? conditions.size : 0))

          attributeNode = conditionSizeArray.map((val, index) => {
            let attributeText = null
            if (index === 0) {
              if (attribute) {
                attributeText = attribute.source.get("name") + ": " + attribute.name
              } else {
                attributeText = "Attribute doesn't exist"
              }
            }
            const value = filter.getIn(["conditions", index, "value"])
            let valueText
            if (_isNil(value)) {
              valueText = "–"
            } else {
              const valueType = attribute ? attribute.data_type : "string"
              if (List.isList(value)) {
                valueText = value.map(v => getUserFriendlyValueFormat(v, valueType)).join(", ")
              } else {
                valueText = getUserFriendlyValueFormat(value)
              }
            }

            const attributeRow = (
              <tr>
                <td className="attribute-name">
                  {attributeText !== null && <span>{conditionCharacter}</span>}
                  {attributeText}
                </td>
                <td className="attribute-operation">
                  {OPERATION_LABEL_MAPPER[filter.getIn(["conditions", index, "operation"])]}
                </td>
                <td className="attribute-value">{valueText}</td>
              </tr>
            )

            const lineRow =
              index > 0 ? (
                <tr>
                  <td className="attribute-name no-attribute" />
                  <td className="operation sub-operation" colSpan="2">
                    <div className="operation-container">
                      <hr className="dashed-line" />
                      <div className="operation-lbl">{filter.getIn(["operations", index - 1])}</div>
                    </div>
                  </td>
                </tr>
              ) : null

            return (
              <React.Fragment key={`attr-${index}`}>
                {lineRow}
                {attributeRow}
              </React.Fragment>
            )
          })
        }

        if (operation) {
          return (
            <React.Fragment key={index}>
              {attributeNode}
              <tr>
                <td colSpan="3" className="operation">
                  <div className="operation-container">
                    <hr className="dashed-line" />
                    <div className="operation-lbl">{operation.get("value")}</div>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          )
        } else {
          return <React.Fragment key={index}>{attributeNode}</React.Fragment>
        }
      })
    }
  }

  render() {
    const {
      history,
      segmentExport,
      match: {
        params: { id },
        path,
      },
    } = this.props

    const { logs, conditionsDescription } = this.state
    if (!segmentExport) {
      return null
    }

    const hasConditions = Boolean(segmentExport.getIn(["settings", "conditions_operation"]))
    const isSmartSegment = path === "/segments/smart/:id/exports/:eid/detail"
    const isFeaturedSegment = path === "/segments/featured/:id/exports/:eid/detail"
    return (
      <section className="export-log-page wrapper">
        <div className="export-details">
          <div className="export-timeline">
            <PaperHeader size="small" className="timeline-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBackInHistory(
                    history,
                    getRoutePath(
                      isSmartSegment
                        ? "segments.smart.detail"
                        : isFeaturedSegment
                        ? "segments.featured.detail"
                        : "segments.detail",
                      {
                        id,
                      },
                    ),
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <div className="title">Timeline</div>
              </div>
            </PaperHeader>
            {segmentExport.stats != null && (
              <Paper hasHeader={true} className="segment-export-timeline" noPaddingTop>
                <Table className="timeline-table">
                  <Thead stickyHeader="timeline-table-sticky-header">
                    <Th className="head timeline-status">Status</Th>
                    <Th className="date">Date</Th>
                    <Th className="user" textAlignRight>
                      User
                    </Th>
                  </Thead>
                  <Tbody>
                    {segmentExport.stats
                      .get("statuses_history")
                      .entrySeq()
                      .map(status => {
                        return (
                          <Tr key={status[0]}>
                            <Td className="timeline-status">
                              <StatusElement status={status[0]} />
                            </Td>
                            <Td className="date">
                              {moment.utc(status[1]).local().format(MOMENT.DATE_TIME_WITH_SECONDS)}
                            </Td>
                            <Td className="user" textAlignRight>
                              <Username userId={segmentExport.user_id} />
                            </Td>
                          </Tr>
                        )
                      })}
                  </Tbody>
                </Table>
              </Paper>
            )}
          </div>
          <div className="attributes">
            <PaperHeader size="small" className="attributes-header">
              <div className="title">Attributes</div>
            </PaperHeader>
            <Paper hasHeader={true} className="">
              {hasConditions ? (
                <>
                  <div className="conditions-description-wrapper">
                    <p className="conditions-description">{conditionsDescription}</p>
                  </div>
                  <table className="table attribute-table">
                    <thead>
                      <tr>
                        <th className="attribute-name">Name</th>
                        <th className="attribute-operation">Condition</th>
                        <th className="attribute-value">Value</th>
                      </tr>
                    </thead>
                    <tbody>{this.addOperationsNode()}</tbody>
                  </table>
                </>
              ) : (
                <div className="no-conditions-message">No conditions defined.</div>
              )}
            </Paper>
          </div>
        </div>
        <div className="export-logs">
          <PaperHeader
            size="small"
            className="logs-header"
            titleText="Logs"
            subTitleText="Segment export logs are archived after 30 days."
          />
          {List.isList(logs.get("data")) && logs.get("data").size > 0 && (
            <Paper hasHeader={true} noPaddingTop>
              <Table className="logs-table">
                <Thead stickyHeader="logs-table-sticky-header">
                  <Th className="logs-head log-status">Level</Th>
                  <Th className="time logs-head">Time</Th>
                  <Th className="message logs-head">Text</Th>
                </Thead>
                <Tbody>
                  {logs.get("data").map((log, index) => (
                    <Tr key={index}>
                      <Td
                        className={`logs-cell log-status ${log.level === "error" ? "error" : ""}`}
                      >
                        {log.level}
                      </Td>
                      <Td className={`logs-cell time ${log.level === "error" ? "error" : ""}`}>
                        {moment.utc(log.created).local().format(MOMENT.DATE_TIME_WITH_SECONDS)}
                      </Td>
                      <Td className={`logs-cell message ${log.level === "error" ? "error" : ""}`}>
                        <Linkify properties={{ target: "_blank" }}>{log.text}</Linkify>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {this.renderWaypoint()}
            </Paper>
          )}
        </div>
      </section>
    )
  }
}

SegmentExportLog.propTypes = {
  attributesMapById: PropTypes.instanceOf(Map).isRequired,
  segmentExport: PropTypes.instanceOf(Record),
  retrieveSegmentExport: PropTypes.func.isRequired,
  areAttributesFulfilled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const idProps = ownProps.match.params
  return {
    attributesMapById: getAttributesMapById(state),
    segmentExport: getSegmentExportData(state, idProps.id, idProps.eid),
    areAttributesFulfilled: areAttributesFulfilled(state),
  }
}

export default connect(mapStateToProps, { retrieveSegmentExport })(SegmentExportLog)
